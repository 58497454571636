import { OfferingFrameModel } from 'interfaces/database';
import ApiService from 'services/ApiService';

class OfferingFrameService extends ApiService<OfferingFrameModel> {
	constructor() {
		super('offeringframe');
	}
}

export default new OfferingFrameService();
