import {
	Vue, Component, Prop, Watch, toNative,
} from 'vue-facing-decorator';
import Swal from 'sweetalert2';
import * as DB from 'interfaces/database';
import { useRoute } from 'vue-router';
import CustomCheckbox from 'components/CustomCheckbox';
import BadgeOfferingService from 'services/BadgeOfferingService';
import Template from './badgeRow.vue';

@Component({
	components: {
		CustomCheckbox,
	},
	mixins: [Template],
})
class BadgeRow extends Vue {
	private	selected = false;

	private	isLoading = false;

	private badgeoffering = {} as DB.BadgeOfferingModel;

	private spinner = false;

	private route = useRoute();

	@Prop()
		checkbadge!: Array<DB.BadgeOfferingModel>;

	@Prop()
		badg!: DB.BadgeModel;

	get filteredBadges() {
		return (id: number): boolean => this.checkbadge.find((badge) => badge.badgeid === id) !== undefined;
	}

	editBadges(badg: DB.BadgeModel): Promise<DB.BadgeOfferingModel[]> | void {
		// get all offerings from the server
		const data: Pick<DB.BadgeOfferingModel, 'badgeid' | 'offeringid'> = {
			offeringid: Number(this.route.params.id),
			badgeid: badg.id,
		};

		this.isLoading = true;
		// Check if the switch box is checked
		if (this.selected) {
			// Set this region to be available
			BadgeOfferingService.create(
				{ ...data },
			)
				.then((response) => {
					this.badgeoffering = response;
					// Get the activated region's currency
					return this.badgeoffering;
				})
				.finally(() => {
					this.isLoading = false;
				}).catch((err) => {
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: `${err.message}`,
					});
				});
		} else {
			BadgeOfferingService
				.delete(this.badgeoffering.id)
				.then(() => undefined)
				.finally(() => {
					this.isLoading = false;
				}).catch((err) => {
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: `${err.message}`,
					});
				});
		}
	}

	@Watch(
		'checkbadge',
		{ deep: true, immediate: true },
	)
	checkedBadge(): void {
		if (this.filteredBadges(this.badg.id) === true) {
			// set the switch box to checked
			this.selected = true;

			// set the filtered region to region Offering
			this.checkbadge.forEach((element) => {
				this.badgeoffering = element;
			});
		} else {
			// uncheck the switch box
			this.selected = false;
		}
	}
}

export default toNative(BadgeRow);
