<template>
	<base-block
		title="PDPs"
		header-bg
	>
		<loading-overlay
			:active="isLoading"
			:can-cancel="true"
		/>
		<div
			ref="badgepdp"
		/>
	</base-block>
</template>
