import { ReferralProgramModel } from 'interfaces/database';
import ApiService from 'services/ApiService';

class ReferralProgramService extends ApiService<ReferralProgramModel> {
	constructor() {
		super('referralprogram');
	}
}

export default new ReferralProgramService();
