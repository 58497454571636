import { HyperlinkModel } from 'interfaces/database';
import ApiService from 'services/ApiService';

class HyperlinkService extends ApiService<HyperlinkModel> {
	constructor() {
		super('hyperlink');
	}
}

export default new HyperlinkService();
