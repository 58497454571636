import {
	Vue, Component, Ref, toNative,
} from 'vue-facing-decorator';
import DiscountEditTable from 'components/Discounts/DiscountsTable/DiscountsEditTable';
import Template from './template.vue';

@Component({
	components: {
		DiscountEditTable,
	},
	mixins: [Template],
})
class DiscountEdit extends Vue {
	@Ref('editdiscountedit')
	public readonly editproduct!: HTMLFormElement;

	protected updateDiscountEditProperties(): void {
		this.editproduct.singleDiscountEdit();
	}
}

export default toNative(DiscountEdit);
