<template>
	<div
		style="width: 95%"
		class="ml-5"
	>
		<loading-overlay
			:active="isLoading"
			:can-cancel="true"
		/>
		<b-modal
			id="shipping-modal"
			ref="addshipping-modal"
			hide-footer
		>
			<template #modal-title>
				Create New Shipping
			</template>
			<div class="d-block text-center">
				<b-form-group
					class="form-group"
					label="Offering: "
					label-for="block-form1-name"
				>
					<Field
						v-slot="{ errors }"
						v-model="addShippingData.offeringid"
						rules="required"
						name="offering"
					>
						<div
							class="control"
						>
							<b-form-input
								v-model="addShippingData.offeringid"
								type="text"
							/>
							<span>{{ errors[0] }}</span>
						</div>
					</Field>
				</b-form-group>

				<b-form-group
					class="form-group"
					label="Sale: "
					label-for="block-form1-name"
				>
					<Field
						v-slot="{ errors }"
						v-model="addShippingData.sale"
						rules="required"
						name="sale"
					>
						<div
							class="control"
						>
							<b-form-input
								v-model="addShippingData.sale"
								type="text"
							/>
							<span>{{ errors[0] }}</span>
						</div>
					</Field>
				</b-form-group>
				<b-form-group
					label="Country:"
					label-for="example-select"
				>
					<custom-select
						id="example-select"
						v-model="addShippingData.countrycode"
						plain
					>
						<b-form-select-option :value="null">
							Please select an Option
						</b-form-select-option>
						<b-form-select-option
							v-for="(country) in countryModels"
							:key="country.id"
							:value="country.iso"
						>
							{{ country.name }} | {{ country.iso }}
						</b-form-select-option>
					</custom-select>
				</b-form-group>

				<b-form-group
					label="Currency:"
					label-for="example-select"
				>
					<custom-select
						id="example-select"
						v-model="addShippingData.currency"
						plain
					>
						<b-form-select-option :value="null">
							Please select an Option
						</b-form-select-option>
						<b-form-select-option
							v-for="currency in currencyModels"
							:key="currency.id"
							:value="currency.id"
						>
							{{ currency.id }} | {{ currency.name }}
						</b-form-select-option>
					</custom-select>
				</b-form-group>

				<b-form-group
					label="Tracking"
					label-class="font-w600"
					label-cols-lg="5"
				>
					<custom-checkbox
						id="checkbox-1"
						class="trackingCheckbox"
						:is-switch="true"
						size="lg"
						:checked="addShippingData.tracking === 1"
						@change="addShippingData.tracking = $event"
					/>
				</b-form-group>

				<b-form-group
					label="Express"
					label-class="font-w600"
					label-cols-lg="5"
				>
					<custom-checkbox
						id="checkbox-2"
						class="expressCheckbox"
						:is-switch="true"
						size="lg"
						:checked="addShippingData.express === 1"
						@change="addShippingData.express = $event"
					/>
				</b-form-group>

				<b-form-group
					label="Scope:"
					label-for="example-select"
				>
					<custom-select
						id="example-select"
						v-model="addShippingData.scope"
						plain
					>
						<b-form-select-option
							v-for="(opt, id) in options"
							:key="id"
							:value="opt"
						>
							{{ opt }}
						</b-form-select-option>
					</custom-select>
				</b-form-group>

				<b-form-group
					class="form-group"
					label="Days min: "
					label-for="block-form1-name"
				>
					<b-form-input
						v-model="addShippingData.days_min"
						type="text"
					/>
				</b-form-group>

				<b-form-group
					class="form-group"
					label="Days max: "
					label-for="block-form1-name"
				>
					<b-form-input
						v-model="addShippingData.days_max"
						type="text"
					/>
				</b-form-group>
			</div>
			<b-button
				class="mt-3"
				block
				@click="addShipping()"
			>
				Save
			</b-button>
		</b-modal>
		<b-col md="12">
			<b-card
				no-body
			>
				<div
					ref="tableContainer"
				/>
			</b-card>
		</b-col>
	</div>
</template>

<style lang="scss">
.excelColumn {
	padding: 0 !important;

	input {
		border-radius: 0 !important;
	}
}
</style>

<style lang="scss" scoped>
@import "../../assets/scss/custom/errors";

.scroller {
  height: 100% !important;
}
.active-item {
  background-color: red;
}
.offeringImage {
	object-fit: cover;
	max-height: 100%;
}
</style>
