import {
	Vue, Component, Ref, toNative,
} from 'vue-facing-decorator';
import EditModel2dTable from 'components/Model2dTable/EditModel2dTable';
import Template from './EditModel2d.vue';

@Component({
	components: {
		EditModel2dTable,
	},
	mixins: [Template],
})
class EditModel2d extends Vue {
	@Ref('editmodel2d') readonly model2dModal!: HTMLFormElement;

	private isLoading = false;

	get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	updateModel2dProperties(): void {
		this.model2dModal.editModel2dImage();
	}
}

export default toNative(EditModel2d);
