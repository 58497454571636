<template>
	<div class="">
		<base-page-heading
			title="Hyperlinks"
			subtitle=""
			class="d-print-none"
		>
			<template #extra>
				<b-button
					ref="hyperlinkShow"
					type="submit"
					size="md"
					variant="secondary"
					@click="showModal"
				>
					<i class="fa fa-fw fa-plus" /> 	Create new
				</b-button>
			</template>
		</base-page-heading>

		<div class="row">
			<HyperlinksTable @routeHyperlink="routeHyperlink" />
		</div>
	</div>
</template>
