import { FlexGroupModel } from 'interfaces/database';
import ApiService from 'services/ApiService';

class FlexGroupService extends ApiService<FlexGroupModel> {
	constructor() {
		super('flexgroup');
	}
}

export default new FlexGroupService();
