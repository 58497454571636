import {
	Vue, Component, Ref, toNative,
} from 'vue-facing-decorator';
import EditCurrencyTable from 'components/CurrencyTable/EditCurrencyTable';
import Template from './template.vue';

@Component({
	components: {
		EditCurrencyTable,
	},
	mixins: [Template],
})
class EditCurrency extends Vue {
	@Ref('editcurrency') readonly currencyModal!: HTMLFormElement;

	private isLoading = false;

	protected updateCurrencyProperties(): void {
		this.currencyModal.editCurrency();
	}
}

export default toNative(EditCurrency);
