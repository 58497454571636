import {
	Vue, Component, Ref, toNative,
} from 'vue-facing-decorator';
import EditHyperlinksTable from 'components/HyperlinksTable/EditHyperlinksTable';
import Template from './EditHyperlinks.vue';

@Component({
	components: {
		EditHyperlinksTable,
	},
	mixins: [Template],
})
class EditHyperlinks extends Vue {
	@Ref('edithyperlinks') readonly hyperlinksModal!: HTMLFormElement;

	private isLoading = false;

	get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	updateHyperlinksProperties(): void {
		this.hyperlinksModal.editHyperlink();
	}
}

export default toNative(EditHyperlinks);
