<template>
	<select
		:id="id ? id: `custom-select-${_uid}`"
		v-model="selected"
		:class="{ 'custom-select-sm': size === 'sm', 'custom-select-lg': size === 'lg' }"
		class="custom-select custom-select-sm"
		@change="onChange"
	>
		<slot />

		<option
			v-for="option in options"
			:key="option.value"
			:value="option.value"
		>
			{{ option.text }}
		</option>
	</select>
</template>
