import {
	Vue, Component, Prop, toNative,
} from 'vue-facing-decorator';
import * as DB from 'interfaces/database';
import Template from './template.vue';

@Component({
	mixins: [Template],
})
class VatInput extends Vue {
	@Prop({
		type: Object,
		required: true,
	})
	public readonly checkOffering!: Record<number, DB.RegionOfferingModel>;

	@Prop({
		type: Object,
		required: true,
	})
	public readonly data!: DB.OfferingModel;
}

export default toNative(VatInput);
