<template>
	<div style="width: 80vw !important">
		<loading-overlay
			:active="isLoaded"
			:can-cancel="true"
		/>
		<base-block
			title="Discount Vouchers"
			header-bg
		>
			<b-row class="d-flex flex-column justify-content-left">
				<b-col
					sm="5"
					md="9"
					class="my-1"
				>
					<b-button
						class="mt-3"
						:disabled="selectedRows.length == 0"
						@click="table.deselectRow()"
					>
						Deselect all
					</b-button>
					<b-button
						class="mt-3"
						:disabled="selectedRows.length == 0"
						@click="exportSelected()"
					>
						Export Selected
					</b-button>
					<b-button
						class="mt-3"
						@click="exportAll()"
					>
						Export all
					</b-button>
				</b-col>
			</b-row>
			<div
				ref="discountVouchersTable"
			/>
		</base-block>
	</div>
</template>
