import {
	Vue, Component, Ref, toNative,
} from 'vue-facing-decorator';
import * as DB from 'interfaces/database';
import { BModal } from 'bootstrap-vue';
import mitt from 'mitt';
import Swal from 'sweetalert2';
import {
	TabEvent, TranslationsResponse, LanguageOptionsModel, Translation, Translations,
} from 'interfaces/app';
import TabulatorBtn from 'components/Tabulator/TabulatorBtn';
import TabulatorImage from 'components/Tabulator/TabulatorImage';
import {
	CellComponentExtended, ColumnDefinitionExtended, TabulatorFull as Tabulator,
} from 'tabulator-tables';
import { createInstance } from 'utils/vue';
import CustomSelect from 'components/CustomSelect';
import newLineFormatter from 'utils/tabulator';
import LanguageService from 'services/LanguageService';
import CrossSellService from 'services/CrossSellService';
import OfferingService from 'services/OfferingService';
import TranslationService from 'services/TranslationService';
import Template from './template.vue';

const eventBus = mitt<TabEvent<DB.CrossSellModel>>();
@Component({
	components: {
		TabulatorBtn,
		TabulatorImage,
		CustomSelect,
	},
	emits: ['routeCrossSell'],
	mixins: [Template],
})
class CrossSellTable extends Vue {
	@Ref('addcrossSell-modal') readonly modal!: BModal;

	@Ref('crossSellTable')
	private readonly tableReference!: HTMLDivElement;

	private crosssell : DB.CrossSellModel[] = [];

	private name?: DB.CrossSellModel['name'] = '';

	private offerings: DB.OfferingModel[] = [];

	private crosssellid: DB.CrossSellModel['crosssellid'] | null = null;

	private perPage = 20;

	private isLoaded = false;

	private columnDefs: ColumnDefinitionExtended[] = [];

	private translations = {} as Record<number, Translation>;

	private language = '';

	private rowData: DB.CrossSellModel[] = [];

	private languageOptions: LanguageOptionsModel[] = [];

	private table?: Tabulator;

	private get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	protected beforeMount(): void {
		this.columnDefs = [
			{
				field: 'id',
				title: 'ID',
			},
			{
				title: 'Name',
				field: 'name',
			},
			{
				title: 'Header',
				field: 'header',
			},
			{
				title: 'Description',
				field: 'description',
				formatter: newLineFormatter,
			},
			{
				title: 'Actions',
				formatter: (cell: CellComponentExtended<DB.CrossSellModel>) => {
					const instance = createInstance({
						component: TabulatorBtn,
						props: {
							data: cell.getData(),
							buttons: [
								{
									id: 'edit',
									eventName: 'editItem',
									className: 'fa-edit',
								},
								{
									id: 'delete',
									eventName: 'deleteItem',
									className: 'fa-times',
								},
							],
							eventBus,
						},
					});
					return instance;
				},
			},
		] as ColumnDefinitionExtended[];
	}

	protected mounted(): void {
		eventBus.on(
			'deleteItem',
			this.deleteCrossSellItem,
		);
		eventBus.on(
			'editItem',
			this.editCrossSellItem,
		);
		this.tableInitialization();
		this.table?.on(
			'tableBuilt',
			this.onTableBuilt,
		);
		this.getLanguageData();
	}

	protected beforeUnmount(): void {
		eventBus.off(
			'deleteItem',
			this.deleteCrossSellItem,
		);
		eventBus.off(
			'editItem',
			this.editCrossSellItem,
		);
		this.table?.off(
			'tableBuilt',
			this.onTableBuilt,
		);
		this.table?.destroy();
	}

	private onTableBuilt(): void {
		this.getData();
	}

	private tableInitialization(): void {
		this.table = new Tabulator(
			this.tableReference,
			{
				height: '60vh',
				layout: 'fitColumns',
				columns: this.columnDefs,
			},
		);
	}

	private async fetchTranslations(language: string): Promise<TranslationsResponse> {
		const parameter = new URLSearchParams({
			ns: 'cross_sell',
			lng: language,
			limit: '0',
		});

		try {
			const data = await TranslationService.getMultiTranslation(parameter);
			return data;
		} catch (error: any) {
			this.$toastError(error.message);
			return Promise.reject(error);
		}
	}

	private async getData(): Promise<void> {
		const parameter = new URLSearchParams({
			limit: '0',
		});
		this.table?.alert('Loading');
		try {
			const [crossSellData, offeringData] = await Promise.all([
				CrossSellService.getAll(parameter),
				OfferingService.getAll(parameter),
			]);
			this.offerings = offeringData;
			this.rowData = crossSellData;
			this.table?.setData(this.rowData);
		} catch (err: any) {
			this.$toastError(err.message);
		} finally {
			this.table?.clearAlert();
		}
	}

	private async getLanguageData(): Promise<void> {
		try {
			const parameter = new URLSearchParams({
				limit: '0',
			});
			const languageResponse = await LanguageService.getAll(parameter);
			this.languageOptions = languageResponse.map((language) => ({ value: language.id, text: language.name }));
			// get the default language
			const defaultLang = languageResponse.find((language: DB.LanguageModel) => language.default);
			// set the language to the default language or the first language in the list
			this.language = defaultLang ? defaultLang.id : languageResponse[0].id;
			// get the translations for the default language
			const translationsData = await this.fetchTranslations(this.language);

			this.translations = translationsData[this.language].cross_sell as Translations;

			// set the translations for the default language
			this.rowData = this.rowData.map((row) => {
				if (this.translations[row.id] !== undefined) {
					return {
						...row,
						header: this.translations[row.id].name,
						description: this.translations[row.id].lines?.join('\n'),
					};
				}
				return row;
			});

			this.table?.updateData(this.rowData);
		} catch (error: any) {
			this.$toastError(error.message);
		}
	}

	protected async translateTableData(data: string): Promise<void> {
		const translations = await this.fetchTranslations(data);
		this.translations = translations[this.language].cross_sell as Translations;

		this.rowData = this.rowData.map((row) => {
			if (this.translations[row.id] !== undefined) {
				return {
					...row,
					header: this.translations[row.id].name,
					description: this.translations[row.id].lines?.join('\n'),
				};
			}
			return row;
		});

		this.table?.updateData(this.rowData);
	}

	protected addCrossSell(): void {
		this.isLoaded = true;
		CrossSellService.create(
			{
				name: this.name as string,
				crosssellid: this.crosssellid as number,
			},
		).then((res) => {
			this.modal.hide();

			this.crosssell.push(res);
			this.name = '';
			this.$emit(
				'routeCrossSell',
				res.id,
			);
			return undefined;
		})
			.finally(() => {
				this.isLoaded = false;
			})
			.catch((err) => {
				this.$toastError(err.message);
			});
	}

	private editCrossSellItem(data: TabEvent<DB.CrossSellModel>['editItem']): void {
		this.$emit(
			'routeCrossSell',
			data.id,
		);
	}

	private deleteCrossSellItem(data: TabEvent<DB.CrossSellModel>['deleteItem']): void {
		Swal.fire({
			title: 'Are you sure?',
			text: 'You will not be able to recover this file!',
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'btn btn-danger m-1',
				cancelButton: 'btn btn-secondary m-1',
			},
			confirmButtonText: 'Yes, delete it!',
			html: false,
		}).then((result) => {
			if (result.value) {
				this.table?.alert('Loading');
				// eslint-disable-next-line promise/no-nesting
				CrossSellService.delete(data.id).then(() => {
					this.$toastSuccess('Item Deleted');
					this.table?.deleteRow(data.id);
					return null;
				}).finally(() => {
					this.table?.clearAlert();
				}).catch((err) => {
					this.$toastError(err.message);
				});
			}
			return undefined;
		}).catch((err) => {
			this.$toastError(err.message);
		});
	}
}

export default toNative(CrossSellTable);
