import { PricingModel } from 'interfaces/database';
import ApiService from 'services/ApiService';

class PricingService extends ApiService<PricingModel> {
	constructor() {
		super('price');
	}
}

export default new PricingService();
