<template>
	<base-block
		title="Paid Orders"
		header-bg
	>
		<div
			ref="paidOrdersTable"
		/>
	</base-block>
</template>
