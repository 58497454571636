<template>
	<div class="">
		<!-- Hero -->
		<base-page-heading
			title="Themes"
			subtitle=""
			class="d-print-none"
		>
			<template #extra>
				<b-button
					ref="themeimportShow"
					type="submit"
					size="md"
					variant="secondary"
					@click="showImport"
				>
					<i class="fa fa-fw fa-plus" /> 	Import
				</b-button>
				<b-button
					ref="themeShow"
					type="submit"
					size="md"
					variant="secondary"
					@click="showModal"
				>
					<i class="fa fa-fw fa-plus" /> 	Create new
				</b-button>
			</template>
		</base-page-heading>

		<div class="row">
			<ThemesTable
				ref="updateTheme"
				@cloneTheme="cloneTheme"
				@routeTheme="routeTheme"
				@openNewTab="openNewTab"
			/>
		</div>
	</div>
</template>
