<template>
	<div class="custom-file mb-3">
		<input
			:id="`customFile-${_uid}`"
			type="file"
			class="custom-file-input"
			:name="name"
			:disabled="disabled"
			:required="required"
			:state="state"
			:placeholder="placeholder"
			@change="onChange"
			@click="onClick"
		>

		<label
			v-if="label"
			class="custom-file-label"
			:for="`customFile-${_uid}`"
		>
			{{ label }}
		</label>
	</div>
</template>
