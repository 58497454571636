import {
	Vue, Component, Ref, toNative,
} from 'vue-facing-decorator';
import CreateDiscountTable from 'components/Discounts/DiscountsTable/CreateDiscountTable';
import Template from './template.vue';

@Component({
	components: {
		CreateDiscountTable,
	},
	mixins: [Template],
})
class CreateDiscount extends Vue {
	@Ref('createDiscountTable')
	public readonly createDisc!: HTMLFormElement;

	protected createNewDiscount(): void {
		this.createDisc.createDiscount();
	}
}

export default toNative(CreateDiscount);
