<template>
	<div
		style="width: 95%"
		class="ml-5"
	>
		<b-row>
			<b-col
				md="5"
				lg="5"
				sm="12"
			>
				<UserPropertiesBox />
			</b-col>

			<b-col
				md="7"
				lg="7"
				sm="12"
			>
				<ExternalSources
					:route-id="routeId"
				/>
			</b-col>
		</b-row>
		<b-row>
			<b-col
				md="12"
				lg="12"
				sm="12"
			>
				<b-tabs
					class="block"
					nav-class="nav-tabs-alt"
					content-class="block-content"
				>
					<b-tab
						title="PAID ORDERS"
						active
					>
						<PaidOrders
							:route-id="routeId"
						/>
					</b-tab>
					<b-tab
						title="SHOPPING CART ITEMS"
						@click="() => sendUserRequest.shoppingCart = true"
					>
						<ShoppingCartItems
							v-if="sendUserRequest.shoppingCart"
							:shoppingcart="sendUserRequest.shoppingCart"
						/>
					</b-tab>
					<b-tab
						title="PRODUCTS"
						@click="() => sendUserRequest.products = true"
					>
						<Products
							v-if="sendUserRequest.products"
							:product-request="sendUserRequest.products"
						/>
					</b-tab>
					<b-tab
						title="PHOTOS"
						@click="() => sendUserRequest.photos = true"
					>
						<Photos
							v-if="sendUserRequest.photos"
							:photos-request="sendUserRequest.photos"
						/>
					</b-tab>
					<b-tab
						title="USER CREDIT"
						@click="() => sendUserRequest.userCredit = true"
					>
						<UserCredit
							v-if="sendUserRequest.userCredit"
							:route-id="routeId"
							:user-credit-request="sendUserRequest.userCredit"
						/>
					</b-tab>
					<b-tab
						title="USER EMAIL LOGS"
						@click="() => sendUserRequest.userEmailLog = true"
					>
						<UserEmailLog
							v-if="sendUserRequest.userEmailLog"
							:route-id="routeId"
							:user-email-request="sendUserRequest.userEmailLog"
						/>
					</b-tab>
					<b-tab
						title="USER REFERRALS"
						@click="() => sendUserRequest.userReferrals = true"
					>
						<UserReferrals
							v-if="sendUserRequest.userReferrals"
							:route-id="routeId"
							:user-referral-request="sendUserRequest.userReferrals"
						/>
					</b-tab>
				</b-tabs>
			</b-col>
		</b-row>
	</div>
</template>

<style scoped>
.fa-check {
	color: green;
}
</style>
