import { ThemeCategoryModel, ThemeModel } from 'interfaces/database';
import ApiService from 'services/ApiService';
import { httpClient } from 'utils/http';

class ThemeCategoryService extends ApiService<ThemeCategoryModel> {
	constructor() {
		super('themecategory');
	}

	async getAllThemes(id: string | number, parameter?: URLSearchParams): Promise<ThemeModel[]> {
		const response = await httpClient.get(`${this.ENDPOINT}/${id}/themes?${parameter}`);
		return response.data;
	}
}

export default new ThemeCategoryService();
