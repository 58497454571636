import { RegionModel } from 'interfaces/database';
import ApiService from 'services/ApiService';

class RegionService extends ApiService<RegionModel> {
	constructor() {
		super('region');
	}
}

export default new RegionService();
