import { Vue, Component, toNative } from 'vue-facing-decorator';
import Model2dTable from 'components/Model2dTable';
import { useRouter } from 'vue-router';
import Template from './template.vue';

@Component({
	components: {
		Model2dTable,
	},
	mixins: [Template],
})
class Model2d extends Vue {
	private router = useRouter();

	showModal(): void {
		this.$root?.$emit(
			'bv::show::modal',
			'model2d-modal',
			'#model2dShow',
		);
	}

	routeModel2d(id: string): void {
		this.router.push(`/model2d/${id}`);
	}
}

export default toNative(Model2d);
