import { createRouter, createWebHistory } from 'vue-router';
import Offerings from 'views/Offerings';
// import Home from 'views/Home.vue';
import { authGuard } from '@auth0/auth0-vue';
import EditOffering from 'views/Offerings/EditOffering';
import Pricing from 'views/Pricing';
import ProductsOrdered from 'views/ProductsOrdered/index';
import ShowOrder from 'views/ProductsOrdered/ShowOrder';
import OrderItem from 'views/ProductsOrdered/OrderItem';
import UserProperties from 'views/ProductsOrdered/UserProperties';
import Badges from 'views/Offerings/Badges/';
import EditBadges from 'views/Offerings/Badges/EditBadges';
import Pdp from 'views/Offerings/Pdp';
import EditPdp from 'views/Offerings/Pdp/EditPdp';
import Model3d from 'views/Offerings/Model3d';
import EditModel3d from 'views/Offerings/Model3d/EditModel3d';
import Model2d from 'views/Offerings/Model2d';
import EditModel2d from 'views/Offerings/Model2d/EditModel2d';
import ProductCategories from 'views/Offerings/ProductCategories';
import EditProductCategories from 'views/Offerings/ProductCategories/EditProductCategories';
import Hyperlinks from 'views/Localization/Hyperlinks';
import EditHyperlinks from 'views/Localization/Hyperlinks/EditHyperlinks';
import PickupStores from 'views/Localization/PickupStores';
import EditPickUpStores from 'views/Localization/PickupStores/EditPickupStores';
import Region from 'views/Localization/Regions';
import EditRegion from 'views/Localization/Regions/EditRegion';
import Currency from 'views/Localization/Currencies';
import EditCurrency from 'views/Localization/Currencies/EditCurrency';
import Country from 'views/Localization/Country';
import EditCountry from 'views/Localization/Country/EditCountry';
import AppSetting from 'views/Developer/AppSetting';
import ApiPermissions from 'views/Developer/ApiPermissions';
import EditApiPermission from 'views/Developer/ApiPermissions/EditApiPermission';
import CrossSell from 'views/Offerings/CrossSell';
import EditCrossSell from 'views/Offerings/CrossSell/EditCrossSell';
import Photoframe from 'views/Themes/Photoframes';
import EditPhotoframe from 'views/Themes/Photoframes/EditPhotoframe';
import Themes from 'views/Themes/Themes';
import EditTheme from 'views/Themes/Themes/EditTheme';
import ThemeEditPage from 'views/Themes/Themes/ThemeEditPage';
import PageObject from 'views/Themes/Themes/PageObject';
import Layout from 'views/Themes/Layouts';
import EditLayout from 'views/Themes/Layouts/EditLayout';
import Category from 'views/Themes/Category';
import EditCategory from 'views/Themes/Category/EditCategory';
import Template from 'views/Themes/Templates';
import EditTemplate from 'views/Themes/Templates/EditTemplate';
import EditTemplateState from 'views/Themes/Templates/TemplateStateEdit';
import EditTemplatePosition from 'views/Themes/Templates/TemplatePositionEdit';
import PageBg from 'views/Themes/PageBackground';
import EditPageBg from 'views/Themes/PageBackground/EditPageBg';
import BulkDiscount from 'views/Discounts/BulkDiscount';
import EditBulkDiscount from 'views/Discounts/BulkDiscount/EditBulkDiscount';
import Discount from 'views/Discounts/Discount';
import CreateDiscount from 'views/Discounts/Discount/CreateDiscount';
import DiscountEdit from 'views/Discounts/Discount/DiscountEdit';
import DiscountVoucher from 'views/Discounts/DiscountVoucher';
import Shipping from 'views/Offerings/Shipping';
import OfferingOption from 'views/Offerings/OfferingOption';
import EditOfferingOption from 'views/Offerings/OfferingOption/EditOfferingOption';
import FlexGroup from 'views/Offerings/FlexGroup';
import EditFlexGroup from 'views/Offerings/FlexGroup/EditFlexGroup';
import LayoutBackend from '../components/layouts/variations/Backend.vue';

const Dashboard = () => import('../components/Dashboard');

const router = createRouter({
	history: createWebHistory('/'),
	routes: [
		{
			path: '/',
			name: 'home',
			redirect: '/dashboard',
			component: LayoutBackend,
			beforeEnter: authGuard,
			children: [
				{
					path: 'dashboard',
					name: 'Dashboard',
					component: Dashboard,
					beforeEnter: authGuard,
				},
				{
					path: '/offerings',
					name: 'offerings',
					component: Offerings,
					beforeEnter: authGuard,

				},
				{
					path: '/offerings/:id',
					name: 'editOffering',
					component: EditOffering,
					beforeEnter: authGuard,
				},
				{
					path: '/pricing',
					name: 'pricing',
					component: Pricing,
					beforeEnter: authGuard,
				},
				{
					path: '/products-ordered',
					name: 'ProductsOrdered',
					component: ProductsOrdered,
					beforeEnter: authGuard,
				},
				{
					path: '/showOrder/:orderid',
					name: 'showOrder',
					component: ShowOrder,
					beforeEnter: authGuard,
				},
				{
					path: '/orderItem/:orderItemId',
					name: 'orderItem',
					component: OrderItem,
					beforeEnter: authGuard,
				},
				{
					path: '/appuser/:id',
					name: 'UserProperties',
					component: UserProperties,
					beforeEnter: authGuard,
				},
				{
					path: '/badges',
					name: 'Badges',
					component: Badges,
					beforeEnter: authGuard,
				},
				{
					path: '/badges/:id',
					name: 'EditBadges',
					component: EditBadges,
					beforeEnter: authGuard,
				},
				{
					path: '/offeringoption',
					name: 'OfferingOption',
					component: OfferingOption,
					beforeEnter: authGuard,
				},
				{
					path: '/offeringoption/:id',
					name: 'EditOfferingOption',
					component: EditOfferingOption,
					beforeEnter: authGuard,
				},
				{
					path: '/pdp',
					name: 'Pdp',
					component: Pdp,
					beforeEnter: authGuard,
				},
				{
					path: '/pdp/:id',
					name: 'EditPdp',
					component: EditPdp,
					beforeEnter: authGuard,
				},
				{
					path: '/model2d',
					name: 'Model2d',
					component: Model2d,
					beforeEnter: authGuard,
				},
				{
					path: '/model2d/:id',
					name: 'EditModel2d',
					component: EditModel2d,
					beforeEnter: authGuard,
				},
				{
					path: '/model3d',
					name: 'Model3d',
					component: Model3d,
					beforeEnter: authGuard,
				},
				{
					path: '/model3d/:id',
					name: 'EditModel3d',
					component: EditModel3d,
					beforeEnter: authGuard,
				},
				{
					path: '/productcategory',
					name: 'ProductCategories',
					component: ProductCategories,
					beforeEnter: authGuard,
				},
				{
					path: '/productcategory/:id',
					name: 'EditProductCategories',
					component: EditProductCategories,
					beforeEnter: authGuard,
				},
				{
					path: '/appsetting/',
					name: 'AppSetting',
					component: AppSetting,
					beforeEnter: authGuard,
				},
				{
					path: '/application/',
					name: 'ApiPermissions',
					component: ApiPermissions,
					beforeEnter: authGuard,
				},
				{
					path: '/application/:id',
					name: 'EditApiPermission',
					component: EditApiPermission,
					beforeEnter: authGuard,
				},
				{
					path: '/hyperlink',
					name: 'Hyperlinks',
					component: Hyperlinks,
					beforeEnter: authGuard,
				},
				{
					path: '/hyperlink/:id',
					name: 'EditHyperlinks',
					component: EditHyperlinks,
					beforeEnter: authGuard,
				},
				{
					path: '/branch',
					name: 'PickupStores',
					component: PickupStores,
					beforeEnter: authGuard,
				},
				{
					path: '/branch/:id',
					name: 'EditPickupStores',
					component: EditPickUpStores,
					beforeEnter: authGuard,
				},
				{
					path: '/region',
					name: 'Region',
					component: Region,
					beforeEnter: authGuard,
				},
				{
					path: '/region/:id',
					name: 'EditRegion',
					component: EditRegion,
					beforeEnter: authGuard,
				},
				{
					path: '/currency',
					name: 'Currency',
					component: Currency,
					beforeEnter: authGuard,
				},
				{
					path: '/currency/:id',
					name: 'EditCurrency',
					component: EditCurrency,
					beforeEnter: authGuard,
				},
				{
					path: '/country',
					name: 'Country',
					component: Country,
					beforeEnter: authGuard,
				},
				{
					path: '/crosssell',
					name: 'CrossSell',
					component: CrossSell,
					beforeEnter: authGuard,
				},
				{
					path: '/crosssell/:id',
					name: 'EditCrossSell',
					component: EditCrossSell,
					beforeEnter: authGuard,
				},
				{
					path: '/flexgroup',
					name: 'FlexGroup',
					component: FlexGroup,
					beforeEnter: authGuard,
				},
				{
					path: '/flexgroup/:id',
					name: 'EditFlexGroup',
					component: EditFlexGroup,
					beforeEnter: authGuard,
				},
				{
					path: '/country/:id',
					name: 'EditCountry',
					component: EditCountry,
					beforeEnter: authGuard,
				},
				{
					path: '/photoframe',
					name: 'Photoframe',
					component: Photoframe,
					beforeEnter: authGuard,
				},
				{
					path: '/photoframe/:id',
					name: 'EditPhotoframe',
					component: EditPhotoframe,
					beforeEnter: authGuard,
				},
				{
					path: '/themes',
					name: 'Themes',
					component: Themes,
					beforeEnter: authGuard,
				},
				{
					path: '/theme/:id',
					name: 'EditTheme',
					component: EditTheme,
					beforeEnter: authGuard,
				},
				{
					path: '/theme/:id/page/:pageid',
					name: 'ThemeEditPage',
					component: ThemeEditPage,
					beforeEnter: authGuard,
				},
				{
					path: '/page/:id/object/:objectid',
					name: 'PageObject',
					component: PageObject,
					beforeEnter: authGuard,
				},
				{
					path: '/layout',
					name: 'Layout',
					component: Layout,
					beforeEnter: authGuard,
				},
				{
					path: '/layout/:id',
					name: 'EditLayout',
					component: EditLayout,
					beforeEnter: authGuard,
				},
				{
					path: '/themecategory',
					name: 'Category',
					component: Category,
					beforeEnter: authGuard,
				},
				{
					path: '/themecategory/:id',
					name: 'EditCategory',
					component: EditCategory,
					beforeEnter: authGuard,
				},
				{
					path: '/template',
					name: 'Template',
					component: Template,
					beforeEnter: authGuard,
				},
				{
					path: '/template/:id',
					name: 'EditTemplate',
					component: EditTemplate,
					beforeEnter: authGuard,
				},
				{
					path: '/template/:templateid/state/:id',
					name: 'EditTemplateState',
					component: EditTemplateState,
					beforeEnter: authGuard,
				},
				{
					path: '/template/:templateid/state/:id/position/:positionid',
					name: 'EditTemplatePosition',
					component: EditTemplatePosition,
					beforeEnter: authGuard,
				},
				{
					path: '/pagebg',
					name: 'PageBg',
					component: PageBg,
					beforeEnter: authGuard,
				},
				{
					path: '/pagebg/:id',
					name: 'EditPageBg',
					component: EditPageBg,
					beforeEnter: authGuard,
				},
				{
					path: '/discount-bulk',
					name: 'BulkDiscount',
					component: BulkDiscount,
					beforeEnter: authGuard,
				},
				{
					path: '/discount-bulk/:id',
					name: 'EditBulkDiscount',
					component: EditBulkDiscount,
					beforeEnter: authGuard,
				},
				{
					path: '/discount',
					name: 'Discount',
					component: Discount,
					beforeEnter: authGuard,
				},
				{
					path: '/discountnew',
					name: 'CreateDiscount',
					component: CreateDiscount,
					beforeEnter: authGuard,
				},
				{
					path: '/discount/:id',
					name: 'DiscountEdit',
					component: DiscountEdit,
					beforeEnter: authGuard,
				},
				{
					path: '/discount-voucher',
					name: 'DiscountVoucher',
					component: DiscountVoucher,
					beforeEnter: authGuard,
				},
				{
					path: '/shipping',
					name: 'Shipping',
					component: Shipping,
					beforeEnter: authGuard,
				},
			],
		},
	],
});
export default router;
