import { AxiosResponse, AxiosError } from 'axios';
import { httpClient } from 'utils/http';

async function fetchAllPages<T>(
	initialUrl: string,
	options: {
		limit?: number;
		additionalParams?: Record<string, string>;
	} = {},
): Promise<T[]> {
	const { limit = 1000, additionalParams = {} } = options;
	const allData: T[] = [];
	let nextPageUrl: string | null = initialUrl;

	const urlParams = new URLSearchParams({ limit: limit.toString(), ...additionalParams });

	try {
		while (nextPageUrl) {
			const fullUrl = `${nextPageUrl}${nextPageUrl.includes('?') ? '&' : '?'}${urlParams.toString()}`;
			// eslint-disable-next-line no-await-in-loop
			const response: AxiosResponse<T[]> = await httpClient.get<T[]>(fullUrl);
			allData.push(...response.data);
			nextPageUrl = response.headers['x-pagination-next'] || null;
		}
		return allData;
	} catch (error) {
		const axiosError = error as AxiosError;
		console.error(
			'Error fetching data:',
			axiosError.message,
		);
		throw new Error(`Failed to fetch data: ${axiosError.message}`);
	}
}

export default fetchAllPages;
