import { httpClient } from 'utils/http';
import {
	Vue, Component, toNative,
} from 'vue-facing-decorator';
import * as DB from 'interfaces/database';
import { useRoute } from 'vue-router';
import Template from './template.vue';

@Component({
	components: {},
	mixins: [Template],
})
class ThemeBorderImage extends Vue {
	private isLoading = false;

	private selectedBorderImage: DB.BorderImageModel[] = [];

	private availableBorderImage: DB.BorderImageModel[] = [];

	private currentItem = {} as DB.BorderImageModel;

	private availableBorderImageActiveIndex: null | number = null;

	private selectedBorderImageActiveIndex: null | number = null;

	private route = useRoute();

	private get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	protected mounted(): void {
		this.getThemeBorderImage();
	}

	protected selectedItem(data: DB.BorderImageModel, index: number): void {
		this.availableBorderImageActiveIndex = index;
		this.currentItem = data;
	}

	protected availableItem(data: DB.BorderImageModel, index: number): void {
		this.selectedBorderImageActiveIndex = index;
		this.currentItem = data;
	}

	private getThemeBorderImage(): void {
		this.isLoading = true;
		const params = new URLSearchParams({
			fields: 'id,name',
			limit: '0',
		});
		Promise.all([
			httpClient.get<DB.BorderImageModel[]>(`/api/theme/${this.route.params.id}/borderimages`),
			httpClient.get<DB.BorderImageModel[]>(`/api/borderimage?${params}`),
		])
			.then(([selectedBordImage, AvailableBordImage]) => {
				this.selectedBorderImage = selectedBordImage.data;
				const combinedArray = [...AvailableBordImage.data, ...selectedBordImage.data];
				this.availableBorderImage = [...new Set(combinedArray)];
				return null;
			}).finally(() => {
				this.isLoading = false;
			})
			.catch((error) => {
				this.$toastError(error.message);
			});
	}

	protected async addToSelected(): Promise<void> {
		this.isLoading = true;
		try {
			const themeLink = await httpClient.post(
				'/api/themeborder',
				{
					themeid: parseInt(
						this.route.params.id as string,
						10,
					),
					borderimageid: this.currentItem.id,
				},
			);
			this.selectedBorderImage.push(this.currentItem);
			const findAndDelete = this.availableBorderImage.findIndex((obj) => obj.id === themeLink.data.borderimage);
			this.availableBorderImage.splice(
				findAndDelete,
				1,
			);
		} catch (error: any) {
			this.$toastError(error.message);
		} finally {
			this.isLoading = false;
		}
	}

	protected async removeFromSelected(): Promise<void> {
		this.isLoading = true;
		try {
			const themeLink = await httpClient.delete(
				'/api/themeborderlink',
				{
					data: {
						themeid: parseInt(
							this.route.params.id as string,
							10,
						),
						borderimageid: this.currentItem.id,
					},
				},
			);
			this.availableBorderImage.push(this.currentItem);
			const findAndDelete = this.selectedBorderImage.findIndex((obj) => obj.id === themeLink.data.borderimage);
			this.selectedBorderImage.splice(
				findAndDelete,
				1,
			);
		} catch (error: any) {
			this.$toastError(error.message);
		} finally {
			this.isLoading = false;
		}
	}
}

export default toNative(ThemeBorderImage);
