<template>
	<!-- Header -->
	<header id="page-header">
		<slot>
			<!-- Header Content -->
			<div class="content-header">
				<!-- Left Section -->
				<div class="d-flex align-items-center">
					<!-- Toggle Sidebar -->
					<base-layout-modifier
						action="sidebarToggle"
						size="sm"
						variant="dual"
						class="mr-2 d-lg-none"
					>
						<i class="fa fa-fw fa-bars" />
					</base-layout-modifier>
					<!-- END Toggle Sidebar -->

					<!-- Toggle Mini Sidebar -->
					<base-layout-modifier
						action="sidebarMiniToggle"
						size="sm"
						variant="dual"
						class="mr-2 d-none d-lg-inline-block"
					>
						<i class="fa fa-fw fa-ellipsis-v" />
					</base-layout-modifier>
					<!-- END Toggle Mini Sidebar -->
				</div>
				<!-- END Left Section -->

				<!-- Right Section -->
				<div class="d-flex align-items-center">
					<!-- User Dropdown -->
					<b-dropdown
						ref="oneDropdownDefaultUser"
						size="sm"
						variant="dual"
						class="d-inline-block ml-2"
						menu-class="p-0 border-0 dropdown-menu-md"
						right
						no-caret
					>
						<template #button-content>
							<div class="d-flex align-items-center">
								<img
									class="rounded-circle"
									:src="user.picture"
									alt="Header Avatar"
									style="width: 21px;"
								>
								<span class="d-none d-sm-inline-block ml-2">{{ user.name }}</span>
								<i class="fa fa-fw fa-angle-down d-none d-sm-inline-block ml-1 mt-1" />
							</div>
						</template>
						<li @click="$refs.oneDropdownDefaultUser.hide(true)">
							<div class="p-3 text-center bg-primary-dark rounded-top">
								<img
									class="img-avatar img-avatar48 img-avatar-thumb"
									:src="user.picture"
									alt="Avatar"
								>
								<p class="mt-2 mb-0 text-white font-w500">
									{{ user.name }}
								</p>
							</div>
							<div class="p-2">
								<div
									class="dropdown-item d-flex align-items-center justify-content-between"
									style="cursor:pointer"
									@click="logoutUser"
								>
									<span class="font-size-sm font-w500">Log Out</span>
								</div>
							</div>
						</li>
					</b-dropdown>
					<!-- END User Dropdown -->
				</div>
				<!-- END Right Section -->
			</div>
			<!-- END Header Content -->

			<!-- Header Search -->
			<div
				id="page-header-search"
				class="overlay-header bg-white"
				:class="{ show: store.state.settings.headerSearch }"
				@keydown.esc="() => store.commit('headerSearch', { mode: 'off' })"
			>
				<div class="content-header">
					<b-form
						class="w-100"
						@submit.stop.prevent="onSubmit"
					>
						<b-input-group>
							<b-input-group-prepend>
								<base-layout-modifier
									action="headerSearchOff"
									variant="alt-danger"
								>
									<i class="fa fa-fw fa-times-circle" />
								</base-layout-modifier>
							</b-input-group-prepend>
							<b-form-input
								v-model="baseSearchTerm"
								placeholder="Search or hit ESC.."
							/>
						</b-input-group>
					</b-form>
				</div>
			</div>
			<!-- END Header Search -->

			<!-- Header Loader -->
			<div
				id="page-header-loader"
				class="overlay-header bg-white"
				:class="{ show: store.state.settings.headerLoader }"
			>
				<div class="content-header">
					<div class="w-100 text-center">
						<i class="fa fa-fw fa-circle-notch fa-spin" />
					</div>
				</div>
			</div>
			<!-- END Header Loader -->
		</slot>
	</header>
	<!-- END Header -->
</template>

<script setup>
import {
	ref, onMounted, onUnmounted,
} from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useAuth0 } from '@auth0/auth0-vue';
import BaseLayoutModifier from '../../BaseLayoutModifier.vue';

const router = useRouter();
const store = useStore();
const { logout, user } = useAuth0();

const baseSearchTerm = ref('');
// eslint-disable-next-line no-undef
defineProps({
	classes: String,
});

const onSubmit = () => {
	router.push(`/backend/pages/generic/search?${baseSearchTerm.value}`);
};

const eventHeaderSearch = (event) => {
	if (event.which === 27) {
		event.preventDefault();
		store.commit(
			'headerSearch',
			{ mode: 'off' },
		);
	}
};

const logoutUser = () => {
	logout();
	router.push({ path: '/' });
};

onMounted(() => {
	document.addEventListener(
		'keydown',
		eventHeaderSearch,
	);
});

onUnmounted(() => {
	document.removeEventListener(
		'keydown',
		eventHeaderSearch,
	);
});
</script>
