<template>
	<div
		style="width: 95%"
		class="ml-5"
	>
		<loading-overlay
			:active="isLoading"
			:can-cancel="true"
		/>

		<b-row>
			<b-col
				md="6"
				lg="6"
				sm="12"
			>
				<base-block
					title="Page Background properties"
					header-bg
				>
					<div class="d-block text-center">
						<b-form-group
							label="Name"
							label-for="example-select"
							label-cols-lg="4"
						>
							<b-input-group>
								<b-form-input
									v-model.number="singlePageBg.name"
									type="text"
								/>
								<template #append>
									<b-input-group-text
										class="input-group-text-alt"
										style="cursor:pointer"
									>
										<custom-checkbox
											id="checkbox-2"
											name="checkbox-1"
											:checked="isNameChecked"
											unchecked-value="not_accepted"
											@change="handleCheckboxChange"
										/>
										Null
									</b-input-group-text>
								</template>
							</b-input-group>
						</b-form-group>
						<b-form-group
							label="Image"
							label-cols-lg="4"
						>
							<custom-file-input
								id="image"
								ref="image"
								:placeholder="placeHolders.thumbnailPlaceholder"
								@click.prevent="updateImagePreview"
							/>

							<div
								v-if="singlePageBg.image"
								class="thumbnail"
							>
								<img
									:src="singlePageBg.image"
									alt=""
									srcset=""
									height="100"
									width="100"
								>
								<div
									style="cursor:pointer"
									@click="removeImage"
								>
									&#10006; Remove
								</div>
							</div>
						</b-form-group>
						<b-form-group
							label="Color"
							label-for="example-select"
							label-cols-lg="4"
						>
							<b-input-group>
								<b-form-input
									v-model="singlePageBg.color"
									type="text"
								/>
								<template #append>
									<b-input-group-text
										class="input-group-text-alt"
										style="cursor:pointer"
									>
										<custom-checkbox
											id="checkbox-color"
											name="checkbox-1"
											:checked="isColorChecked"
											unchecked-value="not_accepted"
											@change="handleColorCheckboxChange"
										/>
										Null
									</b-input-group-text>
								</template>
							</b-input-group>
						</b-form-group>
						<b-form-group
							class="form-group"
							label="serialnumber "
							label-for="block-form1-name"
							label-cols-lg="4"
						>
							<b-form-input
								v-model="singlePageBg.serialnumber"
								type="text"
							/>
						</b-form-group>
					</div>
				</base-block>
			</b-col>
		</b-row>
	</div>
</template>

<style scoped>
.thumbnail{
	display:flex;
	justify-content:space-around;
	align-items:Center;
	background-color: #ccc;
	margin-top:10px;
}
.cameraCheckbox {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-left: 40px;
}
</style>
