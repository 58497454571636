import CountryFlag from 'vue-country-flag-next';
import {
	Vue, Component, Prop, toNative,
} from 'vue-facing-decorator';
import Template from './template.vue';

@Component({
	components: {
		CountryFlag,
	},
	mixins: [Template],
})
class CountryFlagGlob extends Vue {
	@Prop({
		type: Object,
		required: true,
	})
	public readonly data!: any;
}

export default toNative(CountryFlagGlob);
