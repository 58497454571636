import { Vue, Component, toNative } from 'vue-facing-decorator';
import OfferingsTable from 'components/OfferingsTable';
import { useRouter } from 'vue-router';
import Template from './template.vue';

@Component({
	components: {
		OfferingsTable,
	},
	mixins: [Template],
})
class Offerings extends Vue {
	private name = 'Offerings';

	private router = useRouter();

	protected routeOffering(id: string): void {
		this.router.push(`/offerings/${id}`);
	}

	protected openTab(id: string): void {
		const route = this.router.resolve({
			name: 'editOffering',
			params: {
				id,
			},
		});
		window.open(
			route.href,
			'_blank',
		);
	}
}

export default toNative(Offerings);
