<template>
	<div class="bv-no-focus-ring">
		<div
			v-for="(option, index) in options"
			:key="index"
			class="custom-control custom-control-inline custom-checkbox"
		>
			<input
				:id="`checkbox-group-${index}-${_uid}`"
				v-model="selectedValues"
				class="custom-control-input"
				type="checkbox"
				:name="name"
				:value="option[valueField]"
				@change="onChange"
			>
			<label
				class="custom-control-label"
				:for="`checkbox-group-${index}-${_uid}`"
			>
				{{ option[textField] }}
			</label>
		</div>
	</div>
</template>
