<template>
	<!-- Regions -->
	<base-block
		rounded
	>
		<div
			v-if="isLoading"
			class="text-center"
		>
			<div
				class="spinner-grow text-primary m-5"
				role="status"
			>
				<span class="sr-only">Loading...</span>
			</div>
		</div>
		<b-table-simple
			responsive
			table-class="table-vcenter"
		>
			<b-thead head-variant="dark">
				<b-tr>
					<b-th>Region</b-th>
					<b-th>Offering available</b-th>
					<b-th class="text-center">
						Vat
					</b-th>
				</b-tr>
			</b-thead>
			<b-tbody
				v-for="(reg,index) in regionsState"
				:key="index"
			>
				<regionRow
					:reg="reg"
					:checkregion="checkregion"
					:edited="edited"
				/>
			</b-tbody>
		</b-table-simple>
	</base-block>
</template>
