import { Translation, TranslationsResponse } from 'interfaces/app';
import ApiService from 'services/ApiService';
import { httpClient } from 'utils/http';

class TranslationService extends ApiService<Translation> {
	constructor() {
		super('translation');
	}

	async getMultiTranslation(parameter: URLSearchParams): Promise<TranslationsResponse> {
		const response = await httpClient.get(`${this.ENDPOINT}/multi?${parameter}`);
		return response.data;
	}
}

export default new TranslationService();
