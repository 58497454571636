import { ProductCategoryModel } from 'interfaces/database';
import ApiService from 'services/ApiService';
import { httpClient } from 'utils/http';

class ProductCategoryService extends ApiService<ProductCategoryModel> {
	constructor() {
		super('productcategory');
	}

	async batchUpdate(item: Partial<ProductCategoryModel>[]): Promise<void> {
		const response = await httpClient.put(
			this.ENDPOINT,
			item,
		);
		return response.data;
	}
}

export default new ProductCategoryService();
