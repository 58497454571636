import { RegionOfferingModel } from 'interfaces/database';
import ApiService from 'services/ApiService';

class RegionOfferingService extends ApiService<RegionOfferingModel> {
	constructor() {
		super('regionoffering');
	}
}

export default new RegionOfferingService();
