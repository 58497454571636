<template>
	<div class="mt-2 mb-2">
		<button
			v-for="button in buttons"
			:key="button.id"
			class="small ui button"
			@click="onButtonClick(button.eventName)"
		>
			<i :class="`fa fa-fw ${button.className}`" />
		</button>
	</div>
</template>
