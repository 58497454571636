import {
	Vue, Component, Prop, toNative,
} from 'vue-facing-decorator';
import { namespace } from 'vuex-facing-decorator';
import * as DB from 'interfaces/database';
import SalesTable from './SalesTable';
import Template from './template.vue';

const Regions = namespace('Regions');
@Component({
	components: {
		SalesTable,
	},
	mixins: [Template],
})
class SalesPrice extends Vue {
	private editing = false;

	private isLoading = false;

	@Prop()
		editedOffering!: DB.OfferingModel;

	get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	@Regions.Getter
	private regionCurrencyForSalesGetter!: Array<DB.RegionCurrencyModel>;

	@Regions.Getter
	private getLoadingStatus!: boolean;
}

export default toNative(SalesPrice);
