<template>
	<div
		style="width: 95%"
		class="ml-5 mt-4"
	>
		<loading-overlay
			:active="isLoaded"
			:can-cancel="true"
		/>
		<b-modal
			id="hyperlinks-modal"
			ref="addhyperlinks-modal"
			hide-footer
		>
			<template #modal-title>
				Create New Hyperlink
			</template>
			<div class="d-block text-left">
				<b-form-group
					label="Language"
					label-for="example-select"
				>
					<Field
						v-slot="{ errors }"
						v-model="hyperLinkdata.languageid"
						rules="required"
						name="Language"
					>
						<div
							class="control"
						>
							<custom-select
								id="example-select"
								v-model="hyperLinkdata.languageid"
								class="mb-2"
								plain
							>
								<b-form-select-option :value="''">
									Please select an option
								</b-form-select-option>
								<b-form-select-option
									v-for="(lang,id) in language "
									:key="id"
									:value="lang.id"
								>
									{{ lang.name }}
								</b-form-select-option>
							</custom-select>
							<span>{{ errors[0] }}</span>
						</div>
					</Field>
				</b-form-group>

				<b-form-group
					label="PDP"
					label-for="example-select"
				>
					<custom-select
						id="example-select"
						v-model="hyperLinkdata.pdpid"
						class="mb-2"
						plain
					>
						<b-form-select-option :value="null">
							Please select an option
						</b-form-select-option>
						<b-form-select-option
							v-for="(pd,id) in pdp"
							:key="id"
							:value="pd.id"
						>
							{{ pd.name }}
						</b-form-select-option>
					</custom-select>
				</b-form-group>

				<b-form-group
					label="Region"
					label-for="example-select"
				>
					<Field
						v-slot="{ errors }"
						v-model="hyperLinkdata.regionid"
						rules="required"
						name="Region"
					>
						<div
							class="control"
						>
							<custom-select
								id="example-select"
								v-model="hyperLinkdata.regionid"
								class="mb-2"
								plain
							>
								<b-form-select-option :value="null">
									Please select an option
								</b-form-select-option>
								<b-form-select-option
									v-for="(reg,id) in region"
									:key="id"
									:value="reg.id"
								>
									{{ reg.name }}
								</b-form-select-option>
							</custom-select>
							<span>{{ errors[0] }}</span>
						</div>
					</Field>
				</b-form-group>

				<b-form-group
					label="Product Category"
					label-for="example-select"
				>
					<custom-select
						id="example-select"
						v-model="hyperLinkdata.productcategoryid"
						class="mb-2"
						plain
					>
						<b-form-select-option :value="null">
							Please select an option
						</b-form-select-option>
						<b-form-select-option
							v-for="(prodcat,id) in productCategory "
							:key="id"
							:value="prodcat.id"
						>
							{{ prodcat.name }}
						</b-form-select-option>
					</custom-select>
				</b-form-group>
				<b-form-group
					class="form-group"
					label="Tag: "
					label-for="block-form1-name"
				>
					<Field
						v-slot="{ errors }"
						v-model="hyperLinkdata.tag"
						rules="alpha_dash"
						name="Tag"
					>
						<div
							class="control"
						>
							<b-form-input
								v-model="hyperLinkdata.tag"
								type="text"
								required
							/>
							<span>{{ errors[0] }}</span>
						</div>
					</Field>
				</b-form-group>

				<b-form-group
					class="form-group"
					label="URL: "
					label-for="block-form1-name"
				>
					<b-form-input
						v-model="hyperLinkdata.url"
						type="text"
						required
					/>
				</b-form-group>
				<b-form-group
					label="Delegate"
					label-class="font-w600"
				>
					<custom-checkbox
						id="checkbox-1"
						:is-switch="true"
						size="lg"
						:checked="hyperLinkdata.delegate === 1 ? true : false"
						@change="hyperLinkdata.delegate = $event"
					/>
				</b-form-group>
			</div>
			<b-button
				class="mt-3"
				block
				@click="addHyperlinks()"
			>
				Save
			</b-button>
		</b-modal>
		<base-block
			title="Existing Hyperlinks"
			header-bg
		>
			<div
				ref="HyperLinksTable"
			/>
		</base-block>
	</div>
</template>

<style lang="scss">
@import "../../assets/scss/custom/errors";
</style>
