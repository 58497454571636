<template>
	<base-block
		title="User Referrals"
		header-bg
	>
		<div
			ref="userReferralTable"
		/>
	</base-block>
</template>
