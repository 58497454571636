import {
	Vue, Component, Ref, toNative,
} from 'vue-facing-decorator';
import EditCategoryTable from 'components/Themes/CategoryTable/EditCategoryTable';
import Template from './template.vue';

@Component({
	components: {
		EditCategoryTable,
	},
	mixins: [Template],
})
class EditCategory extends Vue {
	@Ref('editthemecategory')
	private readonly themecategory!: HTMLFormElement;

	protected updateThemecategoryProperties(): void {
		this.themecategory.editCategory();
	}
}

export default toNative(EditCategory);
