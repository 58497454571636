import { Vue, Component, toNative } from 'vue-facing-decorator';
import TemplateTable from 'components/Themes/TemplateTable';
import { useRouter } from 'vue-router';
import TemplateView from './template.vue';

@Component({
	components: {
		TemplateTable,
	},
	mixins: [TemplateView],
})
class Template extends Vue {
	private router = useRouter();

	protected showModal(): void {
		this.$root?.$emit(
			'bv::show::modal',
			'template-modal',
			'#templateShow',
		);
	}

	protected showImport(): void {
		this.$root?.$emit(
			'bv::show::modal',
			'import-modal',
			'#importShow',
		);
	}

	protected routeTemplate(id: string): void {
		this.router.push(`/template/${id}`);
	}

	protected openInNewTab(id: string): void {
		const route = this.router.resolve({ name: 'EditTemplate', params: { id } });
		window.open(
			route.href,
			'_blank',
		);
	}
}

export default toNative(Template);
