import {
	VuexModule, Module, Mutation, Action,
} from 'vuex-module-decorators';
import { Vue } from 'vue-facing-decorator';
import * as DB from 'interfaces/database';
import auth0 from 'auth/authWrapper';
import { httpClient } from '../../utils/http';

@Module({ namespaced: true, name: 'ProductCategory' })
export default class ProductCategory extends VuexModule {
	private productCategories: DB.ProductCategoryModel[] = [];

	@Mutation
	setProductCategories(payload: DB.ProductCategoryModel[]): void {
		this.productCategories = payload;
	}

	@Action({ rawError: true })
	async getProductCategoriesAction(id: number | null): Promise<void> {
		if (auth0.isAuthenticated) {
			const parameter = new URLSearchParams({
				limit: '0',
				orderby: 'serialnumber ASC',
			});
			if (id !== null) {
				parameter.set(
					'where',
					JSON.stringify({ parentid: id }),
				);
			}
			return httpClient.get<DB.ProductCategoryModel[]>(`/api/productcategory?${parameter}`).then((res) => {
				this.setProductCategories(res.data);
				return undefined;
			}).catch((err) => {
				Vue.prototype.$app.$bvToast.toast(
					`${err.message}`,
					{
						solid: true,
						variant: 'danger',
					},
				);
			});
		}
		return undefined;
	}

	get getProductCategories(): DB.ProductCategoryModel[] {
		return this.productCategories;
	}
}
