import { CurrencyModel } from 'interfaces/database';
import ApiService from 'services/ApiService';

class CurrencyService extends ApiService<CurrencyModel> {
	constructor() {
		super('currency');
	}
}

export default new CurrencyService();
