import {
	Vue, Component, Ref, toNative,
} from 'vue-facing-decorator';
import EditOfferingOptionTable from 'components/OfferingOptionTable/EditOfferingOptionTable';
import Template from './template.vue';

@Component({
	components: {
		EditOfferingOptionTable,
	},
	mixins: [Template],
})
class EditOfferingOption extends Vue {
	@Ref('editofferingoption') readonly offeringOptionModal!: HTMLFormElement;

	private isLoading = false;

	protected updateOfferingOptionProperties(): void {
		this.offeringOptionModal.editOfferingOption();
	}
}

export default toNative(EditOfferingOption);
