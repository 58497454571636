import { Vue, Component, toNative } from 'vue-facing-decorator';
import CountryTable from 'components/CountryTable';
import { useRouter } from 'vue-router';
import Template from './template.vue';

@Component({
	components: {
		CountryTable,
	},
	mixins: [Template],
})
class Country extends Vue {
	private router = useRouter();

	routeCountry(id: string): void {
		this.router.push(`/country/${id}`);
	}
}

export default toNative(Country);
