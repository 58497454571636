import { DiscountVoucherModel } from 'interfaces/database';
import ApiService from 'services/ApiService';

class DiscountVoucherService extends ApiService<DiscountVoucherModel> {
	constructor() {
		super('discountvoucher');
	}
}

export default new DiscountVoucherService();
