import { OfferingFrameModel, OfferingModel } from 'interfaces/database';
import ApiService from 'services/ApiService';
import { httpClient } from 'utils/http';

class OfferingService extends ApiService<OfferingModel> {
	constructor() {
		super('offering');
	}

	async getOfferingFrame(id: string | number): Promise<OfferingFrameModel> {
		const response = await httpClient.get(`${this.ENDPOINT}/${id}/frame`);
		return response.data;
	}
}

export default new OfferingService();
