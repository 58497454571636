import {
	Vue, Component, Ref, toNative,
} from 'vue-facing-decorator';
import EditLayoutsTable from 'components/Themes/LayoutsTable/EditLayoutsTable';
import { useRouter } from 'vue-router';
import Template from './template.vue';

@Component({
	components: {
		EditLayoutsTable,
	},
	mixins: [Template],
})
class EditLayout extends Vue {
	private router = useRouter();

	@Ref('editlayout')
	private readonly layout!: HTMLFormElement;

	protected updateLayout(): void {
		this.layout.editLayout();
	}

	protected routeLayoutTemplate(id: string): void {
		this.router.push(`/template/${id}`);
	}
}

export default toNative(EditLayout);
