import {
	createApp, Component, h, ComponentInternalInstance,
} from 'vue';

// eslint-disable-next-line import/prefer-default-export
export function createInstance<Props extends Record<string, any>>(
	options: {
		component: Component,
		app?: ComponentInternalInstance | null,
		props?: Props,
	},
): Element {
	let expose = null;

	const childApp = createApp({
		setup() {
			return () => {
				expose = h(
					options.component,
					options.props,
				);
				return expose;
			};
		},
	});

	Object.assign(
		childApp._context,
		options.app?.appContext,
	);

	const rootElement = document.createElement('div');
	childApp.mount(rootElement);

	return rootElement.querySelector('div') as HTMLDivElement;
}
