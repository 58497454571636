<template>
	<base-block
		title="Themes Page Object Table"
		header-bg
	>
		<template #options>
			<b-button
				type="submit"
				size="sm"
				variant="primary"
				@click="toggleExistingPage"
			>
				Create New
			</b-button>
		</template>
		<b-row>
			<b-col
				v-show="showExistingPage"
				lg="12"
			>
				<div class="d-block text-left">
					<b-form-group
						label-for="example-select"
					>
						<custom-select
							id="example-select"
							v-model="pageObjectData.type"
							class="mb-2"
							plain
						>
							<b-form-select-option :value="''">
								Please select an option
							</b-form-select-option>
							<b-form-select-option
								v-for="(theme,id) in pageObject"
								:key="id"
								:value="theme"
							>
								{{ theme }}
							</b-form-select-option>
						</custom-select>
					</b-form-group>
					<b-button
						class="mt-3 mb-2"
						@click="createNew()"
					>
						Create new
					</b-button>
				</div>
			</b-col>
		</b-row>
		<div
			ref="PageObjectTable"
		/>
	</base-block>
</template>
