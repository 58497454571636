import { TemplateModel } from 'interfaces/database';
import ApiService from 'services/ApiService';

class TemplateService extends ApiService<TemplateModel> {
	constructor() {
		super('template');
	}
}

export default new TemplateService();
