<template>
	<div class="">
		<base-page-heading
			title="Products Ordered"
			subtitle=""
			class="d-print-none"
		>
			<template #extra>
				<b-breadcrumb class="breadcrumb-alt">
					<b-breadcrumb-item href="javascript:void(0)">
						Product Stream
					</b-breadcrumb-item>
					<b-breadcrumb-item active>
						Products Ordered
					</b-breadcrumb-item>
				</b-breadcrumb>
			</template>
		</base-page-heading>

		<div class="row">
			<ProductsOrdered />
		</div>
	</div>
</template>
