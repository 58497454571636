import { Vue, Component, toNative } from 'vue-facing-decorator';
import PickupStoresTable from 'components/PickupStoresTable';
import { useRouter } from 'vue-router';
import Template from './template.vue';

@Component({
	components: {
		PickupStoresTable,
	},
	mixins: [Template],
})
class PickupStores extends Vue {
	private router = useRouter();

	protected showModal(): void {
		this.$root?.$emit(
			'bv::show::modal',
			'pickupstores-modal',
			'#pickupstoresShow',
		);
	}

	protected routePickup(id: string): void {
		this.router.push(`/branch/${id}`);
	}
}

export default toNative(PickupStores);
