<template>
	<div
		class="ml-5 mt-4"
		style="width: 95%; height: 100%;"
	>
		<loading-overlay
			:active="isLoading"
			:can-cancel="true"
		/>
		<b-row>
			<b-col
				md="12"
				lg="12"
				sm="12"
			>
				<b-tabs
					card
					content-class="mt-3"
					fill
				>
					<b-tab
						title="Themecategory properties"
						active
					>
						<base-block
							title="Themecategory properties"
							header-bg
						>
							<div
								v-if="spinner"
								class="text-center"
							>
								<div
									class="spinner-grow text-primary m-5"
									role="status"
								>
									<span class="sr-only">Loading...</span>
								</div>
							</div>
							<div
								v-else
								class="d-block text-center"
							>
								<b-form-group
									class="form-group"
									label-for="block-form1-name"
									label-cols-lg="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												Group
											</div>
											<div
												id="group"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="group"
										triggers="hover"
										placement="bottom"
									>
										Category is only available for this group (first part of the offering)
									</b-tooltip>
									<b-input-group>
										<b-form-input
											v-model="singleCategory.groupid"
											type="text"
											disabled
										/>
									</b-input-group>
								</b-form-group>
								<b-form-group
									class="form-group"
									label-for="block-form1-name"
									label-cols-lg="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												Type
											</div>
											<div
												id="type"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="type"
										triggers="hover"
										placement="bottom"
									>
										Category is only available for this type (second part of the offering)
									</b-tooltip>
									<b-input-group>
										<b-form-input
											v-model="singleCategory.typeid"
											type="text"
											disabled
										/>
									</b-input-group>
								</b-form-group>
								<b-form-group
									label-for="example-select"
									label-cols-lg="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												Tracker
											</div>
											<div
												id="tracker"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="tracker"
										triggers="hover"
										placement="bottom"
									>
										Want to limit category availability to traffic from one specific tracker
									</b-tooltip>
									<b-input-group>
										<custom-select
											id="example-select"
											v-model="singleCategory.trackerid"
											plain
										>
											<b-form-select-option value="">
												Select Tracker
											</b-form-select-option>
											<b-form-select-option
												v-for="(track,id) in tracker"
												:key="id"
												:value="track.id"
											>
												{{ track.name }}
											</b-form-select-option>
										</custom-select>
										<template #append>
											<b-input-group-text
												class="input-group-text-alt"
												style="cursor:pointer"
											>
												<custom-checkbox
													id="checkbox-3"
													name="checkbox-1"
													:checked="singleCategory.trackerid == null ? true : false"
													unchecked-value="not_accepted"
													@change="()=> singleCategory.trackerid = null "
												/>
												Null
											</b-input-group-text>
										</template>
									</b-input-group>
								</b-form-group>

								<b-form-group
									class="form-group"
									label-for="block-form1-name"
									label-cols-lg="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												Name
											</div>
											<div
												id="name"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="name"
										triggers="hover"
										placement="bottom"
									>
										shown below the icon
									</b-tooltip>
									<b-input-group>
										<b-form-input
											v-model="singleCategory.name"
											type="text"
										/>
									</b-input-group>
								</b-form-group>

								<b-form-group
									label-for="language-select"
									label-cols-lg="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												Language
											</div>
											<div
												id="language"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="language"
										triggers="hover"
										placement="bottom"
									>
										Category available when user choose this language
									</b-tooltip>
									<b-input-group>
										<custom-select
											id="language-select"
											v-model="singleCategory.locale"
											plain
										>
											<b-form-select-option
												v-for="(lang,id) in language"
												:key="id"
												:value="lang.id"
											>
												{{ lang.name }}
											</b-form-select-option>
										</custom-select>
										<template #append>
											<b-input-group-text
												class="input-group-text-alt"
												style="cursor:pointer"
											>
												<custom-checkbox
													id="checkbox-language-3"
													name="checkbox-1"
													:checked="singleCategory.locale == null ? true : false"
													unchecked-value="not_accepted"
													@change="()=> singleCategory.locale = null "
												/>
												Null
											</b-input-group-text>
										</template>
									</b-input-group>
								</b-form-group>

								<b-form-group
									label-for="thumbnail"
									label-cols-lg="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												Icon
											</div>
											<div
												id="icon"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="icon"
										triggers="hover"
										placement="bottom"
									>
										This icon is shown to the user in the themecategory selector. Should be 140x100 pixels in size.
									</b-tooltip>
									<custom-file-input
										id="thumbnail"
										ref="logodesktop"
										:placeholder="placeHolders.icon"
										@click.prevent="updateIcon"
									/>

									<div
										v-if="singleCategory.icon"
										class="thumbnail"
									>
										<img
											:src="singleCategory.icon"
											alt=""
											srcset=""
											height="100"
											width="100"
										>
										<div
											style="cursor:pointer"
											@click="removeIcon"
										>
											&#10006; Remove
										</div>
									</div>
								</b-form-group>
							</div>
						</base-block>
					</b-tab>
					<b-tab
						title="Sort themes"
					>
						<base-block
							title="Sort themes"
							header-bg
						>
							<div
								v-if="themeData.length > 0 && !Array.isArray(themeData[0])"
								class="mb-2"
							>
								<draggable
									v-model="themeData"
									@end="onDragEnd"
								>
									<transition-group>
										<div
											v-for="(theme) in themeData"
											:key="theme.id"
										>
											<b-list-group>
												<b-list-group-item>
													<img
														:src="theme.icon"
														alt=""
														srcset=""
														height="100"
														width="100"
													>
												</b-list-group-item>
											</b-list-group>
										</div>
									</transition-group>
								</draggable>
							</div>
							<div v-else>
								Currently there are no themes linked to this category
							</div>
						</base-block>
					</b-tab>
				</b-tabs>
			</b-col>
		</b-row>
	</div>
</template>

<style lang="scss" scoped>
@import "../../../../assets/scss/oneui/tooltip";
.thumbnail{
	display:flex;
	justify-content:space-around;
	align-items:Center;
	background-color: #ccc;
	margin-top:10px;
}
.cameraCheckbox {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-left: 40px;
}
</style>
