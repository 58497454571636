import {
	Vue, Component, Prop, Ref, toNative,
} from 'vue-facing-decorator';
import * as DB from 'interfaces/database';
import Swal from 'sweetalert2';
import mitt from 'mitt';
import { TabEvent } from 'interfaces/app';
import { httpClient } from 'utils/http';
import { CellComponentExtended, ColumnDefinitionExtended, TabulatorFull as Tabulator } from 'tabulator-tables';
import { createInstance } from 'utils/vue';
import TabulatorBtn from 'components/Tabulator/TabulatorBtn';
import CustomSelect from 'components/CustomSelect';
import Template from './template.vue';

const eventBus = mitt<TabEvent<DB.PageObjectModel>>();
@Component({
	components: {
		TabulatorBtn,
		CustomSelect,
	},
	emits: ['routePageObject'],
	mixins: [Template],
})
class PageObjectTable extends Vue {
	@Ref('PageObjectTable')
	private readonly tableReference!: HTMLDivElement;

	private loaded = false;

	private placeHolders = {
		mobile: 'Choose a file',
		desktop: 'Choose a file',
	};

	private columnDefs: ColumnDefinitionExtended[] = [];

	private pageObject = ['Photo', 'Text'];

	private showExistingPage = false;

	private showNewPage = false;

	private pageObjectData = {} as DB.PageObjectModel;

	private ExistingPagesData = {} as DB.ThemePageModel;

	private themepageDate: DB.ThemePageModel[] = [];

	private table?: Tabulator;

	@Prop({
		type: String,
		required: true,
	})
	public readonly routeId!: string;

	@Prop({
		type: String,
		required: true,
	})
	public readonly pageId!: string;

	private get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	protected beforeMount(): void {
		this.columnDefs = [
			{
				title: 'Type',
				field: 'type',
			},
			{
				title: 'Actions',
				formatter: (cell: CellComponentExtended<DB.PageObjectModel>) => {
					const instance = createInstance({
						component: TabulatorBtn,
						props: {
							data: cell.getData(),
							buttons: [
								{
									id: 'edit',
									eventName: 'editItem',
									className: 'fa-edit',
								},
								{
									id: 'delete',
									eventName: 'deleteItem',
									className: 'fa-times',
								},
							],
							eventBus,
						},
					});
					return instance;
				},
			},
		] as ColumnDefinitionExtended[];
	}

	protected toggleExistingPage(): void {
		this.showExistingPage = !this.showExistingPage;
	}

	protected mounted(): void {
		eventBus.on(
			'editItem',
			this.editPageObject,
		);
		eventBus.on(
			'deleteItem',
			this.deletePageObject,
		);
		this.tableInitialization();
		this.table?.on(
			'tableBuilt',
			this.onTableBuilt,
		);
	}

	protected beforeUnmount(): void {
		eventBus.off(
			'editItem',
			this.editPageObject,
		);
		eventBus.off(
			'deleteItem',
			this.deletePageObject,
		);
		this.table?.off(
			'tableBuilt',
			this.onTableBuilt,
		);
		this.table?.destroy();
	}

	private onTableBuilt(): void {
		this.getData();
	}

	private tableInitialization(): void {
		this.table = new Tabulator(
			this.tableReference,
			{
				height: '60vh',
				layout: 'fitColumns',
				columns: this.columnDefs,
			},
		);
	}

	private async getData(): Promise<void> {
		const parameter = new URLSearchParams({
			limit: '0',
			where: JSON.stringify({
				pageid: this.pageId,
			}),
		});
		this.table?.alert('Loading');
		try {
			const { data } = await httpClient.get<DB.PageObjectModel[]>(`/api/object?${parameter}`);
			this.table?.setData(data);
		} catch (err: any) {
			this.$toastError(err.message);
		} finally {
			this.table?.clearAlert();
		}
	}

	protected async createNew(): Promise<void> {
		this.table?.alert('Loading...');
		try {
			const { data } = await httpClient.post(
				'/api/object',
				{
					type: this.pageObjectData.type,
					pageid: this.pageId,
				},
			);
			this.table?.addData(data);
		} catch (error: any) {
			this.$toastError(error.message);
		} finally {
			this.table?.clearAlert();
		}
	}

	// route to Edit Themes page
	private editPageObject(data: TabEvent<DB.PageObjectModel>['editItem']): void {
		this.$emit(
			'routePageObject',
			data.id,
		);
	}

	private deletePageObject(data: TabEvent<DB.PageObjectModel>['deleteItem']): void {
		Swal.fire({
			title: 'Are you sure?',
			text: 'You will not be able to recover this file!',
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'btn btn-danger m-1',
				cancelButton: 'btn btn-secondary m-1',
			},
			confirmButtonText: 'Yes, delete it!',
			html: false,
		}).then((result) => {
			if (result.value) {
				this.table?.alert('Loading...');
				// eslint-disable-next-line promise/no-nesting
				return httpClient.delete(`/api/object/${data.id}`).then(() => {
					this.$toastSuccess('Item Deleted');
					this.table?.deleteRow(data.id);
					return null;
				}).finally(() => {
					this.table?.clearAlert();
				}).catch((err) => {
					this.$toastError(err.message);
				});
			}
			return undefined;
		}).catch((err) => {
			this.$toastError(err.message);
		});
	}
}

export default toNative(PageObjectTable);
