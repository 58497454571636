<template>
	<div
		class="ml-5 mt-4"
		style="width: 95%; height: 100%;"
	>
		<loading-overlay
			:active="isLoading"
			:can-cancel="true"
		/>
		<b-row>
			<b-col
				md="12"
				lg="12"
				sm="12"
			>
				<b-tabs
					card
					content-class="mt-3"
					fill
				>
					<b-tab
						title="Themecategory properties"
						active
					>
						<base-block
							title="Themecategory properties"
							header-bg
						>
							<div
								v-if="spinner"
								class="text-center"
							>
								<div
									class="spinner-grow text-primary m-5"
									role="status"
								>
									<span class="sr-only">Loading...</span>
								</div>
							</div>
							<div
								v-else
								class="d-block text-center"
							>
								<b-form-group
									class="form-group"
									label-for="block-form1-name"
									label-cols-lg="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												Layout ID
											</div>
											<div
												id="layoutid"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="layoutid"
										triggers="hover"
										placement="bottom"
									>
										The Layout ID that will be selected by default if the user create product with this theme.
									</b-tooltip>
									<b-input-group>
										<custom-select
											id="block-form-layoutid"
											v-model="singleTheme.layoutid"
										>
											<b-form-select-option :value="null">
												Please select an option
											</b-form-select-option>
											<b-form-select-option
												v-for="(lay,id) in layout "
												:key="id"
												:value="lay.layoutid"
											>
												{{ lay.name }}
											</b-form-select-option>
										</custom-select>
										<template #append>
											<b-input-group-text
												class="input-group-text-alt"
												style="cursor:pointer"
											>
												<custom-checkbox
													id="checkbox-1"
													name="checkbox-1"
													:checked="checkPropertyNull('layoutid')"
													unchecked-value="not_accepted"
													@change="setPropertyNull('layoutid')"
												/>
												Null
											</b-input-group-text>
										</template>
									</b-input-group>
								</b-form-group>
								<b-form-group
									class="form-group"
									label-for="block-form1-name"
									label-cols-lg="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												Theme name
											</div>
											<div
												id="themename"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="themename"
										triggers="hover"
										placement="bottom"
									>
										Show below icon in app
									</b-tooltip>
									<b-input-group>
										<b-form-input
											v-model="singleTheme.name"
											type="text"
										/>
									</b-input-group>
								</b-form-group>
								<b-form-group
									class="form-group"
									label-for="block-form1-name"
									label-cols-lg="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												Theme description
											</div>
											<div
												id="themedescription"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="themedescription"
										triggers="hover"
										placement="bottom"
									>
										Show below theme name in app
									</b-tooltip>
									<b-input-group>
										<b-form-textarea
											id="textarea-small"
											v-model="singleTheme.description"
											size="sm"
										/>
										<template #append>
											<b-input-group-text
												class="input-group-text-alt"
												style="cursor:pointer"
											>
												<custom-checkbox
													id="checkbox-2"
													name="checkbox-1"
													:checked="checkPropertyNull('description')"
													unchecked-value="not_accepted"
													@change="setPropertyNull('description')"
												/>
												Null
											</b-input-group-text>
										</template>
									</b-input-group>
								</b-form-group>

								<b-form-group
									label-cols-lg="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												Theme Icon
											</div>
											<div
												id="icon"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="icon"
										triggers="hover"
										placement="bottom"
									>
										Shown in the app when selecting theme. Should br=e 140x100 pixels in size.
									</b-tooltip>
									<custom-file-input
										id="thumbnail"
										ref="logodesktop"
										:placeholder="placeHolders.icon"
										@click.prevent="updateIcon"
									/>

									<div
										v-if="singleTheme.icon"
										class="thumbnail"
									>
										<img
											:src="singleTheme.icon"
											alt=""
											srcset=""
											height="100"
											width="100"
										>
										<div
											style="cursor:pointer"
											@click="removeIcon"
										>
											&#10006; Remove
										</div>
									</div>
								</b-form-group>

								<b-form-group
									class="form-group"
									label-for="block-form1-name"
									label-cols-lg="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												Default page color
											</div>
											<div
												id="DefaultPageColor"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="DefaultPageColor"
										triggers="hover"
										placement="bottom"
									>
										set to default page background color. Set to NULL if product color should not be editable by user.
									</b-tooltip>
									<b-input-group>
										<b-form-input
											v-model="singleTheme.color"
											type="text"
										/>
										<template #append>
											<b-input-group-text
												class="input-group-text-alt"
												style="cursor:pointer"
											>
												<custom-checkbox
													id="checkbox-color"
													name="checkbox-1"
													:checked="checkPropertyNull('color')"
													unchecked-value="not_accepted"
													@change="setPropertyNull('color')"
												/>
												Null
											</b-input-group-text>
										</template>
									</b-input-group>
								</b-form-group>

								<b-form-group
									class="form-group"
									label-for="block-form1-name"
									label-cols-lg="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												Min. photos
											</div>
											<div
												id="minphotos"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="minphotos"
										triggers="hover"
										placement="bottom"
									>
										Minimum amount of photos the user needs to select for this theme.
									</b-tooltip>
									<b-input-group>
										<b-form-input
											v-model="singleTheme.minphotos"
											type="text"
										/>
										<template #append>
											<b-input-group-text
												class="input-group-text-alt"
												style="cursor:pointer"
											>
												<custom-checkbox
													id="checkbox-minphotos"
													name="checkbox-1"
													:checked="checkPropertyNull('minphotos')"
													unchecked-value="not_accepted"
													@change="setPropertyNull('minphotos')"
												/>
												Null
											</b-input-group-text>
										</template>
									</b-input-group>
								</b-form-group>

								<b-form-group
									class="form-group"
									label-for="block-form1-name"
									label-cols-lg="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												Max. photos
											</div>
											<div
												id="maxphotos"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="maxphotos"
										triggers="hover"
										placement="bottom"
									>
										The Maximum amount of photos the user needs to select for this theme.
									</b-tooltip>
									<b-input-group>
										<b-form-input
											v-model="singleTheme.maxphotos"
											type="text"
										/>
										<template #append>
											<b-input-group-text
												class="input-group-text-alt"
												style="cursor:pointer"
											>
												<custom-checkbox
													id="checkbox-maxphotos"
													name="checkbox-1"
													:checked="checkPropertyNull('maxphotos')"
													unchecked-value="not_accepted"
													@change="setPropertyNull('maxphotos')"
												/>
												Null
											</b-input-group-text>
										</template>
									</b-input-group>
								</b-form-group>

								<b-form-group
									label-class="font-w600"
									label-cols-md="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												offline
											</div>
											<div
												id="offline"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="offline"
										triggers="hover"
										placement="bottom"
									>
										Set theme to available offline
									</b-tooltip>
									<custom-checkbox
										id="checkbox-3"
										:is-switch="true"
										size="lg"
										:checked="checkPropertyTrue('offline')"
										@change="updateProperty('offline', $event)"
									/>
								</b-form-group>
								<b-form-group
									label-class="font-w600"
									label-cols-md="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												photoselect
											</div>
											<div
												id="photoselect"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="photoselect"
										triggers="hover"
										placement="bottom"
									>
										Show photo selection step when building product
									</b-tooltip>
									<custom-checkbox
										id="checkbox-4"
										:is-switch="true"
										size="lg"
										:checked="checkPropertyTrue('photoselect')"
										@change="updateProperty('photoselect', $event)"
									/>
								</b-form-group>

								<b-form-group
									label-class="font-w600"
									label-cols-md="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												layoutselect
											</div>
											<div
												id="layoutselect"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="layoutselect"
										triggers="hover"
										placement="bottom"
									>
										Show layout selection step when building product?
									</b-tooltip>
									<custom-checkbox
										id="checkbox-5"
										:is-switch="true"
										size="lg"
										:checked="checkPropertyTrue('layoutselect')"
										@change="updateProperty('layoutselect', $event)"
									/>
								</b-form-group>

								<b-form-group
									label-class="font-w600"
									label-cols-md="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												Dynamic Layout
											</div>
											<div
												id="dynamicLayout"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="dynamicLayout"
										triggers="hover"
										placement="bottom"
									>
										Show dynamic layout?
									</b-tooltip>
									<custom-checkbox
										id="checkbox-6"
										:is-switch="true"
										size="lg"
										:checked="checkPropertyTrue('dynamicLayout')"
										@change="updateProperty('dynamicLayout', $event)"
									/>
								</b-form-group>
							</div>
						</base-block>
					</b-tab>
					<b-tab
						title="Themes Pages Table"
						@click="displayPages('themepage')"
					>
						<ThemesPagesTable
							v-if="display.themepage"
							:route-id="routeId"
							:page-id="$route.params.pageId"
							@routeThemePages="routeThemePages"
						/>
					</b-tab>
					<b-tab
						title="Theme Categories"
						@click="displayPages('themecategory')"
					>
						<ThemesCategories
							v-if="display.themecategory"
							:single-theme="singleTheme"
						/>
					</b-tab>
					<b-tab
						title="Theme BorderImage"
						@click="displayPages('themeborderimage')"
					>
						<ThemeBorderImage
							v-if="display.themeborderimage"
							:single-theme="singleTheme"
						/>
					</b-tab>
					<b-tab
						title="Theme Layouts"
						@click="displayPages('themelayout')"
					>
						<ThemeLayout
							v-if="display.themelayout"
							:route-id="routeId"
							@routeLayout="routeLayout"
						/>
					</b-tab>
					<b-tab
						title="Theme Variant"
						@click="displayPages('themevariant')"
					>
						<ThemesVariant
							v-if="display.themevariant"
							:route-id="routeId"
						/>
					</b-tab>
				</b-tabs>
			</b-col>
		</b-row>
	</div>
</template>

<style lang="scss" scoped>
@import "../../../../assets/scss/oneui/tooltip";
.thumbnail{
	display:flex;
	justify-content:space-around;
	align-items:Center;
	background-color: #ccc;
	margin-top:10px;
}
.cameraCheckbox {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-left: 40px;
}
</style>
