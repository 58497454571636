<template>
	<div
		style="width: 95%"
		class="ml-5"
	>
		<loading-overlay
			:active="isLoading"
			:can-cancel="true"
		/>

		<b-row>
			<b-col
				md="6"
				lg="6"
				sm="12"
			>
				<base-block
					title="Currency properties"
					header-bg
				>
					<div class="d-block">
						<b-form-group
							class="form-group"
							label="ISO code "
							label-for="block-form1-name"
							label-cols-lg="4"
							disabled
						>
							<Field
								v-slot="{ errors }"
								v-model="singleCurrency.id"
								rules="required"
								name="ISO code"
							>
								<div
									class="control"
								>
									<b-form-input
										v-model="singleCurrency.id"
										type="text"
									/>
									<span>{{ errors[0] }}</span>
								</div>
							</Field>
						</b-form-group>
						<b-form-group
							class="form-group"
							label-for="block-form1-name"
							label-cols-lg="4"
						>
							<template #label>
								<div class="d-flex flex-row">
									<div class="mr-2">
										Name
									</div>
									<div
										id="name"
										class="tip"
									/>
								</div>
							</template>
							<b-tooltip
								target="name"
								triggers="hover"
								placement="bottom"
							>
								Name of the currency as shown in the currency selector in the app
							</b-tooltip>
							<b-input-group>
								<Field
									v-slot="{ errors }"
									v-model="singleCurrency.name"
									rules="required"
									name="Name"
								>
									<div
										class="control"
									>
										<b-form-input
											v-model="singleCurrency.name"
											type="text"
											required
										/>
										<span>{{ errors[0] }}</span>
									</div>
								</Field>
								<template #append>
									<b-input-group-text
										class="input-group-text-alt"
										style="cursor:pointer"
									>
										<custom-checkbox
											id="checkbox-1"
											name="checkbox-1"
											:checked="singleCurrency.name == '' ? true : false"
											unchecked-value="not_accepted"
											@change="()=> singleCurrency.name = '' "
										/>
										Null
									</b-input-group-text>
								</template>
							</b-input-group>
						</b-form-group>
						<b-form-group
							class="form-group"
							label-for="block-form1-name"
							label-cols-lg="4"
						>
							<template #label>
								<div class="d-flex flex-row">
									<div class="mr-2">
										Sign
									</div>
									<div
										id="sign"
										class="tip"
									/>
								</div>
							</template>
							<b-tooltip
								target="sign"
								triggers="hover"
								placement="bottom"
							>
								Sign of the currency as shown in the currency selector in the app
							</b-tooltip>
							<b-form-input
								v-model="singleCurrency.sign"
								type="text"
								required
							/>
						</b-form-group>
						<b-form-group
							class="form-group"
							label-for="block-form1-name"
							label-cols-lg="4"
						>
							<template #label>
								<div class="d-flex flex-row">
									<div class="mr-2">
										Prefix
									</div>
									<div
										id="prefix"
										class="tip"
									/>
								</div>
							</template>
							<b-tooltip
								target="prefix"
								triggers="hover"
								placement="bottom"
							>
								This will be place before any monetary data (such as product price) in the app
							</b-tooltip>
							<b-input-group>
								<b-form-input
									v-model="singleCurrency.prefix"
									type="text"
									required
								/>
								<template #append>
									<b-input-group-text
										class="input-group-text-alt"
										style="cursor:pointer"
									>
										<custom-checkbox
											id="checkbox-2"
											name="checkbox-1"
											:checked="singleCurrency.prefix == '' ? true : false"
											unchecked-value="not_accepted"
											@change="()=> singleCurrency.prefix = '' "
										/>
										Null
									</b-input-group-text>
								</template>
							</b-input-group>
						</b-form-group>
						<b-form-group
							class="form-group"
							label-for="block-form1-name"
							label-cols-lg="4"
						>
							<template #label>
								<div class="d-flex flex-row">
									<div class="mr-2">
										Suffix
									</div>
									<div
										id="suffix"
										class="tip"
									/>
								</div>
							</template>
							<b-tooltip
								target="suffix"
								triggers="hover"
								placement="bottom"
							>
								This will be placed after any monetary data ( such as product price) in the app
							</b-tooltip>
							<b-input-group>
								<b-form-input
									v-model="singleCurrency.suffix"
									type="text"
									required
								/>
								<template #append>
									<b-input-group-text
										class="input-group-text-alt"
										style="cursor:pointer"
									>
										<custom-checkbox
											id="checkbox-3"
											name="checkbox-1"
											:checked="singleCurrency.suffix == null ? true : false"
											unchecked-value="not_accepted"
											@change="()=> singleCurrency.suffix = null "
										/>
										Null
									</b-input-group-text>
								</template>
							</b-input-group>
						</b-form-group>
						<b-form-group
							class="form-group"
							label-for="block-form1-name"
							label-cols-lg="4"
						>
							<template #label>
								<div class="d-flex flex-row">
									<div class="mr-2">
										Seperator
									</div>
									<div
										id="seperator"
										class="tip"
									/>
								</div>
							</template>
							<b-tooltip
								target="seperator"
								triggers="hover"
								placement="bottom"
							>
								The character used to seperate the decimals
							</b-tooltip>
							<b-input-group>
								<b-form-input
									v-model="singleCurrency.separator"
									type="text"
									required
								/>
								<template #append>
									<b-input-group-text
										class="input-group-text-alt"
										style="cursor:pointer"
									>
										<custom-checkbox
											id="checkbox-4"
											name="checkbox-1"
											:checked="singleCurrency.separator == '.' || singleCurrency.separator == ',' ? true : false"
											unchecked-value="not_accepted"
											@change="()=> singleCurrency.separator = '' "
										/>
										Null
									</b-input-group-text>
								</template>
							</b-input-group>
						</b-form-group>
					</div>
				</base-block>
			</b-col>
			<b-col
				md="6"
				lg="6"
				sm="12"
			>
				<base-block
					title="Region"
					header-bg
				>
					<div
						ref="editCurrencyTable"
					/>
				</base-block>
			</b-col>
		</b-row>
	</div>
</template>

<style lang="scss" scoped>
@import "../../../assets/scss/oneui/tooltip";
@import "../../../assets/scss/custom/errors";

.thumbnail{
	display:flex;
	justify-content:space-around;
	align-items:Center;
	background-color: #ccc;
	margin-top:10px;
}
.cameraCheckbox {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-left: 40px;
}
</style>
