<template>
	<div
		style="width: 95%"
		class="ml-5"
	>
		<loading-overlay
			:active="isLoading"
			:can-cancel="true"
		/>
		<b-modal
			id="editapipermission-modal"
			ref="editapipermission-modal"
			hide-footer
		>
			<template #modal-title>
				Add Application Route
			</template>
			<div class="d-block text-left">
				<b-form-group
					class="form-group"
					label="Route: "
					label-for="block-form1-name"
				>
					<b-form-input
						v-model="applicationedit.route"
						type="text"
					/>
				</b-form-group>

				<b-form-group
					class="form-group"
					label="GET: "
					label-for="block-form1-name"
				>
					<custom-checkbox
						id="checkbox1"
						v-model="applicationedit.method_get"
						:is-switch="true"
						size="lg"
					/>
				</b-form-group>

				<b-form-group
					class="form-group"
					label="PUT: "
					label-for="block-form1-name"
				>
					<custom-checkbox
						id="checkbox2"
						v-model="applicationedit.method_put"
						:is-switch="true"
						size="lg"
					/>
				</b-form-group>

				<b-form-group
					class="form-group"
					label="POST: "
					label-for="block-form1-name"
				>
					<custom-checkbox
						id="checkbox3"
						v-model="applicationedit.method_post"
						:is-switch="true"
						size="lg"
					/>
				</b-form-group>

				<b-form-group
					class="form-group"
					label="DELETE: "
					label-for="block-form1-name"
				>
					<custom-checkbox
						id="checkbox4"
						v-model="applicationedit.method_delete"
						:is-switch="true"
						size="lg"
					/>
				</b-form-group>
				<b-form-group
					class="form-group"
					label="Auth level: "
					label-for="block-form1-name"
				>
					<b-form-input
						v-model="applicationedit.authLevel"
						type="text"
					/>
				</b-form-group>
			</div>
			<b-button
				class="mt-3"
				block
				@click="saveSetting()"
			>
				Save
			</b-button>
		</b-modal>
		<base-block
			title="Existing application routes"
			header-bg
		>
			<div
				ref="editApiPermissionsTable"
			/>
		</base-block>
	</div>
</template>
