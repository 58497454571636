<template>
	<!-- badges -->
	<base-block
		header-bg
	>
		<div
			v-if="spinner"
			class="text-center"
		>
			<div
				class="spinner-grow text-primary m-5"
				role="status"
			>
				<span class="sr-only">Loading...</span>
			</div>
		</div>
		<b-table-simple
			v-else
			responsive
			table-class="table-vcenter"
		>
			<b-thead head-variant="dark">
				<b-tr>
					<b-th> Id</b-th>
					<b-th>Name</b-th>
					<b-th>Use</b-th>
				</b-tr>
			</b-thead>
			<b-tbody
				v-for="badg in badges"
				:key="badg.id"
			>
				<BadgeRow
					:badg="badg"
					:checkbadge="checkbadge"
				/>
			</b-tbody>
		</b-table-simple>
	</base-block>
</template>
