<template>
	<div
		style="width: 95%"
		class="ml-5"
	>
		<base-block
			title="Order Info"
			header-bg
		>
			<div
				v-if="spinner"
				class="text-center"
			>
				<div
					class="spinner-grow text-primary m-5"
					role="status"
				/>
			</div>
			<b-table-simple
				v-else
				responsive
				table-class="table-vcenter"
			>
				<b-thead head-variant="light" />
				<b-tbody>
					<b-tr>
						<b-td> Order ID</b-td>
						<b-td> {{ orders.id }} </b-td>
					</b-tr>
					<b-tr>
						<b-td> Order ID</b-td>
						<b-td
							v-for="order in orderItems"
							:key="order.id"
							style="display:flex"
						>
							<router-link :to="{ name: 'orderItem', params: { orderItemId: order.id}}">
								View OrderItem {{ order.id }}
							</router-link>
						</b-td>
					</b-tr>
					<b-tr>
						<b-td> User ID</b-td>
						<b-td>
							<router-link :to="{ name: 'UserProperties', params: { id: orders.userid}}">
								{{ orders.userid }}
							</router-link>
						</b-td>
					</b-tr>
					<b-tr>
						<b-td> Order Date</b-td>
						<b-td> {{ orders.dateadded }} </b-td>
					</b-tr>
					<b-tr>
						<b-td> Currency</b-td>
						<b-td> {{ orders.currency }} </b-td>
					</b-tr>
					<b-tr>
						<b-td> Paid</b-td>
						<b-td> {{ orders.paid ? 'Yes' : 'No' }} </b-td>
					</b-tr>
					<b-tr>
						<b-td> Payment Method</b-td>
						<b-td> {{ orders.paymentmethod }} </b-td>
					</b-tr>
					<b-tr>
						<b-td> Shipping Price</b-td>
						<b-td> {{ orders.price_shipping }} </b-td>
					</b-tr>
					<b-tr>
						<b-td> Handling Price</b-td>
						<b-td> {{ orders.price_handling }} </b-td>
					</b-tr>
					<b-tr>
						<b-td> Product Price</b-td>
						<b-td> {{ orders.price_product }} </b-td>
					</b-tr>
					<b-tr>
						<b-td> Total tax</b-td>
						<b-td> {{ orders.total_tax }} </b-td>
					</b-tr>
					<b-tr>
						<b-td> Voucher ID</b-td>
						<b-td> {{ orders.voucherid }} </b-td>
					</b-tr>
					<b-tr>
						<b-td> Voucher discount</b-td>
						<b-td> {{ orders.discount_voucher }} </b-td>
					</b-tr>
					<b-tr>
						<b-td> Total Price</b-td>
						<b-td> {{ orders.salesvalue }} </b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</base-block>

		<!-- Billing Address -->
		<base-block
			title="Billing Address"
			header-bg
		>
			<div
				v-if="spinner"
				class="text-center"
			>
				<div
					class="spinner-grow text-primary m-5"
					role="status"
				/>
			</div>
			<b-table-simple
				v-else
				responsive
				table-class="table-vcenter"
			>
				<b-thead head-variant="light" />
				<b-tbody>
					<b-tr>
						<b-td> Name</b-td>
						<b-td> {{ orders.billing_name }} </b-td>
					</b-tr>
					<b-tr>
						<b-td> Address </b-td>
						<b-td> {{ orders.billing_address1 }} </b-td>
					</b-tr>
					<b-tr>
						<b-td> Zipcode</b-td>
						<b-td> {{ orders.billing_zipcode }} </b-td>
					</b-tr>
					<b-tr>
						<b-td> City</b-td>
						<b-td> {{ orders.billing_city }} </b-td>
					</b-tr>
					<b-tr>
						<b-td> State </b-td>
						<b-td> {{ orders.billing_state }} </b-td>
					</b-tr>
					<b-tr>
						<b-td> Country </b-td>
						<b-td> {{ orders.billing_country }} </b-td>
					</b-tr>
					<b-tr>
						<b-td> Email </b-td>
						<b-td> {{ orders.billing_email }} </b-td>
					</b-tr>
					<b-tr>
						<b-td> Phone number</b-td>
						<b-td> {{ orders.billing_phone }} </b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</base-block>

		<!-- Billing Address -->
		<base-block
			title="Shipping Address"
			header-bg
		>
			<div
				v-if="spinner"
				class="text-center"
			>
				<div
					class="spinner-grow text-primary m-5"
					role="status"
				/>
			</div>
			<b-table-simple
				v-else
				responsive
				table-class="table-vcenter"
			>
				<b-thead head-variant="light" />
				<b-tbody>
					<b-tr>
						<b-td> Name</b-td>
						<b-td> {{ orders.shipping_name }} </b-td>
					</b-tr>
					<b-tr>
						<b-td> Address </b-td>
						<b-td> {{ orders.shipping_address1 }} </b-td>
					</b-tr>
					<b-tr>
						<b-td> Zipcode</b-td>
						<b-td> {{ orders.shipping_zipcode }} </b-td>
					</b-tr>
					<b-tr>
						<b-td> City</b-td>
						<b-td> {{ orders.shipping_city }} </b-td>
					</b-tr>
					<b-tr>
						<b-td> State </b-td>
						<b-td> {{ orders.shipping_state }} </b-td>
					</b-tr>
					<b-tr>
						<b-td> Country </b-td>
						<b-td> {{ orders.shipping_country }} </b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</base-block>
	</div>
</template>
