import { AddressModel } from 'interfaces/database';
import ApiService from 'services/ApiService';

class AddressService extends ApiService<AddressModel> {
	constructor() {
		super('branch');
	}
}

export default new AddressService();
