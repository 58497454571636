<template>
	<div>
		<base-block
			title="Page properties"
			header-bg
		>
			<b-row>
				<b-col
					md="6"
				>
					<b-form-group
						label-for="example-select"
					>
						<custom-select
							id="example-select"
							v-model="internalPageTemplate.template"
							class="mb-2"
							plain
							@change="onPageTemplateChange"
						>
							<b-form-select-option :value="null">
								Please select an option
							</b-form-select-option>
							<b-form-select-option
								v-for="temp in template"
								:key="temp.id"
								:value="temp.id"
							>
								{{ temp.name }}
							</b-form-select-option>
						</custom-select>
					</b-form-group>
				</b-col>
				<b-col
					md="6"
				>
					<b-button
						v-if="internalPageTemplate.name"
						variant="success"
						@click="editPageTemplate()"
					>
						<i class="fa fa-edit" />
					</b-button>

					<b-button
						variant="danger"
						@click="deletePageTemplate()"
					>
						<i class="fa fa-trash" />
					</b-button>
				</b-col>
			</b-row>
		</base-block>
	</div>
</template>
