<template>
	<base-block
		title="Offerings"
		header-bg
	>
		<loading-overlay
			:active="isLoaded"
			:can-cancel="true"
		/>
		<b-row class="d-flex justify-content-between">
			<b-col
				md="4"
				class="my-1"
			>
				<b-form-group
					label="Group Name"
					label-for="search-group-select"
					label-cols-sm="6"
					label-cols-md="4"
					label-cols-lg="3"
					label-align-sm="right"
					label-size="sm"
					class="mb-0"
				>
					<custom-select
						id="search-group-select"
						v-model="search"
						size="sm"
						plain
						@change="groupIdFilterFunction($event)"
					>
						<b-form-select-option :value="null">
							Please select an option
						</b-form-select-option>
						<b-form-select-option
							v-for="(grp,index) in getGroupName"
							:key="index"
							:value="grp.id"
						>
							{{ grp.name }}
						</b-form-select-option>
					</custom-select>
				</b-form-group>
			</b-col>
		</b-row>
		<div
			ref="badgeoffering"
		/>
	</base-block>
</template>
