<template>
	<div
		class="ml-5 mt-4"
		style="width: 95%; height: 100%;"
	>
		<loading-overlay
			:active="isLoading"
			:can-cancel="true"
		/>
		<b-row>
			<b-col
				md="12"
				lg="12"
				sm="12"
			>
				<b-tabs
					card
					content-class="mt-3"
					fill
				>
					<b-tab
						title="Page properties"
						active
					>
						<base-block
							title="Page properties"
							header-bg
						>
							<div
								v-if="spinner"
								class="text-center"
							>
								<div
									class="spinner-grow text-primary m-5"
									role="status"
								>
									<span class="sr-only">Loading...</span>
								</div>
							</div>
							<div
								v-else
								class="d-block text-center"
							>
								<b-form-group
									class="form-group"
									label-for="block-form1-name"
									label-cols-lg="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												name
											</div>
											<div
												id="name"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="name"
										triggers="hover"
										placement="bottom"
									>
										The name of the page for internal references
									</b-tooltip>
									<b-input-group>
										<b-form-input
											v-model="singlePage.name"
											type="text"
										/>
									</b-input-group>
								</b-form-group>
								<b-form-group
									class="form-group"
									label-for="block-form1-name"
									label-cols-lg="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												Width
											</div>
											<div
												id="width"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="width"
										triggers="hover"
										placement="bottom"
									>
										The width of the page in pixels at @configdpi
									</b-tooltip>
									<b-input-group>
										<b-form-input
											v-model="singlePage.width"
											type="text"
											disabled
										/>
									</b-input-group>
								</b-form-group>
								<b-form-group
									class="form-group"
									label-for="block-form1-name"
									label-cols-lg="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												Height
											</div>
											<div
												id="height"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="height"
										triggers="hover"
										placement="bottom"
									>
										The height of the page in pixel at @configdpi
									</b-tooltip>
									<b-input-group>
										<b-form-input
											v-model="singlePage.height"
											type="text"
											disabled
										/>
									</b-input-group>
								</b-form-group>
								<b-form-group
									class="form-group"
									label-for="block-form1-name"
									label-cols-lg="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												Bleed
											</div>
											<div
												id="bleed"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="bleed"
										triggers="hover"
										placement="bottom"
									>
										The bleed of the page in pixels at @configdpi
									</b-tooltip>
									<b-input-group>
										<b-form-input
											v-model="singlePage.offset"
											type="text"
											disabled
										/>
									</b-input-group>
								</b-form-group>
								<b-form-group
									class="form-group"
									label-for="block-form1-name"
									label-cols-lg="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												Background color
											</div>
											<div
												id="bgcolor"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="bgcolor"
										triggers="hover"
										placement="bottom"
									>
										The background color of the page(in hex or as smartTag)
									</b-tooltip>
									<b-input-group>
										<b-form-input
											v-model="singlePage.backgroundcolor"
											type="text"
										/>
										<template #append>
											<b-input-group-text
												class="input-group-text-alt"
												style="cursor:pointer"
											>
												<custom-checkbox
													id="checkbox-color"
													name="checkbox-1"
													:checked="checkPropertyNull('color')"
													unchecked-value="not_accepted"
													@change="setPropertyNull('color')"
												/>
												Null
											</b-input-group-text>
										</template>
									</b-input-group>
								</b-form-group>
								<b-form-group
									label-cols-lg="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												Background image
											</div>
											<div
												id="bgimage"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="bgimage"
										triggers="hover"
										placement="bottom"
									>
										The background image of the page
									</b-tooltip>
									<custom-file-input
										id="thumbnail"
										ref="logodesktop"
										:placeholder="placeHolders.icon"
										@click.prevent="updateIcon"
									/>

									<div
										v-if="singlePage.icon"
										class="thumbnail"
									>
										<img
											:src="singlePage.icon"
											alt=""
											srcset=""
											height="100"
											width="100"
										>
										<div
											style="cursor:pointer"
											@click="removeIcon"
										>
											&#10006; Remove
										</div>
									</div>
								</b-form-group>

								<b-form-group
									label-cols-lg="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												Background Pattern
											</div>
											<div
												id="bgpattern"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="bgpattern"
										triggers="hover"
										placement="bottom"
									>
										The background image that will be tiled across the page
									</b-tooltip>
									<custom-file-input
										id="thumbnail"
										ref="logodesktop"
										:placeholder="placeHolders.backgroundpattern"
										@click.prevent="updateBackgroundPattern"
									/>

									<div
										v-if="singlePage.backgroundpattern"
										class="thumbnail"
									>
										<img
											:src="singlePage.backgroundpattern"
											alt=""
											srcset=""
											height="100"
											width="100"
										>
										<div
											style="cursor:pointer"
											@click="removeBackgroundPattern"
										>
											&#10006; Remove
										</div>
									</div>
								</b-form-group>

								<b-form-group
									class="form-group"
									label-for="block-form1-name"
									label-cols-lg="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												Serial number
											</div>
											<div
												id="serialnumber"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="serialnumber"
										triggers="hover"
										placement="bottom"
									>
										The serialnumber defines the position of the page in the product
									</b-tooltip>
									<b-input-group>
										<b-form-input
											v-model="singlePage.serialnumber"
											type="text"
										/>
									</b-input-group>
								</b-form-group>

								<b-form-group
									label-class="font-w600"
									label-cols-md="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												Editable
											</div>
											<div
												id="editable"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="editable"
										triggers="hover"
										placement="bottom"
									>
										Is the page editable by the user
									</b-tooltip>
									<custom-checkbox
										id="checkbox-1"
										:is-switch="true"
										size="lg"
										:checked="checkPropertyTrue('editable')"
										@change="updateProperty('editable', $event)"
									/>
								</b-form-group>

								<b-form-group
									label-class="font-w600"
									label-cols-md="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												Movable
											</div>
											<div
												id="movable"
												class="tip"
											/>
										</div>
									</template>
									<custom-checkbox
										id="checkbox-2"
										:is-switch="true"
										size="lg"
										:checked="checkPropertyTrue('movable')"
										@change="updateProperty('movable', $event)"
									/>
								</b-form-group>
							</div>
						</base-block>
					</b-tab>
					<b-tab
						title="Page Template"
						@click="displayPages('pagetemplate')"
					>
						<PageTemplate
							v-if="display.pagetemplate"
							:route-id="routeId"
							:page-id="pageId"
							:single-page-template="singlePage"
							@routeTemplate="routeTemplate"
						/>
					</b-tab>
					<b-tab
						title="Page Object"
						@click="displayPages('pageobject')"
					>
						<PageObjectTable
							v-if="display.pageobject"
							:route-id="routeId"
							:page-id="pageId"
							@routePageObject="routePageObject"
						/>
					</b-tab>
				</b-tabs>
			</b-col>
		</b-row>
	</div>
</template>

<style lang="scss" scoped>
@import "../../../../assets/scss/oneui/tooltip";
.thumbnail{
	display:flex;
	justify-content:space-around;
	align-items:Center;
	background-color: #ccc;
	margin-top:10px;
}
.cameraCheckbox {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-left: 40px;
}
</style>
