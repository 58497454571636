import {
	Vue, Component, Ref, Prop, toNative,
} from 'vue-facing-decorator';
import * as DB from 'interfaces/database';
import { httpClient } from 'utils/http';
import { CellComponentExtended, ColumnDefinitionExtended, TabulatorFull as Tabulator } from 'tabulator-tables';
import { createInstance } from 'utils/vue';
import TabulatorBtn from 'components/Tabulator/TabulatorBtn';
import mitt from 'mitt';
import { TabEvent } from 'interfaces/app';
import Template from './template.vue';

const eventBus = mitt<TabEvent<DB.CreditLogModel>>();
@Component({
	components: {
		TabulatorBtn,
	},
	mixins: [Template],
})
class UserCredit extends Vue {
	@Ref('creditRequestTable')
	private creditRequestReference!: HTMLDivElement;

	@Ref('creditHistoryTable')
	private creditHistoryReference!: HTMLDivElement;

	@Prop({
		type: String,
		required: true,
	})
	private readonly routeId!: string;

	private userCredit : DB.CreditModel[] = [];

	private usercredHistory: DB.CreditLogModel[] = [];

	private loadcredHistory = false;

	private displayTable = false;

	private creditRequestTable?: Tabulator;

	private creditHistoryTable?: Tabulator;

	private isLoading = false;

	private columnDefs: ColumnDefinitionExtended[] = [];

	private showCred = {
		columnDefs: [] as ColumnDefinitionExtended[],
	};

	private get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	protected beforeMount(): void {
		this.columnDefs = [
			{
				field: 'currency',
				title: 'Currency',
			},
			{
				title: 'Balance',
				field: 'balance',
			},
			{
				title: 'Actions',
				field: 'id',
				formatter: (cell: CellComponentExtended<DB.CreditModel>) => {
					const instance = createInstance({
						component: TabulatorBtn,
						props: {
							data: cell.getData(),
							buttons: [
								{
									id: 'creditHistory',
									eventName: 'creditRequest',
									className: 'fa fa-fw fa-history',
								},
							],
							eventBus,
						},
					});
					return instance;
				},
			},
		] as ColumnDefinitionExtended[];
		this.showCred.columnDefs = [
			{
				field: 'orderid',
				title: 'Order ID',
			},
			{
				title: 'Mutation',
				field: 'mutation',
			},
			{
				title: 'Date and time',
				field: 'timestamp',
			},
		];
	}

	protected mounted(): void {
		this.tableInitialization();
		this.creditRequestTable?.on(
			'tableBuilt',
			this.onTableBuilt,
		);
		eventBus.on(
			'creditRequest',
			this.showCreditHistory,
		);
	}

	protected beforeUnmount() {
		this.creditRequestTable?.off(
			'tableBuilt',
			this.onTableBuilt,
		);
		eventBus.off(
			'creditRequest',
			this.showCreditHistory,
		);
		this?.creditRequestTable?.destroy();
	}

	private onTableBuilt(): void {
		this.getCreditRequestTableData();
	}

	private tableInitialization(): void {
		this.creditRequestTable = new Tabulator(
			this.creditRequestReference,
			{
				height: '40vh',
				layout: 'fitColumns',
				columns: this.columnDefs,
			},
		);
	}

	private creditHistoryTableInitialization(): void {
		this.creditHistoryTable = new Tabulator(
			this.creditRequestReference,
			{
				height: '40vh',
				layout: 'fitColumns',
				columns: this.showCred.columnDefs,
			},
		);
	}

	private async getCreditRequestTableData(): Promise<void> {
		const id = this.routeId;
		const parameter = new URLSearchParams({
			limit: '0',
			where: JSON.stringify({
				userid: id,
			}),
		});

		this.creditRequestTable?.alert('Loading');
		try {
			const { data } = await httpClient.get<DB.CreditModel[]>(`/api/credit?${parameter}`);
			this.creditRequestTable?.setData(data);
		} catch (err: any) {
			this.$toastError(err.message);
		} finally {
			this.creditRequestTable?.clearAlert();
		}
	}

	private async getCreditHistoryTableData(creditId: number): Promise<void> {
		const id = this.routeId;
		const parameter = new URLSearchParams({
			limit: '0',
			where: JSON.stringify({
				userid: id,
			}),
		});

		this.creditHistoryTable?.alert('Loading');
		try {
			const { data } = await httpClient.get<DB.CreditLogModel[]>(`/api/credit/${creditId}/log?${parameter}`);
			this.creditHistoryTable?.setData(data);
		} catch (err: any) {
			this.$toastError(err.message);
		} finally {
			this.creditHistoryTable?.clearAlert();
		}
	}

	private showCreditHistory(data: TabEvent<DB.CreditLogModel>['creditRequest']): void {
		this.creditHistoryTableInitialization();
		this.getCreditHistoryTableData(data.id);
		this.displayTable = true;
	}
}

export default toNative(UserCredit);
