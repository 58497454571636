<template>
	<div class="">
		<div class="badgeHeader pl-6 pt-2">
			<router-link to="/pagebg">
				<i class="fa fa-fw fa-arrow-left" />
				<span> Back to Page background </span>
			</router-link>
		</div>
		<base-page-heading
			title="Page Background Edit"
			subtitle=""
			class="d-print-none"
		>
			<template #extra>
				<b-button
					ref="btnShow"
					type="submit"
					size="lg"
					variant="secondary"
					@click="updatePageBgProperties"
				>
					Save changes
				</b-button>
			</template>
		</base-page-heading>

		<div class="row content">
			<EditPageBgTable
				ref="editpagebg"
				:route-id="$route.params.id"
			/>
		</div>
	</div>
</template>

<style scoped>
.badgeHeader{
	background-color: #f6f7f8;
}
</style>
