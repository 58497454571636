import { App } from 'vue';

export default function install(app: App): void {
	// eslint-disable-next-line no-param-reassign
	app.config.globalProperties.$toastError = function toastError(err: string) {
		this.$bvToast.toast(
			err,
			{
				solid: true,
				variant: 'danger',
			},
		);
	};

	// eslint-disable-next-line no-param-reassign
	app.config.globalProperties.$toastSuccess = function toastSuccess(message: string) {
		this.$bvToast.toast(
			message,
			{
				solid: true,
				variant: 'success',
			},
		);
	};
}
