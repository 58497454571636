<template>
	<base-block
		title="Template States"
		header-bg
	>
		<loading-overlay
			:active="isLoading"
			:can-cancel="true"
		/>
		<template #options>
			<b-button
				type="submit"
				size="sm"
				variant="primary"
				@click="toggleTemplateState"
			>
				Create new
			</b-button>
		</template>
		<b-row>
			<b-col
				v-show="showTemplateState"
				lg="12"
			>
				<div class="d-block text-left">
					<b-form-group
						label-for="example-select"
					>
						<custom-select
							id="example-select"
							v-model="TemplateStateData"
							class="mb-2"
							plain
						>
							<b-form-select-option :value="''">
								Please select an option
							</b-form-select-option>
							<b-form-select-option
								v-for="(opt,id) in options"
								:key="id"
								:value="opt"
							>
								{{ opt }}
							</b-form-select-option>
						</custom-select>
					</b-form-group>
					<b-button
						class="mt-3 mb-2"
						@click="saveTemplateState()"
					>
						save
					</b-button>
				</div>
			</b-col>
		</b-row>
		<div
			ref="templateStateTable"
		/>
	</base-block>
</template>
