import { OrderItemModel, OrderModel } from 'interfaces/database';
import ApiService from 'services/ApiService';
import { httpClient } from 'utils/http';

class OrderService extends ApiService<OrderModel> {
	constructor() {
		super('order');
	}

	async getAllOrderItems(id: string | number, parameter?: URLSearchParams): Promise<OrderItemModel[]> {
		const url = `${this.ENDPOINT}/${id}/items?${parameter}`;
		const response = await httpClient.get(url);
		return response.data;
	}
}

export default new OrderService();
