import {
	Vue, Component, Ref, toNative,
} from 'vue-facing-decorator';
import EditPickupStoresTable from 'components/PickupStoresTable/EditPickupStoresTable';
import Template from './template.vue';

@Component({
	components: {
		EditPickupStoresTable,
	},
	mixins: [Template],
})
class EditPickupStores extends Vue {
	@Ref('editpickupstores')
	private readonly pickupStoresModal!: HTMLFormElement;

	protected updatePickupStoresProperties(): void {
		this.pickupStoresModal.editPickupStores();
	}
}

export default toNative(EditPickupStores);
