import { Vue, Component, toNative } from 'vue-facing-decorator';
import CurrencyTable from 'components/CurrencyTable';
import { useRouter } from 'vue-router';
import Template from './template.vue';

@Component({
	components: {
		CurrencyTable,
	},
	mixins: [Template],
})
class Currency extends Vue {
	private router = useRouter();

	protected showModal(): void {
		this.$root?.$emit(
			'bv::show::modal',
			'currency-modal',
			'#currencyShow',
		);
	}

	protected routeCurrency(id: string): void {
		this.router.push(`/currency/${id}`);
	}
}

export default toNative(Currency);
