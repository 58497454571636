import { ShippingModel } from 'interfaces/database';
import ApiService from 'services/ApiService';

class ShippingService extends ApiService<ShippingModel> {
	constructor() {
		super('shipping');
	}
}

export default new ShippingService();
