<template>
	<div
		style="width: 95%"
		class="ml-5"
	>
		<loading-overlay
			:active="isLoading"
			:can-cancel="true"
		/>

		<b-row>
			<b-col
				md="6"
				lg="6"
				sm="12"
			>
				<base-block
					title="2D Model properties"
					header-bg
				>
					<div class="d-block text-center">
						<b-form-group
							class="form-group"
							label="Name: "
							label-for="block-form1-name"
							label-cols-lg="4"
						>
							<div
								class="control"
							>
								<b-form-input
									v-model="singleModel2d.name"
									type="text"
								/>
							</div>
						</b-form-group>

						<b-form-group
							label="Image Url"
							label-cols-lg="4"
						>
							<Field
								v-slot="{ errors }"
								rules="image"
								name="Image Url"
							>
								<div
									class="control"
								>
									<custom-file-input
										id="imageUrl"
										ref="imageUrl"
										v-model="imageUrl"
										:placeholder="placeHolders.thumbnailPlaceholder"
										@click.prevent="updateImagePreview"
									/>
									<span>{{ errors[0] }}</span>
								</div>
							</Field>
							<div
								v-if="imageUrl"
								class="thumbnail"
							>
								<img
									:src="singleModel2d.imageUrl"
									alt=""
									height="100"
									width="100"
								>
								<div
									style="cursor:pointer"
									@click="removeThumbnail"
								>
									&#10006; Remove
								</div>
							</div>
						</b-form-group>

						<b-form-group
							class="form-group"
							label="Image Width "
							label-for="block-form1-name"
							label-cols-lg="4"
						>
							<Field
								v-slot="{ errors }"
								v-model.number="singleModel2d.imageWidth"
								rules="integer"
								name="Image Width"
							>
								<div
									class="control"
								>
									<b-form-input
										v-model="singleModel2d.imageWidth"
										type="text"
									/>
									<span>{{ errors[0] }}</span>
								</div>
							</Field>
						</b-form-group>

						<b-form-group
							class="form-group"
							label="Image Height"
							label-for="block-form1-name"
							label-cols-lg="4"
						>
							<Field
								v-slot="{ errors }"
								v-model.number="singleModel2d.imageHeight"
								rules="integer"
								name="Image Height"
							>
								<div
									class="control"
								>
									<b-form-input
										v-model.number="singleModel2d.imageHeight"
										type="text"
									/>
									<span>{{ errors[0] }}</span>
								</div>
							</Field>
						</b-form-group>

						<b-form-group
							class="form-group"
							label="Frame Left"
							label-for="block-form1-name"
							label-cols-lg="4"
						>
							<Field
								v-slot="{ errors }"
								v-model.number="singleModel2d.frameLeft"
								rules="integer"
								name="Frame Left"
							>
								<div
									class="control"
								>
									<b-form-input
										v-model.number="singleModel2d.frameLeft"
										type="text"
									/>
									<span>{{ errors[0] }}</span>
								</div>
							</Field>
						</b-form-group>

						<b-form-group
							class="form-group"
							label="Frame Top"
							label-for="block-form1-name"
							label-cols-lg="4"
						>
							<Field
								v-slot="{ errors }"
								v-model.number="singleModel2d.frameTop"
								rules="integer"
								name="Frame Top"
							>
								<div
									class="control"
								>
									<b-form-input
										v-model.number="singleModel2d.frameTop"
										type="text"
									/>
									<span>{{ errors[0] }}</span>
								</div>
							</Field>
						</b-form-group>

						<b-form-group
							label="Frame Width"
							label-for="example-select"
							label-cols-lg="4"
						>
							<Field
								v-slot="{ errors }"
								v-model.number="singleModel2d.frameWidth"
								rules="integer"
								name="Frame Width"
							>
								<div
									class="control"
								>
									<b-form-input
										v-model.number="singleModel2d.frameWidth"
										type="text"
									/>
									<span>{{ errors[0] }}</span>
								</div>
							</Field>
						</b-form-group>

						<b-form-group
							class="form-group"
							label="Frame Height"
							label-for="block-form1-name"
							label-cols-lg="4"
						>
							<Field
								v-slot="{ errors }"
								v-model.number="singleModel2d.frameHeight"
								rules="integer"
								name="Frame Height"
							>
								<div
									class="control"
								>
									<b-form-input
										v-model.number="singleModel2d.frameHeight"
										type="text"
									/>
									<span>{{ errors[0] }}</span>
								</div>
							</Field>
						</b-form-group>

						<b-form-group
							class="form-group"
							label="Center Horizontal"
							label-for="block-form1-name"
							label-cols-lg="4"
						>
							<Field
								v-slot="{ errors }"
								v-model.number="singleModel2d.centerHorizontal"
								rules="integer"
								name="Center Horizontal"
							>
								<div
									class="control"
								>
									<b-form-input
										v-model.number="singleModel2d.centerHorizontal"
										type="text"
									/>
									<span>{{ errors[0] }}</span>
								</div>
							</Field>
						</b-form-group>

						<b-form-group
							class="form-group"
							label="Center Vertical"
							label-for="block-form1-name"
							label-cols-lg="4"
						>
							<Field
								v-slot="{ errors }"
								v-model.number="singleModel2d.centerVertical"
								rules="integer"
								name="Center Vertical"
							>
								<div
									class="control"
								>
									<b-form-input
										v-model.number="singleModel2d.centerVertical"
										type="text"
									/>
									<span>{{ errors[0] }}</span>
								</div>
							</Field>
						</b-form-group>

						<b-form-group
							class="form-group"
							label="PPI"
							label-for="block-form1-name"
							label-cols-lg="4"
						>
							<Field
								v-slot="{ errors }"
								v-model.number="singleModel2d.ppi"
								rules="integer"
								name="PPI"
							>
								<div
									class="control"
								>
									<b-form-input
										v-model.number="singleModel2d.ppi"
										type="text"
									/>
									<span>{{ errors[0] }}</span>
								</div>
							</Field>
						</b-form-group>

						<b-form-group
							label="Stack On Top?"
							label-class="font-w600"
							label-cols-lg="3"
						>
							<custom-checkbox
								id="checkbox-1"
								class="cameraCheckbox"
								:is-switch="true"
								size="lg"
								:checked="singleModel2d.stackOnTop === 1 ? true : false"
								@change="singleModel2d.stackOnTop = $event"
							/>
						</b-form-group>
					</div>
				</base-block>
			</b-col>
		</b-row>
	</div>
</template>

<style lang="scss" scoped>
.thumbnail{
	display:flex;
	justify-content:space-around;
	align-items:Center;
	background-color: #ccc;
	margin-top:10px;
}
.cameraCheckbox {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-left: 40px;
}
</style>
