import {
	Vue, Component, Watch, Ref, Prop, toNative,
} from 'vue-facing-decorator';
import * as DB from 'interfaces/database';
import { ColumnDefinitionExtended, TabulatorFull as Tabulator } from 'tabulator-tables';
import { sanitizeUrl } from '@braintree/sanitize-url';
import OfferingService from 'services/OfferingService';
import OrderItemService from 'services/OrderItemService';
import { creatorDomain } from '../../utils/http';
import Template from './template.vue';

@Component({
	components: {
	},
	mixins: [Template],
})
class OrderItemTable extends Vue {
	@Ref('orderitemStatusTable')
	private allTableReference!: HTMLDivElement;

	@Prop({
		type: String,
		required: true,
	})
	private readonly orderItemId!: string;

	private spinner = false;

	private totalRows = 1;

	private currentPage = 1;

	private perPage = 10;

	private isLoading = false;

	private orderStatus: DB.OrderItemStatusModel[] = [];

	private orderItem = {} as DB.OrderItemModel;

	private offeringData = {} as DB.OfferingModel;

	private table?: Tabulator;

	private domain = creatorDomain;

	private columnDefs: ColumnDefinitionExtended[] = [];

	private get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	// eslint-disable-next-line class-methods-use-this
	protected sanitizeURL(url: string) {
		return sanitizeUrl(url);
	}

	private get sanitizedUrl() {
		const fullUrl = `${this.domain}/app/showcase/${this.orderItem.productid}/${this.orderItem.read_token}`;
		return this.sanitizeURL(fullUrl);
	}

	protected beforeMount(): void {
		this.columnDefs = [
			{
				field: 'status',
				title: 'Status',
				headerFilter: true,
			},
			{
				field: 'trackingcode',
				title: 'Tracking Code',
				headerFilter: true,
			},
			{
				field: 'trackinglink',
				title: 'Tracking Link',
				headerFilter: true,
			},
			{
				field: 'carrier',
				title: 'Carrier',
				headerFilter: true,
			},
			{
				field: 'shipdate',
				title: 'Date',
				headerFilter: true,
			},
			{
				title: 'Earliest deliverydate',
				field: 'deliverydate_earliest',
				headerFilter: true,
			},
			{
				field: 'deliverydate_latest',
				title: 'Latest deliverydate',
				headerFilter: true,
			},
		];
	}

	protected created(): void {
		this.fetchOrderItems();
	}

	protected mounted(): void {
		this.tableInitialization();
		this.table?.on(
			'tableBuilt',
			this.onTableBuilt,
		);
	}

	protected beforeUnmount() {
		this.table?.off(
			'tableBuilt',
			this.onTableBuilt,
		);
		this?.table?.destroy();
	}

	private onTableBuilt(): void {
		this.getData();
	}

	private tableInitialization(): void {
		this.table = new Tabulator(
			this.allTableReference,
			{
				height: '60vh',
				layout: 'fitColumns',
				columns: this.columnDefs,
			},
		);
	}

	@Watch('loggedIn')
	async fetchOrderItems(): Promise<void> {
		if (this.loggedIn) {
			this.spinner = true;
			try {
				const response = await OrderItemService.get(this.orderItemId);
				this.orderItem = response;
				const response2 = await OfferingService.get(this.orderItem.offeringid);
				this.offeringData = response2;
			} catch (error: any) {
				this.$toastError(error.message);
			} finally {
				this.spinner = false;
			}
		}
	}

	private async getData(): Promise<void> {
		const parameter = new URLSearchParams({
			limit: '0',
		});

		this.table?.alert('Loading');
		try {
			const data = await OrderItemService.getAllOrderItemStatus(
				this.orderItemId,
				parameter,
			);
			this.table?.setData(data);
		} catch (err: any) {
			this.$toastError(err.message);
		} finally {
			this.table?.clearAlert();
		}
	}
}

export default toNative(OrderItemTable);
