export default function generateRandomId(): string {
	// Generate a random alphanumeric ID using a combination of letters and numbers
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	const idLength = 10; // Adjust the desired length of the random ID
	let randomId = '';

	// eslint-disable-next-line no-plusplus
	for (let i = 0; i < idLength; i++) {
		const randomIndex = Math.floor(Math.random() * characters.length);
		randomId += characters.charAt(randomIndex);
	}

	return randomId;
}
