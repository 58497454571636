import { BulkModel, BulkQuantityModel } from 'interfaces/database';
import ApiService from 'services/ApiService';
import { httpClient } from 'utils/http';

class BulkService extends ApiService<BulkModel> {
	constructor() {
		super('bulk');
	}

	async getAllBulkQuantity(id: BulkModel['id'], parameter?: URLSearchParams): Promise<BulkQuantityModel[]> {
		const response = await httpClient.get(`${this.ENDPOINT}/${id}/quantities?${parameter}`);
		return response.data;
	}
}

export default new BulkService();
