<template>
	<div style="width: 80vw !important">
		<loading-overlay
			:active="isLoaded"
			:can-cancel="true"
		/>
		<base-block
			title="Campaign"
			header-bg
		>
			<div
				ref="DiscountTable"
			/>
		</base-block>
	</div>
</template>
