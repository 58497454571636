/* eslint-disable promise/no-nesting */
/* eslint-disable implicit-arrow-linebreak */
import {
	Vue, Component, Watch, Prop, toNative,
} from 'vue-facing-decorator';
import { namespace } from 'vuex-facing-decorator';
import * as DB from 'interfaces/database';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import RegionOfferingService from 'services/RegionOfferingService';
import regionRow from './regionRow';
import Template from './template.vue';

const Regions = namespace('Regions');

@Component({
	components: {
		regionRow,
	},
	mixins: [Template],
})
class Region extends Vue {
	private regionoffering = {} as DB.RegionOfferingModel;

	private vatInput = false;

	private regionInputHidden = false;

	private isLoading = false;

	private selected = false;

	private vat = '';

	private componentLoaded = false;

	private route = useRoute();

	private store = useStore();

	@Prop()
		edited!: DB.OfferingModel;

	private isActive = false;

	// getter for authenticated user
	get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	@Regions.Getter
	private regionsState!: Array<DB.RegionModel>;

	@Regions.Getter
	private checkregion!: Record<number, DB.RegionOfferingModel>;

	async created(): Promise<void> {
		try {
			this.isLoading = true;
			this.store.dispatch('Regions/region');
			this.store.dispatch(
				'Regions/callRegions',
				this.route.params.id,
			);
		} catch (error: any) {
			this.$toastError(error.message);
		} finally {
			this.isLoading = false;
		}
	}

	// Update the Vat price when the input changes
	@Watch('loggedIn')
	updateVat(): void {
		if (this.loggedIn) {
			RegionOfferingService.update(
				this.regionoffering.id,
				{
					vat: this.regionoffering.vat,
				},
			).then((response) => response)
				.catch((err) => {
					this.$toastError(err.message);
				});
		}
	}
}

export default toNative(Region);
