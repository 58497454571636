import {
	Vue, Component, Ref, toNative,
} from 'vue-facing-decorator';
import EditPdpTable from 'components/PdpTable/EditPdpTable';
import Template from './EditPdp.vue';

@Component({
	components: {
		EditPdpTable,
	},
	mixins: [Template],
})
class EditPdp extends Vue {
	@Ref('editPdpComponent') readonly editPdpChild!: HTMLFormElement;

	get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	updatePdpProperties(): void {
		this.editPdpChild.updatePDP();
	}
}

export default toNative(EditPdp);
