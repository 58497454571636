import { BulkQuantityModel } from 'interfaces/database';
import ApiService from 'services/ApiService';
import { httpClient } from 'utils/http';

class BulkQuantityService extends ApiService<BulkQuantityModel> {
	constructor() {
		super('bulkquantity');
	}

	async update(id: string | number, item: Omit<BulkQuantityModel, 'id'>): Promise<BulkQuantityModel> {
		const response = await httpClient.post(
			`${this.ENDPOINT}/${id}`,
			item,
		);
		return response.data;
	}
}

export default new BulkQuantityService();
