<template>
	<b-tr v-if="editing === false">
		<b-td class="text-center">
			{{ salesP.currency }}
		</b-td>
		<b-td class="font-w600 font-size-sm">
			<b-form-group
				label-for="example-text-input"
			>
				<b-form-input
					placeholder=""
					:value="data.price_base_from ? parseFloat(data.price_base_from).toFixed(2) : null"
					readonly
				/>
			</b-form-group>
		</b-td>
		<b-td class="font-w600 font-size-sm">
			<b-form-group
				label-for="example-text-input"
			>
				<b-form-input
					:value="parseFloat(data.price_base).toFixed(2)"
					placeholder=""
					readonly
				/>
			</b-form-group>
		</b-td>
		<b-td
			v-if="editedOffering.maxpages !== editedOffering.minpages"
			class="font-w600 font-size-sm"
		>
			<b-form-group
				label-for="example-text-input"
			>
				<b-form-input
					placeholder=""
					:value="data.price_page_from ? parseFloat(data.price_page_from).toFixed(2) : null"
					readonly
				/>
			</b-form-group>
		</b-td>
		<b-td
			v-if="editedOffering.maxpages !== editedOffering.minpages"
			class="font-w600 font-size-sm"
		>
			<b-form-group
				label-for="example-text-input"
			>
				<b-form-input
					:value="parseFloat(data.price_page).toFixed(2)"
					placeholder=""
					readonly
				/>
			</b-form-group>
		</b-td>
	</b-tr>
	<b-tr v-else>
		<loading-overlay
			:active="isLoading"
			:can-cancel="true"
		/>
		<b-td class="text-center">
			{{ data.currency }}
		</b-td>
		<b-td class="font-w600 font-size-sm">
			<b-form-group
				label-for="example-text-input"
			>
				<b-form-input
					placeholder=""
					:value="data.price_base_from ? parseFloat(data.price_base_from).toFixed(2) : null"
					@input="data.price_base_from = $event"
				/>
			</b-form-group>
		</b-td>
		<b-td class="font-w600 font-size-sm">
			<b-form-group
				label-for="example-text-input"
			>
				<b-form-input
					:value="parseFloat(data.price_base).toFixed(2)"
					placeholder=""
					@input="data.price_base = $event"
				/>
			</b-form-group>
		</b-td>
		<b-td class="font-w600 font-size-sm">
			<b-form-group
				label-for="example-text-input"
			>
				<b-form-input
					placeholder=""
					:value="data.price_page_from ? parseFloat(data.price_page_from).toFixed(2) : null"
					@input="data.price_page_from = $event"
				/>
			</b-form-group>
		</b-td>
		<b-td class="font-w600 font-size-sm">
			<b-form-group
				label-for="example-text-input"
			>
				<b-form-input
					:value="parseFloat(data.price_page).toFixed(2)"
					placeholder=""
					@input="data.price_page = $event"
				/>
			</b-form-group>
		</b-td>
		<b-td class="text-center">
			<b-btn-group>
				<b-button
					size="sm"
					variant="alt-primary"
					@click="editSalesP()"
				>
					<i class="fa fa-fw fa-save" />
				</b-button>
			</b-btn-group>
		</b-td>
	</b-tr>
</template>
