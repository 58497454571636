<template>
	<base-block
		title="Lister Items"
		header-bg
	>
		<loading-overlay
			:active="isLoading"
			:can-cancel="true"
		/>
		<div
			ref="badgeProductCategories"
		/>
	</base-block>
</template>
