/* eslint-disable implicit-arrow-linebreak */
import {
	Vue, Component, Watch, toNative,
} from 'vue-facing-decorator';
import CustomSelect from 'components/CustomSelect';
import OfferingService from 'services/OfferingService';
import { OfferingModel } from 'interfaces/database';
import Template from './template.vue';

@Component({
	mixins: [Template],
	components: {
		CustomSelect,
	},
})
class Crosssell extends Vue {
	private Offerings: OfferingModel[] = [];

	private crossItemHidden = false;

	// getter for authenticated user
	get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	@Watch('loggedIn')
	async getOffering(): Promise<void> {
		if (this.loggedIn) {
			// get all offerings from the server
			const parameter = new URLSearchParams({
				limit: '0',
			});
			OfferingService
				.getAll(parameter)
				.then((response) => {
					this.Offerings = response;
					return null;
				}).catch((err) => {
					this.$toastError(err.message);
				});
		}
	}
}

export default toNative(Crosssell);
