import { httpClient } from 'utils/http';

export default abstract class ApiService<T> {
	protected readonly ENDPOINT: string;

	constructor(endpoint: string) {
		this.ENDPOINT = `/api/${endpoint}`;
	}

	async get(id: string | number, parameter?: URLSearchParams): Promise<T> {
		const response = await httpClient.get(`${this.ENDPOINT}/${id}?${parameter}`);
		return response.data;
	}

	async getAll(parameter?: URLSearchParams): Promise<T[]> {
		const url = parameter ? `${this.ENDPOINT}?${parameter}` : this.ENDPOINT;
		const response = await httpClient.get(url);
		return response.data;
	}

	async create(item: Partial<Omit<T, 'id'>>): Promise<T>

	async create(item: Partial<Omit<T, 'id'>>[]): Promise<T[]>

	async create(item: Partial<Omit<T, 'id'>> | Partial<Omit<T, 'id'>>[]): Promise<T | T[]> {
		const response = await httpClient.post(
			this.ENDPOINT,
			item,
		);
		return response.data;
	}

	async update(id: string | number, item: Partial<Omit<T, 'id'>>): Promise<T> {
		const response = await httpClient.put(
			`${this.ENDPOINT}/${id}`,
			item,
		);
		return response.data;
	}

	async delete(id: string | number): Promise<void> {
		await httpClient.delete(`${this.ENDPOINT}/${id}`);
	}

	async deleteWhere(parameter: URLSearchParams): Promise<void> {
		await httpClient.delete(`${this.ENDPOINT}?${parameter}`);
	}

	async import(item: any): Promise<any> {
		const response = await httpClient.post(
			`${this.ENDPOINT}/import`,
			item,
		);

		return response.data;
	}
}
