<template>
	<div class="d-flex align-items-center">
		<span
			v-if="text"
			class="mr-2"
		>
			{{ text }}
		</span>
		<custom-checkbox
			class="switchbox"
			:checked="checked"
			:is-switch="true"
			size="lg"
			@change="onChange($event)"
		/>
		<label
			for="switchbox"
			class="switchbox-label"
		/>
	</div>
</template>
