import {
	Vue, Component, Watch, Ref, toNative,
} from 'vue-facing-decorator';
import Swal from 'sweetalert2';
import * as DB from 'interfaces/database';
import { BModal } from 'bootstrap-vue';
import { PickerFileMetadata, PickerOptions, PickerResponse } from 'filestack-js';
import mitt from 'mitt';
import { TabEvent } from 'interfaces/app';
import { CellComponentExtended, ColumnDefinitionExtended, TabulatorFull as Tabulator } from 'tabulator-tables';
import { createInstance } from 'utils/vue';
import TabulatorBtn from 'components/Tabulator/TabulatorBtn';
import TabulatorImage from 'components/Tabulator/TabulatorImage';
import { useRoute } from 'vue-router';
import CustomSelect from 'components/CustomSelect';
import CustomCheckbox from 'components/CustomCheckbox';
import CustomFileInput from 'components/CustomFileInput';
import PDPService from 'services/PDPService';
import PDPImageService from 'services/PDPImageService';
import LanguageService from 'services/LanguageService';
import Template from './EditPdpTable.vue';

const eventBus = mitt<TabEvent<DB.PDPImageModel>>();
@Component({
	components: {
		TabulatorBtn,
		CustomSelect,
		CustomCheckbox,
		CustomFileInput,
	},
	mixins: [Template],
})
class EditPdpTable extends Vue {
	@Ref('image-modal') readonly imageModal!: BModal;

	@Ref('image-edit-modal') readonly imageEditModal!: BModal;

	@Ref('pdpImage')
	private readonly tableReference!: HTMLDivElement;

	private pdpImage : DB.PDPImageModel[] = [];

	private pdp : DB.PDPModel[] = [];

	private singlePdp = {} as DB.PDPModel;

	private language: DB.LanguageModel[] = [];

	private placeHolders = {
		thumbnailPlaceholder: 'Choose a file',
		editImagePlaceholder: 'Choose a file',
	};

	private loaded = false;

	private id: DB.PDPImageModel['id'] | null = null;

	private pdpid: DB.PDPModel['id'] | null = null;

	private langid: DB.LanguageModel['id'] | null = null;

	private width: DB.PDPImageModel['width'] | null = null;

	private height: DB.PDPImageModel['height'] | null = null;

	private columnDefs: ColumnDefinitionExtended[] = [];

	private rowData = null;

	private imagePreview = '';

	private spinner = false;

	private name = '';

	private table?: Tabulator;

	private editModalItems = {} as DB.PDPImageModel;

	private index = 0;

	private isLoading = false;

	private pageOptions = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50];

	private route = useRoute();

	private get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	protected beforeMount(): void {
		this.columnDefs = [
			{
				field: 'id',
				title: 'ID',
				width: '60',
			},
			{
				title: 'Icon',
				field: 'url',
				formatter: (cell: CellComponentExtended<DB.ProductCategoryModel>) => {
					const data = cell.getData();
					const instance = createInstance({
						component: TabulatorImage,
						props: {
							size: 100,
							url: cell.getValue(),
							data,
							eventBus,
						},
					});
					return instance;
				},
			},
			{
				title: 'Language',
				field: 'languageid',
				width: '60',
			},
			{
				title: 'Width',
				field: 'width',
				width: '60',
			},
			{
				title: 'Height',
				field: 'height',
				width: '60',
			},
			{
				title: 'Actions',
				formatter: (cell: CellComponentExtended<DB.BadgeModel>) => {
					const instance = createInstance({
						component: TabulatorBtn,
						props: {
							data: cell.getData(),
							buttons: [
								{
									id: 'edit',
									eventName: 'editItem',
									className: 'fa-edit',
								},
								{
									id: 'delete',
									eventName: 'deleteItem',
									className: 'fa-times',
								},
							],
							eventBus,
						},
					});
					return instance;
				},
			},
		] as ColumnDefinitionExtended[];
	}

	protected mounted(): void {
		this.getSinglePdp();
		eventBus.on(
			'deleteItem',
			this.deletePdp,
		);
		eventBus.on(
			'editItem',
			this.editPdp,
		);
		this.tableInitialization();
		this.table?.on(
			'tableBuilt',
			this.onTableBuilt,
		);
	}

	protected beforeUnmount(): void {
		eventBus.off(
			'deleteItem',
			this.deletePdp,
		);
		eventBus.off(
			'editItem',
			this.editPdp,
		);
		this.table?.off(
			'tableBuilt',
			this.onTableBuilt,
		);
		this.table?.destroy();
	}

	private tableInitialization(): void {
		this.table = new Tabulator(
			this.tableReference,
			{
				height: '40vh',
				layout: 'fitColumns',
				columns: this.columnDefs,
			},
		);
	}

	private onTableBuilt(): void {
		this.getData();
	}

	private async getData(): Promise<void> {
		const { id } = this.route.params;
		const parameter = new URLSearchParams({
			where: JSON.stringify({ pdpid: id }),
			limit: '0',
		});
		this.table?.alert('Loading');
		try {
			const data = await PDPImageService.getAll(parameter);
			this.table?.setData(data);
		} catch (err: any) {
			this.$toastError(err.message);
		} finally {
			this.table?.clearAlert();
		}
	}

	private async getSinglePdp(): Promise<DB.PDPModel | undefined> {
		if (this.loggedIn) {
			// set the loading state
			this.loaded = true;
			try {
				const resp = await PDPService.get(this.route.params.id as string);
				this.singlePdp = resp;

				return this.singlePdp;
			} catch (error: any) {
				this.$toastError(error.message);
			} finally {
				this.loaded = false;
			}
		}
		return undefined;
	}

	protected showImageModal(): void {
		this.fetchData();
		this.imageModal.show();
	}

	protected addPdpImage(): Promise<void> | undefined {
		if (this.loggedIn) {
			// set the loading state
			this.spinner = true;
			PDPImageService.create(
				{
					pdpid: parseInt(
						this.route.params.id as string,
						10,
					) || null,
					languageid: this.langid || null,
					height: this.height as number,
					width: this.width as number,
					url: this.imagePreview as string,
				},
			).then((res) => {
				this.pdpid = null;
				this.langid = null;
				this.height = null;
				this.width = null;
				this.imagePreview = '';
				this.table?.addData([res]);
				this.$toastSuccess('PDP Image added successfully');
				return undefined;
			}).finally(() => {
				this.spinner = false;
				this.imageModal.hide();
			})
				.catch((error) => {
					this.$toastError(error.message);
				});
		}
		return undefined;
	}

	private editPdp(data: TabEvent<DB.PDPImageModel>['editItem']): void {
		this.fetchData(data.id);
		this.imageEditModal.show();
		if (data.id !== undefined) {
			this.editModalItems.id = data.id;
		}
		this.editModalItems.pdpid = parseInt(
			this.route.params.id as string,
			10,
		);
		this.editModalItems.languageid = data.params.languageid;
		this.editModalItems.height = data.params.height;
		this.editModalItems.width = data.params.width;
		this.editModalItems.url = data.params.url;
	}

	protected editPdpImage(): Promise<void> | undefined {
		if (this.loggedIn) {
			// set the loading state
			this.spinner = true;
			PDPImageService.update(
				this.editModalItems.id,
				{
					pdpid: this.editModalItems.pdpid,
					languageid: this.editModalItems.languageid,
					height: this.editModalItems.height,
					width: this.editModalItems.width,
					url: this.editModalItems.url,
				},
			).then((res) => {
				this.table?.updateData([res]);
				this.editModalItems.pdpid = null;
				this.editModalItems.languageid = null;
				this.editModalItems.height = 0;
				this.editModalItems.width = 0;
				this.editModalItems.url = '';
				this.$toastSuccess('PDP Edited successfully');
				return undefined;
			}).finally(() => {
				this.spinner = false;
				this.imageEditModal.hide();
			})
				.catch((error) => {
					this.$toastError(error.message);
				});
		}
		return undefined;
	}

	private deletePdp(data: TabEvent<DB.PDPImageModel>['deleteItem']): void {
		Swal.fire({
			title: 'Are you sure?',
			text: 'You will not be able to recover this file!',
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'btn btn-danger m-1',
				cancelButton: 'btn btn-secondary m-1',
			},
			confirmButtonText: 'Yes, delete it!',
			html: false,
		}).then((result) => {
			if (result.value) {
				this.table?.alert('Loading!');
				// eslint-disable-next-line promise/no-nesting
				PDPImageService.delete(data.id).then(() => {
					this.$toastSuccess('Item Deleted');
					this.table?.deleteRow(data.id);
					return null;
				}).finally(() => {
					this.table?.clearAlert();
				}).catch((err) => {
					this.$toastError(err.message);
				});
			}
			return undefined;
		}).catch((err) => {
			this.$toastError(err.message);
		});
	}

	@Watch('loggedIn')
	private async fetchData(id?: number): Promise<void> {
		if (this.loggedIn) {
			const params = new URLSearchParams({
				limit: '0',
			});
			// set the loading state
			this.spinner = true;
			try {
				if (id) {
					const response3 = await PDPImageService.get(id);
					this.editModalItems.width = response3.width;
					this.editModalItems.height = response3.height;
				}
				const response2 = await LanguageService.getAll(params);
				this.language = response2;
			} catch (error: any) {
				this.$toastError(error.message);
			} finally {
				this.spinner = false;
			}
		}
		return undefined;
	}

	private async updatePDP(): Promise<void> {
		// set the loading state
		this.isLoading = true;
		return PDPService.update(
			this.route.params.id as string,
			{ ...this.singlePdp },
		)
			.then(() => {
				this.$toastSuccess('Pdp name updated');
				return undefined;
			}).catch((err) => {
				this.$toastError(err.message);
			}).finally(() => {
				this.isLoading = false;
			});
	}

	//  update image preview
	protected async editImagePreview(): Promise<void> {
		const options: PickerOptions = {
			fromSources: ['local_file_system'],
			exposeOriginalFile: true,
			onFileSelected: (file: PickerFileMetadata) => new Promise<void>((resolve) => {
				const blob = file.originalFile;

				// Get an object URL for the local file
				const url = URL.createObjectURL(blob as Blob);

				// Create an image and load the object URL
				const img = new Image();
				img.src = url;

				img.onload = () => {
					URL.revokeObjectURL(url);
					this.editModalItems.height = img.height;
					this.editModalItems.width = img.width;

					resolve();
				};
			}),
			onUploadDone: async (files: PickerResponse) => {
				this.editModalItems.url = files.filesUploaded[0].url;
				this.placeHolders.editImagePlaceholder = files.filesUploaded[0].filename;
			},
		};
		this.$client.picker(options).open();
	}

	//  update image preview
	protected async updateImagePreview(): Promise<void> {
		const options: PickerOptions = {
			fromSources: ['local_file_system'],
			exposeOriginalFile: true,
			onFileSelected: (file: PickerFileMetadata) => new Promise<void>((resolve) => {
				const blob = file.originalFile;

				// Get an object URL for the local file
				const url = URL.createObjectURL(blob as Blob);

				// Create an image and load the object URL
				const img = new Image();
				img.src = url;

				img.onload = () => {
					URL.revokeObjectURL(url);
					this.height = img.height;
					this.width = img.width;

					resolve();
				};
			}),
			onUploadDone: async (files: PickerResponse) => {
				this.imagePreview = files.filesUploaded[0].url;
				this.placeHolders.thumbnailPlaceholder = files.filesUploaded[0].filename;
			},
		};
		this.$client.picker(options).open();
	}

	// remove the image preview
	protected removeImage(): void {
		this.imagePreview = '';
		this.placeHolders.thumbnailPlaceholder = '';
	}

	protected removeEditImage(): void {
		this.editModalItems.url = '';
		this.placeHolders.editImagePlaceholder = '';
	}
}

export default toNative(EditPdpTable);
