import {
	Vue, Component, Ref, toNative,
} from 'vue-facing-decorator';
import PricingTable from 'components/PricingTable';
import Template from './template.vue';

@Component({
	components: {
		PricingTable,
	},
	mixins: [Template],
})
class Pricing extends Vue {
	@Ref('updatePrice') readonly updatePrices!: HTMLFormElement;

	private name = 'Pricing';
}

export default toNative(Pricing);
