import {
	Vue, Component, Watch, toNative,
} from 'vue-facing-decorator';
import * as DB from 'interfaces/database';
import { useRoute } from 'vue-router';
import CustomSelect from 'components/CustomSelect';
import CustomCheckbox from 'components/CustomCheckbox';
import RegionService from 'services/RegionService';
import ProductCategoryService from 'services/ProductCategoryService';
import LanguageService from 'services/LanguageService';
import PDPService from 'services/PDPService';
import HyperlinkService from 'services/HyperlinkService';
import Template from './EditHyperlinksTable.vue';

@Component({
	mixins: [Template],
	components: {
		CustomSelect,
		CustomCheckbox,
	},
})
class EditHyperlinksTable extends Vue {
	private singleHyperlink = {} as DB.HyperlinkModel;

	private region: DB.RegionModel[] = [];

	private productCategory: DB.ProductCategoryModel[] = [];

	private language: DB.LanguageModel[] = [];

	private pdp: DB.PDPModel[] = [];

	private loaded = false;

	private visible = false;

	private spinner = false;

	private index = 0;

	private isLoading = false;

	private route = useRoute();

	private pageOptions = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50];

	get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	mounted(): void {
		this.getSingleHyperlink();
		this.fetchData();
	}

	@Watch('loggedIn')
	async fetchData(): Promise<void> {
		try {
			const params = new URLSearchParams({
				limit: '0',
			});

			const [
				regionResponse,
				categoryResponse,
				languageResponse,
				pdpResponse,
			] = await Promise.all([
				RegionService.getAll(params),
				ProductCategoryService.getAll(params),
				LanguageService.getAll(params),
				PDPService.getAll(params),
			]);
			this.region = regionResponse;
			this.productCategory = categoryResponse;
			this.language = languageResponse;
			this.pdp = pdpResponse;
		} catch (error: any) {
			this.$toastError(error.message);
		}
	}

	@Watch('loggedIn')
	async getSingleHyperlink(): Promise<DB.HyperlinkModel | undefined> {
		if (this.loggedIn) {
			// set the loading state
			this.isLoading = true;
			try {
				const resp = await HyperlinkService.get(this.route.params.id as string);
				this.singleHyperlink = resp;
				// eslint-disable-next-line consistent-return
				return this.singleHyperlink;
			} catch (error: any) {
				this.$toastError(error.message);
			} finally {
				this.isLoading = false;
			}
		}
		return undefined;
	}

	editHyperlink(): Promise<void> | undefined {
		if (this.loggedIn) {
			this.isLoading = true;
			HyperlinkService.update(
				this.route.params.id as string,
				{ ...this.singleHyperlink },
			).then(() => {
				this.$toastSuccess('Hyperlink Edited successfully');
				return undefined;
			})
				.finally(() => {
					this.isLoading = false;
				})
				.catch((error) => {
					this.$toastError(error.message);
				});
		}
		return undefined;
	}
}

export default toNative(EditHyperlinksTable);
