import { OrderItemModel, OrderItemStatusModel } from 'interfaces/database';
import ApiService from 'services/ApiService';
import { httpClient } from 'utils/http';

class OrderItemService extends ApiService<OrderItemModel> {
	constructor() {
		super('orderitem');
	}

	async getAllOrderItemStatus(id: string | number, parameter?: URLSearchParams): Promise<OrderItemStatusModel[]> {
		const url = `${this.ENDPOINT}/${id}/status?${parameter}`;
		const response = await httpClient.get(url);
		return response.data;
	}

	// eslint-disable-next-line class-methods-use-this
	async getOrderSummary(parameter?: URLSearchParams): Promise<OrderItemModel[]> {
		const url = `/api/ordersummary?${parameter}`;
		const response = await httpClient.get(url);
		return response.data;
	}

	async getPrintUrls(id: string | number, parameter?: URLSearchParams): Promise<Array<any>> {
		const url = `${this.ENDPOINT}/${id}/print?${parameter}`;
		const response = await httpClient.get(url);
		return response.data;
	}
}

export default new OrderItemService();
