import {
	Vue, Component, Ref, toNative,
} from 'vue-facing-decorator';
import EditTemplateStateEdit from 'components/Themes/TemplateTable/TemplateStateEdit';
import { useRoute, useRouter } from 'vue-router';
import Template from './template.vue';

interface TemplateDataModel {
	data: {
		data: {
			id: number,
			type: string,
		},
		id: number
	}
	id: number,
}

@Component({
	components: {
		EditTemplateStateEdit,
	},
	mixins: [Template],
})
class EditTemplateState extends Vue {
	@Ref('editTemplateStateEdit')
	private readonly template!: HTMLFormElement;

	private route = useRoute();

	private router = useRouter();

	protected updateTemplateStateEdit(): void {
		this.template.editTemplate();
	}

	protected routeTemplateState({ data }: TemplateDataModel): void {
		const query = data.data.type;
		this.router.replace({
			path: `/template/${this.route.params.templateid}/state/${this.route.params.id}/position/${data.data.id}`,
			query: { type: `${query}` },
		});
	}
}

export default toNative(EditTemplateState);
