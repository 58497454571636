import {
	Vue, Component, Watch, Prop, Ref, toNative,
} from 'vue-facing-decorator';
import * as DB from 'interfaces/database';
import { httpClient } from 'utils/http';
import { CellComponent, ColumnDefinitionExtended, TabulatorFull as Tabulator } from 'tabulator-tables';
import { BModal } from 'bootstrap-vue';
import { LanguageOptionsModel, TranslationsResponse } from 'interfaces/app';
import { useRoute } from 'vue-router';
import CustomSelect from 'components/CustomSelect';
import CustomCheckbox from 'components/CustomCheckbox';
import OfferingOptionService from 'services/OfferingOptionService';
import OfferingOptionValueService from 'services/OfferingOptionValueService';
import TranslationService from 'services/TranslationService';
import LanguageService from 'services/LanguageService';
import Template from './template.vue';

@Component({
	components: {
		CustomSelect,
		CustomCheckbox,
	},
	mixins: [Template],
})
class EditOfferingOptionTable extends Vue {
	@Prop({
		type: String,
		required: true,
	})
	private readonly routeId!: string;

	@Ref('value-modal') readonly valueModal!: BModal;

	@Ref('offeringOptionValue')
	private readonly tableReference!: HTMLDivElement;

	private offeringOption = {} as DB.OfferingOptionModel;

	private loaded = false;

	private visible = false;

	private spinner = false;

	private index = 0;

	private isLoading = false;

	private rowData: DB.OfferingOptionValueModel[] = [];

	private table?: Tabulator;

	private columnDefs: ColumnDefinitionExtended[] = [];

	private optionValue: DB.OfferingOptionValueModel['value'] | null = null;

	private translations = {} as Record<number, string>;

	private language = '';

	private languageOptions: LanguageOptionsModel[] = [];

	private pageOptions = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50];

	private route = useRoute();

	private get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	protected beforeMount(): void {
		this.columnDefs = [
			{
				field: 'id',
				title: 'ID',
			},
			{
				title: 'Value',
				field: 'value',
			},
			{
				title: 'Header',
				field: 'header',
				editor: 'input',
				cellEdited: this.handleCellEdit,
			},
		];
	}

	protected mounted(): void {
		this.getData();
		this.tableInitialization();
		this.table?.on(
			'tableBuilt',
			this.onTableBuilt,
		);
		this.getLanguageData();
	}

	protected beforeUnmount(): void {
		this.table?.off(
			'tableBuilt',
			this.onTableBuilt,
		);
		this.table?.destroy();
	}

	private tableInitialization(): void {
		this.table = new Tabulator(
			this.tableReference,
			{
				height: '40vh',
				layout: 'fitColumns',
				columns: this.columnDefs,
			},
		);
	}

	private onTableBuilt(): void {
		this.getTableData();
	}

	@Watch('loggedIn')
	private async getData(): Promise<DB.OfferingOptionModel | undefined> {
		if (this.loggedIn) {
			const parameter = new URLSearchParams({
				limit: '0',
			});
			this.isLoading = true;
			try {
				const offeringOptionData = await OfferingOptionService.get(
					this.routeId,
					parameter,
				);
				this.offeringOption = offeringOptionData;
				// eslint-disable-next-line consistent-return
				return this.offeringOption;
			} catch (error: any) {
				this.$toastError(error.message);
			} finally {
				this.isLoading = false;
			}
		}
		return undefined;
	}

	private async getTableData(): Promise<void> {
		const { id } = this.route.params;
		const parameter = new URLSearchParams({
			where: JSON.stringify({ offeringoptionid: id }),
			limit: '0',
		});
		this.table?.alert('Loading');
		try {
			const data = await OfferingOptionValueService.getAll(parameter);
			this.rowData = data;
			this.table?.setData(this.rowData);
		} catch (err: any) {
			this.$toastError(err.message);
		} finally {
			this.table?.clearAlert();
		}
	}

	private async fetchTranslations(language: string): Promise<TranslationsResponse> {
		const parameter = new URLSearchParams({
			ns: 'offeringoptionvalues',
			lng: language,
			limit: '0',
		});

		try {
			const data = await TranslationService.getMultiTranslation(parameter);
			return data;
		} catch (error: any) {
			this.$toastError(error.message);
			return Promise.reject(error);
		}
	}

	private async getLanguageData(): Promise<void> {
		try {
			const parameter = new URLSearchParams({
				limit: '0',
			});
			const languageResponse = await LanguageService.getAll(parameter);
			this.languageOptions = languageResponse.map((language) => ({ value: language.id, text: language.name }));
			// get the default language
			const defaultLang = languageResponse.find((language: DB.LanguageModel) => language.default);
			// set the language to the default language or the first language in the list
			this.language = defaultLang ? defaultLang.id : languageResponse[0].id;
			// get the translations for the default language
			const translationsData = await this.fetchTranslations(this.language);

			this.translations = translationsData[this.language]?.offeringoptionvalues as string;

			// set the translations for the default language
			this.rowData = this.rowData.map((row) => {
				if (this.translations?.[row.id] !== undefined) {
					return {
						...row,
						header: this.translations[row.id],
					};
				}
				return row;
			});

			this.table?.updateData(this.rowData);
		} catch (error: any) {
			this.$toastError(error.message);
		}
	}

	protected async translateTableData(data: string): Promise<void> {
		const translations = await this.fetchTranslations(data);
		this.translations = translations[this.language]?.offeringoptionvalues as string;

		this.rowData = this.rowData.map((row) => {
			if (this.translations?.[row.id] !== undefined) {
				return {
					...row,
					header: this.translations[row.id],
				};
			}
			return row;
		});

		this.table?.updateData(this.rowData);
	}

	private handleCellEdit(cell: CellComponent): void {
		const row = cell.getRow().getData();
		const field = cell.getField();
		const newValue = cell.getValue();

		row[field] = newValue;

		//  Find and Update 'rowData' state
		const foundRow = this.rowData.find((r) => r.id === row.id);

		if (foundRow) {
			(foundRow as unknown as Record<string, string | number>)[field] = newValue;
		}
		this.saveUpdatedData([cell]).catch((error) => {
			this.$toastError(error.message);
		});
	}

	private async saveUpdatedData(cells: CellComponent[]): Promise<void> {
		const bundle: Record<number, string> = this.formatCellsAsTranslations(cells);
		try {
			await httpClient.put(
				'/api/translation',
				{
					namespace: 'offeringoptionvalues',
					language: this.language,
					bundle,
				},
			);
			this.$toastSuccess('Successfully updated the data for the table');
		} catch (error: any) {
			this.$toastError(error.message);
		}
	}

	// eslint-disable-next-line class-methods-use-this
	private formatCellsAsTranslations(cells: CellComponent[]): Record<number, string> {
		return cells.reduce(
			(result, cell) => {
				const row = cell.getRow().getData();
				return {
					...result,
					[row.id]: row.header ? row.header : '',
				};
			},
			{},
		);
	}

	protected showValueModal(): void {
		this.valueModal.show();
	}

	protected addOptionValue(): Promise<void> | undefined {
		if (this.loggedIn) {
			// set the loading state
			this.spinner = true;
			OfferingOptionValueService.create(
				{
					offeringoptionid: parseInt(
						this.route.params.id as string,
						10,
					),
					value: this.optionValue ?? undefined,
				},
			).then((res) => {
				this.optionValue = null;
				this.table?.addData([res]);
				this.$toastSuccess('Offering Option Value added successfully');
				return undefined;
			}).finally(() => {
				this.spinner = false;
				this.valueModal.hide();
			})
				.catch((error) => {
					this.$toastError(error.message);
				});
		}
		return undefined;
	}

	protected editOfferingOption(): Promise<void> | undefined {
		if (this.loggedIn) {
			this.isLoading = true;
			OfferingOptionService.update(
				this.routeId,
				{ ...this.offeringOption },
			).then(() => {
				this.$toastSuccess('Offering Option Edited successfully');
				return undefined;
			})
				.finally(() => {
					this.isLoading = false;
				})
				.catch((error) => {
					this.$toastError(error.message);
				});
		}
		return undefined;
	}
}

export default toNative(EditOfferingOptionTable);
