import { Vue, Component, toNative } from 'vue-facing-decorator';
import * as DB from 'interfaces/database';
import { MatchedOffering } from 'interfaces/app';
import CustomSelect from 'components/CustomSelect';
import CustomCheckbox from 'components/CustomCheckbox';
import CustomCheckboxGroup from 'components/CustomCheckboxGroup';
import CurrencyService from 'services/CurrencyService';
import AffiliateService from 'services/AffiliateService';
import ProductGroupService from 'services/ProductGroupService';
import OfferingService from 'services/OfferingService';
import ReferralProgramService from 'services/ReferralProgramService';
import DiscountService from 'services/DiscountService';
import BulkOfferingService from 'services/BulkOfferingService';
import Template from './template.vue';

@Component({
	mixins: [Template],
	components: {
		CustomSelect,
		CustomCheckbox,
		CustomCheckboxGroup,
	},
})
class CreateDiscountTable extends Vue {
	private currencies: DB.CurrencyModel[] = [];

	private shippingOptions: string[] =	[
		'No free shipping',
		'Free economy shipping',
		'Free shipping with tracking',
		'Free express shipping',
	];

	private shipping: string | null = null;

	private createDiscountData = {} as DB.DiscountModel;

	private tracker: DB.AffiliateModel[] = [];

	private selectedOfferings: number[] = [];

	private productGroup: DB.ProductGroupModel[] = [];

	private isLoading = false;

	private referralProgram: DB.ReferralProgramModel[] = [];

	private offering: DB.OfferingModel[] = [];

	private currentLastValue = 0;

	private relative = false;

	private absolute = true;

	private currency: DB.CurrencyModel[] = [];

	private currencyModel: string | null = null;

	private get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	protected beforeMount(): void {
		if (this.loggedIn) {
			const params = new URLSearchParams({
				limit: '0',
			});
			const referralParams = new URLSearchParams({
				limit: '0',
				fields: 'id,name',
			});
			Promise.all([
				CurrencyService.getAll(params),
				AffiliateService.getAll(),
				ReferralProgramService.getAll(referralParams),
				ProductGroupService.getAll(params),
				OfferingService.getAll(params),
			])
				.then(([currencyResponse, AffiateResponse, ReferrelResponse, ProductResponse, offeringResponse]) => {
					this.currency = currencyResponse;
					this.tracker = AffiateResponse;
					this.referralProgram = ReferrelResponse;
					this.productGroup = ProductResponse;
					this.offering = offeringResponse;
					return null;
				})
				.catch((error) => {
					this.$toastError(error.message);
				});
		}
	}

	protected ToggleDiscount(discount: string): void {
		if (discount === 'relative') {
			this.relative = true;
			this.absolute = false;
		} else if (discount === 'absolute') {
			this.absolute = true;
			this.relative = false;
		}
	}

	protected async createDiscount(): Promise<void> {
		this.isLoading = true;
		if (this.$auth0.user) {
			try {
				await DiscountService.create(
					{
						...this.createDiscountData,
						currency: this.currencyModel ?? undefined,
						created_by: this.$auth0?.user.value?.name,
					},
				);
			} catch (err: any) {
				this.$toastError(err.message);
			} finally {
				this.isLoading = false;
			}
		}
	}

	protected toggleOfferings(_value: number[], event: InputEvent): void {
		const isChecked = (event.target as HTMLInputElement).checked;
		const currentValue = (event.target as HTMLInputElement).value;
		const matchedOffering: MatchedOffering[] = [];
		// get all the offering details that match the groupid's in selectedOfferings
		this.offering.forEach((offering) => {
			if (this.selectedOfferings.includes(offering.groupid)) {
				matchedOffering.push({
					offeringid: offering.id,
					groupid: offering.groupid,
					variantid: offering.variantid,
					id: offering.id,
					typeid: offering.typeid,
				});
			}
		});
		this.currentLastValue = Number(currentValue);
		if (isChecked) {
			BulkOfferingService.create(
				matchedOffering,
			).then(() => undefined).catch((err) => {
				this.$toastError(err.message);
			});
		} else {
			const ids: number[] = [];
			this.offering.filter((offering) => {
				if (offering.groupid === this.currentLastValue) {
					ids.push(offering.id);
				}
				return offering.id;
			});
			const params = new URLSearchParams({
				where: JSON.stringify({
					id: ids,
				}),
			});
			BulkOfferingService.deleteWhere(params).then(() => undefined).catch((err) => {
				this.$toastError(err.message);
			});
		}
	}
}

export default toNative(CreateDiscountTable);
