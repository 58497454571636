import { ThemePageModel } from 'interfaces/database';
import ApiService from 'services/ApiService';

class ThemePageService extends ApiService<ThemePageModel> {
	constructor() {
		super('themepage');
	}
}

export default new ThemePageService();
