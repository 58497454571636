import { Vue, Component, toNative } from 'vue-facing-decorator';
import HyperlinksTable from 'components/HyperlinksTable';
import { useRouter } from 'vue-router';
import Template from './template.vue';

@Component({
	components: {
		HyperlinksTable,
	},
	mixins: [Template],
})
class Hyperlinks extends Vue {
	private router = useRouter();

	showModal(): void {
		this.$root?.$emit(
			'bv::show::modal',
			'hyperlinks-modal',
			'#hyperlinksShow',
		);
	}

	routeHyperlink(id: string): void {
		this.router.push(`/hyperlink/${id}`);
	}
}

export default toNative(Hyperlinks);
