import axios from 'axios';
import auth0 from '../auth/authWrapper';

export const creatorDomain = CREATOR_HOST;

export const httpClient = axios.create({
	baseURL: BASE_URL,
	headers: {
		'Content-Type': 'application/json',
	},
});

httpClient.interceptors.request.use(
	async (config) => {
		if (auth0) {
			try {
				const token = await auth0.idTokenClaims.value?.__raw;
				// eslint-disable-next-line no-param-reassign
				config.headers.Authorization = `AdminBearer ${token}`;
			} catch (error) {
				// Handle error if unable to get access token
				console.error(
					'Error getting access token:',
					error,
				);
			}
		}

		return config;
	},

	(error) => Promise.reject(error),

);
