import { AffiliateModel } from 'interfaces/database';
import ApiService from 'services/ApiService';

class AffiliateService extends ApiService<AffiliateModel> {
	constructor() {
		super('affiliate');
	}
}

export default new AffiliateService();
