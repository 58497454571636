<template>
	<div
		style="width: 95%"
		class="ml-5"
	>
		<loading-overlay
			:active="isLoading"
			:can-cancel="true"
		/>
		<b-modal
			id="appsetting-modal"
			ref="addappsetting-modal"
			hide-footer
		>
			<template #modal-title>
				Add new Setting
			</template>
			<div class="d-block text-center">
				<b-form-group
					class="form-group"
					label="Key: "
					label-for="block-form1-name"
				>
					<b-form-input
						v-model="settingsData.key"
						type="text"
					/>
				</b-form-group>
				<b-form-group
					class="form-group"
					label="Value: "
					label-for="block-form1-name"
				>
					<b-form-input
						v-model="settingsData.value"
						type="text"
					/>
				</b-form-group>
			</div>
			<b-button
				class="mt-3"
				block
				@click="saveSetting()"
			>
				Save
			</b-button>
		</b-modal>
		<base-block
			title="App Settings"
			header-bg
		>
			<b-row
				class="d-flex justify-content-end mb-2"
			>
				<b-col
					md="6"
					class="d-flex justify-content-end mb-2"
				>
					<b-form-group>
						<custom-select
							id="per-page-select"
							v-model="affiliateid"
							size="sm"
							@change="getData()"
						>
							<b-form-select-option value="null">
								All
							</b-form-select-option>
							<b-form-select-option
								v-for="(data, index) in AffiliateData"
								:key="index"
								:value="data.id"
							>
								{{ data.title }}
							</b-form-select-option>
						</custom-select>
					</b-form-group>
				</b-col>
			</b-row>
			<div
				ref="appSettingTable"
			/>
		</base-block>
	</div>
</template>
