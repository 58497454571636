import {
	Vue, Component, Prop, Watch, toNative,
} from 'vue-facing-decorator';
import * as DB from 'interfaces/database';
import { MatchedOffering } from 'interfaces/app';
import Swal from 'sweetalert2';
import CustomSelect from 'components/CustomSelect';
import CustomCheckbox from 'components/CustomCheckbox';
import CustomCheckboxGroup from 'components/CustomCheckboxGroup';
import BulkQuantityService from 'services/BulkQuantityService';
import BulkService from 'services/BulkService';
import OfferingService from 'services/OfferingService';
import ProductGroupService from 'services/ProductGroupService';
import BulkOfferingService from 'services/BulkOfferingService';
import Template from './template.vue';

@Component({
	mixins: [Template],
	components: {
		CustomSelect,
		CustomCheckbox,
		CustomCheckboxGroup,
	},
})
class EditBulkDiscountTable extends Vue {
	private spinner = false;

	private visible = false;

	private selected = false;

	private BulkQuantityData = {} as DB.BulkQuantityModel;

	private BulkDiscountQuantities: DB.BulkQuantityModel[] = [];

	private selectedOfferings: number[] = [];

	private currentLastValue = 0;

	private subcategoryDisplay = false;

	private offeringDisplay = false;

	private existingCategoryDisplay = false;

	private pdp: DB.PDPModel[] = [];

	private count = ['pages', 'products'];

	private productGroup: DB.ProductGroupModel[] = [];

	private offering: DB.OfferingModel[] = [];

	private singleBulkDiscount = {} as DB.BulkModel;

	private isLoading = false;

	@Prop({
		type: String,
		required: true,
	})
	public readonly bulkDiscountId!: string;

	private get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	protected mounted(): void {
		this.fetchData();
		this.getSingleBulkDiscount();
		this.fetchBulkDiscountQuantities();
	}

	protected async addBulkQuantity(): Promise<void> {
		this.isLoading = true;

		try {
			const response = await BulkQuantityService.create(
				{
					...this.BulkQuantityData,
					bulkid: Number(this.bulkDiscountId),
				},
			);
			this.BulkDiscountQuantities.push(response);
			this.BulkQuantityData = {
				from: null,
				to: null,
				relative: null,
				absolute: null,
				absolute_q: null,
				id: null,
				bulkid: null,
			};
		} catch (err: any) {
			this.$toastError(err.message);
		} finally {
			this.isLoading = false;
		}
	}

	@Watch('loggedIn')
	private async getSingleBulkDiscount(): Promise<DB.BulkModel | undefined> {
		if (this.loggedIn) {
			// set the loading state
			this.spinner = true;
			try {
				const resp = await BulkService.get(this.bulkDiscountId);
				this.singleBulkDiscount = resp;
				// eslint-disable-next-line consistent-return
				return this.singleBulkDiscount;
			} catch (error: any) {
				this.$toastError(error.message);
			} finally {
				this.spinner = false;
			}
		}
		return undefined;
	}

	private fetchBulkDiscountQuantities(): void {
		const Params = new URLSearchParams({
			limit: '0',
		});
		Promise
			.all([
				BulkService.getAllBulkQuantity(
					Number(this.bulkDiscountId),
					Params,
				),
			])
			.then(([bulkQuantityResponse]) => {
				this.BulkDiscountQuantities = bulkQuantityResponse;
				return null;
			})
			.catch((err) => {
				this.$toastError(err.message);
			});
	}

	protected async saveBulkDiscountQuantity(item: DB.BulkQuantityModel): Promise<void> {
		this.isLoading = true;
		try {
			await BulkQuantityService.update(
				item.id as number,
				{
					...item,
					bulkid: parseInt(
						this.bulkDiscountId,
						10,
					),
				},
			);
		} catch (err: any) {
			this.$toastError(err.message);
		} finally {
			this.isLoading = false;
		}
	}

	protected removeBulkDiscountQuanitity(id: number): Promise<void> {
		return Swal.fire({
			title: 'Are you sure?',
			text: 'You will not be able to recover this imaginary file!',
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'btn btn-danger m-1',
				cancelButton: 'btn btn-secondary m-1',
			},
			confirmButtonText: 'Yes, delete it!',
			html: false,
		}).then((result) => {
			if (result.value) {
				// eslint-disable-next-line promise/no-nesting
				BulkQuantityService.delete(id).then(() => {
					const indexOfUserToRemove = this.BulkDiscountQuantities.findIndex((u) => u.id === id);
					this.BulkDiscountQuantities.splice(
						indexOfUserToRemove,
						1,
					);
					return null;
				}).catch((err) => {
					this.$toastError(err.message);
				});
			}
			return undefined;
		}).catch((err) => {
			this.$toastError(err.message);
		});
	}

	protected editSingleBulkDiscount(): void {
		if (this.loggedIn) {
			this.spinner = true;
			BulkService.update(
				this.bulkDiscountId,
				{
					...this.singleBulkDiscount,
				},
			).then(() => {
				this.$toastSuccess('Bulk Discount Edited successfully');
				return undefined;
			})
				.finally(() => {
					this.spinner = false;
				})
				.catch((error) => {
					this.$toastError(error.message);
				});
		}
	}

	protected toggleOfferings(_value: number[], event: InputEvent): void {
		const isChecked = (event.target as HTMLInputElement).checked;
		const currentValue = (event.target as HTMLInputElement).value;
		const matchedOffering: MatchedOffering[] = [];
		// get all the offering details that match the groupid's in selectedOfferings
		this.offering.forEach((offering) => {
			if (this.selectedOfferings.includes(offering.groupid)) {
				matchedOffering.push({
					offeringid: offering.id,
					groupid: offering.groupid,
					variantid: offering.variantid,
					id: offering.id,
					typeid: offering.typeid,
				});
			}
		});
		this.currentLastValue = Number(currentValue);
		if (isChecked) {
			BulkOfferingService.create(
				matchedOffering,
			).then(() => undefined).catch((err) => {
				this.$toastError(err.message);
			});
		} else {
			const ids: number[] = [];
			this.offering.filter((offering) => {
				if (offering.groupid === this.currentLastValue) {
					ids.push(offering.id);
				}
				return offering.id;
			});
			const params = new URLSearchParams({
				where: JSON.stringify({
					id: ids,
				}),
			});
			BulkOfferingService.deleteWhere(params).then(() => undefined).catch((err) => {
				this.$toastError(err.message);
			});
		}
	}

	private fetchData(): void {
		const Params = new URLSearchParams({
			limit: '0',
		});
		Promise
			.all([
				ProductGroupService.getAll(Params),
				OfferingService.getAll(Params),
			])
			.then(([productGroupResponse, offeringResponse]) => {
				this.productGroup = productGroupResponse;
				this.offering = offeringResponse.map((off: DB.OfferingModel) => ({
					...off,
					switchStatus: false, // Add switchStatus property with initial value
				}));
				return null;
			}).catch((error) => {
				this.$toastError(error.message);
			});
	}
}

export default toNative(EditBulkDiscountTable);
