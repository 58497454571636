<template>
	<div>
		<!-- Hero -->
		<base-page-heading
			title="Offering"
			subtitle=""
			class="d-print-none"
		>
			<template #extra>
				<b-breadcrumb class="breadcrumb-alt">
					<b-breadcrumb-item href="javascript:void(0)">
						Offering Category
					</b-breadcrumb-item>
					<b-breadcrumb-item active>
						offering
					</b-breadcrumb-item>
				</b-breadcrumb>
			</template>
		</base-page-heading>

		<div class="row">
			<OfferingsTable
				@routeOffering="routeOffering"
				@openTab="openTab"
			/>
		</div>
	</div>
</template>
