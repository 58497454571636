import { OfferingOptionValueModel } from 'interfaces/database';
import ApiService from 'services/ApiService';

class OfferingOptionValueService extends ApiService<OfferingOptionValueModel> {
	constructor() {
		super('offeringoptionvalue');
	}
}

export default new OfferingOptionValueService();
