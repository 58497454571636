<template>
	<div
		style="width: 95%; height: 100%;"
		class="content ml-5 mt-4"
	>
		<loading-overlay
			:active="isLoading"
			:can-cancel="true"
		/>
		<b-row>
			<b-col md="12">
				<b-form>
					<b-tabs
						card
						content-class="mt-3"
						fill
					>
						<b-tab
							title="Discount properties"
							active
						>
							<base-block
								rounded
								title="Discount properties"
								header-bg
							>
								<b-row class="py-sm-3 py-md-5">
									<b-col
										sm="10"
										md="10"
									>
										<b-form-group
											label="Campaign title"
											label-for="block-form1-username"
											label-cols-lg="5"
										>
											<b-form-input
												v-model="createDiscountData.name"
												class="form-control-alt"
												placeholder="name"
											/>
										</b-form-group>

										<b-form-group
											label="Tracker"
											label-for="example-select"
											label-cols-lg="5"
										>
											<b-input-group>
												<custom-select
													id="example-select"
													v-model="createDiscountData.trackerid"
													plain
												>
													<b-form-select-option
														v-for="(track,index) in tracker "
														:key="track.id"
														:value="tracker[index].id"
													>
														{{ track.name }}
													</b-form-select-option>
												</custom-select>
											</b-input-group>
										</b-form-group>

										<b-form-group
											label="Referral Program"
											label-for="example-select"
											label-cols-lg="5"
										>
											<b-input-group>
												<custom-select
													id="example-select"
													v-model="createDiscountData.referralprogramid"
													plain
												>
													<b-form-select-option :value="undefined">
														Select Referral Program
													</b-form-select-option>
													<b-form-select-option
														v-for="(refer) in referralProgram"
														:key="refer.id"
														:value="refer.id"
													>
														{{ refer.name }}
													</b-form-select-option>
												</custom-select>
											</b-input-group>
										</b-form-group>

										<b-form-group
											label="Discount Type: "
											label-for="example-select"
											label-cols-lg="5"
										>
											<b-button-group>
												<b-button
													:variant="absolute === true ? 'success' : 'secondary'"
													@click="ToggleDiscount('absolute')"
												>
													Absolute
												</b-button>
												<b-button
													:variant="relative === true ? 'success' : 'secondary'"
													@click="ToggleDiscount('relative')"
												>
													Relative
												</b-button>
											</b-button-group>
										</b-form-group>

										<div v-if="absolute">
											<b-form-group
												label="value"
												label-for="example-select"
												label-cols-lg="5"
											>
												<b-input-group
													size="lg"
													prepend="£"
													class="flex"
												>
													<b-form-input class="form-control" />
													<div class="value">
														<custom-select
															id="example-select"
															v-model="currencyModel"
															class="form-control"
															plain
														>
															<b-form-select-option
																v-for="(cur) in currency"
																:key="cur.id"
																:value="cur.id"
															>
																{{ cur.id }}
															</b-form-select-option>
														</custom-select>
													</div>
												</b-input-group>
											</b-form-group>
										</div>

										<div v-if="relative">
											<b-form-group
												label="Percentage"
												label-for="example-select"
												label-cols-lg="5"
											>
												<b-input-group>
													<b-form-input aria-label="Small text input with custom switch" />
													<b-input-group-append is-text>
														small
													</b-input-group-append>
												</b-input-group>
											</b-form-group>

											<b-form-group
												label="Max. Products:"
												label-for="block-form1-username"
												label-cols-lg="5"
											>
												<b-form-input
													v-model="createDiscountData.relative"
													type="number"
													class="form-control-alt"
													placeholder="Max. Products"
												/>
											</b-form-group>

											<b-form-group
												label="Extra Pages: "
												label-for="block-form1-username"
												label-cols-lg="5"
											>
												<b-form-input
													v-model="createDiscountData.extrapages"
													type="number"
													class="form-control-alt"
													placeholder="Extra Pages"
												/>
											</b-form-group>
										</div>

										<b-form-group
											label="Max Orders: "
											label-for="block-form1-username"
											label-cols-lg="5"
										>
											<b-form-input
												v-model="createDiscountData.quantity"
												type="number"
												class="form-control-alt"
												placeholder="Max Orders"
											/>
										</b-form-group>

										<b-form-group
											label="Global Shipping Discount: "
											label-for="example-select"
											label-cols-lg="5"
										>
											<b-input-group>
												<custom-select
													id="per-page-select"
													v-model="shipping"
													:options="shippingOptions"
													size="sm"
												/>
											</b-input-group>
										</b-form-group>

										<b-form-group
											label="Free Handling: "
											label-class="font-w600"
											label-cols-lg="5"
										>
											<custom-checkbox
												id="checkbox-1"
												v-model="createDiscountData.freehandling"
												is-switch="true"
												size="lg"
											/>
										</b-form-group>

										<b-form-group
											label="! voucher Per User: "
											label-class="font-w600"
											label-cols-lg="5"
										>
											<custom-checkbox
												id="checkbox-2"
												is-switch="true"
												size="lg"
											/>
										</b-form-group>

										<b-form-group
											label="Registered User Only: "
											label-class="font-w600"
											label-cols-lg="5"
										>
											<custom-checkbox
												id="checkbox-3"
												v-model="createDiscountData.registered"
												is-switch="true"
												size="lg"
											/>
										</b-form-group>

										<b-form-group
											label="New Customers Only: "
											label-class="font-w600"
											label-cols-lg="5"
										>
											<custom-checkbox
												id="checkbox-4"
												v-model="createDiscountData.newcustomers"
												is-switch="true"
												size="lg"
											/>
										</b-form-group>
									</b-col>
								</b-row>
							</base-block>
						</b-tab>
						<b-tab
							title="Valid Offerings"
						>
							<base-block
								title="Valid Offerings"
								header-bg
							>
								<div>
									<b-form-group v-slot="{ ariaDescribedby }">
										<custom-checkbox-group
											id="checkbox-group-1"
											ref="checkboxRef"
											v-model="selectedOfferings"
											:options="productGroup"
											value-field="id"
											text-field="name"
											:aria-describedby="ariaDescribedby"
											name="flavour-1"
											@change="toggleOfferings(selectedOfferings, $event)"
										/>
									</b-form-group>
								</div>
								<b-row
									v-for="(off, index) in offering"
									:key="index"
								>
									<b-col md="4">
										<custom-checkbox
											:id="`checkbox-${index}`"
											is-switch="true"
											size="lg"
											:checked="selectedOfferings.includes(off.groupid)"
										/>
									</b-col>
									<b-col md="8">
										<div>{{ off.id }} - {{ off.name }} - {{ off.description }}</div>
									</b-col>
								</b-row>
							</base-block>
						</b-tab>
					</b-tabs>
				</b-form>
			</b-col>
		</b-row>
	</div>
</template>

<style scoped>
.value {
	display: block;
	width: 100%;
}
</style>
