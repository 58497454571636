<template>
	<div
		style="width: 95%"
		class="ml-5 mt-4"
	>
		<loading-overlay
			:active="isLoaded"
			:can-cancel="true"
		/>
		<base-block
			title="Existing Country"
			header-bg
		>
			<div
				ref="countryTable"
			/>
		</base-block>
	</div>
</template>
