<template>
	<div
		class="ml-5 mt-4"
		style="width: 95%; height: 100%;"
	>
		<loading-overlay
			:active="isLoading"
			:can-cancel="true"
		/>
		<b-row>
			<b-col
				md="12"
				lg="12"
				sm="12"
			>
				<b-tabs
					card
					content-class="mt-3"
					fill
				>
					<b-tab
						title="Templatestate properties"
						active
					>
						<base-block
							title="Template properties"
							header-bg
						>
							<div
								v-if="spinner"
								class="text-center"
							>
								<div
									class="spinner-grow text-primary m-5"
									role="status"
								>
									<span class="sr-only">Loading...</span>
								</div>
							</div>
							<div
								v-else
								class="d-block"
							>
								<b-form-group
									class="form-group"
									label="Type"
									label-for="block-form1-name"
									label-cols-lg="4"
								>
									<b-input-group>
										<custom-select
											id="block-form-layoutid"
											v-model="singleTemplateState.type"
											disabled
										>
											<b-form-select-option :value="null">
												Please select an option
											</b-form-select-option>
											<b-form-select-option
												v-for="(op,id) in options"
												:key="id"
												:value="op"
											>
												{{ op }}
											</b-form-select-option>
										</custom-select>
									</b-input-group>
								</b-form-group>
								<b-form-group
									class="form-group"
									label-for="block-form1-name"
									label-cols-lg="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												Priority
											</div>
											<div
												id="priority"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="priority"
										triggers="hover"
										placement="bottom"
									>
										Can be used to prioritize template positions over others
									</b-tooltip>
									<b-input-group>
										<b-form-input
											v-model="singleTemplateState.priority"
											type="text"
										/>
										<template #append>
											<b-input-group-text
												class="input-group-text-alt"
												style="cursor:pointer"
											>
												<custom-checkbox
													id="checkbox-priority"
													name="checkbox-1"
													:checked="isPriorityNull"
													unchecked-value="not_accepted"
													@change="setPriorityToZero"
												/>
												Null
											</b-input-group-text>
										</template>
									</b-input-group>
								</b-form-group>
								<b-form-group
									label-class="font-w600"
									label-cols-lg="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												Combine
											</div>
											<div
												id="combine"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="combine"
										triggers="hover"
										placement="bottom"
									>
										Can this position be combined with positions from other priority groups
									</b-tooltip>
									<custom-checkbox
										id="checkbox-1"
										:is-switch="true"
										size="lg"
										:checked="isCombineTrue"
										@change="onChangeEvent($event)"
									/>
								</b-form-group>
							</div>
						</base-block>
					</b-tab>
					<b-tab
						title="Template Positions"
					>
						<TemplatePosition
							:template-state-id="templateStateId"
							@routeTemplateState="routeTemplateState"
						/>
					</b-tab>
				</b-tabs>
			</b-col>
		</b-row>
	</div>
</template>

<style lang="scss" scoped>
@import "../../../../assets/scss/oneui/tooltip";
.thumbnail{
	display:flex;
	justify-content:space-around;
	align-items:Center;
	background-color: #ccc;
	margin-top:10px;
}
.cameraCheckbox {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-left: 40px;
}
</style>
