import {
	Vue, Component, Watch, toNative,
} from 'vue-facing-decorator';
import * as DB from 'interfaces/database';
import { useRoute } from 'vue-router';
import CustomSelect from 'components/CustomSelect';
import CustomCheckbox from 'components/CustomCheckbox';
import RegionService from 'services/RegionService';
import CurrencyService from 'services/CurrencyService';
import CountryService from 'services/CountryService';
import Template from './EditCountryTable.vue';

@Component({
	mixins: [Template],
	components: {
		CustomSelect,
		CustomCheckbox,
	},
})
class EditCountryTable extends Vue {
	private singleCountry = {} as DB.CountryModel;

	private currency: DB.CurrencyModel[] = [];

	private region: DB.RegionModel[] = [];

	private loaded = false;

	private visible = false;

	private spinner = false;

	private index = 0;

	private isLoading = false;

	private pageOptions = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50];

	private route = useRoute();

	get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	mounted() {
		const parameter = new URLSearchParams({
			limit: '0',
		});
		this.getSingleCountry();
		Promise
			.all([
				CurrencyService.getAll(parameter),
				RegionService.getAll(parameter),
			])
			.then(([currencyResponse, regionResponse]) => {
				this.currency = currencyResponse;
				this.region = regionResponse;
				return null;
			}).catch((error) => {
				this.$toastError(error.message);
			});
	}

	@Watch('loggedIn')
	async getSingleCountry(): Promise<DB.CountryModel | undefined> {
		if (this.loggedIn) {
			// set the loading state
			this.isLoading = true;
			try {
				const resp = await CountryService.get(this.route.params.id as string);
				this.singleCountry = resp;
				// eslint-disable-next-line consistent-return
				return this.singleCountry;
			} catch (error: any) {
				this.$toastError(error.message);
			} finally {
				this.isLoading = false;
			}
		}
		return undefined;
	}

	editCountry(): Promise<void> | undefined {
		if (this.loggedIn) {
			this.isLoading = true;
			CountryService.update(
				this.route.params.id as string,
				{ ...this.singleCountry },
			).then(() => {
				this.$toastSuccess('country Edited successfully');
				return undefined;
			})
				.finally(() => {
					this.isLoading = false;
				})
				.catch((error) => {
					this.$toastError(error.message);
				});
		}
		return undefined;
	}
}

export default toNative(EditCountryTable);
