import {
	Vue, Component, Watch, Prop, toNative,
} from 'vue-facing-decorator';
import * as DB from 'interfaces/database';
import { httpClient } from 'utils/http';
import { PickerOptions, PickerResponse } from 'filestack-js';
import { PlaceHolderModel } from 'interfaces/app';
import CustomSelect from 'components/CustomSelect';
import CustomCheckbox from 'components/CustomCheckbox';
import CustomFileInput from 'components/CustomFileInput';
import Template from './template.vue';

@Component({
	components: {
		CustomSelect,
		CustomCheckbox,
		CustomFileInput,
	},
	mixins: [Template],
})
class PageObjectEditTable extends Vue {
	@Prop({
		type: String,
		required: true,
	})
	public readonly objectId!: string;

	private singlePageObjectEdit = {} as Record<string, DB.PageObjectModel | number | string | null>;

	private fontData = {} as DB.FontModel;

	private loaded = false;

	private placeHolders: Pick<PlaceHolderModel, 'icon'> = {
		icon: 'Choose a file',
	};

	private subObjectProperty: { source: string} = {
		source: '',
	};

	private options = ['Photo', 'text'];

	private fonts = {} as DB.FontModel;

	private alignment = ['left', 'center', 'right'];

	private visible = false;

	private spinner = false;

	private index = 0;

	private isLoading = false;

	private get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	protected mounted(): void {
		this.getSinglePageObjectAndFont();
	}

	@Watch('loggedIn')
	private async getSinglePageObjectAndFont(): Promise<void> {
		const parameter = new URLSearchParams({
			limit: '0',
		});
		if (this.loggedIn) {
			// set the loading state
			this.isLoading = true;
			try {
				const [fontResponse, singlePageObjectResponse] = await Promise.all([
					httpClient.get<DB.FontModel>(`/api/object/${this.objectId}/subobject?${parameter}`),
					httpClient.get<Record<string, DB.PageObjectModel>>(`/api/object/${this.objectId}`),
				]);
				this.fonts = fontResponse.data;
				this.singlePageObjectEdit = singlePageObjectResponse.data;
			} catch (error: any) {
				this.$toastError(error.message);
			} finally {
				this.isLoading = false;
			}
		}
		return undefined;
	}

	protected setPropertyNull(property: string): void {
		this.singlePageObjectEdit[property] = null;
	}

	protected checkPropertyNull(property: string): boolean {
		return this.singlePageObjectEdit[property] === null;
	}

	protected checkPropertyTrue(property: string): boolean {
		return this.singlePageObjectEdit[property] === 1;
	}

	protected updateProperty(property: string, event: number): void {
		this.singlePageObjectEdit[property] = event;
	}

	protected async editPageObject(): Promise<void> {
		this.isLoading = true;
		await this.editSubObject();
		return httpClient.put<DB.PageObjectModel>(
			`/api/object/${this.objectId}`,
			{ ...this.singlePageObjectEdit },
		).then(() => {
			this.$toastSuccess('Page Object Edited successfully');
			return undefined;
		})
			.finally(() => {
				this.isLoading = false;
			})
			.catch((error) => {
				this.$toastError(error.message);
			});
	}

	protected editSubObject(): Promise<void> | undefined {
		if (this.loggedIn) {
			this.isLoading = true;
			return httpClient.put<DB.PageObjectPhotoModel>(
				`/api/objectphoto/${this.singlePageObjectEdit.id}`,
				{
					objectid: this.objectId,
					source: this.subObjectProperty.source || null,
				},
			).then(() => undefined)
				.finally(() => {
					this.isLoading = false;
				})
				.catch((error) => {
					this.$toastError(error.message);
				});
		}
		return Promise.resolve(undefined);
	}

	protected updateIcon(): void {
		const options: PickerOptions = {
			fromSources: ['local_file_system'],
			onUploadDone: async (files: PickerResponse) => {
				this.subObjectProperty.source = files.filesUploaded[0].url;
				this.placeHolders.icon = files.filesUploaded[0].filename;
			},
		};
		this.$client.picker(options).open();
	}

	protected removeIcon(): void {
		this.subObjectProperty.source = '';
	}
}

export default toNative(PageObjectEditTable);
