<template>
	<base-block
		title="Shopping Cart Items"
		header-bg
	>
		<div
			ref="shoppingCartItemsTable"
		/>
	</base-block>
</template>
