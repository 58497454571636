import 'core-js/stable';
import { createApp } from 'vue';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import 'bootstrap';
import BootstrapVue from 'bootstrap-vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faLink, faUser, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import VueSweetalert2 from 'vue-sweetalert2';
import VueObserveVisibility from 'vue-observe-visibility';
import CountryFlag from 'vue-country-flag-next';
import * as filestack from 'filestack-js';
import {
	configure, defineRule, Field,
} from 'vee-validate';
import { localize } from '@vee-validate/i18n';
import * as rules from '@vee-validate/rules';
import en from '@vee-validate/i18n/dist/locale/en.json';
import nl from '@vee-validate/i18n/dist/locale/nl.json';
import fr from '@vee-validate/i18n/dist/locale/fr.json';
import es from '@vee-validate/i18n/dist/locale/es.json';
import toast from 'utils/toast';
import auth0 from './auth/authWrapper';
import store from './store/index';
// Your app initialization logic goes here
import App from './App.vue';
import router from './router';
// Custom components
import BaseLayoutModifier from './components/BaseLayoutModifier.vue';
import BaseBlock from './components/BaseBlock.vue';
import BaseBackground from './components/BaseBackground.vue';
import BasePageHeading from './components/BasePageHeading.vue';
import BaseNavigation from './components/BaseNavigation.vue';
// Custom directives
import clickRipple from './directives/clickRipple';
import toggleClass from './directives/toggleClass';
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
import 'tabulator-tables/dist/css/tabulator_bootstrap5.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Bugsnag.start({
	apiKey: '58f627a9cb9aa4dc8642806705b3c189',
	plugins: [new BugsnagPluginVue()],
	onUncaughtException: (event) => {
		// report error to bugsnag
		Bugsnag.notify(event);
	},
	// report errors to bugnag dashboard
	autoTrackSessions: true,
	enabledReleaseStages: ['production', 'staging', 'development'],
});
const bugsnagVue = Bugsnag.getPlugin('vue');

const app = createApp(App);
app.use(router);
app.use(store);
app.use(auth0);
if (bugsnagVue) {
	app.use(bugsnagVue);
}

// initialize filestack
const client = filestack.init(FILESTACK_API_KEY);
app.use(VueObserveVisibility);
app.use(BootstrapVue as any);
app.use(VueSweetalert2);
app.use(toast);
// Register global components
app.component(
	'LoadingOverlay',
	Loading,
);
app.component(
	BaseLayoutModifier.name,
	BaseLayoutModifier,
);
app.component(
	BaseBlock.name,
	BaseBlock,
);
app.component(
	BaseBackground.name,
	BaseBackground,
);
app.component(
	BasePageHeading.name,
	BasePageHeading,
);
app.component(
	BaseNavigation.name,
	BaseNavigation,
);
app.component(
	'CountryFlag',
	CountryFlag,
);
app.component(
	'Field',
	Field,
);

// eslint-disable-next-line no-restricted-syntax
Object.keys(rules).forEach((rule) => {
	defineRule(
		rule,
		(rules as any)[rule],
	);
});

configure({
	generateMessage: localize({
		en,
		nl,
		fr,
		es,
	}),
});

// Register global directives
app.directive(
	'click-ripple',
	clickRipple,
);
app.directive(
	'toggle-class',
	toggleClass,
);

library.add(
	faLink,
	faUser,
	faPowerOff,
);
app.component(
	'FontAwesomeIcon',
	FontAwesomeIcon,
);

app.mount('#app');

app.config.globalProperties.$client = client;
