import {
	Vue, Component, Watch, Prop, Ref, toNative,
} from 'vue-facing-decorator';
import * as DB from 'interfaces/database';
import CountryFlagGlob from 'components/Tabulator/CountryFlag';
import { PickerOptions, PickerResponse } from 'filestack-js';
import mitt from 'mitt';
import { TabEvent } from 'interfaces/app';
import { CellComponentExtended, ColumnDefinitionExtended, TabulatorFull as Tabulator } from 'tabulator-tables';
import { createInstance } from 'utils/vue';
import TabulatorSwitchBox from 'components/Tabulator/TabulatorSwitchBox';
import { nextTick } from 'vue';
import CustomCheckbox from 'components/CustomCheckbox';
import CustomFileInput from 'components/CustomFileInput';
import RegionCurrencyService from 'services/RegionCurrencyService';
import CountryService from 'services/CountryService';
import CurrencyService from 'services/CurrencyService';
import RegionService from 'services/RegionService';
import OfferingTable from '../OfferingTable';
import Template from './template.vue';

const eventBus = mitt<TabEvent<DB.CurrencyModel>>();
@Component({
	components: {
		CountryFlagGlob,
		TabulatorSwitchBox,
		OfferingTable,
		CustomCheckbox,
		CustomFileInput,
	},
	mixins: [Template],
})
class EditRegionTable extends Vue {
	@Ref('countryTable')
	private readonly countryTableReference!: HTMLDivElement;

	@Ref('currencyTable')
	private readonly currencyTableReference!: HTMLDivElement;

	private singleRegion = {} as DB.RegionModel;

	private region: DB.RegionCurrencyModel[] = [];

	private productCategory: DB.ProductCategoryModel[] = [];

	private language: DB.LanguageModel[] = [];

	private loaded = false;

	private placeHolders = {
		mobile: 'Choose a file',
		desktop: 'Choose a file',
	};

	private offeringDisplay = false;

	private visible = false;

	private spinner = false;

	private index = 0;

	private tabIndex = 0;

	private isLoading = false;

	private checkRegion = {} as Record<string, DB.RegionCurrencyModel>;

	private checkOffering = {} as Record<string, DB.RegionOfferingModel>;

	private rowData: DB.OfferingModel[] = [];

	private countryGrid = {
		columnDefs: [] as ColumnDefinitionExtended[],
		table: null as unknown as Tabulator,
	};

	private currencyGrid = {
		columnDefs: [] as ColumnDefinitionExtended[],
		table: null as unknown as Tabulator,
	};

	@Prop({
		type: String,
		required: true,
	})
	private readonly routeId!: string;

	private get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	protected beforeMount(): void {
		this.currencyGrid.columnDefs = [
			{
				field: 'name',
				title: 'Currency',
			},
			{
				title: 'ISO code',
				field: 'id',
			},
			{
				title: 'Available In region?',
				formatter: (cell: CellComponentExtended<DB.CurrencyModel>) => {
					const data = cell.getData();
					const instance = createInstance({
						component: TabulatorSwitchBox,
						props: {
							data,
							eventBus,
							checked: Boolean(data && this.checkRegion[data.id]),
						},
					});
					return instance;
				},
			},
		] as ColumnDefinitionExtended[];
		this.countryGrid.columnDefs = [
			{
				field: 'iso',
				title: 'ISO',
			},
			{
				title: 'Name',
				field: 'name',
				formatter: (cell: CellComponentExtended<DB.CountryModel>) => {
					const data = cell.getData();
					const instance = createInstance({
						component: CountryFlagGlob,
						props: {
							data,
						},
					});
					return instance;
				},
				headerFilter: true,
			},
		] as ColumnDefinitionExtended[];
	}

	protected mounted(): void {
		this.getSingleRegion();
		nextTick(() => {
			this.getOfferingRegion();
		});
		eventBus.on(
			'switchBoxChanged',
			this.switchBoxChanged,
		);
		this.tableInitialization();
	}

	private switchBoxChanged(data: TabEvent<DB.CurrencyModel>['switchBoxChanged']): void {
		this.currencyGrid.table.alert('Loading...');
		if (data.event) {
			RegionCurrencyService.create(
				{
					regionid: parseInt(
						this.routeId,
						10,
					),
					currencyid: data.params.id,
				},
			)
				.then((response) => {
					this.checkRegion[response.currencyid] = response;
					return undefined;
				})
				.finally(() => {
					this.currencyGrid.table.clearAlert();
				}).catch((err) => {
					this.$toastError(err.message);
				});
		} else {
			RegionCurrencyService
				.delete(this.checkRegion[data.params.id].id)
				.then(() => {
					delete this.checkRegion[data.params.id];
					return undefined;
				})
				.finally(() => {
					this.currencyGrid.table.clearAlert();
				}).catch((err) => {
					this.$toastError(err.message);
				});
		}
	}

	protected beforeUnmount() {
		eventBus.off(
			'switchBoxChanged',
			this.switchBoxChanged,
		);
		this.currencyGrid.table.destroy();
		this.countryGrid.table.destroy();
	}

	private tableInitialization(): void {
		this.countryGrid.table = new Tabulator(
			this.countryTableReference,
			{
				height: '60vh',
				layout: 'fitColumns',
				columns: this.countryGrid.columnDefs,
			},
		);
		this.currencyGrid.table = new Tabulator(
			this.currencyTableReference,
			{
				height: '60vh',
				layout: 'fitColumns',
				columns: this.currencyGrid.columnDefs,
			},
		);
	}

	protected async getCountryData(): Promise<void> {
		const route = this.routeId;
		const parameter = new URLSearchParams({
			where: JSON.stringify({ regionid: route }),
			limit: '0',
		});
		this.countryGrid.table?.alert('Loading');
		try {
			const data = await CountryService.getAll(parameter);
			this.countryGrid.table?.setData(data);
		} catch (err: any) {
			this.$toastError(err.message);
		} finally {
			this.countryGrid.table?.clearAlert();
		}
	}

	protected async getCurrencyData(): Promise<void> {
		const parameter = new URLSearchParams({
			limit: '0',
		});
		this.currencyGrid.table?.alert('Loading');
		try {
			const data = await CurrencyService.getAll(parameter);
			this.currencyGrid.table?.setData(data);
		} catch (err: any) {
			this.$toastError(err.message);
		} finally {
			this.currencyGrid.table?.clearAlert();
		}
	}

	@Watch('loggedIn')
	private async getOfferingRegion(): Promise<void> {
		if (this.loggedIn) {
			const params = new URLSearchParams({
				where: JSON.stringify({ regionid: this.routeId }),
				limit: '0',
			});

			try {
				const resp = await RegionCurrencyService.getAll(params);
				this.checkRegion = resp.reduce(
					(obj, item) => ({
						...obj,
						[item.currencyid]: item,
					}),
					{},
				);
			} catch (error: any) {
				this.$toastError(error.message);
			}
		}
		return undefined;
	}

	@Watch('loggedIn')
	private async getSingleRegion(): Promise<DB.RegionModel | undefined> {
		if (this.loggedIn) {
			// set the loading state
			this.isLoading = true;
			try {
				const resp = await RegionService.get(this.routeId);
				this.singleRegion = resp;
				// eslint-disable-next-line consistent-return
				return this.singleRegion;
			} catch (error: any) {
				this.$toastError(error.message);
			} finally {
				this.isLoading = false;
			}
		}
		return undefined;
	}

	protected editRegion(): Promise<void> | undefined {
		if (this.loggedIn) {
			this.isLoading = true;
			RegionService.update(
				this.routeId,
				{ ...this.singleRegion },
			).then(() => {
				this.$toastSuccess('Region Edited successfully');
				return undefined;
			})
				.finally(() => {
					this.isLoading = false;
				})
				.catch((error) => {
					this.$toastError(error.message);
				});
		}
		return undefined;
	}

	protected async updateLogoDesktop(): Promise<void> {
		const options: PickerOptions = {
			fromSources: ['local_file_system'],
			onUploadDone: async (files: PickerResponse) => {
				this.singleRegion.logo_desktop = files.filesUploaded[0].url;
				this.placeHolders.desktop = files.filesUploaded[0].filename;
			},
		};
		this.$client.picker(options).open();
	}

	protected async updateLogoMobile(): Promise<void> {
		const options: PickerOptions = {
			fromSources: ['local_file_system'],
			onUploadDone: async (files: PickerResponse) => {
				this.singleRegion.logo_mobile = files.filesUploaded[0].url;
				this.placeHolders.mobile = files.filesUploaded[0].filename;
			},
		};
		this.$client.picker(options).open();
	}

	protected removeDesktop(): void {
		this.singleRegion.logo_desktop = '';
	}

	protected removeMobile(): void {
		this.singleRegion.logo_mobile = '';
	}
}

export default toNative(EditRegionTable);
