import {
	Vue, Component, Watch, Prop, toNative,
} from 'vue-facing-decorator';
import * as DB from 'interfaces/database';
import { PickerResponse } from 'filestack-js';
import CustomFileInput from 'components/CustomFileInput';
import LayoutService from 'services/LayoutService';
import LayoutTemplate from '../LayoutTemplate';
import Template from './template.vue';

@Component({
	components: {
		LayoutTemplate,
		CustomFileInput,
	},
	emits: ['routeLayoutTemplate'],
	mixins: [Template],
})
class EditLayoutsTable extends Vue {
	@Prop({
		type: String,
		required: true,
	})
	public readonly routeId!: string;

	private singleLayout = {} as DB.LayoutModel;

	private loaded = false;

	private placeHolders = {
		icon: 'Choose a file',
	};

	private visible = false;

	private spinner = false;

	private index = 0;

	private rowHeight = 60;

	private isLoading = false;

	private get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	protected mounted(): void {
		this.getSingleCategory();
	}

	@Watch('loggedIn')
	private async getSingleCategory(): Promise<DB.LayoutModel | undefined> {
		if (this.loggedIn) {
			// set the loading state
			this.isLoading = true;
			try {
				const resp = await LayoutService.get(this.routeId);
				this.singleLayout = resp;
				// eslint-disable-next-line consistent-return
				return this.singleLayout;
			} catch (error: any) {
				this.$toastError(error.message);
			} finally {
				this.isLoading = false;
			}
		}
		return undefined;
	}

	protected editPhotoframe(): Promise<void> | undefined {
		if (this.loggedIn) {
			this.isLoading = true;
			LayoutService.update(
				this.routeId,
				{ ...this.singleLayout },
			).then(() => {
				this.$toastSuccess('Layout Edited successfully');
				return undefined;
			})
				.finally(() => {
					this.isLoading = false;
				})
				.catch((error) => {
					this.$toastError(error.message);
				});
		}
		return undefined;
	}

	protected updateIcon(): void {
		const options = {
			fromSources: ['local_file_system'],
			onUploadDone: async (files: PickerResponse) => {
				this.singleLayout.icon = files.filesUploaded[0].url;
				this.placeHolders.icon = files.filesUploaded[0].filename;
			},
		};
		Vue.prototype.$client.picker(options).open();
	}

	protected routeLayoutTemplate(id: string): void {
		this.$emit(
			'routeLayoutTemplate',
			`${id}`,
		);
	}

	protected removeIcon(): void {
		this.singleLayout.icon = '';
	}
}

export default toNative(EditLayoutsTable);
