<template>
	<base-block
		title="Products"
		header-bg
	>
		<div
			ref="productsTable"
		/>
	</base-block>
</template>
