<template>
	<div>
		<div class="">
			<base-page-heading
				title="App Setting"
				subtitle=""
				class="d-print-none"
			>
				<template #extra>
					<b-button
						ref="showBtn"
						type="submit"
						size="md"
						variant="secondary"
						@click="showModal"
					>
						<i class="fa fa-fw fa-plus" /> 	Create new
					</b-button>
				</template>
			</base-page-heading>

			<div class="row content">
				<AppSettingTable />
			</div>
		</div>
	</div>
</template>
