<template>
	<div
		style="width: 95%"
		class="ml-5 mt-4"
	>
		<base-block
			title="Order Info"
			header-bg
		>
			<div
				v-if="spinner"
				class="text-center"
			>
				<div
					class="spinner-grow text-primary m-5"
					role="status"
				/>
			</div>
			<b-table-simple
				v-else
				responsive
				table-class="table-vcenter"
			>
				<b-thead head-variant="light" />
				<b-tbody>
					<b-tr>
						<b-td> Order Item ID</b-td>
						<b-td> {{ orderItem.id }} </b-td>
					</b-tr>
					<b-tr>
						<b-td> External ID</b-td>
						<b-td>
							{{ orderItem.externalOrderId }}
						</b-td>
					</b-tr>
					<b-tr>
						<b-td> Order ID</b-td>
						<b-td>
							<router-link :to="{ name: 'showOrder', params: { orderid: orderItem.orderid }}">
								{{ orderItem.orderid }}
							</router-link>
						</b-td>
					</b-tr>
					<b-tr>
						<b-td> Offering ID</b-td>
						<b-td>
							<router-link :to="{ name: 'editOffering', params: { id: orderItem.offeringid }}">
								{{ orderItem.offeringid }}
							</router-link>
						</b-td>
					</b-tr>
					<b-tr>
						<b-td> Product ID</b-td>
						<b-td>  <a :href="sanitizedUrl">{{ orderItem.productid }} </a> </b-td>
					</b-tr>
					<b-tr>
						<b-td> Offering name</b-td>
						<b-td> {{ offeringData.name }} {{ offeringData.description }} ( {{ offeringData.variantname }} ) </b-td>
					</b-tr>
					<b-tr>
						<b-td> Number of pages</b-td>
						<b-td> {{ orderItem.pages }} </b-td>
					</b-tr>
					<b-tr>
						<b-td> Order quantity</b-td>
						<b-td> {{ orderItem.quantity }} </b-td>
					</b-tr>
					<b-tr>
						<b-td> Shipping Price</b-td>
						<b-td> {{ orderItem.price_shipping }} </b-td>
					</b-tr>
					<b-tr>
						<b-td> Handling Price</b-td>
						<b-td> {{ orderItem.price_handling }} </b-td>
					</b-tr>
					<b-tr>
						<b-td> Product Price</b-td>
						<b-td> {{ orderItem.price_product }} </b-td>
					</b-tr>
					<b-tr>
						<b-td> Voucher discount</b-td>
						<b-td> {{ orderItem.discount_voucher }} </b-td>
					</b-tr>
					<b-tr>
						<b-td> Total Price</b-td>
						<b-td> {{ orderItem.salesvalue }} </b-td>
					</b-tr>
					<b-tr>
						<b-td> Product VAT</b-td>
						<b-td> {{ orderItem.vat }} </b-td>
					</b-tr>
					<b-tr>
						<b-td> Tracker ID</b-td>
						<b-td> {{ orderItem.affiliateid }} </b-td>
					</b-tr>
					<b-tr>
						<b-td> Download Printfile</b-td>
						<b-td> <i class="fa fa-fw fa-download" /></b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</base-block>

		<base-block
			title="Orderitem Status Info"
			header-bg
		>
			<div
				ref="orderitemStatusTable"
			/>
		</base-block>
	</div>
</template>
