import { Vue, Component, toNative } from 'vue-facing-decorator';
import ShippingTable from 'components/ShippingTable';
import Template from './template.vue';

@Component({
	components: {
		ShippingTable,
	},
	mixins: [Template],
})
class Shipping extends Vue {
	showModal(): void {
		this.$root?.$emit(
			'bv::show::modal',
			'shipping-modal',
			'#shippingShow',
		);
	}
}

export default toNative(Shipping);
