<template>
	<div class="">
		<base-page-heading
			title="Discount"
			subtitle=""
			class="d-print-none"
		>
			<template #extra>
				<b-button
					ref="pdpShow"
					type="submit"
					size="md"
					variant="secondary"
					@click="createDiscount"
				>
					<i class="fa fa-fw fa-plus" /> 	Create new
				</b-button>
			</template>
		</base-page-heading>

		<div class="row content">
			<DiscountTable
				@routeDiscount="routeDiscount"
			/>
		</div>
	</div>
</template>
