import {
	Vue, Component, Ref, toNative,
} from 'vue-facing-decorator';
import EditProductCategoriesTable from 'components/ProductCategoriesTable/EditProductCategoriesTable/';
import Template from './template.vue';

@Component({
	components: {
		EditProductCategoriesTable,
	},
	mixins: [Template],
})
class EditProductCategories extends Vue {
	@Ref('editproductcategory')
	public readonly editproduct!: HTMLFormElement;

	private isLoading = false;

	private get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	protected updateProductCategoryProperties(): void {
		this.editproduct.editSingleProductCategory();
	}
}

export default toNative(EditProductCategories);
