import {
	Vue, Component, Ref, Prop, toNative,
} from 'vue-facing-decorator';
import * as DB from 'interfaces/database';
import { httpClient } from 'utils/http';
import { ColumnDefinitionExtended, TabulatorFull as Tabulator } from 'tabulator-tables';
import Template from './template.vue';

@Component({
	components: {},
	mixins: [Template],
})
class UserEmailLog extends Vue {
	@Ref('emailLogTable')
	private allTableReference!: HTMLDivElement;

	@Prop({
		type: String,
		required: true,
	})
	private readonly routeId!: string;

	private table?: Tabulator;

	private columnDefs: ColumnDefinitionExtended[] = [];

	private get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	protected beforeMount(): void {
		this.columnDefs = [
			{
				field: 'provider',
				title: 'Provider',
			},
			{
				title: 'Email ID',
				field: 'emailid',
			},
			{
				title: 'Email Address',
				field: 'emailaddress',
			},
			{
				title: 'Action',
				field: 'action',
			},
		];
	}

	protected mounted(): void {
		this.tableInitialization();
		this.table?.on(
			'tableBuilt',
			this.onTableBuilt,
		);
	}

	protected beforeUnmount() {
		this.table?.off(
			'tableBuilt',
			this.onTableBuilt,
		);
		this?.table?.destroy();
	}

	private onTableBuilt(): void {
		this.getData();
	}

	private tableInitialization(): void {
		this.table = new Tabulator(
			this.allTableReference,
			{
				height: '60vh',
				layout: 'fitColumns',
				columns: this.columnDefs,
			},
		);
	}

	private async getData(): Promise<void> {
		const id = this.routeId;
		const parameter = new URLSearchParams({
			limit: '0',
			where: JSON.stringify({
				userid: parseInt(
					id,
					10,
				),
			}),
		});

		this.table?.alert('Loading');
		try {
			const { data } = await httpClient.get<DB.EmailLogType[]>(`/api/emaillog?${parameter}`);
			this.table?.setData(data);
		} catch (err: any) {
			this.$toastError(err.message);
		} finally {
			this.table?.clearAlert();
		}
	}
}

export default toNative(UserEmailLog);
