import {
	Vue, Component, Watch, Prop, toNative,
} from 'vue-facing-decorator';
import * as DB from 'interfaces/database';
import CustomCheckbox from 'components/CustomCheckbox';
import AddressService from 'services/AddressService';
import Template from './template.vue';

@Component({
	mixins: [Template],
	components: {
		CustomCheckbox,
	},
})
class EditPickupStoresTable extends Vue {
	@Prop({
		type: String,
		required: true,
	})
	public readonly routeId!: string;

	private singlePickupStore = {} as DB.AddressModel;

	private loaded = false;

	private visible = false;

	private spinner = false;

	private index = 0;

	private isLoading = false;

	private get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	protected mounted(): void {
		this.getSinglePickupStore();
	}

	@Watch('loggedIn')
	private async getSinglePickupStore(): Promise<DB.AddressModel | undefined> {
		if (this.loggedIn) {
			// set the loading state
			this.isLoading = true;
			try {
				const resp = await AddressService.get(this.routeId);
				this.singlePickupStore = resp;
				// eslint-disable-next-line consistent-return
				return this.singlePickupStore;
			} catch (error: any) {
				this.$toastError(error.message);
			} finally {
				this.isLoading = false;
			}
		}
		return undefined;
	}

	protected editPickupStores(): Promise<void> | undefined {
		if (this.loggedIn) {
			this.isLoading = true;
			AddressService.update(
				this.routeId,
				{ ...this.singlePickupStore },
			).then(() => {
				this.$toastSuccess('Pickup Store Edited successfully');
				return undefined;
			})
				.finally(() => {
					this.isLoading = false;
				})
				.catch((error) => {
					this.$toastError(error.message);
				});
		}
		return undefined;
	}
}

export default toNative(EditPickupStoresTable);
