import { ThemeCategoryLinkModel } from 'interfaces/database';
import ApiService from 'services/ApiService';
import { httpClient } from 'utils/http';

class ThemeCategoryLinkService extends ApiService<ThemeCategoryLinkModel> {
	constructor() {
		super('themecategorylink');
	}

	// eslint-disable-next-line class-methods-use-this
	async removeLink(themeid: number, categoryid: number): Promise<ThemeCategoryLinkModel> {
		const response = await httpClient.delete<ThemeCategoryLinkModel>(
			'/api/themeborderlink',
			{
				data: {
					themeid,
					categoryid,
				},
			},
		);

		return response.data;
	}

	// eslint-disable-next-line class-methods-use-this
	async addLinks(item: Omit<ThemeCategoryLinkModel, 'id'>[]): Promise<ThemeCategoryLinkModel[]> {
		const response = await httpClient.put(
			'/api/themecategorylinks',
			item,
		);
		return response.data;
	}
}

export default new ThemeCategoryLinkService();
