import {
	Vue, Component, Watch, Prop, Ref, toNative,
} from 'vue-facing-decorator';
import * as DB from 'interfaces/database';
import mitt from 'mitt';
import { TabEvent, MatchedOffering } from 'interfaces/app';
import {
	CellComponent, CellComponentExtended, ColumnDefinitionExtended, TabulatorFull as Tabulator,
} from 'tabulator-tables';
import { createInstance } from 'utils/vue';
import { saveAs } from 'file-saver';
import TabulatorBtn from 'components/Tabulator/TabulatorBtn';
import { useRoute } from 'vue-router';
import CustomSelect from 'components/CustomSelect';
import CustomCheckbox from 'components/CustomCheckbox';
import CustomCheckboxGroup from 'components/CustomCheckboxGroup';
import DiscountVoucherService from 'services/DiscountVoucherService';
import BulkOfferingService from 'services/BulkOfferingService';
import DiscountService from 'services/DiscountService';
import AffiliateService from 'services/AffiliateService';
import ReferralProgramService from 'services/ReferralProgramService';
import ProductGroupService from 'services/ProductGroupService';
import OfferingService from 'services/OfferingService';
import ThemeService from 'services/ThemeService';
import ThemeCategoryService from 'services/ThemeCategoryService';
import ThemeCategoryLinkService from 'services/ThemeCategoryLinkService';
import Template from './template.vue';

const eventBus = mitt<TabEvent<DB.DiscountVoucherModel>>();
@Component({
	mixins: [Template],
	components: {
		CustomSelect,
		CustomCheckbox,
		CustomCheckboxGroup,
	},
})
class DiscountEditTable extends Vue {
	@Ref('discountVouchersTable')
	private readonly tableReference!: HTMLDivElement;

	private spinner = false;

	private addRemarkData = '';

	private visible = false;

	private selected = false;

	private index = 0;

	private subcategoryDisplay = false;

	private offeringDisplay = false;

	private existingCategoryDisplay = false;

	private route = useRoute();

	private shippingOptions: string[] =	[
		'No free shipping',
		'Free economy shipping',
		'Free shipping with tracking',
		'Free express shipping',
	];

	private table?: Tabulator;

	private discountVoucherData = {} as DB.DiscountVoucherModel;

	private singleDiscountData = {} as DB.DiscountModel;

	private selectedOfferings: number[] = [];

	private currentLastValue = 0;

	private trackerData: DB.AffiliateModel[] = [];

	private productGroup: DB.ProductGroupModel[] = [];

	private referral: DB.ReferralProgramModel[] = [];

	private showDiscountVoucher = false;

	private offering:	DB.OfferingModel[] = [];

	private selectedRows: DB.DiscountVoucherModel[] = [];

	private columnDefs: ColumnDefinitionExtended[] = [];

	private selectedCategories: DB.ThemeCategoryModel[] = [];

	private availableCategories: DB.ThemeCategoryModel[] = [];

	private currentItem = {} as DB.ThemeCategoryModel;

	private availableCategoryActiveIndex: null | number = null;

	private selectedCategoryActiveIndex: null | number = null;

	private voucherLength = 0;

	private rowData: DB.OfferingModel[] = [];

	private filterSubCatergory = null;

	private categorySearch = '';

	private isLoading = false;

	private pageOptions = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50];

	@Prop({
		type: String,
		required: true,
	})
	public readonly discountEditId!: string;

	private get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	protected beforeMount(): void {
		this.columnDefs = [
			{
				field: 'id',
				title: 'ID',
				headerFilter: true,
			},
			{
				title: 'Remark',
				formatter: (cell: CellComponentExtended<DB.DiscountVoucherModel>) => {
					const instance = createInstance({
						component: TabulatorBtn,
						props: {
							data: cell.getData(),
							buttons: [
								{
									id: 'remark',
									eventName: 'remarkItem',
									className: 'fa-comment',
								},
							],
							eventBus,
						},
					});
					return instance;
				},
				editor: 'textarea',
				cellEdited: this.cellValueChanged,
			},
			{
				title: 'Issue',
				formatter: (cell: CellComponentExtended<DB.DiscountVoucherModel>) => {
					const claimed = document.createElement('div');
					claimed.classList.add('text-center');
					claimed.innerText = `Claimed: ${cell.getData().claimed}`;

					const used = document.createElement('div');
					used.classList.add('text-center');
					used.innerText = `Used: ${cell.getData().used}`;

					const container = document.createElement('div');
					container.appendChild(claimed);
					container.appendChild(used);

					return container;
				},
			},
			{
				title: 'Voucher Code',
				field: 'vouchercode',
			},
			{
				title: 'Valid',
				formatter: (cell: CellComponentExtended<DB.DiscountVoucherModel>) => {
					const validity = document.createElement('div');
					validity.classList.add('text-center');
					const icon = document.createElement('i');
					if (cell.getData().validity) {
						icon.classList.add(
							'fa',
							'fa-check-circle',
							'text-success',
						);
					} else {
						icon.classList.add(
							'fa',
							'fa-times-circle',
							'text-danger',
						);
					}
					validity.appendChild(icon);
					return validity;
				},
			},
			{
				title: 'Limit',
				field: 'limit',
				formatter: (cell: CellComponentExtended<DB.DiscountVoucherModel>) => {
					const input = document.createElement('input');
					input.setAttribute(
						'type',
						'number',
					);
					input.setAttribute(
						'disabled',
						'true',
					);
					input.setAttribute(
						'value',
						cell.getData().limit.toString(),
					);
					input.classList.add('text-center');
					return input;
				},
			},
			{
				title: 'Start',
				field: 'startdate',
				formatter: (cell: CellComponentExtended<DB.DiscountVoucherModel>) => {
					const input = document.createElement('input');
					input.setAttribute(
						'type',
						'date',
					);
					input.setAttribute(
						'disabled',
						'true',
					);
					input.setAttribute(
						'value',
						cell.getValue().toString(),
					);
					input.classList.add('text-center');
					return input;
				},
			},
			{
				title: 'End',
				field: 'enddate',
				formatter: (cell: CellComponentExtended<DB.DiscountVoucherModel>) => {
					const input = document.createElement('input');
					input.setAttribute(
						'type',
						'date',
					);
					input.setAttribute(
						'disabled',
						'true',
					);
					input.setAttribute(
						'value',
						cell.getData().enddate.toString(),
					);
					input.classList.add('text-center');
					return input;
				},
			},
		] as ColumnDefinitionExtended[];
	}

	protected mounted(): void {
		this.tableInitialization();
		this.fetchData();
		this.getThemeCategories();
		this.onGridReadyDiscount();
		this.table?.on(
			'tableBuilt',
			this.onTableBuilt,
		);
	}

	private tableInitialization(): void {
		this.table = new Tabulator(
			this.tableReference,
			{
				height: '60vh',
				layout: 'fitColumns',
				columns: this.columnDefs,
				selectable: true,
			},
		);
	}

	protected beforeUnmount() {
		this.table?.off(
			'tableBuilt',
			this.onTableBuilt,
		);
		this.table?.off(
			'rowSelectionChanged',
			this.onSelectionChanged,
		);
		this.table?.destroy();
	}

	private onTableBuilt(): void {
		this.onGridReadyDiscount().then(() => this.table?.on(
			'rowSelectionChanged',
			this.onSelectionChanged,
		)).catch((err) => {
			this.$toastError(err.message);
		});
	}

	private async onGridReadyDiscount(): Promise<void> {
		const parameter = new URLSearchParams({
			where: JSON.stringify({
				discountid: this.discountEditId,
			}),
			limit: '0',
		});

		this.table?.alert('Loading');
		try {
			const data = await DiscountVoucherService.getAll(parameter);
			this.table?.setData(data);
		} catch (err: any) {
			this.$toastError(err.message);
		} finally {
			this.table?.clearAlert();
		}
	}

	protected toggleDiscountVoucher(): void {
		this.showDiscountVoucher = !this.showDiscountVoucher;
	}

	protected exportSelected(): void {
		const csvString = this.convertToCsvString(this.selectedRows);
		const blob = new Blob(
			[csvString],
			{ type: 'text/csv;charset=utf-8' },
		);
		saveAs(
			blob,
			'Data.csv',
		);
	}

	private convertToCsvString(rows: DB.DiscountVoucherModel[]): string {
		const separator = ',';
		const headers = Object.keys(rows[0]) as (keyof DB.DiscountVoucherModel)[];
		const csvRows: string[] = [];

		// Create the header row
		const headerRow = headers.join(separator);
		csvRows.push(headerRow);

		// Create the data rows
		// eslint-disable-next-line no-restricted-syntax
		for (const row of rows) {
			const values = headers.map((header) => {
				const cellValue = row[header];
				return this.escapeCsvValue(cellValue);
			});
			const csvRow = values.join(separator);
			csvRows.push(csvRow);
		}

		return csvRows.join('\n');
	}

	// eslint-disable-next-line class-methods-use-this
	private escapeCsvValue(value: string | number | null | undefined): string {
		let escapedValue = String(value).replace(
			/"/g,
			'""',
		);
		if (escapedValue.includes(',') || escapedValue.includes('"') || escapedValue.includes('\n')) {
			escapedValue = `"${escapedValue}"`;
		}
		return escapedValue;
	}

	protected async exportAll(): Promise<void> {
		const parameter = new URLSearchParams({
			where: JSON.stringify({
				discountid: this.discountEditId,
			}),
			limit: '0',
		});
		this.table?.alert('Loading');
		try {
			const data = await DiscountVoucherService.getAll(parameter);
			const csvString = this.convertToCsvString(data);
			const blob = new Blob(
				[csvString],
				{ type: 'text/csv;charset=utf-8' },
			);
			saveAs(
				blob,
				'Data.csv',
			);
		} catch (error: any) {
			this.$toastError(error.message);
		} finally {
			this.table?.clearAlert();
		}
	}

	protected cellValueChanged(cell: CellComponent): void {
		this.table?.alert('Loading..');
		const rowData = cell.getData() as DB.DiscountVoucherModel;
		DiscountVoucherService.update(
			rowData.id,
			{
				remark: rowData.remark,
			},
		).then(() => {
			this.$toastSuccess('Remark updated');
			return undefined;
		}).finally(() => {
			this.table?.clearAlert();
		})
			.catch((error) => {
				this.$toastError(error.message);
			});
	}

	private onSelectionChanged(): void {
		if (this.table) {
			this.selectedRows = this.table.getSelectedData();
		}
	}

	protected toggleOfferings(_value: number[], event: InputEvent): void {
		const isChecked = (event.target as HTMLInputElement).checked;
		const currentValue = (event.target as HTMLInputElement).value;
		const matchedOffering: MatchedOffering[] = [];
		// get all the offering details that match the groupid's in selectedOfferings
		this.offering.forEach((offering) => {
			if (this.selectedOfferings.includes(offering.groupid)) {
				matchedOffering.push({
					offeringid: offering.id,
					groupid: offering.groupid,
					variantid: offering.variantid,
					id: offering.id,
					typeid: offering.typeid,
				});
			}
		});
		this.currentLastValue = Number(currentValue);
		if (isChecked) {
			BulkOfferingService.create(
				matchedOffering,
			).then(() => undefined).catch((err) => {
				this.$toastError(err.message);
			});
		} else {
			const ids: number[] = [];
			this.offering.filter((offering) => {
				if (offering.groupid === this.currentLastValue) {
					ids.push(offering.id);
				}
				return offering.id;
			});
			const params = new URLSearchParams({
				where: JSON.stringify({
					id: ids,
				}),
			});
			BulkOfferingService.deleteWhere(params).then(() => undefined).catch((err) => {
				this.$toastError(err.message);
			});
		}
	}

	protected saveDiscountVoucher(): void {
		this.isLoading = true;
		DiscountService.saveDiscountVoucher(
			this.discountEditId,
			{
				...this.discountVoucherData,
				createdby: this.$auth0?.user,

			} as DB.DiscountVoucherModel,
		).then((res) => {
			this.$toastSuccess('Discount Voucher Added');
			// add data to table
			this.table?.addData([res]);
			return undefined;
		})
			.finally(() => {
				this.isLoading = false;
			})
			.catch((err) => {
				this.$toastError(err.message);
			});
	}

	protected singleDiscountEdit(): Promise<void> | undefined {
		if (this.loggedIn) {
			this.spinner = true;
			DiscountService.update(
				this.discountEditId,
				{
					...this.singleDiscountData,
				},
			).then(() => {
				this.$toastSuccess('Discount Edited successfully');
				return undefined;
			})
				.finally(() => {
					this.spinner = false;
				})
				.catch((error) => {
					this.$toastError(error.message);
				});
		}
		return undefined;
	}

	@Watch('loggedIn')
	private fetchData(): void {
		if (this.loggedIn) {
			const params = new URLSearchParams({
				limit: '0',
			});
			const referralParams = new URLSearchParams({
				limit: '0',
				fields: 'id,name',
			});
			// set the loading state
			this.spinner = true;
			Promise.all([
				DiscountService.get(this.discountEditId),
				AffiliateService.getAll(),
				ReferralProgramService.getAll(referralParams),
				ProductGroupService.getAll(params),
				OfferingService.getAll(params),
			])
				.then(([discountResponse, AffiateResponse, ReferrelResponse, ProductResponse, offeringResponse]) => {
					this.singleDiscountData = discountResponse;
					this.trackerData = AffiateResponse;
					this.referral = ReferrelResponse;
					this.productGroup = ProductResponse;
					this.offering = offeringResponse;
					return null;
				}).finally(() => {
					this.spinner = false;
				})
				.catch((error) => {
					this.$toastError(error.message);
				});
		}
		return undefined;
	}

	protected selectedItem(data: DB.ThemeCategoryModel, index: number): void {
		this.availableCategoryActiveIndex = index;
		this.currentItem = data;
	}

	protected availableItem(data: DB.ThemeCategoryModel, index: number): void {
		this.selectedCategoryActiveIndex = index;
		this.currentItem = data;
	}

	private getThemeCategories(): void {
		this.isLoading = true;
		const params = new URLSearchParams({
			limit: '0',
		});

		Promise.all([
			ThemeService.getAllThemeCategories(this.discountEditId),
			ThemeCategoryService.getAll(params),
		])
			.then(([selectedTheme, AvailableTheme]) => {
				this.selectedCategories = selectedTheme;
				const combinedArray = [...AvailableTheme, ...selectedTheme];
				this.availableCategories = [...new Set(combinedArray)];
				return null;
			}).finally(() => {
				this.isLoading = false;
			})
			.catch((error) => {
				this.$toastError(error.message);
			});
	}

	protected async addToSelected(): Promise<void> {
		this.isLoading = true;
		try {
			const themeLink = await ThemeCategoryLinkService.create(
				{
					themeid: parseInt(
						this.route.params.id as string,
						10,
					),
					categoryid: this.currentItem.id,
				},
			);
			this.selectedCategories.push(this.currentItem);
			const findAndDelete = this.availableCategories.findIndex((obj) => obj.id === themeLink.categoryid);
			this.availableCategories.splice(
				findAndDelete,
				1,
			);
		} catch (error: any) {
			this.$toastError(error.message);
		} finally {
			this.isLoading = false;
		}
	}

	protected async removeFromSelected(): Promise<void> {
		this.isLoading = true;
		try {
			const themeLink = await ThemeCategoryLinkService.removeLink(
				parseInt(
					this.route.params.id as string,
					10,
				),
				this.currentItem.id,
			);
			this.availableCategories.push(this.currentItem);
			const findAndDelete = this.selectedCategories.findIndex((obj) => obj.id === themeLink.categoryid);
			this.selectedCategories.splice(
				findAndDelete,
				1,
			);
		} catch (error: any) {
			this.$toastError(error.message);
		} finally {
			this.isLoading = false;
		}
	}
}

export default toNative(DiscountEditTable);
