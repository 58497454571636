<template>
	<div
		style="width: 95%"
		class="ml-5"
	>
		<loading-overlay
			:active="isLoading"
			:can-cancel="true"
		/>
		<b-row class="d-flex justify-content-end mb-4">
			<b-col
				sm="5"
				md="3"
				class="my-1"
				align-self="center"
			>
				<b-form-group
					label="Product Group"
					label-for="search-group-select"
					label-cols-sm="6"
					label-cols-md="4"
					label-cols-lg="4"
					label-align-sm="right"
					label-size="sm"
					class="mb-0"
				>
					<custom-select
						id="search-group-select"
						v-model="selectedProductGroup"
						size="sm"
						@change="onSelectProductGroup"
					>
						<b-form-select-option
							v-for="(productGroup) in productGroupModels "
							:key="productGroup.id"
							:value="productGroup"
						>
							{{ productGroup.name }}
						</b-form-select-option>
					</custom-select>
				</b-form-group>
			</b-col>
			<b-col
				sm="5"
				md="3"
				class="my-1"
				align-self="center"
			>
				<b-form-group
					label="Language:"
					label-for="search-group-select"
					label-cols-sm="6"
					label-cols-md="4"
					label-cols-lg="4"
					label-align-sm="right"
					label-size="sm"
					class="mb-0"
				>
					<custom-select
						id="search-group-select"
						v-model="language"
						size="sm"
						:options="languageOptions"
						@change="translateTableData(language)"
					/>
				</b-form-group>
			</b-col>
		</b-row>
		<base-block
			title="Offerings"
			header-bg
		>
			<div
				ref="offeringTable"
			/>
		</base-block>
	</div>
</template>
