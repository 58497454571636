import {
	Vue, Component, Prop, Ref, toNative,
} from 'vue-facing-decorator';
import * as DB from 'interfaces/database';
import mitt from 'mitt';
import Swal from 'sweetalert2';
import { TabEvent } from 'interfaces/app';
import { httpClient } from 'utils/http';
import { CellComponentExtended, ColumnDefinitionExtended, TabulatorFull as Tabulator } from 'tabulator-tables';
import { createInstance } from 'utils/vue';
import TabulatorBtn from 'components/Tabulator/TabulatorBtn';
import { useRoute } from 'vue-router';
import CustomSelect from 'components/CustomSelect';
import Template from './template.vue';

const eventBus = mitt<TabEvent<DB.ThemeLayoutLinkModel>>();
@Component({
	components: {
		TabulatorBtn,
		CustomSelect,
	},
	emits: ['routeLayout'],
	mixins: [Template],
})
class ThemeLayout extends Vue {
	@Ref('ThemesLayoutsTable')
	private readonly tableReference!: HTMLDivElement;

	private isLoading = false;

	private columnDefs: ColumnDefinitionExtended[] = [];

	private showExistingLayout = false;

	private showNewLayout = false;

	private layoutdata = {} as DB.LayoutModel;

	private ExistingLayoutsData = {} as DB.LayoutModel;

	private serialnumber: undefined | number = undefined;

	private layoutTemplate: DB.LayoutModel[] = [];

	private layoutTheme: DB.ThemeLayoutLinkModel[] = [];

	private table?: Tabulator;

	private route = useRoute();

	@Prop({
		type: String,
		required: true,
	})
	public readonly routeId!: string;

	private get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	protected beforeMount(): void {
		this.columnDefs = [
			{
				field: 'name',
				title: 'Name',
			},
			{
				title: 'Icon',
				formatter: 'image',
				formatterParams: {
					height: 150,
				},
			},
			{
				title: 'Actions',
				formatter: (cell: CellComponentExtended<DB.ThemeLayoutLinkModel>) => {
					const instance = createInstance({
						component: TabulatorBtn,
						props: {
							data: cell.getData(),
							buttons: [
								{
									id: 'edit',
									eventName: 'editItem',
									className: 'fa-edit',
								},
								{
									id: 'delete',
									eventName: 'deleteItem',
									className: 'fa-times',
								},
							],
							eventBus,
						},
					});
					return instance;
				},
			},
		] as ColumnDefinitionExtended[];
	}

	protected toggleNewLayout(): void {
		this.showNewLayout = !this.showNewLayout;
	}

	protected toggleExistingLayout(): void {
		this.showExistingLayout = !this.showExistingLayout;
	}

	protected mounted(): void {
		eventBus.on(
			'editItem',
			this.editThemeLayout,
		);
		eventBus.on(
			'deleteItem',
			this.deleteThemeLayout,
		);
		this.tableInitialization();
		this.table?.on(
			'tableBuilt',
			this.onTableBuilt,
		);
	}

	protected beforeUnmount(): void {
		eventBus.off(
			'editItem',
			this.editThemeLayout,
		);
		eventBus.off(
			'deleteItem',
			this.deleteThemeLayout,
		);
		this.table?.off(
			'tableBuilt',
			this.onTableBuilt,
		);
		this.table?.destroy();
	}

	private onTableBuilt(): void {
		this.getData();
	}

	private tableInitialization(): void {
		this.table = new Tabulator(
			this.tableReference,
			{
				height: '60vh',
				layout: 'fitColumns',
				columns: this.columnDefs,
			},
		);
	}

	private async getData(): Promise<void> {
		const parameter = new URLSearchParams({
			limit: '0',
		});
		this.table?.alert('Loading');
		try {
			const { data } = await httpClient.get<DB.ThemeLayoutLinkModel[]>(`/api/theme/${this.routeId}/layouts?${parameter}`);
			this.layoutTheme = data;
			this.serialnumber = this.layoutTheme.length;
			this.table?.setData(data);
		} catch (err: any) {
			this.$toastError(err.message);
		} finally {
			this.table?.clearAlert();
		}
	}

	protected async addLayout(): Promise<void> {
		this.table?.alert('Loading Data');
		try {
			const response = await httpClient.post(
				'/api/layout',
				{
					name: this.layoutdata.name,
				},
			);
			const response2 = await httpClient.post(
				'/api/themelayout',
				{
					themeid: parseInt(
						this.routeId,
						10,
					),
					layoutid: response.data.id,
				},
			);
			this.table?.addData({
				...response2.data,
				name: response.data.name,
			});
		} catch (error: any) {
			this.$toastError(error.message);
		} finally {
			this.table?.clearAlert();
		}
	}

	protected async addLayouts(): Promise<void> {
		this.isLoading = true;
		try {
			await httpClient.post(
				'/api/layouttemplate',
				{
					layoutid: parseInt(
						this.route.params.id as string,
						10,
					),
					templateid: this.ExistingLayoutsData.id,
				},
			);
		} catch (error: any) {
			this.$toastError(error.message);
		} finally {
			this.isLoading = false;
		}
	}

	// route to Edit Layout Page
	private editThemeLayout(data: TabEvent<DB.ThemeLayoutLinkModel>['editItem']): void {
		this.$emit(
			'routeLayout',
			data.params.layoutid,
		);
	}

	// Delete item from the Themes page
	private deleteThemeLayout(data: TabEvent<DB.ThemeLayoutLinkModel>['deleteItem']): void {
		Swal.fire({
			title: 'Are you sure?',
			text: 'You will not be able to recover this file!',
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'btn btn-danger m-1',
				cancelButton: 'btn btn-secondary m-1',
			},
			confirmButtonText: 'Yes, delete it!',
			html: false,
		}).then((result) => {
			if (result.value) {
				this.table?.alert('Deleting Data');
				// eslint-disable-next-line promise/no-nesting
				return httpClient.delete(`/api/themelayout/${data.id}`).then(() => {
					this.$toastSuccess('Item Deleted');
					this.table?.deleteRow(data.id);
					return null;
				}).finally(() => {
					this.table?.clearAlert();
				}).catch((err) => {
					this.$toastError(err.message);
				});
			}
			return undefined;
		}).catch((err) => {
			this.$toastError(err.message);
		});
	}
}

export default toNative(ThemeLayout);
