import {
	Vue, Component, Ref, toNative,
} from 'vue-facing-decorator';
import EditTemplateTable from 'components/Themes/TemplateTable/EditTemplateTable';
import { useRouter } from 'vue-router';
import Template from './template.vue';

@Component({
	components: {
		EditTemplateTable,
	},
	mixins: [Template],
})
class EditTemplate extends Vue {
	@Ref('edittemplate')
	private readonly template!: HTMLFormElement;

	private router = useRouter();

	protected updateTemplateProperties(): void {
		this.template.editTemplate();
	}

	protected routeTemplateEdit(
		data: {
			id: string,
			templateid: string
		},
	): void {
		this.router.push(`/template/${data.templateid}/state/${data.id}`);
	}
}

export default toNative(EditTemplate);
