import { createAuth0 } from '@auth0/auth0-vue';

const auth0 = createAuth0({
	domain: AUTH0_DOMAIN,
	clientId: AUTH0_CLIENTID,
	authorizationParams: {
		redirect_uri: window.location.origin,
	},
});

export default auth0;
