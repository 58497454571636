<template>
	<base-block
		ref="salesPri"
		rounded
	>
		<template #options />
		<div
			v-if="getLoadingStatus"
			class="text-center"
		>
			<div
				class="spinner-grow text-primary m-5"
				role="status"
			>
				<span class="sr-only">Loading...</span>
			</div>
		</div>
		<b-table-simple
			v-else
			responsive
			bordered
			striped
			table-class="table-vcenter"
		>
			<b-thead>
				<b-tr>
					<b-th
						style="width: 10%;"
						rowspan="3"
					>
						Currency
					</b-th>
					<b-th
						class="text-center"
						:colspan="editedOffering.maxpages === editedOffering.minpages ? 2 : 4"
					>
						Price (in cents)
					</b-th>
				</b-tr>
				<b-tr v-if="editedOffering.maxpages !== editedOffering.minpages">
					<b-td
						class="text-center"
						colspan="2"
					>
						Base Price
					</b-td>
					<b-td
						class="text-center"
						colspan="2"
					>
						Extra Pages
					</b-td>
				</b-tr>

				<b-tr>
					<b-td>From</b-td>
					<b-td>Current</b-td>
					<b-td v-if="editedOffering.maxpages !== editedOffering.minpages">
						From
					</b-td>
					<b-td v-if="editedOffering.maxpages !== editedOffering.minpages">
						Current
					</b-td>
				</b-tr>
			</b-thead>
			<b-tbody
				v-for="(salesP) in regionCurrencyForSalesGetter"
				:key="salesP.id"
			>
				<SalesTable
					:sales-p="salesP"
					:edited-offering="editedOffering"
				/>
			</b-tbody>
		</b-table-simple>
	</base-block>
</template>
