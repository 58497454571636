import { PDPModel } from 'interfaces/database';
import ApiService from 'services/ApiService';

class PDPService extends ApiService<PDPModel> {
	constructor() {
		super('pdp');
	}
}

export default new PDPService();
