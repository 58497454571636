import { RegionCurrencyModel } from 'interfaces/database';
import ApiService from 'services/ApiService';

class RegionCurrencyService extends ApiService<RegionCurrencyModel> {
	constructor() {
		super('regioncurrency');
	}
}

export default new RegionCurrencyService();
