import {
	Vue, Component, Watch, Prop, toNative,
} from 'vue-facing-decorator';
import * as DB from 'interfaces/database';
import { PickerOptions, PickerResponse } from 'filestack-js';
import CustomCheckbox from 'components/CustomCheckbox';
import CustomFileInput from 'components/CustomFileInput';
import { httpClient } from 'utils/http';
import Template from './template.vue';

@Component({
	mixins: [Template],
	components: {
		CustomCheckbox,
		CustomFileInput,
	},
})
class EditPageBgTable extends Vue {
	@Prop({
		type: String,
		required: true,
	})
	public readonly routeId!: string;

	private singlePageBg = {} as DB.PageBackgroundModel;

	private loaded = false;

	private visible = false;

	private spinner = false;

	private placeHolders = {
		thumbnailPlaceholder: 'Choose a file',
	};

	private index = 0;

	private isLoading = false;

	private get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	protected mounted(): void {
		this.getSinglePageBg();
	}

	protected get isNameChecked(): boolean {
		return this.singlePageBg.name === '';
	}

	protected get isColorChecked(): boolean {
		return this.singlePageBg.color === '';
	}

	protected handleCheckboxChange(): void {
		this.singlePageBg.name = '';
	}

	protected handleColorCheckboxChange(): void {
		this.singlePageBg.color = '';
	}

	@Watch('loggedIn')
	private async getSinglePageBg(): Promise<DB.PageBackgroundModel | undefined> {
		if (this.loggedIn) {
			// set the loading state
			this.isLoading = true;
			try {
				const resp = await httpClient.get<DB.PageBackgroundModel>(`/api/pagebg/${this.routeId}`);
				this.singlePageBg = resp.data;
				// eslint-disable-next-line consistent-return
				return this.singlePageBg;
			} catch (error: any) {
				this.$toastError(error.message);
			} finally {
				this.isLoading = false;
			}
		}
		return undefined;
	}

	protected async editPageBg(): Promise<void> {
		try {
			if (this.loggedIn) {
				this.isLoading = true;
				await httpClient.put<DB.PageBackgroundModel>(
					`/api/pagebg/${this.routeId}`,
					{ ...this.singlePageBg },
				);
				this.$toastSuccess('Page Background Edited successfully');
			}
		} catch (error: any) {
			this.$toastError(error.message);
		} finally {
			this.isLoading = false;
		}
	}

	//  update image preview
	protected async updateImagePreview(): Promise<void> {
		const options: PickerOptions = {
			fromSources: ['local_file_system'],
			onUploadDone: async (files: PickerResponse) => {
				this.singlePageBg.image = files.filesUploaded[0].url;
				this.placeHolders.thumbnailPlaceholder = files.filesUploaded[0].filename;
			},
		};
		this.$client.picker(options).open();
	}

	protected removeImage(): void {
		this.singlePageBg.image = '';
	}
}

export default toNative(EditPageBgTable);
