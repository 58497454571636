<template>
	<div
		v-if="data"
		class="container"
	>
		<span>{{ data.externalid }}</span>
		<span>{{ data.name }}</span>
	</div>
</template>
<style scoped>
	.container {
		display: flex;
		flex-direction: column;
		margin: 0;
		line-height: .8;
		padding: 0;
	}
	.container > span {
		padding-top: 6px;
	}
</style>
