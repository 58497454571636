import {
	Component,
	Model,
	Prop,
	Vue,
	Watch,
	toNative,
} from 'vue-facing-decorator';
import Template from './template.vue';

@Component({
	emits: ['change', 'input'],
	mixins: [Template],
})
class CustomCheckboxGroup extends Vue {
	@Model({ type: Array, name: 'value', default: () => [] })
	public readonly modelValue!: any[];

	@Prop({ type: Array, default: () => [] })
	public readonly options!: any[];

	@Prop({ type: String, default: 'value' })
	public readonly valueField!: string;

	@Prop({ type: String, default: 'text' })
	public readonly textField!: string;

	@Prop({ type: String, default: '' })
	public readonly name!: string;

	protected selectedValues: any[] = [];

	@Watch(
		'modelValue',
		{ immediate: true },
	)
	onValueChanged(val: any[]) {
		this.selectedValues = [...val];
	}

	onChange(event: Event) {
		this.$emit(
			'change',
			event,
		);
		this.$emit(
			'input',
			this.selectedValues,
		);
	}
}

export default toNative(CustomCheckboxGroup);
