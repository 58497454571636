<template>
	<div
		style="width: 95%"
		class="ml-5"
	>
		<loading-overlay
			:active="isLoading"
			:can-cancel="true"
		/>
		<b-modal
			id="bv-modal"
			ref="image-modal"
			hide-footer
		>
			<template #modal-title>
				Create New Badge Image
			</template>
			<div
				v-if="spinner"
				class="text-center"
			>
				<div
					class="spinner-grow text-primary m-5"
					role="status"
				>
					<span class="sr-only">Loading...</span>
				</div>
			</div>
			<div v-else>
				<b-form-group
					label="Region"
					label-for="example-selected"
					label-cols-lg="3"
				>
					<b-input-group>
						<custom-select
							id="example-selected"
							v-model="regionid"
							plain
						>
							<b-form-select-option :value="null">
								Please select an option
							</b-form-select-option>
							<b-form-select-option
								v-for="(reg,index) in region"
								:key="index"
								:value="reg.id"
							>
								{{ reg.id }} | {{ reg.name }}
							</b-form-select-option>
						</custom-select>
						<template #append>
							<b-input-group-text
								class="input-group-text-alt"
								style="cursor:pointer"
							>
								<custom-checkbox
									id="checkbox-1"
									name="checkbox-2"
									:checked="regionid == null ? true : false"
									unchecked-value="not_accepted"
									@change="()=> regionid = null"
								/>
								Null
							</b-input-group-text>
						</template>
					</b-input-group>
				</b-form-group>

				<b-form-group
					label="Language"
					label-for="example-selecting"
					label-cols-lg="3"
				>
					<b-input-group>
						<custom-select
							id="example-selecting"
							v-model="langid"
							plain
						>
							<b-form-select-option :value="null">
								Please select an option
							</b-form-select-option>
							<b-form-select-option
								v-for="(lang,id) in language "
								:key="id"
								:value="lang.id"
							>
								{{ lang.id }} | {{ lang.name }}
							</b-form-select-option>
						</custom-select>
						<template #append>
							<b-input-group-text
								class="input-group-text-alt"
								style="cursor:pointer"
							>
								<custom-checkbox
									id="checkbox-2"
									name="checkbox-1"
									:checked="langid == null ? true : false"
									unchecked-value="not_accepted"
									@change="()=> langid = null "
								/>
								Null
							</b-input-group-text>
						</template>
					</b-input-group>
				</b-form-group>

				<b-form-group
					label="Image"
					label-cols-lg="3"
				>
					<custom-file-input
						id="image"
						ref="image"
						:placeholder="placeHolders.thumbnailPlaceholder"
						@click.prevent="updateImagePreview"
					/>

					<div
						v-if="imagePreview"
						class="thumbnail"
					>
						<img
							:src="imagePreview"
							alt=""
							srcset=""
							height="100"
							width="100"
						>
						<div
							style="cursor:pointer"
							@click="removeImage"
						>
							&#10006; Remove
						</div>
					</div>
				</b-form-group>
				<b-button
					class="mt-3"
					block
					@click="addBadgeImage"
				>
					Save
				</b-button>
			</div>
		</b-modal>

		<b-modal
			id="bv-edit-modal"
			ref="image-edit-modal"
			hide-footer
		>
			<template #modal-title>
				Edit Badge Image
			</template>
			<div
				v-if="spinner"
				class="text-center"
			>
				<div
					class="spinner-grow text-primary m-5"
					role="status"
				>
					<span class="sr-only">Loading...</span>
				</div>
			</div>
			<div v-else>
				<b-form-group
					label="Region"
					label-for="region-select"
					label-cols-lg="3"
				>
					<b-input-group>
						<custom-select
							id="region-select"
							v-model.number="editBadgesDetails.regionid"
							plain
						>
							<b-form-select-option
								v-for="(reg,index) in region"
								:key="index"
								:value="reg.id"
							>
								{{ reg.id }} | {{ reg.name }}
							</b-form-select-option>
						</custom-select>
						<template #append>
							<b-input-group-text
								class="input-group-text-alt"
								style="cursor:pointer"
							>
								<custom-checkbox
									id="checkbox-3"
									name="checkbox-2"
									:checked="editBadgesDetails.regionid == null ? true : false"
									unchecked-value="not_accepted"
									@change="()=> editBadgesDetails.regionid = null "
								/>
								Null
							</b-input-group-text>
						</template>
					</b-input-group>
				</b-form-group>

				<b-form-group
					label="Language"
					label-for="language-select"
					label-cols-lg="3"
				>
					<b-input-group>
						<custom-select
							id="language-select"
							v-model="editBadgesDetails.languageid"
							plain
						>
							<b-form-select-option
								v-for="(lang,id) in language"
								:key="id"
								:value="lang.id"
							>
								{{ lang.id }} | {{ lang.name }}
							</b-form-select-option>
						</custom-select>
						<template #append>
							<b-input-group-text
								class="input-group-text-alt"
								style="cursor:pointer"
							>
								<custom-checkbox
									id="checkbox-4"
									name="checkbox-6"
									:checked="editBadgesDetails.languageid == null ? true : false"
									unchecked-value="not_accepted"
									@change="()=> editBadgesDetails.languageid = null "
								/>
								Null
							</b-input-group-text>
						</template>
					</b-input-group>
				</b-form-group>

				<b-form-group
					label="Image"
					label-cols-lg="3"
				>
					<custom-file-input
						id="editimage"
						ref="editimage"
						:placeholder="placeHolders.editBadgesPlaceholder"
						@click.prevent="updateEditPreview"
					/>

					<div
						v-if="editBadgesDetails.url"
						class="thumbnail"
					>
						<img
							:src="editBadgesDetails.url"
							alt=""
							srcset=""
							height="100"
							width="100"
						>
						<div
							style="cursor:pointer"
							@click="removeEditImage"
						>
							&#10006; Remove
						</div>
					</div>
				</b-form-group>
				<b-button
					class="mt-3"
					block
					@click="editBadgeImage()"
				>
					Save
				</b-button>
			</div>
		</b-modal>
		<b-row>
			<b-col
				md="12"
				lg="12"
				sm="12"
			>
				<b-tabs
					card
					content-class="mt-3"
					fill
				>
					<b-tab
						title="Badge properties"
						active
					>
						<base-block
							title="Badge properties"
							header-bg
						>
							<div class="d-block text-center">
								<b-form-group
									class="form-group"
									label="Name: "
									label-for="block-form1-name"
									label-cols-lg="3"
								>
									<div
										class="control"
									>
										<b-form-input
											v-if="singleBadge.name"
											ref="badgeImageName"
											v-model="singleBadge.name"
											type="text"
										/>
									</div>
								</b-form-group>
							</div>
						</base-block>
					</b-tab>
					<b-tab
						title="Badge Images"
					>
						<base-block
							title="Badge Images"
							header-bg
						>
							<b-row class="d-flex justify-content-end">
								<b-col
									md="6"
									class="mb-2 d-flex justify-content-end"
								>
									<router-link
										to="#"
										@click="showImageModal"
									>
										Add Image
									</router-link>
								</b-col>
							</b-row>
							<div
								ref="badgeImage"
							/>
						</base-block>
					</b-tab>
					<b-tab
						title="Offerings"
						@click="() => display.offeringDisplay = true"
					>
						<OfferingTable
							v-if="display.offeringDisplay"
							:route-id="routeId"
						/>
					</b-tab>
					<b-tab
						title="PDPs"
						@click="() => display.badgePdpDisplay = true"
					>
						<BadgePdpTable
							v-if="display.badgePdpDisplay"
							:route-id="routeId"
						/>
					</b-tab>
					<b-tab
						title="Lister Items"
						@click="()=> display.productCategoryDisplay = true"
					>
						<BadgeProductCategoryTable
							v-if="display.productCategoryDisplay"
							:route-id="routeId"
						/>
					</b-tab>
				</b-tabs>
			</b-col>
		</b-row>
	</div>
</template>

<style lang="scss" scoped>
@import "../../assets/scss/custom/errors";

.thumbnail{
	display:flex;
	justify-content:space-around;
	align-items:Center;
	background-color: #ccc;
	margin-top:10px;
}
</style>
