import {
	Component,
	Model,
	Prop,
	Vue,
	Watch,
	toNative,
} from 'vue-facing-decorator';
import { LanguageOptionsModel } from 'interfaces/app';
import Template from './template.vue';

@Component({
	emits: ['change', 'input'],
	mixins: [Template],
})
class CustomSelect extends Vue {
	@Prop({
		type: String,
		default: '',
	})
	public id!: string;

	@Prop({
		type: Array,
		default: () => [],
	})
	public readonly options!: LanguageOptionsModel[];

	@Prop({ type: String, default: '' })
	public size!: string;

	@Model({
		type: [String, Number],
		default: '',
		name: 'value',
	})
	public modelValue!: string | number;

	private selected: string | number = '';

	@Watch(
		'modelValue',
		{ immediate: true },
	)
	modelValueChanged(): void {
		if (this.modelValue !== this.selected) {
			this.selected = this.modelValue;
		}
	}

	onChange(): void {
		if (this.modelValue !== this.selected) {
			this.$emit(
				'input',
				this.selected,
			);
			this.$emit('change');
		}
	}
}

export default toNative(CustomSelect);
