import {
	Vue, Component, Ref, toNative,
} from 'vue-facing-decorator';
import EditThemesTable from 'components/Themes/ThemesTable/EditThemesTable';
import { useRoute, useRouter } from 'vue-router';
import Template from './template.vue';

@Component({
	components: {
		EditThemesTable,
	},
	mixins: [Template],
})
class EditTheme extends Vue {
	@Ref('edittheme')
	private readonly theme!: HTMLFormElement;

	private route = useRoute();

	private router = useRouter();

	protected updateThemeProperties(): void {
		this.theme.editTheme();
	}

	protected routeLayout(id: string): void {
		this.router.push(`/layout/${id}`);
	}

	protected routeThemePages(id: string): void {
		this.router.push(`/theme/${this.route.params.id}/page/${id}`);
	}
}

export default toNative(EditTheme);
