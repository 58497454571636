import { httpClient } from 'utils/http';
import {
	Vue, Component, Prop, Watch, toNative,
} from 'vue-facing-decorator';
import * as DB from 'interfaces/database';
import CustomSelect from 'components/CustomSelect';
import Template from './template.vue';

@Component({
	components: {
		CustomSelect,
	},
	emits: ['routeTemplate'],
	mixins: [Template],
})
class PageTemplate extends Vue {
	private isLoading = false;

	private template: DB.TemplateModel[] = [];

	private internalPageTemplate = {} as DB.PageModel;

	@Prop({
		type: String,
		required: true,
	})
	public readonly pageId!: string;

	@Prop({
		type: Object,
		required: true,
	})
	public readonly singlePageTemplate!: DB.PageModel;

	private get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	protected mounted(): void {
		this.getTemplate();
	}

	@Watch(
		'singlePageTemplate',
		{ immediate: true },
	)
	protected onSinglePageTemplateChange(): void {
		this.internalPageTemplate = this.singlePageTemplate;
	}

	private async getTemplate(): Promise<void> {
		this.isLoading = true;
		const params = new URLSearchParams({
			fields: 'id,name',
			limit: '0',
		});
		try {
			const { data } = await httpClient.get<DB.TemplateModel[]>(`/api/template?${params}`);
			this.template = data;
		} catch (error: any) {
			this.$toastError(error.message);
		} finally {
			this.isLoading = false;
		}
	}

	protected async onPageTemplateChange(): Promise<void> {
		this.isLoading = true;
		try {
			await httpClient.put(
				`/api/page/${this.pageId}`,
				{
					template: this.internalPageTemplate.template,
				},
			);
			this.$toastSuccess('Page Template Updated');
		} catch (error: any) {
			this.$toastError(error.message);
		} finally {
			this.isLoading = false;
		}
	}

	protected editPageTemplate(): void {
		this.$emit(
			'routeTemplate',
			this.internalPageTemplate.template,
		);
	}

	protected async deletePageTemplate(): Promise<void> {
		this.isLoading = true;
		try {
			await httpClient.put(
				`/api/page/${this.pageId}`,
				{
					template: null,
				},
			);
			this.internalPageTemplate = {} as DB.PageModel;
			this.$toastSuccess('Page Template Deleted');
		} catch (error: any) {
			this.$toastError(error.message);
		} finally {
			this.isLoading = false;
		}
	}
}

export default toNative(PageTemplate);
