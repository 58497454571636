<template>
	<div
		class=""
	>
		<!-- Hero -->
		<base-page-heading
			title="Pricing"
			subtitle=""
			class="d-print-none"
		/>

		<div
			class="container-fluid"
		>
			<PricingTable ref="updatePrice" />
		</div>
	</div>
</template>
