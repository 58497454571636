import { CellComponent, ColumnComponent, RowComponent } from 'tabulator-tables';
import {
	drawBorder as drawSelectBorder, removeBorder as removeSelectBorder,
} from 'utils/tabulator-select';

enum Borders {
	Top = 'top',
	Bottom = 'bottom',
	Left = 'left',
	Right = 'right'
}

function drawBorder(cell: CellComponent | undefined, direction: string) {
	cell?.getElement().classList.add(`autofill-${direction}`);
}

export function removeBorder(cell: CellComponent | undefined, direction: string) {
	cell?.getElement().classList.remove(`autofill-${direction}`);
}

function removeVerticalBorders(cell: CellComponent) {
	removeBorder(
		cell,
		Borders.Left,
	);
	removeBorder(
		cell,
		Borders.Right,
	);
}

function removeHorizontalBorders(cell: CellComponent) {
	removeBorder(
		cell,
		Borders.Top,
	);
	removeBorder(
		cell,
		Borders.Bottom,
	);
}

function removeAllBorders(cell: CellComponent) {
	removeHorizontalBorders(cell);
	removeVerticalBorders(cell);
}

export function autofillStart(cell: CellComponent, selectedCell: CellComponent, selectedColumns: ColumnComponent[], autofillRows: RowComponent[]) {
	const currRow = cell.getRow();
	const currRowPosition = currRow.getPosition();
	const bottomSelectedRowPosition = selectedCell.getRow().getPosition();
	const [leftSelectedColumn] = selectedColumns;
	const rightSelectedColumn = selectedColumns[selectedColumns.length - 1];

	if (currRowPosition > bottomSelectedRowPosition) {
		let bottomAutofillRow = autofillRows[autofillRows.length - 1];
		while (bottomAutofillRow && bottomAutofillRow.getPosition() >= currRowPosition) {
			// eslint-disable-next-line no-loop-func
			selectedColumns.forEach((col) => {
				const currCell = bottomAutofillRow.getCell(col);
				removeVerticalBorders(currCell);
				removeBorder(
					currCell,
					Borders.Bottom,
				);
			});
			autofillRows.pop();
			bottomAutofillRow = autofillRows[autofillRows.length - 1];
		}

		let nextAutofillRow = bottomAutofillRow?.getNextRow();
		while (nextAutofillRow && nextAutofillRow.getPosition() < currRow.getPosition()) {
			drawBorder(
				nextAutofillRow.getCell(leftSelectedColumn),
				Borders.Left,
			);
			drawBorder(
				nextAutofillRow.getCell(rightSelectedColumn),
				Borders.Right,
			);

			autofillRows.push(nextAutofillRow);
			nextAutofillRow = nextAutofillRow.getNextRow();
		}

		drawBorder(
			currRow.getCell(leftSelectedColumn),
			Borders.Left,
		);
		drawBorder(
			currRow.getCell(rightSelectedColumn),
			Borders.Right,
		);
		selectedColumns.forEach((col) => {
			removeBorder(
				bottomAutofillRow?.getCell(col),
				Borders.Bottom,
			);
			drawBorder(
				currRow.getCell(col),
				Borders.Bottom,
			);
		});
		autofillRows.push(currRow);
	} else if (currRowPosition < bottomSelectedRowPosition) {
		let [topAutofillRow] = autofillRows;
		while (topAutofillRow && topAutofillRow.getPosition() <= currRowPosition) {
			// eslint-disable-next-line no-loop-func
			selectedColumns.forEach((col) => {
				const currCell = topAutofillRow?.getCell(col);
				removeVerticalBorders(currCell);
				removeBorder(
					currCell,
					Borders.Top,
				);
			});
			autofillRows.shift();
			[topAutofillRow] = autofillRows;
		}

		let prevAutofillRow = topAutofillRow?.getPrevRow();
		while (prevAutofillRow && prevAutofillRow.getPosition() > currRow.getPosition()) {
			drawBorder(
				prevAutofillRow.getCell(leftSelectedColumn),
				Borders.Left,
			);
			drawBorder(
				prevAutofillRow.getCell(rightSelectedColumn),
				Borders.Right,
			);

			autofillRows.unshift(prevAutofillRow);
			prevAutofillRow = prevAutofillRow.getPrevRow();
		}

		drawBorder(
			currRow.getCell(leftSelectedColumn),
			Borders.Left,
		);
		drawBorder(
			currRow.getCell(rightSelectedColumn),
			Borders.Right,
		);
		selectedColumns.forEach((col) => {
			removeBorder(
				topAutofillRow?.getCell(col),
				Borders.Top,
			);
			drawBorder(
				currRow.getCell(col),
				Borders.Top,
			);
		});
		autofillRows.unshift(currRow);
	} else {
		selectedColumns.forEach((col) => {
			autofillRows.forEach((row) => {
				const currCell = row.getCell(col);
				removeAllBorders(currCell);
			});
		});
		while (autofillRows.length) autofillRows.pop();
	}
}

export function autofillStop(selectedRows: RowComponent[], selectedColumns: ColumnComponent[], autofillRows: RowComponent[]): RowComponent[] {
	const topSelectedRow = selectedRows[0];
	const bottomSelectedRow = selectedRows[selectedRows.length - 1];
	const topAutofillRow = autofillRows[0];
	const bottomAutofillRow = autofillRows[autofillRows.length - 1];
	const [leftSelectedColumn] = selectedColumns;
	const rightSelectedColumn = selectedColumns[selectedColumns.length - 1];

	let newSelectedRows: RowComponent[];

	if (topAutofillRow && topAutofillRow.getPosition() > bottomSelectedRow.getPosition()) {
		selectedColumns.forEach((col) => {
			removeSelectBorder(
				bottomSelectedRow.getCell(col),
				Borders.Bottom,
			);
			drawSelectBorder(
				bottomAutofillRow.getCell(col),
				Borders.Bottom,
			);
		});
		newSelectedRows = [...selectedRows, ...autofillRows];
	} else {
		selectedColumns.forEach((col) => {
			removeSelectBorder(
				topSelectedRow.getCell(col),
				Borders.Top,
			);
			if (topAutofillRow) {
				drawSelectBorder(
					topAutofillRow.getCell(col),
					Borders.Top,
				);
			}
		});
		newSelectedRows = [...autofillRows, ...selectedRows];
	}

	autofillRows.forEach((row) => {
		selectedColumns.forEach((col) => {
			const currCell = row.getCell(col);
			removeAllBorders(currCell);
		});

		drawSelectBorder(
			row.getCell(leftSelectedColumn),
			Borders.Left,
		);
		drawSelectBorder(
			row.getCell(rightSelectedColumn),
			Borders.Right,
		);
	});

	return newSelectedRows;
}
