<template>
	<tr v-if="editing === false">
		<b-td>{{ data.offeringid }}</b-td>
		<b-td>{{ data.description }}</b-td>
		<b-td>{{ data.optional }}</b-td>
		<b-td>{{ data.peritem }}</b-td>
		<b-td>{{ data.autoinclude }}</b-td>
		<b-td style="display:flex;margin-right:5px">
			<b-button
				size="sm"
				variant="primary"
				@click="editUpsell(up)"
			>
				Edit
			</b-button>
			<b-button
				size="sm"
				variant="danger"
				@click="deleteUpsell(up)"
			>
				Delete
			</b-button>
		</b-td>
	</tr>
	<tr v-else>
		<b-td>
			<b-form-input
				v-model="data.offeringid"
				type="text"
				placeholder="Offering ID"
				required
			/>
		</b-td>
		<b-td>
			<b-form-input
				v-model="data.description"
				type="text"
				placeholder="Description"
				required
			/>
		</b-td>
		<b-td>
			<b-form-input
				v-model="data.optional"
				type="text"
				placeholder="Optional"
				required
			/>
		</b-td>
		<b-td>
			<b-form-input
				v-model="data.peritem"
				type="text"
				placeholder="Per Item"
				required
			/>
		</b-td>
		<b-td>
			<b-form-input
				v-model="data.autoinclude"
				type="text"
				placeholder="Auto Include"
				required
			/>
		</b-td>
		<b-td style="display:flex;margin-right:5px">
			<b-button
				size="sm"
				variant="primary"
				@click="saveUpsell()"
			>
				Save
			</b-button>
			<b-button
				size="sm"
				variant="danger"
				@click="cancelUpsell()"
			>
				Cancel
			</b-button>
		</b-td>
	</tr>
</template>
