import { Vue, Component, toNative } from 'vue-facing-decorator';
import PageBgTable from 'components/Themes/PageBgTable';
import { useRouter } from 'vue-router';
import Template from './template.vue';

@Component({
	components: {
		PageBgTable,
	},
	mixins: [Template],
})
class PageBackground extends Vue {
	private router = useRouter();

	protected showModal(): void {
		this.$root?.$emit(
			'bv::show::modal',
			'pagebg-modal',
			'#pagebgShow',
		);
	}

	protected routePagebg(id: string): void {
		this.router.push(`/pagebg/${id}`);
	}
}

export default toNative(PageBackground);
