import {
	Vue, Component, Prop, toNative,
} from 'vue-facing-decorator';
import * as DB from 'interfaces/database';
import Template from './template.vue';

@Component({
	mixins: [Template],
})
class PriceDetails extends Vue {
	@Prop({
		type: Object,
		required: true,
	})
	public readonly data!: DB.OfferingModel;
}

export default toNative(PriceDetails);
