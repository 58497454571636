<template>
	<div
		class="mt-2 mb-2"
	>
		<img
			:src="url"
			:height="size"
			:width="size"
		>
		<b-button
			v-if="isEditable"
			class="mt-3"
			@click="editImage"
		>
			<i
				class="fa fa-file-upload"
			/>
		</b-button>
	</div>
</template>
