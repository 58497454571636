<template>
	<base-block
		title="Photos"
		header-bg
	>
		<b-row class="d-flex justify-content-end">
			<b-col
				md="6"
				class="d-flex justify-content-end mb-2"
			>
				<b-form-group>
					<custom-select
						id="per-page-select"
						v-model="photosourcesInput"
						size="sm"
						@change="getData()"
					>
						<b-form-select-option value="">
							--Select source --
						</b-form-select-option>
						<b-form-select-option
							v-for="(photo,index) in photosources"
							:key="index"
							:value="photo.id"
						>
							{{ photo.name }}
						</b-form-select-option>
					</custom-select>
				</b-form-group>
			</b-col>
		</b-row>
		<div
			ref="photosTable"
		/>
	</base-block>
</template>
