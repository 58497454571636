import {
	Vue, Component, Watch, Ref, toNative,
} from 'vue-facing-decorator';
import * as DB from 'interfaces/database';
import mitt from 'mitt';
import { TabEvent } from 'interfaces/app';
import { CellComponentExtended, ColumnDefinitionExtended, TabulatorFull as Tabulator } from 'tabulator-tables';
import { createInstance } from 'utils/vue';
import TabulatorSwitchBox from 'components/Tabulator/TabulatorSwitchBox';
import { useRoute } from 'vue-router';
import CustomCheckbox from 'components/CustomCheckbox';
import RegionService from 'services/RegionService';
import RegionCurrencyService from 'services/RegionCurrencyService';
import CurrencyService from 'services/CurrencyService';
import Template from './template.vue';

const eventBus = mitt<TabEvent<DB.RegionModel>>();
@Component({
	components: {
		TabulatorSwitchBox,
		CustomCheckbox,
	},
	mixins: [Template],
})
class EditCurrencyTable extends Vue {
	@Ref('editCurrencyTable')
	private readonly tableReference!: HTMLDivElement;

	private singleCurrency = {} as DB.CurrencyModel;

	private region: DB.RegionModel[] = [];

	private productCategory: DB.ProductCategoryModel[] = [];

	private language: DB.LanguageModel[] = [];

	private visible = false;

	private spinner = false;

	private columnDefs: ColumnDefinitionExtended[] = [];

	private checkRegion = {} as Record<string, DB.RegionCurrencyModel>;

	private checkedValue = false;

	private index = 0;

	private table?: Tabulator;

	private isLoading = false;

	private route = useRoute();

	private get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	beforeMount(): void {
		this.columnDefs = [
			{
				field: 'name',
				title: 'Region',
			},
			{
				title: 'Available In region?',
				formatter: (cell: CellComponentExtended<DB.RegionModel>) => {
					const data = cell.getData();
					const instance = createInstance({
						component: TabulatorSwitchBox,
						props: {
							data,
							eventBus,
							checked: Boolean(data && this.checkRegion[data.id]),
						},
					});
					return instance;
				},
			},
		] as ColumnDefinitionExtended[];
	}

	protected mounted(): void {
		this.getSingleCurrency();
		this.getOfferingRegion();
		eventBus.on(
			'switchBoxChanged',
			this.switchBoxChanged,
		);
		this.tableInitialization();
		this.table?.on(
			'tableBuilt',
			this.onTableBuilt,
		);
	}

	protected beforeUnmount(): void {
		eventBus.off(
			'switchBoxChanged',
			this.switchBoxChanged,
		);
		this.table?.off(
			'tableBuilt',
			this.onTableBuilt,
		);
		this.table?.destroy();
	}

	private onTableBuilt(): void {
		this.getData();
	}

	private tableInitialization(): void {
		this.table = new Tabulator(
			this.tableReference,
			{
				height: '45vh',
				layout: 'fitColumns',
				columns: this.columnDefs,
			},
		);
	}

	private async getData(): Promise<void> {
		const parameter = new URLSearchParams({
			limit: '0',
		});
		this.table?.alert('Loading');
		try {
			const data = await RegionService.getAll(parameter);
			this.table?.setData(data);
		} catch (err: any) {
			this.$toastError(err.message);
		} finally {
			this.table?.clearAlert();
		}
	}

	private switchBoxChanged(data: TabEvent<DB.RegionModel>['switchBoxChanged']): void {
		this.table?.alert('Loading...');
		if (data.event) {
			RegionCurrencyService
				.create(
					{
						regionid: data.params.id,
						currencyid: this.route.params.id as string,
					},
				)
				.then((response) => {
					this.checkRegion[response.regionid] = response;
					return undefined;
				})
				.finally(() => {
					this.table?.clearAlert();
				}).catch((err) => {
					this.$toastError(err.message);
				});
		} else {
			RegionCurrencyService
				.delete(this.checkRegion[data.params.id].id)
				.then(() => {
					delete this.checkRegion[data.params.id];
					return undefined;
				})
				.finally(() => {
					this.table?.clearAlert();
				}).catch((err) => {
					this.$toastError(err.message);
				});
		}
	}

	@Watch('loggedIn')
	private async getSingleCurrency(): Promise<DB.CurrencyModel | undefined> {
		if (this.loggedIn) {
			// set the loading state
			this.isLoading = true;
			try {
				const resp = await CurrencyService.get(this.route.params.id as string);
				this.singleCurrency = resp;
				// eslint-disable-next-line consistent-return
				return this.singleCurrency;
			} catch (error: any) {
				this.$toastError(error.message);
			} finally {
				this.isLoading = false;
			}
		}
		return undefined;
	}

	@Watch('loggedIn')
	private async getOfferingRegion(): Promise<void> {
		if (this.loggedIn) {
			const params = new URLSearchParams({
				where: JSON.stringify({ currencyid: this.route.params.id }),
				limit: '0',
			});

			try {
				const resp = await RegionCurrencyService.getAll(params);
				this.checkRegion = resp.reduce(
					(obj, item) => ({
						...obj,
						[item.regionid]: item,
					}),
					{},
				);
			} catch (error: any) {
				this.$toastError(error.message);
			}
		}
		return undefined;
	}

	protected editCurrency(): Promise<void> | undefined {
		if (this.loggedIn) {
			this.isLoading = true;
			CurrencyService.update(
				this.route.params.id as string,
				{ ...this.singleCurrency },
			).then(() => {
				this.$toastSuccess('Currency Edited successfully');
				return undefined;
			})
				.finally(() => {
					this.isLoading = false;
				})
				.catch((error) => {
					this.$toastError(error.message);
				});
		}
		return undefined;
	}
}

export default toNative(EditCurrencyTable);
