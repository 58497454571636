import { Vue, Component, toNative } from 'vue-facing-decorator';
import UserPropertyTable from 'components/UserProperties';
import Template from './UserProperties.vue';

@Component({
	components: {
		UserPropertyTable,
	},
	mixins: [Template],
})
class UserProperties extends Vue {
	private name = 'UserProperties';
}

export default toNative(UserProperties);
