<template>
	<base-block
		title="External Sources"
		class="mt-4"
		header-bg
	>
		<div
			ref="externalSourcesTable"
		/>
	</base-block>
</template>
