import { BadgeOfferingModel } from 'interfaces/database';
import ApiService from 'services/ApiService';

class BadgeOfferingService extends ApiService<BadgeOfferingModel> {
	constructor() {
		super('badgeoffering');
	}
}

export default new BadgeOfferingService();
