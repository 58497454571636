<template>
	<div class="">
		<base-page-heading
			title="App User properties"
			subtitle=""
			class="d-print-none"
		>
			<template #extra>
				<b-breadcrumb class="breadcrumb-alt">
					<b-breadcrumb-item href="javascript:void(0)">
						User List
					</b-breadcrumb-item>
					<b-breadcrumb-item active>
						App User Properties
					</b-breadcrumb-item>
				</b-breadcrumb>
			</template>
		</base-page-heading>

		<div class="row">
			<UserPropertyTable />
		</div>
	</div>
</template>
