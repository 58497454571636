<template>
	<div class="">
		<base-page-heading
			title="Discount Vouchers"
			subtitle=""
			class="d-print-none"
		/>

		<div class="row content">
			<DiscountVoucherTable />
		</div>
	</div>
</template>
