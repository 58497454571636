import {
	Component, Prop, Vue, Watch, toNative,
} from 'vue-facing-decorator';
import { getModule } from 'vuex-module-decorators';
import RegionsStore from 'store/offerings/region';
import * as DB from 'interfaces/database';
import { nextTick } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import CustomCheckbox from 'components/CustomCheckbox';
import RegionOfferingService from 'services/RegionOfferingService';
import Template from './regionRow.vue';

@Component({
	mixins: [Template],
	components: {
		CustomCheckbox,
	},
})
class RegionRow extends Vue {
	private data = {} as Record<number, DB.RegionOfferingModel>;

	private isLoading = false;

	private isActive = false;

	private selected = false;

	private vat = '';

	private store = useStore();

	private route = useRoute();

	@Prop()
		reg!: DB.RegionModel;

	@Prop()
		checkregion!: Record<number, DB.RegionOfferingModel>;

	@Prop()
		edited!: DB.OfferingModel;

	mounted(): void {
		getModule(
			RegionsStore,
			this.store,
		);
	}

	// watch the filteredRegion and set selected to true if the filtered region is true
	@Watch(
		'checkregion',
		{ immediate: true, deep: true },
	)
	checkRegionAndSwitch(): void {
		if (this.checkregion[this.reg.id]) {
			// set the switch box to checked
			this.selected = true;

			this.data[this.reg.id] = this.checkregion[this.reg.id];
			// make the Vat input visible
			this.isActive = true;
		} else {
			// uncheck the switch box
			this.selected = false;
			// hide the vat input
			this.isActive = false;
		}
	}

	editRegions(checked: boolean, reg: DB.RegionModel): void {
		// Check if the switch box is checked
		if (checked) {
			this.isActive = true;
			this.isLoading = true;
			// Set this region to be available
			RegionOfferingService.create(
				{
					offeringid: this.edited.id,
					regionid: reg.id,
					typeid: this.edited.typeid,
					groupid: this.edited.groupid,
				},
			)
				.then((response) => {
					this.data[response.regionid] = response;
					this.store.commit(
						'Regions/regionId',
						reg.id,
					);
					this.store.commit('Regions/removeRegionCurrency');
					return undefined;
				})
				.then(() => {
					this.store.dispatch(
						'Regions/getRegionCurrency',
						this.route.params.id,
					);
					return undefined;
				})
				.finally(() => {
					this.isLoading = false;
				})
				.catch((err) => {
					this.$toastError(err.message);
				});
		} else {
			this.isActive = false;
			this.isLoading = true;
			// if a user unchecks it, send a delete request and remove it from list of available regions
			RegionOfferingService
				.delete(this.data[reg.id].id)
				.then(() => {
					this.store.commit(
						'Regions/removeRegionId',
						this.reg.id,
					);
					this.store.commit('Regions/removeRegionCurrency');
					this.store.commit('Regions/removeRegionCurrencySales');
					return undefined;
				})
				.then(() => {
					nextTick(() => {
						this.store.dispatch(
							'Regions/getRegionCurrency',
							this.route.params.id,
						);
					});
					return undefined;
				}).finally(() => {
					this.isLoading = false;
				})
				.catch((err) => {
					this.$toastError(err.message);
				});
		}
	}
}

export default toNative(RegionRow);
