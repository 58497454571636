import { ModelWithId, TabEvent } from 'interfaces/app';
import DB from 'interfaces/database';
import { Emitter } from 'mitt';
import {
	Vue, Component, Prop, toNative,
} from 'vue-facing-decorator';
import Template from './template.vue';

@Component({
	mixins: [Template],
})
class TabulatorImage<DataType extends DB & ModelWithId> extends Vue {
	@Prop({
		type: Number,
	})
	public readonly size!: number;

	@Prop({
		type: Object,
	})
	public readonly data!: DataType;

	@Prop({
		type: String,
		required: true,
	})
	public readonly url!: string;

	@Prop({
		type: Boolean,
	})
	public readonly isEditable!: boolean;

	@Prop({
		type: Object,
	})
	public readonly eventBus!: Emitter<TabEvent<any>>;

	@Prop({
		type: String,
	})
	public readonly eventName!: keyof TabEvent<DataType>;

	editImage(): void {
		this.eventBus.emit(
			this.eventName as keyof TabEvent<DataType> || 'editImage',
			{
				id: this.data.id,
				params: this.data,
			},
		);
	}
}

export default toNative(TabulatorImage);
