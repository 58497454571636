<template>
	<base-block
		title="Themes Variant Table"
		header-bg
	>
		<template #options>
			<b-button
				type="submit"
				size="sm"
				variant="primary"
				@click="toggleShowNewPage"
			>
				Add variant
			</b-button>
		</template>
		<b-row>
			<b-col
				v-show="showNewPage"
				lg="12"
			>
				<b-form-group
					label="User Input?"
					label-class="font-w600"
					label-cols-md="4"
				>
					<custom-checkbox
						id="checkbox-1"
						:is-switch="true"
						size="lg"
						:checked="isCheckedTrue"
						@change="setChangeEvent($event)"
					/>
				</b-form-group>
				<div class="d-block text-left">
					<b-form-group
						class="form-group"
						label="Tag"
						label-for="block-form1-name"
					>
						<b-form-input
							v-model="themevariant.tag"
							type="text"
						/>
					</b-form-group>
					<b-form-group
						class="form-group"
						label="Value"
						label-for="block-form1-name"
					>
						<b-form-input
							v-model="themevariant.value"
							type="text"
						/>
					</b-form-group>
					<b-form-group
						class="form-group"
						label="Label"
						label-for="block-form1-name"
					>
						<b-form-input
							v-model="themevariant.label"
							type="text"
						/>
					</b-form-group>
					<b-button
						class="mt-3 mb-2"
						@click="addThemeVariant()"
					>
						Add Variant
					</b-button>
				</div>
			</b-col>
		</b-row>
		<div
			ref="themevariantTable"
		/>
	</base-block>
</template>
