import { Vue, Component, toNative } from 'vue-facing-decorator';
import ProductsOrdered from 'components/ProductOrderedTable';
import Template from './template.vue';

@Component({
	components: {
		ProductsOrdered,
	},
	mixins: [Template],
})
class PricingTable extends Vue {}

export default toNative(PricingTable);
