import { PDPImageModel } from 'interfaces/database';
import ApiService from 'services/ApiService';

class PDPImageService extends ApiService<PDPImageModel> {
	constructor() {
		super('pdpimage');
	}
}

export default new PDPImageService();
