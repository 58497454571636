<template>
	<div>
		<div class="">
			<base-page-heading
				title="API Permissions"
				subtitle=""
				class="d-print-none"
			/>

			<div class="row content">
				<ApiPermissionsTable
					@routeApiPermission="routeApiPermission"
				/>
			</div>
		</div>
	</div>
</template>
