import {
	Vue, Component, Ref, toNative,
} from 'vue-facing-decorator';
import EditPhotoframeTable from 'components/Themes/PhotoframeTable/EditPhotoframeTable';
import Template from './template.vue';

@Component({
	components: {
		EditPhotoframeTable,
	},
	mixins: [Template],
})
class EditPhotoframe extends Vue {
	@Ref('editphotoframe')
	private readonly photoframe!: HTMLFormElement;

	protected updatePhotoframeProperties(): void {
		this.photoframe.editPhotoframe();
	}
}

export default toNative(EditPhotoframe);
