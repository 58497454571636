import { ProductCategoryOfferingModel } from 'interfaces/database';
import ApiService from 'services/ApiService';

class ProductCategoryOfferingService extends ApiService<ProductCategoryOfferingModel> {
	constructor() {
		super('productcategoryoffering');
	}
}

export default new ProductCategoryOfferingService();
