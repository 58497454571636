<template>
	<div
		style="width: 95%"
		class="ml-5 mt-4"
	>
		<loading-overlay
			:active="isLoaded"
			:can-cancel="true"
		/>
		<b-modal
			id="bv-modal"
			ref="my-modal"
			hide-footer
		>
			<template #modal-title>
				Create New Badge
			</template>
			<div class="d-block text-center">
				<b-form-group
					class="form-group"
					label="Name: "
					label-for="block-form1-name"
				>
					<Field
						v-slot="{ errors }"
						v-model="name"
						rules="required"
						name="name"
					>
						<div
							class="control"
						>
							<b-form-input
								v-model="name"
								type="text"
							/>
							<span>{{ errors[0] }}</span>
						</div>
					</Field>
				</b-form-group>
			</div>
			<b-button
				class="mt-3"
				block
				@click="addBadge()"
			>
				Save
			</b-button>
		</b-modal>
		<base-block
			title="Existing Badges"
			header-bg
		>
			<div
				ref="table"
			/>
		</base-block>
	</div>
</template>

<style lang="scss">
@import "../../assets/scss/custom/errors";
</style>
