<template>
	<div
		style="width: 95%"
		class="ml-5"
	>
		<loading-overlay
			:active="isLoading"
			:can-cancel="true"
		/>

		<b-row>
			<b-col
				md="6"
				lg="6"
				sm="12"
			>
				<base-block
					title="Hyperlink properties"
					header-bg
				>
					<div class="d-block text-left">
						<b-form-group
							label="Language"
							label-for="example-select"
							label-cols-md="4"
						>
							<Field
								v-slot="{ errors }"
								v-model="singleHyperlink.languageid"
								rules="required"
								name="Language"
							>
								<div
									class="control"
								>
									<custom-select
										id="example-select"
										v-model="singleHyperlink.languageid"
										class="mb-2"
										plain
									>
										<b-form-select-option :value="''">
											Please select an option
										</b-form-select-option>
										<b-form-select-option
											v-for="(lang,id) in language "
											:key="id"
											:value="lang.id"
										>
											{{ lang.name }}
										</b-form-select-option>
									</custom-select>
									<span>{{ errors[0] }}</span>
								</div>
							</Field>
						</b-form-group>

						<b-form-group
							label="PDP"
							label-for="example-select"
							label-cols-md="4"
						>
							<custom-select
								id="example-select"
								v-model="singleHyperlink.pdpid"
								class="mb-2"
								plain
							>
								<b-form-select-option :value="null">
									Please select an option
								</b-form-select-option>
								<b-form-select-option
									v-for="(pd,id) in pdp "
									:key="id"
									:value="pd.id"
								>
									{{ pd.name }}
								</b-form-select-option>
							</custom-select>
						</b-form-group>

						<b-form-group
							label="Region"
							label-for="example-select"
							label-cols-md="4"
						>
							<Field
								v-slot="{ errors }"
								v-model="singleHyperlink.regionid"
								rules="required"
								name="Region"
							>
								<div
									class="control"
								>
									<custom-select
										id="example-select"
										v-model="singleHyperlink.regionid"
										class="mb-2"
										plain
									>
										<b-form-select-option :value="null">
											Please select an option
										</b-form-select-option>
										<b-form-select-option
											v-for="(reg,id) in region"
											:key="id"
											:value="reg.id"
										>
											{{ reg.name }}
										</b-form-select-option>
									</custom-select>
									<span>{{ errors[0] }}</span>
								</div>
							</Field>
						</b-form-group>

						<b-form-group
							label="Product Category"
							label-for="example-select"
							label-cols-md="4"
						>
							<b-input-group>
								<custom-select
									id="example-select"
									v-model="singleHyperlink.productcategoryid"
									class="mb-2"
									plain
								>
									<b-form-select-option :value="null">
										Please select an option
									</b-form-select-option>
									<b-form-select-option
										v-for="(prodcat,id) in productCategory "
										:key="id"
										:value="prodcat.id"
									>
										{{ prodcat.name }}
									</b-form-select-option>
								</custom-select>
								<template #append>
									<b-input-group-text
										class="input-group-text-alt"
										style="cursor:pointer"
									>
										<custom-checkbox
											id="checkbox-1"
											name="checkbox-1"
											:checked="singleHyperlink.productcategoryid == null ? true : false"
											unchecked-value="not_accepted"
											@change="()=> singleHyperlink.productcategoryid = null "
										/>
										Null
									</b-input-group-text>
								</template>
							</b-input-group>
						</b-form-group>
						<b-form-group
							class="form-group"
							label-for="block-form1-name"
							label-cols-md="4"
						>
							<template #label>
								<div class="d-flex flex-row">
									<div class="mr-2">
										Tag
									</div>
									<div
										id="tag"
										class="tip"
									/>
								</div>
							</template>
							<b-tooltip
								target="tag"
								triggers="hover"
								placement="bottom"
							>
								With What tag is this hyperlink identified in the Creator? (optional)
							</b-tooltip>
							<b-input-group>
								<Field
									v-slot="{ errors }"
									v-model="singleHyperlink.tag"
									rules="alpha_dash"
									name="Tag"
								>
									<div
										class="control"
									>
										<b-form-input
											v-model="singleHyperlink.tag"
											type="text"
											required
										/>
										<span>{{ errors[0] }}</span>
									</div>
								</Field>
								<template #append>
									<b-input-group-text
										class="input-group-text-alt"
										style="cursor:pointer"
									>
										<custom-checkbox
											id="checkbox-2"
											name="checkbox-1"
											:checked="singleHyperlink.tag == '' ? true : false"
											unchecked-value="not_accepted"
											@change="()=> singleHyperlink.tag = '' "
										/>
										Null
									</b-input-group-text>
								</template>
							</b-input-group>
						</b-form-group>

						<b-form-group
							class="form-group"
							label="URL: "
							label-for="block-form1-name"
							label-cols-md="4"
						>
							<b-form-input
								v-model="singleHyperlink.url"
								type="text"
								required
							/>
						</b-form-group>
						<b-form-group
							label-class="font-w600"
							label-cols-md="4"
						>
							<template #label>
								<div class="d-flex flex-row">
									<div class="mr-2">
										Delegate
									</div>
									<div
										id="delegate"
										class="tip"
									/>
								</div>
							</template>
							<b-tooltip
								target="delegate"
								triggers="hover"
								placement="bottom"
							>
								Send user to this hyperlink through delegate request?
							</b-tooltip>
							<custom-checkbox
								id="checkbox-3"
								:is-switch="true"
								size="lg"
								:checked="singleHyperlink.delegate === 1 ? true : false"
								@change="singleHyperlink.delegate = $event"
							/>
						</b-form-group>
					</div>
				</base-block>
			</b-col>
		</b-row>
	</div>
</template>

<style lang="scss" scoped>
@import "../../assets/scss/oneui/tooltip";
@import "../../assets/scss/custom/errors";

.thumbnail{
	display:flex;
	justify-content:space-around;
	align-items:Center;
	background-color: #ccc;
	margin-top:10px;
}
.cameraCheckbox {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-left: 40px;
}
</style>
