<template>
	<div
		:style="{ backgroundColor: data.color }"
		class="color d-flex justify-content-center align-items-center"
	>
		{{ data.color }}
	</div>
</template>

<style scoped>
.color {
	height: 180px;
	width: 150px;
}
</style>
