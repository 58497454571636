import {
	Vue, Component, Watch, toNative,
} from 'vue-facing-decorator';
import * as DB from 'interfaces/database';
import { PickerOptions, PickerResponse } from 'filestack-js';
import { useRoute } from 'vue-router';
import CustomCheckbox from 'components/CustomCheckbox';
import CustomFileInput from 'components/CustomFileInput';
import Model3DService from 'services/Model3DService';
import Template from './EditModel3dTable.vue';

@Component({
	mixins: [Template],
	components: {
		CustomCheckbox,
		CustomFileInput,
	},
})
class EditModel3dTable extends Vue {
	private singleModel3d = {} as DB.Model3DModel;

	private loaded = false;

	private visible = false;

	private spinner = false;

	private glb = '';

	private hdr = '';

	private texture: string | null = '';

	private placeHolders = {
		hdr: 'Choose a file',
		texture: 'Choose a file',
		glb: 'Choose a file',
	};

	private index = 0;

	private isLoading = false;

	private pageOptions = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50];

	private route = useRoute();

	get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	show(): void {
		this.visible = true;
	}

	handleHide(): void {
		this.visible = false;
	}

	showSingle(index: number): void {
		this.index = index;
		this.show();
	}

	mounted(): void {
		this.getSingleModel3d();
	}

	@Watch('loggedIn')
	async getSingleModel3d(): Promise<DB.Model3DModel | undefined> {
		if (this.loggedIn) {
			// set the loading state
			this.isLoading = true;
			try {
				const resp = await Model3DService.get(this.route.params.id as string);
				this.singleModel3d = resp;
				this.glb = resp.sceneUrl;
				this.hdr = resp.envTextureUrl !== null ? resp.envTextureUrl : '';
				this.texture = resp.textureUrl !== null ? resp.textureUrl : '';
				// eslint-disable-next-line consistent-return
				return this.singleModel3d;
			} catch (error: any) {
				this.$toastError(error.message);
			} finally {
				this.isLoading = false;
			}
		}
		return undefined;
	}

	editModel3dImage(): Promise<void> | undefined {
		if (this.loggedIn) {
			this.isLoading = true;
			Model3DService.update(
				this.route.params.id as string,
				{ ...this.singleModel3d },
			).then(() => {
				this.$toastSuccess('3D Model Edited successfully');
				return undefined;
			})
				.finally(() => {
					this.isLoading = false;
				})
				.catch((error) => {
					this.$toastError(error.message);
				});
		}
		return undefined;
	}

	//  update image preview
	async updateGlbPreview(): Promise<void> {
		const options: PickerOptions = {
			fromSources: ['local_file_system'],
			onUploadDone: async (files: PickerResponse) => {
				this.singleModel3d.sceneUrl = files.filesUploaded[0].url;
				this.placeHolders.glb = files.filesUploaded[0].filename;
				this.glb = files.filesUploaded[0].filename;
			},
		};
		this.$client.picker(options).open();
	}

	//  update image preview
	async updateHdrPreview(): Promise<void> {
		const options: PickerOptions = {
			fromSources: ['local_file_system'],
			onUploadDone: async (files: PickerResponse) => {
				this.singleModel3d.envTextureUrl = files.filesUploaded[0].url;
				this.placeHolders.hdr = files.filesUploaded[0].filename;
				this.hdr = files.filesUploaded[0].filename;
			},
		};
		this.$client.picker(options).open();
	}

	async updateTexturePreview(): Promise<void> {
		const options: PickerOptions = {
			fromSources: ['local_file_system'],
			onUploadDone: async (files: PickerResponse) => {
				this.singleModel3d.textureUrl = files.filesUploaded[0].url;
				this.placeHolders.texture = files.filesUploaded[0].filename;
				this.texture = files.filesUploaded[0].filename;
			},
		};
		this.$client.picker(options).open();
	}
}

export default toNative(EditModel3dTable);
