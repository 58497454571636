import { Vue, Component, toNative } from 'vue-facing-decorator';
import CountryFlag from 'vue-country-flag-next';
import { useRoute } from 'vue-router';
import UserPropertiesBox from './UserPropertiesBox.vue';
import ExternalSources from './ExternalSources';
import PaidOrders from './PaidOrders';
import ShoppingCartItems from './ShoppingCartItems';
import Products from './Products';
import Photos from './Photos';
import UserCredit from './UserCredit';
import UserEmailLog from './UserEmailLog';
import UserReferrals from './UserReferrals';
import Template from './template.vue';

@Component({
	components: {
		CountryFlag,
		UserPropertiesBox,
		ExternalSources,
		PaidOrders,
		ShoppingCartItems,
		Products,
		Photos,
		UserCredit,
		UserEmailLog,
		UserReferrals,
	},
	mixins: [Template],
})
class UserPropertyTable extends Vue {
	private name = 'UserPropertyTable';

	private route = useRoute();

	private sendUserRequest = {
		shoppingCart: false,
		products: false,
		photos: false,
		userCredit: false,
		userEmailLog: false,
		userReferrals: false,
	};

	protected get routeId() {
		return this.route.params.id;
	}
}

export default toNative(UserPropertyTable);
