import {
	Vue, Component, Watch, Prop, toNative,
} from 'vue-facing-decorator';
import * as DB from 'interfaces/database';
import CustomCheckbox from 'components/CustomCheckbox';
import CustomSelect from 'components/CustomSelect';
import CrossSellService from 'services/CrossSellService';
import OfferingService from 'services/OfferingService';
import ThemeService from 'services/ThemeService';
import Template from './template.vue';

@Component({
	components: {
		CustomCheckbox,
		CustomSelect,
	},
	mixins: [Template],
})
class EditCrossSellTable extends Vue {
	@Prop({
		type: String,
		required: true,
	})
	private readonly routeId!: string;

	private singleCrossSell = {} as DB.CrossSellModel;

	private offerings: DB.OfferingModel[] = [];

	private theme: DB.ThemeModel[] = [];

	private loaded = false;

	private visible = false;

	private spinner = false;

	private index = 0;

	private isLoading = false;

	private pageOptions = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50];

	private get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	protected mounted(): void {
		this.getData();
	}

	@Watch('loggedIn')
	private async getData(): Promise<DB.CrossSellModel | undefined> {
		if (this.loggedIn) {
			const parameter = new URLSearchParams({
				limit: '0',
			});
			this.isLoading = true;
			try {
				const [crossData, offeringData, ThemeData] = await Promise.all([
					CrossSellService.get(this.routeId),
					OfferingService.getAll(parameter),
					ThemeService.getAll(parameter),
				]);
				this.singleCrossSell = crossData;
				this.offerings = offeringData;
				this.theme = ThemeData;
				// eslint-disable-next-line consistent-return
				return this.singleCrossSell;
			} catch (error: any) {
				this.$toastError(error.message);
			} finally {
				this.isLoading = false;
			}
		}
		return undefined;
	}

	protected editCrossSell(): Promise<void> | undefined {
		if (this.loggedIn) {
			this.isLoading = true;
			CrossSellService.update(
				this.routeId,
				{ ...this.singleCrossSell },
			).then(() => {
				this.$toastSuccess('Cross Sell Edited successfully');
				return undefined;
			})
				.finally(() => {
					this.isLoading = false;
				})
				.catch((error) => {
					this.$toastError(error.message);
				});
		}
		return undefined;
	}
}

export default toNative(EditCrossSellTable);
