<template>
	<div
		style="width: 95%"
		class="ml-5"
	>
		<loading-overlay
			:active="isLoading"
			:can-cancel="true"
		/>

		<b-modal
			id="bv-modal"
			ref="value-modal"
			hide-footer
		>
			<template #modal-title>
				Create New Offering Option
			</template>
			<div
				v-if="spinner"
				class="text-center"
			>
				<div
					class="spinner-grow text-primary m-5"
					role="status"
				>
					<span class="sr-only">Loading...</span>
				</div>
			</div>
			<div v-else>
				<b-form-group
					class="form-group"
					label="value "
					label-for="block-form1-name"
					label-cols-lg="3"
				>
					<div
						class="control"
					>
						<b-form-input
							ref="optionValue"
							v-model="optionValue"
							type="text"
						/>
					</div>
				</b-form-group>
				<b-button
					class="mt-3"
					block
					@click="addOptionValue"
				>
					Save
				</b-button>
			</div>
		</b-modal>

		<b-row
			style="width: 95%"
			class="d-flex justify-content-end mb-4 ml-5"
		>
			<b-col
				sm="5"
				md="3"
				class="my-1"
				align-self="center"
			>
				<b-form-group
					label="Language:"
					label-for="search-group-select"
					label-cols-sm="6"
					label-cols-md="4"
					label-cols-lg="4"
					label-align-sm="right"
					label-size="sm"
					class="mb-0"
				>
					<custom-select
						id="search-group-select"
						v-model="language"
						size="sm"
						:options="languageOptions"
						@change="translateTableData(language)"
					/>
				</b-form-group>
			</b-col>
		</b-row>

		<b-row
			style="width: 95%"
			class="ml-5"
		>
			<b-col
				md="5"
				lg="5"
				sm="12"
			>
				<base-block
					title="Offering Option properties"
					header-bg
				>
					<div class="d-block text-center">
						<b-form-group
							class="form-group"
							label="Name: "
							label-for="block-form1-name"
							label-cols-lg="4"
						>
							<div
								class="control"
							>
								<b-form-input
									v-model="offeringOption.name"
									type="text"
								/>
							</div>
						</b-form-group>

						<b-form-group
							class="form-group"
							label="Description: "
							label-for="block-form1-name"
							label-cols-lg="4"
						>
							<div
								class="control"
							>
								<b-form-input
									v-model="offeringOption.description"
									type="text"
								/>
							</div>
						</b-form-group>

						<b-form-group
							class="form-group"
							label="Serial number"
							label-for="block-form1-name"
							label-cols-lg="4"
						>
							<Field
								v-slot="{ errors }"
								v-model.number="offeringOption.serialnumber"
								rules="integer"
								name="Serial number"
							>
								<div
									class="control"
								>
									<b-form-input
										v-model.number="offeringOption.serialnumber"
										type="text"
									/>
									<span>{{ errors[0] }}</span>
								</div>
							</Field>
						</b-form-group>

						<b-form-group
							label="Show during edit"
							label-class="font-w600"
							label-cols-lg="3"
						>
							<custom-checkbox
								id="checkbox-1"
								class="pubCheckbox"
								:is-switch="true"
								size="lg"
								:checked="offeringOption.showduringedit === 1 ? true : false"
								@change="offeringOption.showduringedit = $event"
							/>
						</b-form-group>

						<b-form-group
							label="Show after edit"
							label-class="font-w600"
							label-cols-lg="3"
						>
							<custom-checkbox
								id="checkbox-2"
								class="pubCheckbox"
								:is-switch="true"
								size="lg"
								:checked="offeringOption.showafteredit === 1 ? true : false"
								@change="offeringOption.showafteredit = $event"
							/>
						</b-form-group>

						<b-form-group
							label="Show PDP"
							label-class="font-w600"
							label-cols-lg="3"
						>
							<custom-checkbox
								id="checkbox-3"
								class="pubCheckbox"
								:is-switch="true"
								size="lg"
								:checked="offeringOption.showpdp === 1 ? true : false"
								@change="offeringOption.showpdp = $event"
							/>
						</b-form-group>

						<b-form-group
							label="Production"
							label-class="font-w600"
							label-cols-lg="3"
						>
							<custom-checkbox
								id="checkbox-4"
								class="pubCheckbox"
								:is-switch="true"
								size="lg"
								:checked="offeringOption.production === 1 ? true : false"
								@change="offeringOption.production = $event"
							/>
						</b-form-group>
					</div>
				</base-block>
			</b-col>
			<b-col
				md="7"
				lg="7"
				sm="12"
			>
				<base-block
					title="Offering Option Values"
					header-bg
				>
					<b-row
						class="d-flex justify-content-end"
					>
						<b-col
							md="6"
							class="d-flex justify-content-end mb-2"
						>
							<router-link
								to="#"
								@click="showValueModal"
							>
								Add Value
							</router-link>
						</b-col>
					</b-row>

					<div
						ref="offeringOptionValue"
					/>
				</base-block>
			</b-col>
		</b-row>
	</div>
</template>

<style lang="scss" scoped>
.thumbnail{
	display:flex;
	justify-content:space-around;
	align-items:Center;
	background-color: #ccc;
	margin-top:10px;
}
.cameraCheckbox {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-left: 40px;
}
</style>
