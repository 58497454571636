import { LayoutModel, LayoutTemplateLinkModel } from 'interfaces/database';
import ApiService from 'services/ApiService';
import { httpClient } from 'utils/http';

class LayoutService extends ApiService<LayoutModel> {
	constructor() {
		super('layout');
	}

	async getTemplateLinks(id: string | number, parameter?: URLSearchParams): Promise<LayoutTemplateLinkModel[]> {
		const response = await httpClient.get(`${this.ENDPOINT}/${id}/templates?${parameter}`);
		return response.data;
	}
}

export default new LayoutService();
