<template>
	<div
		style="width: 95%"
		class="ml-5 mt-4"
	>
		<loading-overlay
			:active="isLoaded"
			:can-cancel="true"
		/>
		<b-modal
			id="pagebg-modal"
			ref="addpagebg-modal"
			hide-footer
		>
			<template #modal-title>
				Create New Page Background
			</template>
			<div class="d-block text-center">
				<b-form-group
					class="form-group"
					label="Name: "
					label-for="block-form1-name"
				>
					<b-form-input
						v-model="name"
						type="text"
					/>
				</b-form-group>
			</div>
			<b-button
				class="mt-3"
				block
				@click="addPagebg()"
			>
				Save
			</b-button>
		</b-modal>
		<base-block
			title="Existing Page Background"
			header-bg
		>
			<div
				ref="pagebg"
			/>
		</base-block>
	</div>
</template>
