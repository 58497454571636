<template>
	<div
		style="width: 95%"
		class="ml-5 mt-4"
	>
		<loading-overlay
			:active="isLoaded"
			:can-cancel="true"
		/>
		<b-modal
			id="currency-modal"
			ref="addcurrency-modal"
			hide-footer
		>
			<template #modal-title>
				Create New Currency
			</template>
			<div class="d-block text-left">
				<b-form-group
					class="form-group"
					label="ID "
					label-for="block-form1-name"
				>
					<Field
						v-slot="{ errors }"
						v-model="currencyData.id"
						rules="required|apha_dash"
						name="ID"
					>
						<div
							class="control"
						>
							<b-form-input
								v-model="currencyData.id"
								type="text"
								required
							/>
							<span>{{ errors[0] }}</span>
						</div>
					</Field>
				</b-form-group>
				<b-form-group
					label="Currency"
					label-for="example-select"
				>
					<Field
						v-slot="{ errors }"
						v-model="currencyData.data"
						rules="required"
						name="Currency"
					>
						<div
							class="control"
						>
							<custom-select
								id="example-select"
								v-model="currencyData.data"
								class="mb-2"
								plain
							>
								<b-form-select-option :value="''">
									Please select an option
								</b-form-select-option>
								<b-form-select-option
									v-for="(curr,id) in jsonCurrency "
									:key="id"
									:value="curr"
								>
									{{ curr.name }} - {{ curr.sign }} - {{ curr.name }}
								</b-form-select-option>
							</custom-select>
							<span>{{ errors[0] }}</span>
						</div>
					</Field>
				</b-form-group>
				<b-button
					class="mt-3"
					block
					@click="addCurrency()"
				>
					Save
				</b-button>
			</div>
		</b-modal>
		<base-block
			title="Existing currencies"
			header-bg
		>
			<div
				ref="currencyTable"
			/>
		</base-block>
	</div>
</template>

<style lang="scss" scoped>
@import "../../assets/scss/custom/errors";
</style>
