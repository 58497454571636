<template>
	<base-block
		title="User Credit Balance"
		header-bg
	>
		<div
			ref="creditRequestTable"
		/>
		<div />
		<div
			v-show="displayTable"
			ref="creditHistoryTable"
		/>
	</base-block>
</template>
