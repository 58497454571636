import {
	Vue, Component, Prop, toNative,
} from 'vue-facing-decorator';
import { h } from 'vue';
import * as DB from 'interfaces/database';
import PricingService from 'services/PricingService';
import Template from './SalesTable.vue';

@Component({
	mixins: [Template],
})
class SalesTable extends Vue {
	private isLoading = false;

	private editing = false;

	@Prop({
		type: Object,
	})
		salesP!: DB.PricingModel;

	@Prop()
		editedOffering!: DB.OfferingModel;

	private data = {} as DB.PricingModel;

	mounted(): void {
		this.data = this.salesP;
	}

	edit(): void {
		this.editing = true;
	}

	editSalesP(): Promise<void> | void {
		this.isLoading = true;
		PricingService.update(
			this.data.id,
			{
				price_base_from: this.data.price_base_from,
				price_base: this.data.price_base,
				price_page_from: this.data.price_page_from,
				price_page: this.data.price_page,
			},
		)
			.then(() => setTimeout(
				() => {
					this.$bvToast.toast(
						h(
							'p',
							{ class: ['text-center', 'mb-0'] },
							[
								h(
									'b-spinner',
									{ props: { type: 'grow', small: true } },
								),
								h(
									'strong',
									'Price Edited',
								),
								h(
									'b-spinner',
									{ props: { type: 'grow', small: true } },
								),
							],
						),
						{
							solid: true,
							variant: 'success',
						},
					);
				},
				2000,
			))
			.finally(() => {
				this.isLoading = false;
			})
			.catch((err) => {
				this.$toastError(err.message);
			});
	}
}

export default toNative(SalesTable);
