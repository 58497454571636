<template>
	<div class="">
		<!-- Hero -->
		<base-page-heading
			title="Layouts"
			subtitle=""
			class="d-print-none"
		/>

		<div class="row">
			<LayoutTable
				@routeLayout="routeLayout"
				@openInNewTab="openInNewTab"
			/>
		</div>
	</div>
</template>
