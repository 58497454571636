/* eslint-disable implicit-arrow-linebreak */
import {
	Vue, Component, Watch, toNative,
} from 'vue-facing-decorator';
import { h } from 'vue';
import Upsell from 'components/Upsell';
import Region from 'components/Region';
import SalesPrice from 'components/SalesPrice';
import CrossSell from 'components/CrossSell';
import Handling from 'components/Handling';
import Badges from 'components/Badges';
import Frame from 'components/Frame';
import { getModule } from 'vuex-module-decorators';
import * as DB from 'interfaces/database';
import { PickerOptions, PickerResponse } from 'filestack-js';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import CustomSelect from 'components/CustomSelect';
import CustomCheckbox from 'components/CustomCheckbox';
import CustomFileInput from 'components/CustomFileInput';
import { httpClient } from '../../../utils/http';
import Regions from '../../../store/offerings/region';
import Template from './template.vue';

@Component({
	components: {
		Upsell,
		Region,
		SalesPrice,
		CrossSell,
		Handling,
		Badges,
		Frame,
		CustomSelect,
		CustomCheckbox,
		CustomFileInput,
	},
	mixins: [Template],
})
class EditOffering extends Vue {
	private name = 'EditOffering';

	private pdpid: DB.PDPModel[] = [];

	private model3did : DB.Model3DModel[] = [];

	private model2did: DB.Model2DModel[] = [];

	private themeid : DB.ThemeModel[] = [];

	private routeId?: string;

	private spinner = false;

	private isLoading = false;

	private selected = null;

	private tabIndex = 0;

	private tabIndex1 = 0;

	private editedOffering = {} as DB.OfferingModel;

	private salesPrice: DB.PricingModel[] = [];

	private imagePreview = '';

	private maskPreview = '';

	private variantPreview = '';

	private overlayPreview = '';

	private placeHolders = {
		thumbnailPlaceholder: 'No file chosen',
		maskPlaceholder: 'No file chosen',
		variantPlaceholder: 'No file chosen',
		overlayPlaceholder: 'No file chosen',
	};

	private type = [
		'photo',
		'logo',
	];

	private visible = false;

	private indexes = 0;

	private id = 9000;

	private route = useRoute();

	private store = useStore();

	// getter for authenticated user
	get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	async created(): Promise<void> {
		getModule(
			Regions,
			this.store,
		);
		this.getOfferingById();
		this.fetchPDP();
		this.fetchModel2d();
		this.fetchModel3d();
	}

	mounted() {
		this.routeId = this.route.params.id as string;
	}

	editOff(): void {
		if (this.loggedIn) {
			this.isLoading = true;
			// Edit all offerings from the server
			httpClient.put(
				`/api/offering/${this.editedOffering.id}`,
				{
					...this.editedOffering,
					thumbnail: this.imagePreview,
					mask: this.maskPreview,
					variantthumbnail: this.variantPreview,
					overlay: this.overlayPreview,
				},
			)
				.then(() =>
				// Custom toast with spinner
					setTimeout(
						() => {
							this.$bvToast.toast(
								h(
									'p',
									{ class: ['text-center', 'mb-0'] },
									[
										h(
											'b-spinner',
											{ props: { type: 'grow', small: true } },
										),
										h(
											'strong',
											'Offering Edited Successfully',
										),
										h(
											'b-spinner',
											{ props: { type: 'grow', small: true } },
										),
									],
								),
								{
									solid: true,
									variant: 'success',
								},
							);
						},
						2000,
					))
				.finally(() => {
					this.isLoading = false;
				})
				.catch((err) => {
					this.$toastError(err.message);
				});
		}
	}

	// get Offerings by id
	@Watch('loggedIn')
	getOfferingById(): Promise<void> | void {
		if (this.loggedIn) {
			this.isLoading = true;
			// get all offerings from the server
			return httpClient.get(`/api/offering/${this.route.params.id}`)
				.then((response) => {
					this.editedOffering = response.data;
					this.imagePreview = response.data.thumbnail;
					this.maskPreview = response.data.mask;
					this.overlayPreview = response.data.overlay;
					this.variantPreview = response.data.variantthumbnail;
					this.fetchTheme();

					return undefined;
				})
				.finally(() => {
					this.isLoading = false;
				}).catch((err) => {
					this.$toastError(err.message);
				});
		}

		return undefined;
	}

	show(): void {
		this.visible = true;
	}

	handleHide(): void {
		this.visible = false;
	}

	showSingle(): void {
		this.indexes = 0;
		this.show();
	}

	@Watch('loggedIn')
	async fetchPDP(): Promise<void | DB.PDPModel[]> {
		if (this.loggedIn) {
		// get all PDPID from the server
			return httpClient.get('/api/pdp?limit=0')
				.then((response) => {
					this.pdpid = response.data;
					return this.pdpid;
				})
				.catch((err) => {
					this.$toastError(err.message);
				});
		}
		return undefined;
	}

	@Watch('loggedIn')
	fetchModel2d(): Promise<void | DB.Model2DModel[]> | undefined {
		if (this.loggedIn) {
		// get all MODEL2D from the server
			return httpClient.get('/api/model2d?limit=0')
				.then((response) => {
					this.model2did = response.data;
					return this.model2did;
				})
				.catch((err) => {
					this.$toastError(err.message);
				});
		}
		return undefined;
	}

	@Watch('loggedIn')
	fetchModel3d(): Promise<void | DB.Model3DModel[]> | undefined {
		if (this.loggedIn) {
		// get all MODEL3D from the server
			return httpClient.get('/api/model3d?limit=0')
				.then((response) => {
					this.model3did = response.data;
					return this.model3did;
				})
				.catch((err) => {
					this.$toastError(err.message);
				});
		}
		return undefined;
	}

	@Watch('loggedIn')
	fetchTheme(): Promise<void | DB.ThemeModel[]> | undefined {
		if (this.loggedIn) {
		// get all THEMEID from the server
			return httpClient.get(
				`/api/theme?limit=0&where={"groupid": ${this.editedOffering.groupid}, "typeid": ${this.editedOffering.typeid}}`,
			)
				.then((response) => {
					this.themeid = response.data;
					return this.themeid;
				})
				.catch((err) => {
					this.$toastError(err.message);
				});
		}
		return undefined;
	}

	//  update image preview
	async updatePreview(): Promise<void> {
		const options: PickerOptions = {
			fromSources: ['local_file_system'],
			onUploadDone: async (files: PickerResponse) => {
				this.imagePreview = files.filesUploaded[0].url;
				this.placeHolders.thumbnailPlaceholder = files.filesUploaded[0].filename;
			},
		};
		this.$client.picker(options).open();
	}

	// update mask preview
	updateMaskPreview(): void {
		const options: PickerOptions = {
			fromSources: ['local_file_system'],
			onUploadDone: async (files: PickerResponse) => {
				this.maskPreview = files.filesUploaded[0].url;
				this.placeHolders.maskPlaceholder = files.filesUploaded[0].filename;
			},
		};
		this.$client.picker(options).open();
	}

	// update variant preview
	updateVariantPreview(): void {
		const options: PickerOptions = {
			fromSources: ['local_file_system'],
			onUploadDone: async (files: PickerResponse) => {
				this.variantPreview = files.filesUploaded[0].url;
				this.placeHolders.variantPlaceholder = files.filesUploaded[0].filename;
			},
		};
		this.$client.picker(options).open();
	}

	// update overlay preview
	updateOverlayPreview(): void {
		const options: PickerOptions = {
			fromSources: ['local_file_system'],
			onUploadDone: async (files: PickerResponse) => {
				this.overlayPreview = files.filesUploaded[0].url;
				this.placeHolders.overlayPlaceholder = files.filesUploaded[0].filename;
			},
		};
		this.$client.picker(options).open();
	}

	// remove the image preview for thumbnail
	removeThumbnail(): void {
		this.imagePreview = '';
		this.placeHolders.thumbnailPlaceholder = 'No file chosen';
	}

	// remove the image preview for mask
	removeMask(): void {
		this.maskPreview = '';
		this.placeHolders.maskPlaceholder = 'No file chosen';
	}

	// remove the image preview for thumbnail variant
	removeVariant(): void {
		this.variantPreview = '';
		this.placeHolders.variantPlaceholder = 'No file chosen';
	}

	// remove vthe image preview for overlay
	removeOverlay(): void {
		this.overlayPreview = '';
		this.placeHolders.overlayPlaceholder = 'No file chosen';
	}
}

export default toNative(EditOffering);
