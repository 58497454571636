import { Model2DModel } from 'interfaces/database';
import ApiService from 'services/ApiService';

class Model2DService extends ApiService<Model2DModel> {
	constructor() {
		super('model2d');
	}
}

export default new Model2DService();
