import { Vue, Component, toNative } from 'vue-facing-decorator';
import RegionTable from 'components/RegionTable';
import { useRouter } from 'vue-router';
import Template from './template.vue';

@Component({
	components: {
		RegionTable,
	},
	mixins: [Template],
})
class Region extends Vue {
	private router = useRouter();

	protected showModal(): void {
		this.$root?.$emit(
			'bv::show::modal',
			'region-modal',
			'#regionShow',
		);
	}

	protected routeRegion(id: string): void {
		this.router.push(`/region/${id}`);
	}
}

export default toNative(Region);
