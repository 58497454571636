<template>
	<div>
		<div v-if="data.salesvalue">
			{{ data.currency }} : {{ (data.salesvalue / 100).toFixed(2) }}
		</div>
		<div v-else-if="discount_voucher">
			{{ data.currency }} {{ (data.discount_voucher / 100).toFixed(2) }}
		</div>
	</div>
</template>
