import { BadgeProductCategoryModel } from 'interfaces/database';
import ApiService from 'services/ApiService';

class BadgeProductCategoryService extends ApiService<BadgeProductCategoryModel> {
	constructor() {
		super('badgeproductcategory');
	}
}

export default new BadgeProductCategoryService();
