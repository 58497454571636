import {
	Vue, Component, toNative,
} from 'vue-facing-decorator';
import { namespace } from 'vuex-facing-decorator';
import Swal from 'sweetalert2';
import * as DB from 'interfaces/database';
import { useRoute } from 'vue-router';
import CustomSelect from 'components/CustomSelect';
import HandlingService from 'services/HandlingService';
import handlingRow from './handlingRow.vue';
import Template from './template.vue';

const Regions = namespace('Regions');

@Component({
	components: {
		handlingRow,
		CustomSelect,
	},
	mixins: [Template],
})
class Handling extends Vue {
	private arrScopes = ['Quantity', 'Item', 'Variant', 'Type', 'Group', 'Order'];

	private salesCurrency : DB.RegionCurrencyModel[] = [];

	private scope: DB.HandlingModel['scope'] | undefined;

	private currency = '';

	private price = '';

	private footer = true;

	private isLoading = false;

	private route = useRoute();

	private HandlingPrice : DB.HandlingModel[] = [];

	@Regions.Getter
	private regionCurrencyForSales!: Array<DB.RegionCurrencyModel>;

	get currentCurrency(): DB.RegionCurrencyModel[] {
		this.salesCurrency = this.regionCurrencyForSales;
		return this.salesCurrency;
	}

	mounted(): void {
		this.getHandlingPrice();
	}

	add(): Promise<any> | void {
		this.isLoading = true;

		HandlingService.create(
			{
				scope: this.scope,
				currency: this.currency,
				price: Number(this.price),
				offeringid: Number(this.route.params.id),
			},
		).then((res) => {
			this.HandlingPrice.push(res);
			this.scope = undefined;
			this.currency = '';
			this.price = '';
			if (this.salesCurrency.length === 0) {
				this.footer = false;
			}
			return undefined;
		}).finally(() => {
			this.isLoading = false;
		}).catch((err) => {
			this.$toastError(err.message);
		});
	}

	removePrice(id: number): Promise<void> | void {
		Swal.fire({
			title: 'Are you sure?',
			text: 'You will not be able to recover this imaginary file!',
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'btn btn-danger m-1',
				cancelButton: 'btn btn-secondary m-1',
			},
			confirmButtonText: 'Yes, delete it!',
			html: false,
		}).then((result) => {
			if (result.value) {
				// eslint-disable-next-line promise/no-nesting
				HandlingService.delete(id).then(() => {
					const indexOfUserToRemove = this.HandlingPrice.findIndex((u) => u.id === id);
					this.HandlingPrice.splice(
						indexOfUserToRemove,
						1,
					);
					return null;
				}).catch((err) => {
					this.$toastError(err.message);
				});
			}
			return undefined;
		}).catch((err) => {
			this.$toastError(err.message);
		});
	}

	getHandlingPrice(): void {
		// get handling data and filter by offering id
		HandlingService.getAll().then((res) => {
			this.HandlingPrice = res;
			return undefined;
		}).catch((err) => {
			this.$toastError(err.message);
		});
	}
}

export default toNative(Handling);
