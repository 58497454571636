<template>
	<div
		class="ml-5 mt-4"
		style="width: 95%; height: 100%;"
	>
		<loading-overlay
			:active="isLoading"
			:can-cancel="true"
		/>
		<b-row>
			<b-col
				md="12"
				lg="12"
				sm="12"
			>
				<b-tabs
					card
					content-class="mt-3"
					fill
				>
					<b-tab
						title="Properties"
						active
					>
						<base-block
							title="Bulk Discount properties"
							header-bg
						>
							<div
								v-if="spinner"
								class="text-center"
							>
								<div
									class="spinner-grow text-primary m-5"
									role="status"
								>
									<span class="sr-only">Loading...</span>
								</div>
							</div>
							<div
								v-else
								class="d-block text-center"
							>
								<b-form-group
									label="ID:"
									label-for="example-select1"
									label-cols-lg="5"
									disabled
								>
									<b-form-input
										v-model="singleBulkDiscount.id"
										type="text"
									/>
								</b-form-group>

								<b-form-group
									label="Name:"
									label-for="example-select1"
									label-cols-lg="5"
								>
									<b-form-input
										v-model="singleBulkDiscount.name"
										type="text"
									/>
								</b-form-group>

								<b-form-group
									label="Pages:"
									label-for="example-select"
									label-cols-lg="5"
								>
									<b-input-group>
										<custom-select
											id="example-select"
											v-model="singleBulkDiscount.count"
											plain
										>
											<b-form-select-option
												v-for="(con,id) in count"
												:key="id"
												:value="con"
											>
												{{ con }}
											</b-form-select-option>
										</custom-select>
									</b-input-group>
								</b-form-group>

								<b-form-group
									label="Active:"
									label-class="font-w600"
									label-cols-lg="5"
								>
									<custom-checkbox
										id="checkbox-active"
										class="activeCheckbox"
										:is-switch="true"
										size="lg"
										:checked="singleBulkDiscount.active === 1"
										@change="singleBulkDiscount.active = $event"
									/>
								</b-form-group>
							</div>
						</base-block>
					</b-tab>
					<b-tab
						title="Bulk Discount Quantities"
					>
						<base-block
							title="Bulk Discount Quantities"
							header-bg
						>
							<b-table-simple
								responsive
								table-class="table-vcenter"
							>
								<b-thead head-variant="dark">
									<b-tr>
										<b-th>From</b-th>
										<b-th>To</b-th>
										<b-th>Relative</b-th>
										<b-th>Absolute</b-th>
										<b-th>Absolute pp</b-th>
										<b-th>Actions</b-th>
									</b-tr>
								</b-thead>
								<b-tbody>
									<b-tr
										v-for="(item, index) in BulkDiscountQuantities"
										:key="index"
									>
										<b-td>
											<b-form-input
												v-model.number="item.from"
												type="number"
												class="form-control-sm"
											/>
										</b-td>
										<b-td>
											<b-form-input
												v-model.number="item.to"
												type="number"
												class="form-control-sm"
											/>
										</b-td>
										<b-td>
											<b-form-input
												v-model.number="item.relative"
												type="number"
												class="form-control-sm"
											/>
										</b-td>
										<b-td>
											<b-form-input
												v-model.number="item.absolute"
												type="number"
												class="form-control-sm"
											/>
										</b-td>
										<b-td>
											<b-form-input
												v-model.number="item.absolute_q"
												type="number"
												class="form-control-sm"
											/>
										</b-td>
										<b-td>
											<b-btn-group>
												<b-button
													size="sm"
													variant="alt-primary"
													@click="saveBulkDiscountQuantity(item)"
												>
													<i class="fa fa-fw fa-save" />
												</b-button>
												<b-button
													size="sm"
													variant="alt-primary"
													@click="removeBulkDiscountQuanitity(item.id)"
												>
													<i class="fa fa-fw fa-eraser" />
												</b-button>
											</b-btn-group>
										</b-td>
									</b-tr>
								</b-tbody>
								<b-tfoot>
									<b-tr>
										<b-td>
											<b-form-group>
												<b-form-group
													label-for="example-text-input1"
												>
													<b-form-input
														v-model="BulkQuantityData.from"
														type="number"
														placeholder=""
													/>
												</b-form-group>
											</b-form-group>
										</b-td>
										<b-td>
											<b-form-group
												label-for="example-text-input2"
											>
												<b-form-input
													v-model="BulkQuantityData.to"
													type="number"
													placeholder=""
												/>
											</b-form-group>
										</b-td>
										<b-td>
											<b-form-group
												label-for="example-text-input"
											>
												<b-form-input
													v-model="BulkQuantityData.relative"
													type="number"
													placeholder=""
												/>
											</b-form-group>
										</b-td>
										<b-td>
											<b-form-group
												label-for="example-text-input"
											>
												<b-form-input
													v-model="BulkQuantityData.absolute"
													type="number"
													placeholder=""
												/>
											</b-form-group>
										</b-td>

										<b-td>
											<b-form-group
												label-for="example-text-input"
											>
												<b-form-input
													v-model="BulkQuantityData.absolute_q"
													type="number"
													placeholder=""
												/>
											</b-form-group>
										</b-td>
										<b-td>
											<b-btn-group class="mb-4">
												<b-button
													size="sm"
													variant="alt-primary"
													@click="addBulkQuantity()"
												>
													<i class="fa fa-fw fa-save" />
												</b-button>
											</b-btn-group>
										</b-td>
									</b-tr>
								</b-tfoot>
							</b-table-simple>
						</base-block>
					</b-tab>
					<b-tab
						title="Valid Offerings"
						@click="() => offeringDisplay = true"
					>
						<base-block
							title="Valid Offerings"
							header-bg
						>
							<div>
								<b-form-group v-slot="{ ariaDescribedby }">
									<custom-checkbox-group
										id="checkbox-group"
										ref="checkboxRef"
										v-model="selectedOfferings"
										:options="productGroup"
										value-field="id"
										text-field="name"
										:aria-describedby="ariaDescribedby"
										name="flavour-1"
										@change="toggleOfferings(selectedOfferings, $event)"
									/>
								</b-form-group>
							</div>
							<b-row
								v-for="(off, index) in offering"
								:key="index"
							>
								<b-col md="4">
									<custom-checkbox
										:id="`checkbox-${index}`"
										:is-switch="true"
										size="lg"
										:checked="selectedOfferings.includes(off.groupid)"
									/>
								</b-col>
								<b-col md="8">
									<div>{{ off.id }} - {{ off.name }} - {{ off.description }}</div>
								</b-col>
							</b-row>
						</base-block>
					</b-tab>
				</b-tabs>
			</b-col>
		</b-row>
	</div>
</template>

<style lang="scss" scoped>
@import "../../../../assets/scss/custom/errors";

.thumbnail{
	display:flex;
	justify-content:space-around;
	align-items:Center;
	background-color: #ccc;
	margin-top:10px;
}
.activeCheckbox {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-left: 40px;
}

</style>
