<template>
	<div class="priceInput">
		<span
			v-if="prefix"
			class="currency"
		>
			{{ prefix }}
		</span>
		<b-form-input
			v-if="!notDecimalNumber"
			ref="inputRef"
			class="inputField"
			:class="[ (sameAsCatalogueBase || sameAsCataloguePage ) && 'same-as-catalogue' ]"
			placeholder=""
			:value="loading ? '' : internalValue"
			:readonly="readonly"
			@blur="onBlur"
			@change="onChange"
			@focus="onFocus"
			@input="onInput"
		/>
		<b-form-input
			v-else
			ref="inputRef"
			class="inputField"
			placeholder=""
			:value="internalValue"
			:readonly="readonly"
			@blur="onBlur"
			@change="onChange"
			@focus="onFocus"
			@input="onInput"
		/>
		<div>
			<i
				v-show="loading"
				class="fa fa-spinner fa-spin"
			/>
			<i
				v-show="success"
				class="fa fa-check"
			/>
			<i
				v-show="error"
				class="fa fa-exclamation-triangle"
			/>
		</div>

		<div
			class="autofill"
			@mousedown="onMouseDown"
		>
			<i class="fa fa-circle" />
		</div>
	</div>
</template>

<style lang="scss">
@import "../../../assets/scss/custom/errors";

.priceInput {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	height: 100%;
	width: 100%;
	background: #fff;
	border: 1px solid #ccc;

	.inputField {
		border: 0;
		flex: 1 1 300px;
		height: 100%;
		line-height: 1.5;
		padding: 5px 10px 5px 5px;

		&:focus {
			outline: none;
		}
	}
	.same-as-catalogue {
		color: #E9ECEF;
	}
	.currency {
		border-right: 0;
		padding: 5px 5px 5px 10px;
		line-height: 1.5;
		color: #999;
	}

	.autofill {
		color: blue;
		position: absolute !important;
		right: -5px;
		bottom: -5px;
		font-size: 10px;
		display: none;
	}

	.autofill:hover {
		cursor: crosshair;
	}
}
</style>
