import {
	Vue, Component, Ref, Prop, toNative,
} from 'vue-facing-decorator';
import * as DB from 'interfaces/database';
import { httpClient } from 'utils/http';
import { ColumnDefinitionExtended, TabulatorFull as Tabulator } from 'tabulator-tables';
import { useRouter } from 'vue-router';
import Template from './template.vue';

@Component({
	components: {},
	mixins: [Template],
})
class UserReferrals extends Vue {
	@Ref('userReferralTable')
	private allTableReference!: HTMLDivElement;

	@Prop({
		type: String,
		required: true,
	})
	private readonly routeId!: string;

	private data : number[] = [];

	private userRef : DB.OrderModel[] = [];

	private table?: Tabulator;

	private isLoading = false;

	private columnDefs: ColumnDefinitionExtended[] = [];

	private router = useRouter();

	get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	beforeMount(): void {
		this.columnDefs = [
			{
				field: 'id',
				title: 'Order ID',
				formatter: (cell) => {
					const cellValue = cell.getValue();
					const route = {
						name: 'showOrder',
						params: { orderid: cellValue },
					};
					const link = document.createElement('a');
					link.href = this.router.resolve(route).href;
					link.innerText = cellValue;
					link.addEventListener(
						'click',
						(e) => {
							e.preventDefault();
							this.router.push(route);
						},
					);
					return link;
				},
			},
			{
				title: 'Referral User ID',
				field: 'userid',
				formatter: (cell) => {
					const cellValue = cell.getValue();
					const route = {
						name: 'UserProperties',
						params: { id: cellValue },
					};
					const link = document.createElement('a');
					link.href = this.router.resolve(route).href;
					link.innerText = cellValue;
					link.addEventListener(
						'click',
						(e) => {
							e.preventDefault();
							this.router.push(route);
						},
					);
					return link;
				},
			},
			{
				title: 'Date or order',
				field: 'dateadded',
			},
			{
				title: 'Sales Value',
				field: 'salesvalue',
			},
		];
	}

	protected mounted(): void {
		this.tableInitialization();
		this.table?.on(
			'tableBuilt',
			this.onTableBuilt,
		);
	}

	protected beforeUnmount() {
		this.table?.off(
			'tableBuilt',
			this.onTableBuilt,
		);
		this?.table?.destroy();
	}

	private onTableBuilt(): void {
		this.getData();
	}

	private tableInitialization(): void {
		this.table = new Tabulator(
			this.allTableReference,
			{
				height: '60vh',
				layout: 'fitColumns',
				columns: this.columnDefs,
			},
		);
	}

	private async getData(): Promise<void> {
		const id = this.routeId;
		const voucherParameter = new URLSearchParams({
			limit: '0',
			where: JSON.stringify({
				referrerid: parseInt(
					id,
					10,
				),
			}),
		});

		const orderParameter = new URLSearchParams({
			limit: '0',
		});

		this.table?.alert('Loading');
		try {
			const { data } = await httpClient.get<DB.DiscountVoucherModel[]>(`/api/discountvoucher?${voucherParameter}`);
			const orderData = await httpClient.get<DB.OrderModel[]>(`/api/order?${orderParameter}&where={"voucherid": ${data[0].referrerid}, "paid": ${1} }`);
			this.table?.setData(orderData);
		} catch (err: any) {
			this.$toastError(err.message);
		} finally {
			this.table?.clearAlert();
		}
	}
}

export default toNative(UserReferrals);
