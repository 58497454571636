<template>
	<div
		style="width: 95%"
		class="ml-5"
	>
		<loading-overlay
			:active="isLoading"
			:can-cancel="true"
		/>

		<b-row>
			<b-col
				md="6"
				lg="6"
				sm="12"
			>
				<base-block
					title="Country properties"
					header-bg
				>
					<div class="d-block text-left">
						<b-form-group
							class="form-group"
							label="Name "
							label-for="block-form1-name"
							label-cols-md="4"
						>
							<Field
								v-slot="{ errors }"
								v-model="singleCountry.name"
								rules="required"
								name="name"
							>
								<div
									class="control"
								>
									<b-form-input
										v-model="singleCountry.name"
										type="text"
										required
									/>
									<span>{{ errors[0] }}</span>
								</div>
							</Field>
						</b-form-group>
						<b-form-group
							class="form-group"
							label="ISO code"
							label-for="block-form1-name"
							label-cols-md="4"
						>
							<Field
								v-slot="{ errors }"
								v-model="singleCountry.iso"
								rules="required"
								name="name"
							>
								<div
									class="control"
								>
									<b-form-input
										v-model="singleCountry.iso"
										type="text"
										required
									/>
									<span>{{ errors[0] }}</span>
								</div>
							</Field>
						</b-form-group>
						<b-form-group
							class="form-group"
							label="ISO3 code"
							label-for="block-form1-name"
							label-cols-md="4"
						>
							<b-form-input
								v-model="singleCountry.iso3"
								type="text"
								required
							/>
						</b-form-group>
						<b-form-group
							label="Default Currency"
							class="form-group"
							label-for="block-form-currency"
							label-cols-lg="4"
						>
							<b-input-group>
								<custom-select
									id="block-form-currency"
									v-model="singleCountry.currency"
								>
									<b-form-select-option :value="null">
										Please select an option
									</b-form-select-option>
									<b-form-select-option
										v-for="(curr,id) in currency "
										:key="id"
										:value="curr.id"
									>
										{{ curr.id }}
									</b-form-select-option>
								</custom-select>
								<template #append>
									<b-input-group-text
										class="input-group-text-alt"
										style="cursor:pointer"
									>
										<custom-checkbox
											id="checkbox-1"
											name="checkbox-1"
											:checked="singleCountry.currency == null ? true : false"
											unchecked-value="not_accepted"
											@change="()=> singleCountry.currency = null "
										/>
										Null
									</b-input-group-text>
								</template>
							</b-input-group>
						</b-form-group>
						<b-form-group
							label="Region"
							class="form-group"
							label-for="block-form-region"
							label-cols-lg="4"
						>
							<b-input-group>
								<custom-select
									id="block-form-region"
									v-model="singleCountry.regionid"
								>
									<b-form-select-option :value="null">
										Please select an option
									</b-form-select-option>
									<b-form-select-option
										v-for="(reg,id) in region "
										:key="id"
										:value="reg.id"
									>
										{{ reg.name }}
									</b-form-select-option>
								</custom-select>
								<template #append>
									<b-input-group-text
										class="input-group-text-alt"
										style="cursor:pointer"
									>
										<custom-checkbox
											id="checkbox-2"
											name="checkbox-1"
											:checked="singleCountry.regionid == null ? true : false"
											unchecked-value="not_accepted"
											@change="()=> singleCountry.regionid = null "
										/>
										Null
									</b-input-group-text>
								</template>
							</b-input-group>
						</b-form-group>
						<b-form-group
							label-class="font-w600"
							label-cols-md="4"
						>
							<template #label>
								<div class="d-flex flex-row">
									<div class="mr-2">
										States
									</div>
									<div
										id="states"
										class="tip"
									/>
								</div>
							</template>
							<b-tooltip
								target="states"
								triggers="hover"
								placement="bottom"
							>
								Does this country need  a state or province in the postal address?
							</b-tooltip>
							<custom-checkbox
								id="checkbox-3"
								:is-switch="true"
								size="lg"
								:checked="singleCountry.states === 1 ? true : false"
								@change="singleCountry.states = $event"
							/>
						</b-form-group>
					</div>
				</base-block>
			</b-col>
		</b-row>
	</div>
</template>

<style lang="scss" scoped>
@import "../../assets/scss/oneui/tooltip";
@import "../../assets/scss/custom/errors";

.thumbnail{
	display:flex;
	justify-content:space-around;
	align-items:Center;
	background-color: #ccc;
	margin-top:10px;
}
.cameraCheckbox {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-left: 40px;
}
</style>
