import {
	Vue, Component, Watch, toNative,
} from 'vue-facing-decorator';
import * as DB from 'interfaces/database';
import { useRoute } from 'vue-router';
import BadgeOfferingService from 'services/BadgeOfferingService';
import BadgeService from 'services/BadgeService';
import BadgeRow from './badgeRow';
import Template from './template.vue';

@Component({
	components: {
		BadgeRow,
	},
	mixins: [Template],
})
class Badges extends Vue {
	private badges: DB.BadgeModel[] = [];

	private checkbadge: DB.BadgeOfferingModel[] = [];

	private selected = false;

	private spinner = false;

	private route = useRoute();

	get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	mounted(): void {
		this.getBadges();
		this.getBadgeOff();
	}

	// get all badges
	@Watch('loggedIn')
	async getBadges(): Promise<void> {
		try {
			this.spinner = true;
			this.badges = await BadgeService.getAll();
		} catch (error: any) {
			this.$toastError(error.message);
		}
	}

	async getBadgeOff(): Promise<void> {
		try {
			this.spinner = true;
			const params = new URLSearchParams({
				where: JSON.stringify({ offeringid: this.route.params.id }),
			});
			this.checkbadge = await BadgeOfferingService.getAll(params);
		} catch (error: any) {
			this.$toastError(error.message);
		} finally {
			this.spinner = false;
		}
	}
}

export default toNative(Badges);
