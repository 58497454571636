import {
	Vue, Component, Watch, Prop, Ref, toNative,
} from 'vue-facing-decorator';
import * as DB from 'interfaces/database';
import mitt from 'mitt';
import VatInput from 'components/Tabulator/VatInput';
import { TabEvent } from 'interfaces/app';
import { CellComponentExtended, ColumnDefinitionExtended, TabulatorFull as Tabulator } from 'tabulator-tables';
import PriceDetails from 'components/Tabulator/PriceDetails';
import { createInstance } from 'utils/vue';
import TabulatorSwitchBox from 'components/Tabulator/TabulatorSwitchBox';
import RegionOfferingService from 'services/RegionOfferingService';
import OfferingService from 'services/OfferingService';
import Template from './template.vue';

const eventBus = mitt<TabEvent<DB.OfferingModel>>();
@Component({
	components: {
		TabulatorSwitchBox,
		VatInput,
	},
	mixins: [Template],
})
class OfferingTable extends Vue {
	@Ref('offeringTable')
	private readonly tableReference!: HTMLDivElement;

	private loaded = false;

	private checkOffering = {} as Record<string, DB.RegionOfferingModel>;

	private columnDefs: ColumnDefinitionExtended[] = [];

	private rowData: DB.OfferingModel[] = [];

	private table?: Tabulator;

	@Prop({
		type: String,
		required: true,
	})
	private readonly routeId!: string;

	private get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	protected beforeMount(): void {
		this.columnDefs = [
			{
				field: 'id',
				title: 'Offering ID',
				headerFilter: true,
			},
			{
				title: 'External ID',
				field: 'externalid',
				headerFilter: true,
			},
			{
				title: 'Name',
				field: 'externalid',
				formatter(cell: CellComponentExtended<DB.OfferingModel>) {
					const instance = createInstance({
						component: PriceDetails,
						props: {
							data: cell.getData(),
						},
					});
					return instance;
				},
				headerFilter: 'input',
				headerFilterPlaceholder: 'Filter...',
			},
			{
				title: 'Available In region?',
				formatter: (cell: CellComponentExtended<DB.OfferingModel>) => {
					const data = cell.getData();
					const instance = createInstance({
						component: TabulatorSwitchBox,
						props: {
							data,
							eventBus,
							checked: Boolean(data && this.checkOffering[data.id]),
						},
					});
					return instance;
				},
			},
			{
				title: 'Vat',
				formatter: (cell: CellComponentExtended<DB.OfferingModel>) => {
					const data = cell.getData();
					const instance = createInstance({
						component: VatInput,
						props: {
							data,
							eventBus,
							checkOffering: this.checkOffering,
						},
					});
					return instance;
				},
			},
		] as ColumnDefinitionExtended[];
	}

	private switchBoxChanged(data: TabEvent<DB.OfferingModel>['switchBoxChanged']): void {
		this.table?.alert('Loading...');
		if (data.event) {
			RegionOfferingService.create(
				{
					offeringid: data.params.id,
					regionid: parseInt(
						this.routeId,
						10,
					),
					groupid: data.params.groupid,
					typeid: data.params.typeid,
				},
			)
				.then((response) => {
					this.checkOffering[response.offeringid] = response;
					return undefined;
				})
				.finally(() => {
					this.table?.clearAlert();
				}).catch((err) => {
					this.$toastError(err.message);
				});
		} else {
			RegionOfferingService
				.delete(this.checkOffering[data.params.id].id)
				.then(() => {
					delete this.checkOffering[data.params.id];
					return undefined;
				})
				.finally(() => {
					this.table?.clearAlert();
				}).catch((err) => {
					this.$toastError(err.message);
				});
		}
	}

	protected mounted(): void {
		this.getRegionOffering();
		eventBus.on(
			'switchBoxChanged',
			this.switchBoxChanged,
		);
		this.tableInitialization();
		this.table?.on(
			'tableBuilt',
			this.onTableBuilt,
		);
	}

	protected beforeUnmount(): void {
		eventBus.off(
			'switchBoxChanged',
			this.switchBoxChanged,
		);
		this.table?.off(
			'tableBuilt',
			this.onTableBuilt,
		);
		this.table?.destroy();
	}

	private onTableBuilt(): void {
		this.getData();
	}

	private tableInitialization(): void {
		this.table = new Tabulator(
			this.tableReference,
			{
				height: '60vh',
				layout: 'fitColumns',
				columns: this.columnDefs,
			},
		);
	}

	private async getData(): Promise<void> {
		const parameter = new URLSearchParams({
			limit: '0',
		});
		this.table?.alert('Loading');
		try {
			const data = await OfferingService.getAll(parameter);
			this.table?.setData(data);
		} catch (err: any) {
			this.$toastError(err.message);
		} finally {
			this.table?.clearAlert();
		}
	}

	@Watch('loggedIn')
	private async getRegionOffering(): Promise<void> {
		if (this.loggedIn) {
			const params = new URLSearchParams({
				where: JSON.stringify({ regionid: this.routeId }),
				limit: '0',
			});

			try {
				const resp = await RegionOfferingService.getAll(params);
				this.checkOffering = resp.reduce(
					(obj, item) => ({
						...obj,
						[item.offeringid]: item,
					}),
					{},
				);
			} catch (error: any) {
				this.$toastError(error.message);
			}
		}
		return undefined;
	}
}

export default toNative(OfferingTable);
