import { Vue, Component, toNative } from 'vue-facing-decorator';
import Model3dTable from 'components/Model3dTable';
import { useRouter } from 'vue-router';
import Template from './template.vue';

@Component({
	components: {
		Model3dTable,
	},
	mixins: [Template],
})
class Model3d extends Vue {
	private router = useRouter();

	showModal(): void {
		this.$root?.$emit(
			'bv::show::modal',
			'model3d-modal',
			'#model3dShow',
		);
	}

	routeModel3d(id: string): void {
		this.router.push(`/model3d/${id}`);
	}
}

export default toNative(Model3d);
