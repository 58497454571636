import {
	Vue, Component, Ref, toNative,
} from 'vue-facing-decorator';
import EditCrossSellTable from 'components/CrossSellTable/EditCrossSellTable';
import Template from './template.vue';

@Component({
	components: {
		EditCrossSellTable,
	},
	mixins: [Template],
})
class EditCrossSell extends Vue {
	@Ref('editcrosssell') readonly crossSellModal!: HTMLFormElement;

	private isLoading = false;

	protected updateCrossSellProperties(): void {
		this.crossSellModal.editCrossSell();
	}
}

export default toNative(EditCrossSell);
