<template>
	<div
		v-if="data"
		class="mt-3"
	>
		<b-form-input
			v-if="checkOffering[data.id]"
			v-model="checkOffering[data.id].vat"
			type="text"
		/>
	</div>
</template>
