import { Vue, Component, toNative } from 'vue-facing-decorator';
import ApiPermissionsTable from 'components/ApiPermissionsTable/';
import { useRouter } from 'vue-router';
import Template from './template.vue';

@Component({
	components: {
		ApiPermissionsTable,
	},
	mixins: [Template],
})
class ApiPermissions extends Vue {
	private router = useRouter();

	protected routeApiPermission(id: string): void {
		this.router.push(`/application/${id}`);
	}
}

export default toNative(ApiPermissions);
