<template>
	<base-block
		rounded
		title="Cross Sell items"
		header-bg
	>
		<b-col
			v-show="crossItemHidden"
			lg="12"
		>
			<b-form-group
				label="Name"
				label-for="example-text-input"
			>
				<custom-select
					id="example-select"
					plain
				>
					<b-form-select-option
						v-for="(Off) in Offerings"
						:key="Off.id"
						:value="Off.id"
					>
						{{ Off.id }} - {{ Off.name }} - {{ Off.description }}
					</b-form-select-option>
				</custom-select>
			</b-form-group>

			<b-button
				type="submit"
				variant="primary"
			>
				Save
			</b-button>
		</b-col>

		<template #options>
			<b-button
				type="submit"
				size="sm"
				variant="primary"
				@click="crossItemHidden = !crossItemHidden"
			>
				Add cross-sell
			</b-button>
		</template>
		<b-table-simple
			responsive
			table-class="table-vcenter"
		>
			<b-thead head-variant="dark">
				<b-tr>
					<b-th
						class="text-center"
						style="width: 50px;"
					>
						#
					</b-th>
					<b-th>Offering ID</b-th>
					<b-th>Theme ID</b-th>
					<b-th>Actions</b-th>
				</b-tr>
			</b-thead>
			<b-tbody />
		</b-table-simple>
	</base-block>
</template>
