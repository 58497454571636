<template>
	<div :class="{ 'custom-control': true, 'custom-switch': isSwitch, 'custom-checkbox': !isSwitch, 'b-custom-control-sm': size === 'sm', 'b-custom-control-lg': size === 'lg' }">
		<input
			:id="id ? id : `custom-checkbox-${_uid}`"
			v-model="localChecked"
			type="checkbox"
			class="custom-control-input"
			:name="name"
			:true-value="checkedValue"
			:false-value="uncheckedValue"
			:disabled="disabled"
			@change="onChange"
		>
		<label
			class="custom-control-label"
			:for="id ? id : `custom-checkbox-${_uid}`"
		>
			<slot />
		</label>
	</div>
</template>
