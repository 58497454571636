import { ThemeCategoryModel, ThemeModel } from 'interfaces/database';
import ApiService from 'services/ApiService';
import { httpClient } from 'utils/http';

class ThemeService extends ApiService<ThemeModel> {
	constructor() {
		super('theme');
	}

	async getAllThemeCategories(id: string | number): Promise<ThemeCategoryModel[]> {
		const response = await httpClient.get(`${this.ENDPOINT}/${id}/categories`);
		return response.data;
	}
}

export default new ThemeService();
