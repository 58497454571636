<template>
	<b-tr>
		<loading-overlay
			:active="isLoading"
			:can-cancel="true"
		/>
		<b-td class="font-w600 font-size-sm">
			<a>
				{{ reg.name }}
			</a>
		</b-td>
		<b-td class="d-none d-sm-table-cell">
			<b-form-group>
				<custom-checkbox
					ref="inp"
					v-model="selected"
					name="checkbox"
					:is-switch="true"
					size="lg"
					@change="editRegions($event, reg)"
				/>
			</b-form-group>
		</b-td>
		<b-td
			v-show="isActive"
			class="text-center"
		>
			<b-form-group
				label-for="example-text-input"
			>
				<b-form-input
					:value="data[reg.id] ? data[reg.id].vat : null"
					@input="data[reg.id].vat = $event"
				/>
			</b-form-group>
		</b-td>
	</b-tr>
</template>
