import * as DB from 'interfaces/database';
import {
	Vue, Component, Prop, toNative,
} from 'vue-facing-decorator';
import Template from './template.vue';

@Component({
	mixins: [Template],
})
class ShoppingCartOfferingRenderer extends Vue {
	@Prop({
		type: Object,
		required: true,
	})
	public readonly data!: DB.ShoppingCartItemModel;

	@Prop({
		type: Object,
		required: true,
	})
	public readonly offerings!: Record<string, DB.OfferingModel[]>;
}

export default toNative(ShoppingCartOfferingRenderer);
