<template>
	<div>
		<loading-overlay
			:active="isLoading"
			:can-cancel="true"
		/>
		<base-page-heading
			title="Offering"
			subtitle=""
			class="d-print-none"
		>
			<template #extra>
				<b-button
					id="submit"
					type="submit"
					size="md"
					variant="secondary"
					@click="editOff"
				>
					Save changes
				</b-button>
			</template>
		</base-page-heading>

		<div class="content">
			<b-row>
				<b-col md="6">
					<b-form>
						<base-block
							rounded
							title="Offering properties"
							header-bg
						>
							<b-row class="py-sm-3 py-md-5">
								<b-col
									sm="10"
									md="10"
								>
									<b-form-group
										label="Offering Id"
										label-for="block-form1-username"
										label-cols-lg="5"
									>
										<b-form-input
											id="offeringid"
											v-model="editedOffering.id"
											class="form-control-alt"
											placeholder="Offering Id"
											disabled
										/>
									</b-form-group>
									<b-form-group
										label-class="font-w600"
										label-cols-lg="5"
									>
										<template #label>
											<div class="d-flex flex-row">
												<div class="mr-2">
													In Stock?
												</div>
												<div
													id="instock"
													class="tip"
												/>
											</div>
										</template>
										<b-tooltip
											target="instock"
											triggers="hover"
											placement="bottom"
										>
											This can be used to indicate out-of-stock status
										</b-tooltip>
										<custom-checkbox
											id="checkbox-23"
											:is-switch="true"
											size="lg"
											:checked="editedOffering.instock === 1 ? true : false"
											@change="editedOffering.instock = $event"
										/>
									</b-form-group>
									<b-form-group
										class="form-group"
										label-for="block-form1-username"
										label-cols-lg="5"
									>
										<template #label>
											<div class="d-flex flex-row">
												<div class="mr-2">
													External Id
												</div>
												<div
													id="externalid"
													class="tip"
												/>
											</div>
										</template>
										<b-tooltip
											target="externalid"
											triggers="hover"
											placement="bottom"
										>
											The ID of the offering in the external production facility
										</b-tooltip>
										<b-input-group>
											<b-form-input
												v-model="editedOffering.externalid"
												type="text"
											/>
											<template #append>
												<b-input-group-text
													class="input-group-text-alt"
													style="cursor:pointer"
												>
													<custom-checkbox
														id="checkbox-1"
														name="checkbox-1"
														:checked="editedOffering.externalid == null ? true : false"
														unchecked-value="not_accepted"
														@change="()=> editedOffering.externalid = null "
													/>
													Null
												</b-input-group-text>
											</template>
										</b-input-group>
									</b-form-group>

									<b-form-group
										label-for="example-select"
										label-cols-lg="5"
									>
										<template #label>
											<div class="d-flex flex-row">
												<div class="mr-2">
													PDP ID
												</div>
												<div
													id="pdpid"
													class="tip"
												/>
											</div>
										</template>
										<b-tooltip
											target="pdpid"
											triggers="hover"
											placement="bottom"
										>
											If you want to show a Product Detail Page for this offering, select the corresponding page
										</b-tooltip>
										<b-input-group>
											<custom-select
												id="example-select"
												v-model="editedOffering.pdpid"
												plain
											>
												<b-form-select-option value="" />
												<b-form-select-option
													v-for="(pdp,id) in pdpid "
													id="pdp-data"
													:key="id"
													:value="pdp.id"
												>
													{{ pdp.id }} | {{ pdp.name }} - {{ pdp.description }}
												</b-form-select-option>
											</custom-select>
											<template #append>
												<b-input-group-text
													class="input-group-text-alt"
													style="cursor:pointer"
												>
													<custom-checkbox
														id="checkbox-2"
														name="checkbox-1"
														:checked="editedOffering.pdpid == null ? true : false"
														unchecked-value="not_accepted"
														@change="()=> editedOffering.pdpid = null "
													/>
													Null
												</b-input-group-text>
											</template>
										</b-input-group>
									</b-form-group>

									<b-form-group
										label-for="example-select"
										label-cols-lg="5"
									>
										<template #label>
											<div class="d-flex flex-row">
												<div class="mr-2">
													THEME ID
												</div>
												<div
													id="themeid"
													class="tip"
												/>
											</div>
										</template>
										<b-tooltip
											target="themeid"
											triggers="hover"
											placement="bottom"
										>
											The theme ID that will be selected by default if user create product of this offering
										</b-tooltip>
										<b-input-group>
											<custom-select
												id="example-select"
												v-model="editedOffering.themeid"
												plain
											>
												<b-form-select-option value="" />
												<b-form-select-option
													v-for="(theme) in themeid "
													:key="theme.id"
													:value="theme.id"
												>
													{{ theme.id }} | {{ theme.name }} - {{ theme.description }}
												</b-form-select-option>
											</custom-select>
											<template #append>
												<b-input-group-text
													class="input-group-text-alt"
													style="cursor:pointer"
												>
													<custom-checkbox
														id="checkbox-3"
														name="checkbox-1"
														:checked="editedOffering.themeid == null ? true : false"
														unchecked-value="not_accepted"
														@change="()=> editedOffering.themeid = null "
													/>
													Null
												</b-input-group-text>
											</template>
										</b-input-group>
									</b-form-group>

									<b-form-group
										label-for="example-select"
										label-cols-lg="5"
									>
										<template #label>
											<div class="d-flex flex-row">
												<div class="mr-2">
													2D Model ID
												</div>
												<div
													id="2dmodelid"
													class="tip"
												/>
											</div>
										</template>
										<b-tooltip
											target="2dmodelid"
											triggers="hover"
											placement="bottom"
										>
											References the 2d model of this product that can be used for showing the end result to the user.
										</b-tooltip>
										<b-input-group>
											<custom-select
												id="example-select"
												v-model="editedOffering.model2did"
												plain
											>
												<b-form-select-option value="" />
												<b-form-select-option
													v-for="(model,id) in model2did "
													:key="id"
													:value="model.id"
												>
													{{ model.id }} | {{ model.name }} - {{ model.description }}
												</b-form-select-option>
											</custom-select>
											<template #append>
												<b-input-group-text
													class="input-group-text-alt"
													style="cursor:pointer"
												>
													<custom-checkbox
														id="model2d-checkbox"
														name="checkbox-1"
														:checked="editedOffering.model2did == null ? true : false"
														@change="()=> editedOffering.model2did = null "
													/>
													Null
												</b-input-group-text>
											</template>
										</b-input-group>
									</b-form-group>

									<b-form-group
										label-for="example-select"
										label-cols-lg="5"
									>
										<template #label>
											<div class="d-flex flex-row">
												<div class="mr-2">
													3D Model ID
												</div>
												<div
													id="3dmodelid"
													class="tip"
												/>
											</div>
										</template>
										<b-tooltip
											target="3dmodelid"
											triggers="hover"
											placement="bottom"
										>
											References the 3d model of this product that can be used for showing the end result to the user.
										</b-tooltip>
										<b-input-group>
											<custom-select
												id="example-select"
												v-model="editedOffering.model3did"
												plain
											>
												<b-form-select-option value="" />
												<b-form-select-option
													v-for="(model,id) in model3did "
													:key="id"
													:value="model.id"
												>
													{{ model.id }} | {{ model.name }} - {{ model.description }}
												</b-form-select-option>
											</custom-select>
											<template #append>
												<b-input-group-text
													class="input-group-text-alt"
													style="cursor:pointer"
												>
													<custom-checkbox
														id="model3d-checkbox"
														name="checkbox-1"
														:checked="editedOffering.model3did == null ? true : false"
														@change="()=> editedOffering.model3did = null "
													/>
													Null
												</b-input-group-text>
											</template>
										</b-input-group>
									</b-form-group>

									<b-form-group
										label-cols-lg="5"
									>
										<template #label>
											<div class="d-flex flex-row">
												<div class="mr-2">
													Product icon
												</div>
												<div
													id="producticon"
													class="tip"
												/>
											</div>
										</template>
										<b-tooltip
											target="producticon"
											triggers="hover"
											placement="bottom"
										>
											This icon is shown to the user in the product selector. Should be 220x160 pixels in size.
										</b-tooltip>
										<custom-file-input
											:placeholder="placeHolders.thumbnailPlaceholder"
											@click.prevent="updatePreview()"
										/>
										<div
											v-if="imagePreview"
											class="thumbnail"
										>
											<img
												:src="imagePreview"
												alt=""
												height="100"
												width="100"
												@click="showSingle"
											>
											<div
												style="cursor:pointer"
												@click="removeThumbnail"
											>
												&#10006; Remove
											</div>
										</div>
									</b-form-group>

									<b-form-group
										label="Mask"

										label-cols-lg="5"
									>
										<custom-file-input
											:placeholder="placeHolders.maskPlaceholder"
											@click.prevent="updateMaskPreview"
										/>

										<div
											v-if="maskPreview"
											class="thumbnail"
										>
											<img
												:src="maskPreview"
												alt=""
												srcset=""
												height="100"
												width="100"
											>
											<div
												style="cursor:pointer"
												@click="removeMask"
											>
												&#10006; Remove
											</div>
										</div>
									</b-form-group>

									<b-form-group
										label="Product name"
										label-cols-lg="5"
									>
										<Field
											v-slot="{ errors }"
											v-model="editedOffering.name"
											rules="alpha_dash"
											name="Product name"
										>
											<div
												class="control"
											>
												<b-form-input
													v-model="editedOffering.name"
													placeholder="Product Name"
												/>
												<span>{{ errors[0] }}</span>
											</div>
										</Field>
									</b-form-group>

									<b-form-group
										label="Product description"
										label-for="example-text-input"
										label-cols-lg="5"
									>
										<b-form-input
											v-model="editedOffering.description"
										/>
									</b-form-group>

									<b-form-group
										class="form-group"
										label="Variant Name"
										label-for="block-form1-username"
										label-cols-lg="5"
									>
										<b-input-group>
											<Field
												v-slot="{ errors }"
												v-model="editedOffering.variantname"
												rules="alpha_dash"
												name="Variant Name"
											>
												<div
													class="control"
												>
													<b-form-input
														v-model="editedOffering.variantname"
														type="text"
													/>
													<span>{{ errors[0] }}</span>
												</div>
											</Field>
											<template #append>
												<b-input-group-text
													class="input-group-text-alt"
													style="cursor:pointer"
												>
													<custom-checkbox
														id="checkbox-5"
														name="checkbox-1"
														:checked="editedOffering.variantname == null ? true : false"
														@change="()=> editedOffering.variantname = null "
													/>
													Null
												</b-input-group-text>
											</template>
										</b-input-group>
									</b-form-group>

									<b-form-group
										label-cols-lg="5"
									>
										<template #label>
											<div class="d-flex flex-row">
												<div class="mr-2">
													Variant thumbnail
												</div>
												<div
													id="variantthumbnail"
													class="tip"
												/>
											</div>
										</template>
										<b-tooltip
											target="variantthumbnail"
											triggers="hover"
											placement="bottom"
										>
											This icon is shown to the user in the variant selector when adding the product to the shopping cart
										</b-tooltip>
										<custom-file-input
											:placeholder="placeHolders.variantPlaceHolder"
											@click.prevent="updateVariantPreview"
										/>

										<div
											v-if="variantPreview"
											class="thumbnail"
										>
											<img
												:src="variantPreview"
												alt=""
												height="100"
												width="100"
											>
											<div
												style="cursor:pointer"
												@click="removeVariant"
											>
												&#10006; Remove
											</div>
										</div>
									</b-form-group>

									<b-form-group
										class="form-group"
										label-for="block-form1-username"
										label-cols-lg="5"
									>
										<template #label>
											<div class="d-flex flex-row">
												<div class="mr-2">
													Size tag
												</div>
												<div
													id="sizetag"
													class="tip"
												/>
											</div>
										</template>
										<b-tooltip
											target="sizetag"
											triggers="hover"
											placement="bottom"
										>
											This is the size shown to the customer in the app e.g 13 x 13 cm
										</b-tooltip>
										<b-input-group>
											<b-form-input
												v-model="editedOffering.size"
												type="text"
											/>
											<template #append>
												<b-input-group-text
													class="input-group-text-alt"
													style="cursor:pointer"
												>
													<custom-checkbox
														id="checkbox-6"
														name="checkbox-1"
														:checked="editedOffering.size == null ? true : false"
														@change="()=> editedOffering.size = null "
													/>
													Null
												</b-input-group-text>
											</template>
										</b-input-group>
									</b-form-group>

									<b-form-group
										label-for="block-form1-username"
										label-cols-lg="5"
									>
										<template #label>
											<div class="d-flex flex-row">
												<div class="mr-2">
													Production Days
												</div>
												<div
													id="productionDays"
													class="tip"
												/>
											</div>
										</template>
										<b-tooltip
											target="productionDays"
											triggers="hover"
											placement="bottom"
										>
											The amount of working days that production of the offering will take (max)
										</b-tooltip>
										<b-input-group>
											<Field
												v-slot="{ errors }"
												v-model.number="editedOffering.productionDays"
												rules="integer"
												name="Production Days"
											>
												<div
													class="control"
												>
													<b-form-input
														v-model="editedOffering.productionDays"
														placeholder="Production Days"
													/>
													<span>{{ errors[0] }}</span>
												</div>
											</Field>
											<template #append>
												<b-input-group-text
													class="input-group-text-alt"
													style="cursor:pointer"
												>
													<custom-checkbox
														id="productionDay"
														name="checkbox-1"
														:checked="editedOffering.productionDays == null ? true : false"
														@change="()=> editedOffering.productionDays = null "
													/>
													Null
												</b-input-group-text>
											</template>
										</b-input-group>
									</b-form-group>

									<b-form-group
										label-for="block-form1-username"
										label-cols-lg="5"
									>
										<template #label>
											<div class="d-flex flex-row">
												<div class="mr-2">
													Minimum Quantity
												</div>
												<div
													id="minimumQuantity"
													class="tip"
												/>
											</div>
										</template>
										<b-tooltip
											target="minimumQuantity"
											triggers="hover"
											placement="bottom"
										>
											What is the minimum quantity a customer can order?
										</b-tooltip>
										<b-input-group>
											<Field
												v-slot="{ errors }"
												v-model.number="editedOffering.quantityMin"
												rules="integer"
												name="minimumQuantity"
											>
												<div
													class="control"
												>
													<b-form-input
														v-model="editedOffering.quantityMin"
														placeholder="Minimum Quanitity"
													/>
													<span>{{ errors[0] }}</span>
												</div>
											</Field>
											<template #append>
												<b-input-group-text
													class="input-group-text-alt"
													style="cursor:pointer"
												>
													<custom-checkbox
														id="quantityMin"
														name="checkbox-1"
														:checked="editedOffering.quantityMin == null ? true : false"
														@change="()=> editedOffering.quantityMin = null "
													/>
													Null
												</b-input-group-text>
											</template>
										</b-input-group>
									</b-form-group>

									<b-form-group
										label-for="block-form1-username"
										label-cols-lg="5"
									>
										<template #label>
											<div class="d-flex flex-row">
												<div class="mr-2">
													Maximum Quantity
												</div>
												<div
													id="maximumQuantity"
													class="tip"
												/>
											</div>
										</template>
										<b-tooltip
											target="maximumQuantity"
											triggers="hover"
											placement="bottom"
										>
											What is the maximum quantity a customer can order?
										</b-tooltip>
										<b-input-group>
											<Field
												v-slot="{ errors }"
												v-model.number="editedOffering.quantityMax"
												rules="integer"
												name="maximumQuantity"
											>
												<div
													class="control"
												>
													<b-form-input
														v-model="editedOffering.quantityMax"
														placeholder="Maximum Quantity"
													/>
													<span>{{ errors[0] }}</span>
												</div>
											</Field>
											<template #append>
												<b-input-group-text
													class="input-group-text-alt"
													style="cursor:pointer"
												>
													<custom-checkbox
														id="quantityMax"
														name="checkbox-1"
														:checked="editedOffering.quantityMax == null ? true : false"
														@change="()=> editedOffering.quantityMax = null "
													/>
													Null
												</b-input-group-text>
											</template>
										</b-input-group>
									</b-form-group>

									<b-form-group
										label-for="block-form1-username"
										label-cols-lg="5"
									>
										<template #label>
											<div class="d-flex flex-row">
												<div class="mr-2">
													Quality Interval
												</div>
												<div
													id="quanityInterval"
													class="tip"
												/>
											</div>
										</template>
										<b-tooltip
											target="quanityInterval"
											triggers="hover"
											placement="bottom"
										>
											At what quantity interval can a customer order?
										</b-tooltip>
										<b-input-group>
											<Field
												v-slot="{ errors }"
												v-model.number="editedOffering.quantityInterval"
												rules="integer"
												name="quantityInterval"
											>
												<div
													class="control"
												>
													<b-form-input
														v-model="editedOffering.quantityInterval"
														placeholder="quantity Interval"
													/>
													<span>{{ errors[0] }}</span>
												</div>
											</Field>
											<template #append>
												<b-input-group-text
													class="input-group-text-alt"
													style="cursor:pointer"
												>
													<custom-checkbox
														id="qualityInterval"
														name="checkbox-1"
														:checked="editedOffering.quantityInterval == null ? true : false"
														@change="()=> editedOffering.quantityInterval = null "
													/>
													Null
												</b-input-group-text>
											</template>
										</b-input-group>
									</b-form-group>

									<b-form-group
										label-for="block-form1-username"
										label-cols-lg="5"
									>
										<template #label>
											<div class="d-flex flex-row">
												<div class="mr-2">
													Page width
												</div>
												<div
													id="pagewidth"
													class="tip"
												/>
											</div>
										</template>
										<b-tooltip
											target="pagewidth"
											triggers="hover"
											placement="bottom"
										>
											Calculated @configdpi and without bleed margin
										</b-tooltip>
										<Field
											v-slot="{ errors }"
											v-model.number="editedOffering.width"
											rules="integer"
											name="Page Width"
										>
											<div
												class="control"
											>
												<b-form-input
													v-model="editedOffering.width"
													placeholder="width"
												/>
												<span>{{ errors[0] }}</span>
											</div>
										</Field>
									</b-form-group>

									<b-form-group
										label="Page height"
										label-for="block-form1-username"
										label-cols-lg="5"
									>
										<Field
											v-slot="{ errors }"
											v-model.number="editedOffering.height"
											rules="integer"
											name="Page Height"
										>
											<div
												class="control"
											>
												<b-form-input
													v-model="editedOffering.height"
													placeholder="Height"
												/>
												<span>{{ errors[0] }}</span>
											</div>
										</Field>
									</b-form-group>

									<b-form-group
										label-for="block-form1-username"
										label-cols-lg="5"
									>
										<template #label>
											<div class="d-flex flex-row">
												<div class="mr-2">
													Product depth
												</div>
												<div
													id="productdepth"
													class="tip"
												/>
											</div>
										</template>
										<b-tooltip
											target="productdepth"
											triggers="hover"
											placement="bottom"
										>
											Depth of this product in pixels (@configdpi)
										</b-tooltip>
										<Field
											v-slot="{ errors }"
											v-model.number="editedOffering.depth"
											rules="integer"
											name="Product Depth"
										>
											<div
												class="control"
											>
												<b-form-input
													v-model="editedOffering.depth"
													placeholder="Depth"
												/>
												<span>{{ errors[0] }}</span>
											</div>
										</Field>
									</b-form-group>

									<b-form-group
										class="form-group"
										label-for="block-form1-username"
										label-cols-lg="5"
									>
										<template #label>
											<div class="d-flex flex-row">
												<div class="mr-2">
													Bleed Margin
												</div>
												<div
													id="bleedmargin"
													class="tip"
												/>
											</div>
										</template>
										<b-tooltip
											target="bleedmargin"
											triggers="hover"
											placement="bottom"
										>
											Size of the bleed area in pixels ( at @configdpi)
										</b-tooltip>
										<b-input-group>
											<Field
												v-slot="{ errors }"
												v-model.number="editedOffering.bleedmargin"
												rules="integer"
												name="Bleed Margin"
											>
												<div
													class="control"
												>
													<b-form-input
														v-model="editedOffering.bleedmargin"
														type="text"
													/>
													<span>{{ errors[0] }}</span>
												</div>
											</Field>
											<template #append>
												<b-input-group-text
													class="input-group-text-alt"
													style="cursor:pointer"
												>
													<custom-checkbox
														id="checkbox-7"
														name="checkbox-1"
														:checked="editedOffering.bleedmargin == null ? true : false"
														@change="()=> editedOffering.bleedmargin = null "
													/>
													Null
												</b-input-group-text>
											</template>
										</b-input-group>
									</b-form-group>

									<b-form-group
										label-for="block-form1-username"
										label-cols-lg="5"
									>
										<template #label>
											<div class="d-flex flex-row">
												<div class="mr-2">
													Config PPI
												</div>
												<div
													id="configppi"
													class="tip"
												/>
											</div>
										</template>
										<b-tooltip
											target="configppi"
											triggers="hover"
											placement="bottom"
										>
											At what pixels-per-inch value is the highest configured in the editor
										</b-tooltip>
										<Field
											v-slot="{ errors }"
											v-model.number="editedOffering.configdpi"
											rules="integer"
											name="Config PPI"
										>
											<div
												class="control"
											>
												<b-form-input
													v-model="editedOffering.configdpi"
													placeholder="Config DPI"
												/>
												<span>{{ errors[0] }}</span>
											</div>
										</Field>
									</b-form-group>

									<b-form-group
										label-for="example-text-input"
										label-cols-lg="5"
									>
										<template #label>
											<div class="d-flex flex-row">
												<div class="mr-2">
													Quality PPI
												</div>
												<div
													id="qualityppi"
													class="tip"
												/>
											</div>
										</template>
										<b-tooltip
											target="qualityppi"
											triggers="hover"
											placement="bottom"
										>
											Below what pixels-per-inch should we display a warning about the image quality?
										</b-tooltip>
										<Field
											v-slot="{ errors }"
											v-model.number="editedOffering.qualitydpi"
											rules="integer"
											name="Quality PPI"
										>
											<div
												class="control"
											>
												<b-form-input
													v-model="editedOffering.qualitydpi"
													placeholder="Quality DPI"
												/>
												<span>{{ errors[0] }}</span>
											</div>
										</Field>
									</b-form-group>

									<b-form-group
										label-for="example-text-input"
										label-cols-lg="5"
									>
										<template #label>
											<div class="d-flex flex-row">
												<div class="mr-2">
													Minimum PPI
												</div>
												<div
													id="minimumppi"
													class="tip"
												/>
											</div>
										</template>
										<b-tooltip
											target="minimumppi"
											triggers="hover"
											placement="bottom"
										>
											What is the minimum allowable pixels-per-inch for a photo? User can not resize photo bigger than that.
										</b-tooltip>
										<Field
											v-slot="{ errors }"
											v-model.number="editedOffering.minimumdpi"
											rules="integer"
											name="Minimun PPI"
										>
											<div
												class="control"
											>
												<b-form-input
													v-model="editedOffering.minimumdpi"
													placeholder="Minimun DPI"
												/>
												<span>{{ errors[0] }}</span>
											</div>
										</Field>
									</b-form-group>

									<b-form-group
										label-for="example-text-input"
										label-cols-lg="5"
									>
										<template #label>
											<div class="d-flex flex-row">
												<div class="mr-2">
													Minimum pages
												</div>
												<div
													id="minimumpages"
													class="tip"
												/>
											</div>
										</template>
										<b-tooltip
											target="minimumpages"
											triggers="hover"
											placement="bottom"
										>
											The minimum number of pages for the product. Includes the front and back cover.
										</b-tooltip>
										<Field
											v-slot="{ errors }"
											v-model.number="editedOffering.minpages"
											rules="integer"
											name="Minimun Pages"
										>
											<div
												class="control"
											>
												<b-form-input
													v-model="editedOffering.minpages"
													placeholder="Minimum pages"
												/>
												<span>{{ errors[0] }}</span>
											</div>
										</Field>
									</b-form-group>

									<b-form-group
										label="Minimum print pages"
										label-for="example-text-input"
										label-cols-lg="5"
									>
										<template #label>
											<div class="d-flex flex-row">
												<div class="mr-2">
													Minimum print pages
												</div>
												<div
													id="minimumprintpages"
													class="tip"
												/>
											</div>
										</template>
										<b-tooltip
											target="minimumprintpages"
											triggers="hover"
											placement="bottom"
										>
											The minimum number of print pages for the product. Includes the front and back cover.
										</b-tooltip>
										<Field
											v-slot="{ errors }"
											v-model.number="editedOffering.minprintpages"
											rules="integer"
											name="Minimun Print Pages"
										>
											<div
												class="control"
											>
												<b-form-input
													v-model="editedOffering.minprintpages"
													placeholder="Minimum print Pages"
												/>
												<span>{{ errors[0] }}</span>
											</div>
										</Field>
									</b-form-group>

									<b-form-group
										label-for="example-text-input"
										label-cols-lg="5"
									>
										<template #label>
											<div class="d-flex flex-row">
												<div class="mr-2">
													Maximum pages
												</div>
												<div
													id="maximumpages"
													class="tip"
												/>
											</div>
										</template>
										<b-tooltip
											target="maximumpages"
											triggers="hover"
											placement="bottom"
										>
											The maximum number of print pages for the product. Includes the front and back cover.
										</b-tooltip>
										<Field
											v-slot="{ errors }"
											v-model.number="editedOffering.maxpages"
											rules="integer"
											name="Maximum Pages"
										>
											<div
												class="control"
											>
												<b-form-input
													v-model="editedOffering.maxpages"
													placeholder="Maximum pages"
												/>
												<span>{{ errors[0] }}</span>
											</div>
										</Field>
									</b-form-group>

									<b-form-group
										label-for="example-text-input"
										label-cols-lg="5"
									>
										<template #label>
											<div class="d-flex flex-row">
												<div class="mr-2">
													Setup pages
												</div>
												<div
													id="setuppages"
													class="tip"
												/>
											</div>
										</template>
										<b-tooltip
											target="setuppages"
											triggers="hover"
											placement="bottom"
										>
											Number of pages that will be added to the product in the editor on default
										</b-tooltip>
										<Field
											v-slot="{ errors }"
											v-model.number="editedOffering.setuppages"
											rules="integer"
											name="Setup Pages"
										>
											<div
												class="control"
											>
												<b-form-input
													v-model="editedOffering.setuppages"
													placeholder="Setup pages"
												/>
												<span>{{ errors[0] }}</span>
											</div>
										</Field>
									</b-form-group>

									<b-form-group
										label="Page interval"
										label-for="example-text-input"
										label-cols-lg="5"
									>
										<template #label>
											<div class="d-flex flex-row">
												<div class="mr-2">
													Page Interval
												</div>
												<div
													id="pageinterval"
													class="tip"
												/>
											</div>
										</template>
										<b-tooltip
											target="pageinterval"
											triggers="hover"
											placement="bottom"
										>
											In what multiple should extra pages be charged?
										</b-tooltip>
										<Field
											v-slot="{ errors }"
											v-model.number="editedOffering.pageinterval"
											rules="integer"
											name="Page Interval"
										>
											<div
												class="control"
											>
												<b-form-input
													v-model="editedOffering.pageinterval"
													placeholder="Page interval"
												/>
												<span>{{ errors[0] }}</span>
											</div>
										</Field>
									</b-form-group>

									<b-form-group
										label-class="font-w600"
										label-cols-lg="5"
									>
										<template #label>
											<div class="d-flex flex-row">
												<div class="mr-2">
													Add Blank Pages
												</div>
												<div
													id="addblankpages"
													class="tip"
												/>
											</div>
										</template>
										<b-tooltip
											target="addblankpages"
											triggers="hover"
											placement="bottom"
										>
											If the number of pages created by the user is not multiple of a 'page intervale', do you want to add blank pages to the print files(s)?
										</b-tooltip>
										<custom-checkbox
											id="checkbox-8"
											:is-switch="true"
											size="lg"
											:checked="editedOffering.addblankpages === 1 ? true : false"
											@change="editedOffering.addblankpages = $event"
										/>
									</b-form-group>

									<b-form-group
										label-class="font-w600"
										label-cols-lg="5"
									>
										<template #label>
											<div class="d-flex flex-row">
												<div class="mr-2">
													Page Quantity
												</div>
												<div
													id="pagequality"
													class="tip"
												/>
											</div>
										</template>
										<b-tooltip
											target="pagequality"
											triggers="hover"
											placement="bottom"
										>
											Can the user change the quantity property of a page in this product?
										</b-tooltip>
										<custom-checkbox
											id="checkbox-9"
											:is-switch="true"
											size="lg"
											:checked="editedOffering.pagequantity === 1 ? true : false"
											@change="editedOffering.pagequantity = $event"
										/>
									</b-form-group>

									<b-form-group
										label-for="example-text-input"
										label-cols-lg="5"
									>
										<template #label>
											<div class="d-flex flex-row">
												<div class="mr-2">
													Show Bleed
												</div>
												<div
													id="showbleed"
													class="tip"
												/>
											</div>
										</template>
										<b-tooltip
											target="showbleed"
											triggers="hover"
											placement="bottom"
										>
											Add bleed margin to user interface? (0) No (1) Optional with toggle button, default hidden (2) Yes, always shown (3) Optional with toggle button, default shown
										</b-tooltip>
										<Field
											v-slot="{ errors }"
											v-model="editedOffering.showbleed"
											rules="integer"
											name="Show Bleed"
										>
											<div
												class="control"
											>
												<b-form-input
													v-model="editedOffering.showbleed"
													placeholder="Show Bleed"
												/>
												<span>{{ errors[0] }}</span>
											</div>
										</Field>
									</b-form-group>

									<b-form-group
										label-cols-lg="5"
									>
										<template #label>
											<div class="d-flex flex-row">
												<div class="mr-2">
													Overlay Image
												</div>
												<div
													id="overlayimage"
													class="tip"
												/>
											</div>
										</template>
										<b-tooltip
											target="overlayimage"
											triggers="hover"
											placement="bottom"
										>
											Upload an image if you want to show the product in the app with a sem-transparent overlay
										</b-tooltip>
										<custom-file-input
											:placeholder="placeHolders.overlayPlaceholder"
											@click.prevent="updateOverlayPreview"
										/>

										<div
											v-if="overlayPreview"
											class="thumbnail"
										>
											<img
												:src="overlayPreview"
												alt=""
												srcset=""
												height="100"
												width="100"
											>
											<div
												style="cursor:pointer"
												@click="removeOverlay"
											>
												&#10006; Remove
											</div>
										</div>
									</b-form-group>

									<b-form-group
										label-class="font-w600"
										label-cols-lg="5"
									>
										<template #label>
											<div class="d-flex flex-row">
												<div class="mr-2">
													Colors Supported?
												</div>
												<div
													id="colorsupported"
													class="tip"
												/>
											</div>
										</template>
										<b-tooltip
											target="colorsupported"
											triggers="hover"
											placement="bottom"
										>
											Does the product support color printing? (i.e not for engraved products)
										</b-tooltip>
										<custom-checkbox
											id="checkbox-10"
											:is-switch="true"
											size="lg"
											:checked="editedOffering.color === 1 ? true : false"
											@change="editedOffering.color = $event"
										/>
									</b-form-group>

									<b-form-group
										label-for="example-text-input"
										label-cols-lg="5"
									>
										<template #label>
											<div class="d-flex flex-row">
												<div class="mr-2">
													MinimuM fontsize (pt)
												</div>
												<div
													id="minimumfontsize"
													class="tip"
												/>
											</div>
										</template>
										<b-tooltip
											target="minimumfontsize"
											triggers="hover"
											placement="bottom"
										>
											What is the minimum fontsize that can be used in this product? (i.e. for engraved products)
										</b-tooltip>
										<Field
											v-slot="{ errors }"
											v-model="editedOffering.minfontsize"
											rules="integer"
											name="Minimum fontsize"
										>
											<div
												class="control"
											>
												<b-form-input
													v-model="editedOffering.minfontsize"
													placeholder="Minimun fontsize (pt)"
												/>
												<span>{{ errors[0] }}</span>
											</div>
										</Field>
									</b-form-group>

									<b-form-group
										label-class="font-w600"
										label-cols-lg="5"
									>
										<template #label>
											<div class="d-flex flex-row">
												<div class="mr-2">
													Page fill required?
												</div>
												<div
													id="pagefillrequired"
													class="tip"
												/>
											</div>
										</template>
										<b-tooltip
											target="pagefillrequired"
											triggers="hover"
											placement="bottom"
										>
											Is a page fill required? Meaning a photo cannot be sized smaller than the page
										</b-tooltip>
										<custom-checkbox
											id="checkbox-11"
											:is-switch="true"
											size="lg"
											:checked="editedOffering.pagefill === 1 ? true : false"
											@change="editedOffering.pagefill = $event"
										/>
									</b-form-group>

									<b-form-group
										label-class="font-w600"
										label-cols-lg="5"
									>
										<template #label>
											<div class="d-flex flex-row">
												<div class="mr-2">
													Start with right page
												</div>
												<div
													id="startwithrightpage"
													class="tip"
												/>
											</div>
										</template>
										<b-tooltip
											target="startwithrightpage"
											triggers="hover"
											placement="bottom"
										>
											Inside of front and back cover is a blank page.
										</b-tooltip>
										<custom-checkbox
											id="checkbox-12"
											:is-switch="true"
											size="lg"
											:checked="editedOffering.startright === 1 ? true : false"
											@change="editedOffering.startright = $event"
										/>
									</b-form-group>

									<b-form-group
										label-class="font-w600"
										label-cols-lg="5"
									>
										<template #label>
											<div class="d-flex flex-row">
												<div class="mr-2">
													Fixed cover
												</div>
												<div
													id="fixedcover"
													class="tip"
												/>
											</div>
										</template>
										<b-tooltip
											target="fixedcover"
											triggers="hover"
											placement="bottom"
										>
											Both inside and outside of the book cover is fixed (no editable)
										</b-tooltip>
										<custom-checkbox
											id="checkbox-13"
											:is-switch="true"
											size="lg"
											:checked="editedOffering.fixedcover === 1 ? true : false"
											@change="editedOffering.fixedcover = $event"
										/>
									</b-form-group>

									<b-form-group
										label-class="font-w600"
										label-cols-lg="5"
									>
										<template #label>
											<div class="d-flex flex-row">
												<div class="mr-2">
													Spread
												</div>
												<div
													id="spread"
													class="tip"
												/>
											</div>
										</template>
										<b-tooltip
											target="spread"
											triggers="hover"
											placement="bottom"
										>
											Do pages need to be added and removed in pairs (spreads)?
										</b-tooltip>
										<custom-checkbox
											id="checkbox-14"
											:is-switch="true"
											size="lg"
											:checked="editedOffering.spread === 1 ? true : false"
											@change="editedOffering.spread = $event"
										/>
									</b-form-group>

									<b-form-group
										label-class="font-w600"
										label-cols-lg="5"
									>
										<template #label>
											<div class="d-flex flex-row">
												<div class="mr-2">
													Panel Cards
												</div>
												<div
													id="panelcards"
													class="tip"
												/>
											</div>
										</template>
										<b-tooltip
											target="panelcards"
											triggers="hover"
											placement="bottom"
										>
											Panel cards are folded cards
										</b-tooltip>
										<custom-checkbox
											id="checkbox-15"
											:is-switch="true"
											size="lg"
											:checked="editedOffering.panel === 1 ? true : false"
											@change="editedOffering.panel = $event"
										/>
									</b-form-group>

									<b-form-group
										label-for="example-select"
										label-cols-lg="5"
									>
										<template #label>
											<div class="d-flex flex-row">
												<div class="mr-2">
													Fold direction
												</div>
												<div
													id="folddirection"
													class="tip"
												/>
											</div>
										</template>
										<b-tooltip
											target="folddirection"
											triggers="hover"
											placement="bottom"
										>
											In what direction is the panel card folded? Ignore if the card is not a panel card
										</b-tooltip>
										<custom-select
											id="example-select"
											v-model="editedOffering.panelfold"
											plain
										>
											<b-form-select-option
												:value="editedOffering.panelfold"
											>
												{{ editedOffering.panelfold }}
											</b-form-select-option>
										</custom-select>
									</b-form-group>

									<b-form-group
										class="form-group"
										label-for="block-form1-username"
										label-cols-lg="5"
									>
										<template #label>
											<div class="d-flex flex-row">
												<div class="mr-2">
													Size of Set
												</div>
												<div
													id="sizeofset"
													class="tip"
												/>
											</div>
										</template>
										<b-tooltip
											target="sizeofset"
											triggers="hover"
											placement="bottom"
										>
											Is the card sold as a set to the creator? Then enter the size of each set. Otherwise set 0.
										</b-tooltip>
										<b-input-group>
											<Field
												v-slot="{ errors }"
												v-model="editedOffering.set"
												rules="integer"
												name="Size of set"
											>
												<div
													class="control"
												>
													<b-form-input
														v-model="editedOffering.set"
														type="text"
													/>
													<span>{{ errors[0] }}</span>
												</div>
											</Field>
											<template #append>
												<b-input-group-text
													class="input-group-text-alt"
												>
													<custom-checkbox
														id="checkbox-16"
														name="checkbox-1"
														:checked="editedOffering.set == null ? true : false"
														@change="()=> editedOffering.set = null "
													/>
													Null
												</b-input-group-text>
											</template>
										</b-input-group>
									</b-form-group>

									<b-form-group
										label-class="font-w600"
										label-cols-lg="5"
									>
										<template #label>
											<div class="d-flex flex-row">
												<div class="mr-2">
													Has back
												</div>
												<div
													id="hasback"
													class="tip"
												/>
											</div>
										</template>
										<b-tooltip
											target="hasback"
											triggers="hover"
											placement="bottom"
										>
											Does the product have a printed back side for each photo
										</b-tooltip>
										<custom-checkbox
											id="checkbox-17"
											:is-switch="true"
											size="lg"
											:checked="editedOffering.hasback === 1 ? true : false"
											@change="editedOffering.hasback = $event"
										/>
									</b-form-group>

									<b-form-group
										label-class="font-w600"
										label-cols-lg="5"
									>
										<template #label>
											<div class="d-flex flex-row">
												<div class="mr-2">
													Virtual
												</div>
												<div
													id="virtual"
													class="tip"
												/>
											</div>
										</template>
										<b-tooltip
											target="virtual"
											triggers="hover"
											placement="bottom"
										>
											Is this a virtual offering? (consists of multiple sub offerings)
										</b-tooltip>
										<custom-checkbox
											id="checkbox-18"
											:is-switch="true"
											size="lg"
											:checked="editedOffering.virtual === 1 ? true : false"
											@change="editedOffering.virtual = $event"
										/>
									</b-form-group>

									<b-form-group
										label-class="font-w600"
										label-cols-lg="5"
									>
										<template #label>
											<div class="d-flex flex-row">
												<div class="mr-2">
													Apply upScaling
												</div>
												<div
													id="applyupscaling"
													class="tip"
												/>
											</div>
										</template>
										<b-tooltip
											target="applyupscaling"
											triggers="hover"
											placement="bottom"
										>
											Do we use upscaling algorithms on photos(when necessary) for this offering
										</b-tooltip>
										<custom-checkbox
											id="checkbox-19"
											:is-switch="true"
											size="lg"
											:checked="editedOffering.applyUpscaling === 1 ? true : false"
											@change="editedOffering.applyUpscaling = $event"
										/>
									</b-form-group>

									<b-form-group
										label-class="font-w600"
										label-cols-lg="5"
									>
										<template #label>
											<div class="d-flex flex-row">
												<div class="mr-2">
													Apply enhancement
												</div>
												<div
													id="applyenhancement"
													class="tip"
												/>
											</div>
										</template>
										<b-tooltip
											target="applyenhancement"
											triggers="hover"
											placement="bottom"
										>
											Do we use enhancement software on photos for this offering?
										</b-tooltip>
										<custom-checkbox
											id="checkbox-21"
											:is-switch="true"
											size="lg"
											:checked="editedOffering.applyEnhancement === 1 ? true : false"
											@change="editedOffering.applyEnhancement = $event"
										/>
									</b-form-group>

									<b-form-group
										label="Editor Verision"
										label-for="block-form-username"
										label-cols-lg="5"
									>
										<b-form-input
											id="offeringid"
											v-model.number="editedOffering.editorVersion "
											class="form-control-altt"
											placeholder="Editor Version"
										/>
									</b-form-group>

									<b-form-group
										label="Type"
										label-for="example-select"
										label-cols-lg="5"
									>
										<custom-select
											id="example-select"
											v-model="editedOffering.type"
											plain
										>
											<b-form-select-option
												v-for="(ty,index) in type"
												id="pdp-data"
												:key="index"
												:value="ty"
											>
												{{ ty }}
											</b-form-select-option>
										</custom-select>
									</b-form-group>

									<b-form-group
										label="show pricing"
										label-class="font-w600"
										label-cols-lg="5"
									>
										<custom-checkbox
											id="checkbox-22"
											:is-switch="true"
											size="lg"
											:checked="editedOffering.showPricing === 1 ? true : false"
											@change="editedOffering.showPricing = $event"
										/>
									</b-form-group>
								</b-col>
							</b-row>
						</base-block>
					</b-form>
				</b-col>

				<b-col md="6">
					<b-tabs
						v-model="tabIndex1"
						class="block"
						nav-class="nav-tabs-alt"
						content-class="block-content"
					>
						<b-tab
							title="Sales Price"
							active
						>
							<SalesPrice
								:edited-offering="editedOffering"
							/>
						</b-tab>
						<b-tab title="Regions">
							<Region
								:edited="editedOffering"
							/>
						</b-tab>
						<b-tab title="Handling Price">
							<Handling v-if="tabIndex1 == 2" />
						</b-tab>
					</b-tabs>

					<b-tabs
						v-model="tabIndex"
						class="block"
						nav-class="nav-tabs-alt"
						content-class="block-content"
					>
						<b-tab
							title="Upsell"
							active
						>
							<!-- Upsell Items -->
							<Upsell />
						</b-tab>
						<b-tab title="Cross Sell">
							<!-- Cross-sell Items -->
							<CrossSell v-if="tabIndex == 1" />
						</b-tab>

						<b-tab
							title="Badges"
						>
							<Badges v-if="tabIndex == 2" />
						</b-tab>

						<b-tab title="Display Frame">
							<!-- Display Frame -->
							<Frame
								v-if="tabIndex == 3"
								:route-id="routeId"
							/>
						</b-tab>
					</b-tabs>
				</b-col>
			</b-row>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import "../../../assets/scss/oneui/tooltip";
@import "../../../assets/scss/custom/errors";

.thumbnail{
	display:flex;
	justify-content:space-around;
	align-items:Center;
	background-color: #ccc;
	margin-top:10px;
}
</style>
