<template>
	<div
		style="width: 95%"
		class="ml-5"
	>
		<b-row class="d-flex justify-content-end mb-4">
			<b-col
				sm="5"
				md="3"
				class="my-1"
				align-self="center"
			>
				<b-form-group
					label="Show Records"
					label-for="search-group-select"
					label-cols-sm="6"
					label-cols-md="4"
					label-cols-lg="4"
					label-align-sm="right"
					label-size="sm"
					class="mb-0"
				>
					<custom-select
						id="search-group-select"
						v-model="searchFilters.priceType"
						size="sm"
						@change="applyFilters"
					>
						<b-form-select-option
							value="all"
							selected
						>
							All
						</b-form-select-option>
						<b-form-select-option value="discounted">
							Discounted only
						</b-form-select-option>
						<b-form-select-option value="missing">
							Missing prices
						</b-form-select-option>
					</custom-select>
				</b-form-group>
			</b-col>
			<b-col
				sm="5"
				md="3"
				class="my-1"
				align-self="center"
			>
				<b-form-group
					label="Product Group"
					label-for="search-group-select"
					label-cols-sm="6"
					label-cols-md="4"
					label-cols-lg="4"
					label-align-sm="right"
					label-size="sm"
					class="mb-0"
				>
					<custom-select
						id="search-group-select"
						v-model="searchFilters.productGroup"
						size="sm"
						@change="applyFilters"
					>
						<b-form-select-option value="0">
							All
						</b-form-select-option>
						<b-form-select-option
							v-for="(productGroup) in productGroupModels "
							:key="productGroup.id"
							:value="productGroup.id"
						>
							{{ productGroup.name }}
						</b-form-select-option>
					</custom-select>
				</b-form-group>
			</b-col>
		</b-row>
		<b-col md="12">
			<b-card
				no-body
			>
				<div
					ref="tableContainer"
				/>
			</b-card>
		</b-col>
	</div>
</template>

<style lang="scss">
.excelColumn {
	padding: 0 !important;

	input {
		border-radius: 0 !important;
	}
}
</style>

<style lang="scss" scoped>

.scroller {
  height: 100% !important;
}
.active-item {
  background-color: red;
}
.offeringImage {
	object-fit: cover;
	max-height: 100%;
}
</style>
