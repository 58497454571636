<template>
	<div
		style="width: 95%"
		class="ml-5"
	>
		<loading-overlay
			:active="isLoaded"
			:can-cancel="true"
		/>
		<b-modal
			id="theme-modal"
			ref="theme-modal"
			hide-footer
		>
			<template #modal-title>
				Create New Theme
			</template>
			<div class="d-block text-center">
				<b-form-group
					class="form-group"
					label="Name: "
					label-for="block-form1-name"
				>
					<b-form-input
						v-model="addThemeData.name"
						type="text"
					/>
				</b-form-group>
				<b-form-group
					class="form-group"
					label="Product Group"
					label-for="block-form-offering"
				>
					<b-input-group>
						<custom-select
							id="block-form-offering"
							v-model="addThemeData.offering"
						>
							<b-form-select-option :value="{}">
								--select offering--
							</b-form-select-option>
							<b-form-select-option
								v-for="(off,id) in offering "
								:key="id"
								:value="off"
							>
								{{ off.id }} - {{ off.name }} - {{ off.description }}
							</b-form-select-option>
						</custom-select>
					</b-input-group>
				</b-form-group>
			</div>
			<b-button
				class="mt-3"
				block
				@click="addTheme()"
			>
				Save
			</b-button>
		</b-modal>
		<b-modal
			id="themeimport-modal"
			ref="themeimport-modal"
			hide-footer
		>
			<template #modal-title>
				Import Theme
			</template>
			<div class="d-block text-center">
				<b-form-group
					label-for="thumbnail"
					label-cols-lg="4"
				>
					<custom-file-input
						id="thumbnail"
						ref="fileimport"
						:placeholder="placeHolders.icon"
						@click.prevent="uploadFile"
					/>
				</b-form-group>
			</div>
		</b-modal>
		<b-row class="d-flex justify-content-end mb-4">
			<b-col
				sm="5"
				md="3"
				class="my-1"
				align-self="center"
			>
				<b-form-group
					label="Language:"
					label-for="search-group-select"
					label-cols-sm="6"
					label-cols-md="4"
					label-cols-lg="4"
					label-align-sm="right"
					label-size="sm"
					class="mb-0"
				>
					<custom-select
						id="search-group-select"
						v-model="language"
						size="sm"
						:options="languageOptions"
						@change="translateTableData(language)"
					/>
				</b-form-group>
			</b-col>
		</b-row>
		<base-block
			title="Existing Theme"
			header-bg
		>
			<b-row class="d-flex justify-content-end">
				<b-col
					md="4"
					class="my-1 d-flex justify-content-end"
				>
					<b-form-group
						class="form-group"
						label-for="block-form-offering"
					>
						<b-input-group>
							<custom-select
								id="block-form-offering"
								v-model="offeringData"
								@change="getData()"
							>
								<b-form-select-option :value="{}">
									--select offering--
								</b-form-select-option>
								<b-form-select-option
									v-for="(off,id) in offering "
									:key="id"
									:value="off"
								>
									{{ off.id }} - {{ off.name }} - {{ off.description }}
								</b-form-select-option>
							</custom-select>
						</b-input-group>
					</b-form-group>
				</b-col>
			</b-row>
			<b-row class="d-flex flex-column justify-content-left">
				<b-col
					sm="5"
					md="9"
					class="my-1"
				>
					<b-button
						class="mt-3"
						:disabled="selectedRows.length == 0"
						@click="table.deselectRow()"
					>
						Deselect all
					</b-button>
					<b-button
						class="mt-3"
						:disabled="selectedRows.length == 0"
						@click="exportSelected()"
					>
						Export Selected
					</b-button>
					<b-button
						class="mt-3"
						@click="exportAll()"
					>
						Export all
					</b-button>
				</b-col>
			</b-row>
			<div
				ref="themesTable"
			/>
		</base-block>
	</div>
</template>
