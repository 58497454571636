import {
	Vue, Component, Watch, Prop, toNative,
} from 'vue-facing-decorator';
import * as DB from 'interfaces/database';
import { PickerOptions, PickerResponse } from 'filestack-js';
import draggable from 'vuedraggable';
import CustomSelect from 'components/CustomSelect';
import CustomCheckbox from 'components/CustomCheckbox';
import CustomFileInput from 'components/CustomFileInput';
import AffiliateService from 'services/AffiliateService';
import LanguageService from 'services/LanguageService';
import ThemeCategoryService from 'services/ThemeCategoryService';
import ThemeCategoryLinkService from 'services/ThemeCategoryLinkService';
import Template from './template.vue';

@Component({
	components: {
		draggable,
		CustomSelect,
		CustomCheckbox,
		CustomFileInput,
	},
	mixins: [Template],
})
class EditCategoryTable extends Vue {
	@Prop({
		type: String,
		required: true,
	})
	public readonly themeCategoryId!: string;

	private singleCategory = {} as DB.ThemeCategoryModel;

	private language: DB.LanguageModel[] = [];

	private tracker: DB.AffiliateModel[] = [];

	private themeData: DB.ThemeModel[] = [];

	private loaded = false;

	private placeHolders: { icon: string } = {
		icon: 'Choose a file',
	};

	private visible = false;

	private spinner = false;

	private index = 0;

	private rowHeight = 60;

	private isLoading = false;

	private get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	protected mounted(): void {
		this.getSingleCategory();
		const parameters = new URLSearchParams({ limit: '0' });
		Promise
			.all([
				LanguageService.getAll(parameters),
				AffiliateService.getAll(parameters),
				ThemeCategoryService.getAllThemes(this.themeCategoryId),
			])
			.then(([languageResponse, trackerResponse, themeResponse]) => {
				this.language = languageResponse;
				this.tracker = trackerResponse;
				this.themeData = themeResponse;
				return null;
			}).catch((error) => {
				this.$toastError(error.message);
			});
	}

	@Watch('loggedIn')
	private async getSingleCategory(): Promise<DB.ThemeCategoryModel | undefined> {
		if (this.loggedIn) {
			// set the loading state
			this.isLoading = true;
			try {
				const resp = await ThemeCategoryService.get(this.themeCategoryId);
				this.singleCategory = resp;
				// eslint-disable-next-line consistent-return
				return this.singleCategory;
			} catch (error: any) {
				this.$toastError(error.message);
			} finally {
				this.isLoading = false;
			}
		}
		return undefined;
	}

	protected async editCategory(): Promise<void> {
		if (this.loggedIn) {
			this.isLoading = true;
			try {
				await ThemeCategoryService.update(
					this.themeCategoryId,
					{ ...this.singleCategory },
				);
				this.$toastSuccess('Theme Category Edited successfully');
			} catch (error: any) {
				this.$toastError(error.message);
			} finally {
				this.isLoading = false;
			}
		}
	}

	protected async onDragEnd(): Promise<void> {
		this.isLoading = true;
		try {
			const arr = this.themeData.map((element, index) => (
				{
					themeid: element.id,
					categoryid: parseInt(
						this.themeCategoryId,
						10,
					),
					serialnumber: index,
				}));
			await ThemeCategoryLinkService.addLinks(
				arr,
			);
			this.$toastSuccess('Theme Sorted successfully');
		} catch (err: any) {
			this.$toastError(err.message);
		} finally {
			this.isLoading = false;
		}
	}

	protected updateIcon(): void {
		const options: PickerOptions = {
			fromSources: ['local_file_system'],
			onUploadDone: async (files: PickerResponse) => {
				this.singleCategory.icon = files.filesUploaded[0].url;
				this.placeHolders.icon = files.filesUploaded[0].filename;
			},
		};
		this.$client.picker(options).open();
	}

	protected removeIcon(): void {
		this.singleCategory.icon = '';
	}
}

export default toNative(EditCategoryTable);
