import {
	Vue, Component, Ref, toNative,
} from 'vue-facing-decorator';
import EditCountryTable from 'components/CountryTable/EditCountryTable';
import Template from './EditCountry.vue';

@Component({
	components: {
		EditCountryTable,
	},
	mixins: [Template],
})
class EditCountry extends Vue {
	@Ref('editcountry') readonly countryModal!: HTMLFormElement;

	private isLoading = false;

	get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	updateCountryProperties(): void {
		this.countryModal.editCountry();
	}
}

export default toNative(EditCountry);
