import {
	Component,
	Prop,
	Vue,
	toNative,
} from 'vue-facing-decorator';
import { ComponentPublicInstance, ref } from 'vue';
import Template from './template.vue';

@Component({
	emits: ['blur', 'keydown', 'keyup', 'cursor-reached', 'change', 'focus', 'mousedown'],
	mixins: [Template],
})
class PriceInput extends Vue {
	@Prop({
		type: String,
		default: undefined,
	})
	public readonly prefix!: string;

	@Prop({
		type: Number,
		required: true,
	})
	public readonly value!: number;

	@Prop({
		type: Boolean,
	})
	public readonly notDecimalNumber!: boolean;

	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly readonly!: boolean;

	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly sameAsCatalogueBase!: boolean;

	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly sameAsCataloguePage!: boolean;

	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly loading!: boolean;

	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly success!: boolean;

	public internalValue?: string = '';

	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly error!: boolean;

	public inputRef = ref<HTMLInputElement | null>(null);

	public get inputElement(): HTMLInputElement {
		return ((this.$refs.inputRef as ComponentPublicInstance).$el as HTMLInputElement);
	}

	private lastSelectionStart: number | null = null;

	protected beforeUnmount() {
		this.inputElement.removeEventListener(
			'keydown',
			this.onKeyDown,
		);
		this.inputElement.removeEventListener(
			'keyup',
			this.onKeyUp,
		);
	}

	protected created() {
		if (this.notDecimalNumber) {
			this.internalValue = this.value
				? this.value.toString()
				: '';
		} else {
			this.internalValue = this.value
				? (this.value / 100).toFixed(2)
				: '';
		}
	}

	protected mounted() {
		this.inputElement.addEventListener(
			'keydown',
			this.onKeyDown,
		);
		this.inputElement.addEventListener(
			'keyup',
			this.onKeyUp,
		);
	}

	protected onBlur(event: Event): void {
		this.$emit(
			'blur',
			event,
		);
	}

	protected onKeyDown(event: KeyboardEvent): void {
		this.lastSelectionStart = this.inputElement.selectionStart;
		this.$emit(
			'keydown',
			event,
		);
	}

	protected onKeyUp(event: KeyboardEvent): void {
		this.$emit(
			'keyup',
			event,
		);
		let pointReached: InputCursorReach | undefined;

		if (
			this.lastSelectionStart === this.inputElement.selectionStart
			&& !event.shiftKey
		) {
			if (this.inputElement.selectionStart === 0
				&& event.code !== 'Delete'
			) {
				pointReached = 'start';
			} else if (this.inputElement.selectionStart === this.inputElement.value.length) {
				pointReached = 'end';
			}
		}

		if (pointReached) {
			this.$emit(
				'cursor-reached',
				pointReached,
			);
		}
	}

	protected onInput(): void {
		const input = this.inputElement;
		let caretPosition = 0;

		if (input.selectionStart !== null) {
			caretPosition = input.selectionStart;
		}

		input.setSelectionRange(
			caretPosition,
			caretPosition,
		);
		/* const val = input.value.trim();
		if (/^\d*\.?\d*$/.test(val) && value !== '') {
			const formattedValue = Number(val).toFixed(2);
			input.value = formattedValue;
			this.internalValue = formattedValue;
			input.setSelectionRange(caretPosition,
				caretPosition);
		} */
	}

	protected onChange(value: string) {
		if (this.notDecimalNumber) {
			this.$emit(
				'change',
				Number(value),
				this.value,
			);
		} else {
			this.$emit(
				'change',
				(Number(value) * 100),
				this.value,
			);
		}
	}

	protected onFocus(event: FocusEvent) {
		this.$emit(
			'focus',
			event,
		);
	}

	protected onMouseDown(event: UIEvent): void {
		this.$emit(
			'mousedown',
			event,
		);
	}
}

export default toNative(PriceInput);
