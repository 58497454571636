import {
	Vue, Component, Ref, toNative,
} from 'vue-facing-decorator';
import * as DB from 'interfaces/database';
import { BModal } from 'bootstrap-vue';
import mitt from 'mitt';
import Swal from 'sweetalert2';
import { TabEvent } from 'interfaces/app';
import { CellComponentExtended, ColumnDefinitionExtended, TabulatorFull as Tabulator } from 'tabulator-tables';
import { createInstance } from 'utils/vue';
import TabulatorBtn from 'components/Tabulator/TabulatorBtn';
import RegionService from 'services/RegionService';
import Template from './template.vue';

const eventBus = mitt<TabEvent<DB.RegionModel>>();
@Component({
	components: {
		TabulatorBtn,
	},
	emits: ['routeRegion'],
	mixins: [Template],
})
class RegionTable extends Vue {
	@Ref('addregion-modal')
	private readonly modal!: BModal;

	@Ref('regionTable')
	private readonly tableReference!: HTMLDivElement;

	private regiondata = {} as Pick<DB.RegionModel, 'name' | 'locale'>;

	private isLoaded = false;

	private isLoading = false;

	private table?: Tabulator;

	private columnDefs: ColumnDefinitionExtended[] = [];

	private data: DB.HyperlinkModel[] & { regionname?: string} = [];

	private get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	protected beforeMount(): void {
		this.columnDefs = [
			{
				field: 'name',
				title: 'Name',
			},
			{
				title: 'Locale',
				field: 'locale',
			},
			{
				title: 'Logo desktop',
				field: 'logo_desktop',
				formatter: 'image',
				formatterParams: {
					height: '150px',
				},
			},
			{
				title: 'Logo mobile',
				field: 'logo_mobile',
				formatter: 'image',
				formatterParams: {
					height: '150px',
				},
			},
			{
				title: 'Actions',
				formatter: (cell: CellComponentExtended<DB.ProductCategoryModel>) => {
					const instance = createInstance({
						component: TabulatorBtn,
						props: {
							data: cell.getData(),
							buttons: [
								{
									id: 'edit',
									eventName: 'editItem',
									className: 'fa-edit',
								},
								{
									id: 'delete',
									eventName: 'deleteItem',
									className: 'fa-times',
								},
							],
							eventBus,
						},
					});
					return instance;
				},
			},
		] as ColumnDefinitionExtended[];
	}

	protected mounted(): void {
		eventBus.on(
			'deleteItem',
			this.deleteRegion,
		);
		eventBus.on(
			'editItem',
			this.editRegion,
		);
		this.tableInitialization();
		this.table?.on(
			'tableBuilt',
			this.onTableBuilt,
		);
	}

	protected beforeUnmount(): void {
		eventBus.off(
			'deleteItem',
			this.deleteRegion,
		);
		eventBus.off(
			'editItem',
			this.editRegion,
		);
		this.table?.off(
			'tableBuilt',
			this.onTableBuilt,
		);
		this.table?.destroy();
	}

	private onTableBuilt(): void {
		this.getData();
	}

	private tableInitialization(): void {
		this.table = new Tabulator(
			this.tableReference,
			{
				height: '60vh',
				layout: 'fitColumns',
				columns: this.columnDefs,
			},
		);
	}

	private async getData(): Promise<void> {
		const parameter = new URLSearchParams({
			limit: '0',
		});
		this.table?.alert('Loading');
		try {
			const data = await RegionService.getAll(parameter);
			this.table?.setData(data);
		} catch (err: any) {
			this.$toastError(err.message);
		} finally {
			this.table?.clearAlert();
		}
	}

	protected addRegion(): void {
		this.isLoaded = true;
		RegionService.create(
			{ ...this.regiondata },
		).then((res) => {
			this.modal.hide();

			this.regiondata = {
				name: '',
				locale: '',
			};
			this.$emit(
				'routeRegion',
				res.id,
			);
			return undefined;
		})
			.finally(() => {
				this.isLoaded = false;
			})
			.catch((err) => {
				this.$toastError(err.message);
			});
	}

	private editRegion(data: TabEvent<DB.RegionModel>['editItem']): void {
		this.$emit(
			'routeRegion',
			data.id,
		);
	}

	private deleteRegion(data: TabEvent<DB.RegionModel>['deleteItem']): void {
		Swal.fire({
			title: 'Are you sure?',
			text: 'You will not be able to recover this file!',
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'btn btn-danger m-1',
				cancelButton: 'btn btn-secondary m-1',
			},
			confirmButtonText: 'Yes, delete it!',
			html: false,
		}).then((result) => {
			if (result.value) {
				this.table?.alert('Loading');
				// eslint-disable-next-line promise/no-nesting
				RegionService.delete(data.id).then(() => {
					this.$toastSuccess('Item Deleted');
					this.table?.deleteRow(data.id);
					return null;
				}).finally(() => {
					this.table?.clearAlert();
				}).catch((err) => {
					this.$toastError(err.message);
				});
			}
			return undefined;
		}).catch((err) => {
			this.$toastError(err.message);
		});
	}
}

export default toNative(RegionTable);
