<template>
	<div
		class="ml-5 mt-4"
		style="width: 95%; height: 100%;"
	>
		<loading-overlay
			:active="isLoading"
			:can-cancel="true"
		/>
		<b-row>
			<b-col
				md="12"
				lg="12"
				sm="12"
			>
				<b-tabs
					card
					content-class="mt-3"
					fill
				>
					<b-tab
						title="Layout properties"
						active
					>
						<base-block
							title="Layout properties"
							header-bg
						>
							<div
								v-if="spinner"
								class="text-center"
							>
								<div
									class="spinner-grow text-primary m-5"
									role="status"
								>
									<span class="sr-only">Loading...</span>
								</div>
							</div>
							<div
								v-else
								class="d-block text-center"
							>
								<b-form-group
									class="form-group"
									label="name"
									label-for="block-form1-name"
									label-cols-lg="4"
								>
									<b-input-group>
										<b-form-input
											v-model="singleLayout.name"
											type="text"
										/>
									</b-input-group>
								</b-form-group>
								<b-form-group
									label="Theme Icon"
									label-for="thumbnail"
									label-cols-lg="4"
								>
									<custom-file-input
										id="thumbnail"
										ref="logodesktop"
										:placeholder="placeHolders.icon"
										@click.prevent="updateIcon"
									/>

									<div
										v-if="singleLayout.icon"
										class="thumbnail"
									>
										<img
											:src="singleLayout.icon"
											alt=""
											srcset=""
											height="100"
											width="100"
										>
										<div
											style="cursor:pointer"
											@click="removeIcon"
										>
											&#10006; Remove
										</div>
									</div>
								</b-form-group>
							</div>
						</base-block>
					</b-tab>
					<b-tab
						title="Layout Template"
					>
						<LayoutTemplate
							:route-id="routeId"
							@routeLayoutTemplate="routeLayoutTemplate"
						/>
					</b-tab>
				</b-tabs>
			</b-col>
		</b-row>
	</div>
</template>

<style lang="scss" scoped>
@import "../../../../assets/scss/oneui/tooltip";
.thumbnail{
	display:flex;
	justify-content:space-around;
	align-items:Center;
	background-color: #ccc;
	margin-top:10px;
}
.cameraCheckbox {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-left: 40px;
}
</style>
