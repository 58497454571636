<template>
	<base-block
		title="Theme Categories"
		header-bg
	>
		<loading-overlay
			:active="isLoading"
			:can-cancel="true"
		/>
		<b-row class="mb-3">
			<b-col md="5">
				<b-card header="Available">
					<b-list-group
						style="overflow-y: auto; height: 200px"
					>
						<b-list-group-item
							v-for="(category, index) in availableCategories"
							:key="category.id"
							:active="selectedCategoryActiveIndex === index ? true : false"
							@click="availableItem(category, index)"
						>
							{{ `(${category.locale})` }} {{ category.name }}
						</b-list-group-item>
					</b-list-group>
				</b-card>
			</b-col>

			<b-col
				md="2"
				class="d-flex flex-column justify-content-around align-items-center"
			>
				<b-button
					size="sm"
					variant="outline-dark"
					@click="addToSelected"
				>
					<i class="fa fa-arrow-right mr-1" />
				</b-button>

				<b-button
					size="sm"
					variant="outline-dark"
					@click="removeFromSelected"
				>
					<i class="fa fa-arrow-left mr-1" />
				</b-button>
			</b-col>

			<b-col md="5">
				<b-card header="Selected">
					<b-list-group
						style="overflow-y: auto; height: 200px"
					>
						<b-list-group-item
							v-for="(category, index) in selectedCategories"
							:key="category.id"
							:active="availableCategoryActiveIndex === index ? true : false"
							@click="selectedItem(category, index)"
						>
							{{ `(${category.locale})` }} {{ category.name }}
						</b-list-group-item>
					</b-list-group>
				</b-card>
			</b-col>
		</b-row>
	</base-block>
</template>
