import { Model3DModel } from 'interfaces/database';
import ApiService from 'services/ApiService';

class Model3DService extends ApiService<Model3DModel> {
	constructor() {
		super('model3d');
	}
}

export default new Model3DService();
