<template>
	<div
		style="width: 95%"
		class="ml-5"
	>
		<loading-overlay
			:active="isLoaded"
			:can-cancel="true"
		/>
		<b-modal
			id="pdp-modal"
			ref="addpdp-modal"
			hide-footer
		>
			<template #modal-title>
				Create New Pdp
			</template>
			<div class="d-block text-center">
				<b-form-group
					class="form-group"
					label="Name: "
					label-for="block-form1-name"
				>
					<Field
						v-slot="{ errors }"
						v-model="name"
						rules="required"
						name="name"
					>
						<div
							class="control"
						>
							<b-form-input
								v-model="name"
								type="text"
							/>
							<span>{{ errors[0] }}</span>
						</div>
					</Field>
				</b-form-group>
			</div>
			<b-button
				class="mt-3"
				block
				@click="addPdp()"
			>
				Save
			</b-button>
		</b-modal>
		<b-row class="d-flex justify-content-end mb-4">
			<b-col
				sm="5"
				md="3"
				class="my-1"
				align-self="center"
			>
				<b-form-group
					label="Language:"
					label-for="search-group-select"
					label-cols-sm="6"
					label-cols-md="4"
					label-cols-lg="4"
					label-align-sm="right"
					label-size="sm"
					class="mb-0"
				>
					<custom-select
						id="search-group-select"
						v-model="language"
						size="sm"
						:options="languageOptions"
						@change="translateTableData(language)"
					/>
				</b-form-group>
			</b-col>
		</b-row>
		<base-block
			title="Existing Pdps"
			header-bg
		>
			<div
				ref="pdpTable"
			/>
		</base-block>
	</div>
</template>

<style lang="scss">
@import "../../assets/scss/custom/errors";
</style>
