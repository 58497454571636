<template>
	<div
		style="width: 95%"
		class="ml-5 mt-4"
	>
		<loading-overlay
			:active="isLoaded"
			:can-cancel="true"
		/>
		<b-modal
			id="region-modal"
			ref="addregion-modal"
			hide-footer
		>
			<template #modal-title>
				Create New Region
			</template>
			<div class="d-block text-left">
				<b-form-group
					class="form-group"
					label="Name "
					label-for="block-form1-name"
				>
					<Field
						v-slot="{ errors }"
						v-model="regiondata.name"
						rules="required"
						name="name"
					>
						<div
							class="control"
						>
							<b-form-input
								v-model="regiondata.name"
								type="text"
							/>
							<span>{{ errors[0] }}</span>
						</div>
					</Field>
				</b-form-group>
				<b-form-group
					class="form-group"
					label="Default locale "
					label-for="block-form1-name"
				>
					<Field
						v-slot="{ errors }"
						v-model="regiondata.locale"
						rules="alpha_dash"
						name="Default Locale"
					>
						<div
							class="control"
						>
							<b-form-input
								v-model="regiondata.locale"
								type="text"
							/>
							<span>{{ errors[0] }}</span>
						</div>
					</Field>
				</b-form-group>
			</div>
			<b-button
				class="mt-3"
				block
				@click="addRegion()"
			>
				Save
			</b-button>
		</b-modal>
		<base-block
			title="Existing regions"
			header-bg
		>
			<div
				ref="regionTable"
			/>
		</base-block>
	</div>
</template>

<style lang="scss" scoped>
@import "../../assets/scss/custom/errors";
</style>
