import {
	Vue, Component, Watch, toNative,
} from 'vue-facing-decorator';
import * as DB from 'interfaces/database';
import { useRoute } from 'vue-router';
import OrderService from 'services/OrderService';
import Template from './template.vue';

@Component({
	mixins: [Template],
})
class ShowOrdersTable extends Vue {
	private name = 'ShowOrdersTable';

	private orders = {} as DB.OrderModel;

	private orderItems: DB.OrderItemModel[] = [];

	private spinner = false;

	private route = useRoute();

	private get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	protected mounted(): void {
		this.fetchOrders();
	}

	@Watch('loggedIn')
	private async fetchOrders(): Promise<void> {
		if (this.loggedIn) {
			this.spinner = true;
			try {
				const response = await OrderService.get(this.route.params.orderid as string);
				this.orders = response;
				const response2 = await OrderService.getAllOrderItems(this.route.params.orderid as string);
				this.orderItems = response2;
			} catch (err: any) {
				this.$toastError(err.message);
			} finally {
				this.spinner = false;
			}
		}
	}
}

export default toNative(ShowOrdersTable);
