import {
	Vue, Component, Ref, toNative,
} from 'vue-facing-decorator';
import EditModel3dTable from 'components/Model3dTable/EditModel3dTable';
import Template from './EditModel3d.vue';

@Component({
	components: {
		EditModel3dTable,
	},
	mixins: [Template],
})
class EditModel3d extends Vue {
	@Ref('editmodel3d') readonly model3dModal!: HTMLFormElement;

	private isLoading = false;

	get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	updateModel3dProperties(): void {
		this.model3dModal.editModel3dImage();
	}
}

export default toNative(EditModel3d);
