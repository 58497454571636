<template>
	<b-tr>
		<b-td>{{ badg.id }}</b-td>
		<b-td>{{ badg.name }}</b-td>
		<b-td>
			<custom-checkbox
				id="checkbox1"
				v-model="selected"
				:is-switch="true"
				size="lg"
				@change="editBadges(badg)"
			/>
		</b-td>
	</b-tr>
</template>
