<template>
	<div class="">
		<div class="back-arrow pl-6 pt-2">
			<router-link to="/layout">
				<i class="fa fa-fw fa-arrow-left" />
				<span> Back to layouts </span>
			</router-link>
		</div>
		<base-page-heading
			title="Layout Edit"
			subtitle=""
			class="d-print-none"
		>
			<template #extra>
				<b-button
					ref="btnShow"
					type="submit"
					size="lg"
					variant="secondary"
					@click="updateLayout"
				>
					Save changes
				</b-button>
			</template>
		</base-page-heading>

		<div class="row content">
			<EditLayoutsTable
				ref="editlayout"
				:route-id="$route.params.id"
				@routeLayoutTemplate="routeLayoutTemplate"
			/>
		</div>
	</div>
</template>

<style scoped>
.back-arrow{
	background-color: #f6f7f8;
}
</style>
