import { HandlingModel } from 'interfaces/database';
import ApiService from 'services/ApiService';

class HandlingService extends ApiService<HandlingModel> {
	constructor() {
		super('handling');
	}
}

export default new HandlingService();
