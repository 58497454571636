import {
	Vue, Component, Ref, toNative,
} from 'vue-facing-decorator';
import PageObjectEditTable from 'components/Themes/ThemesTable/PageObjectEditTable';
import Template from './template.vue';

@Component({
	components: {
		PageObjectEditTable,
	},
	mixins: [Template],
})
class PageObject extends Vue {
	@Ref('objecteditpage')
	private readonly objectedit!: HTMLFormElement;

	protected updateObjectEditProperties(): void {
		this.objectedit.editPageObject();
	}
}

export default toNative(PageObject);
