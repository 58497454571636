import {
	VuexModule, Module, Mutation, Action,
} from 'vuex-module-decorators';
import { nextTick } from 'vue';
import { Vue } from 'vue-facing-decorator';
import * as DB from 'interfaces/database';
import auth0 from 'auth/authWrapper';
import { httpClient } from '../../utils/http';

@Module({ namespaced: true, name: 'Regions' })
class Region extends VuexModule {
	regionCurrency : DB.RegionCurrencyModel[] = [];

	modifiedRegionCurrency: DB.RegionCurrencyModel[] = [];

	checkRegion: DB.RegionOfferingModel[] = [];

	regions: Array<DB.RegionModel> = [];

	regionid: number[] = [];

	regionCurrencySales: DB.RegionCurrencyModel[] = [];

	isLoading = false;

	currency: Array<DB.CurrencyModel> = [];

	@Mutation
	setRegion(regions: DB.RegionModel[]): void {
		this.regions = regions;
	}

	@Mutation
	setRegionCurrency(regionCurrency : DB.RegionCurrencyModel) : void {
		if (this.regionCurrency.includes(regionCurrency) === false) {
			this.regionCurrency.push(regionCurrency);
		}
	}

	@Mutation
	setLoadingStatus(status: boolean) : void {
		this.isLoading = status;
	}

	@Mutation
	removeRegionCurrency() : void {
		// Set the region currency to empty array
		this.regionCurrency = [];
	}

	@Mutation
	removeRegionCurrencySales() : void {
		// Set the region currency to empty array
		this.regionCurrencySales = [];
	}

	@Mutation
	setRegionCurrencySales(regioncurrencysales: DB.RegionCurrencyModel[]) : void {
		this.regionCurrencySales = regioncurrencysales;
	}

	@Mutation
	checkReg(payload: never[]) : void {
		this.checkRegion = payload;
	}

	@Mutation
	setCurrency(payload: never[]) : void {
		this.currency = payload;
	}

	@Mutation
	regionId(payload : number) : void {
		// empty regionid array then push payload
		if (this.regionid.includes(payload) === false) {
			this.regionid.push(payload);
		}
	}

	@Mutation
	removeRegionId(payload : number) : void {
		// empty regionid array then push payload
		const index = this.regionid.indexOf(payload);
		this.regionid.splice(
			index,
			1,
		);
	}

	@Action
	async region(): Promise<void> {
		if (auth0.isAuthenticated) {
			try {
				const response = await httpClient.get<DB.RegionModel[]>('/api/region');
				this.context.commit(
					'setRegion',
					response.data,
				);
			} catch (error: any) {
				Vue.prototype.$app.$bvToast.toast(
					`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					},
				);
			}
		}
	}

	@Action
	async getCurrency(): Promise<void> {
		if (auth0.isAuthenticated) {
			try {
				const response = await httpClient.get<DB.CurrencyModel[]>('/api/currency');
				this.context.commit(
					'Regions/setCurrency',
					response.data,
					{ root: true },
				);
			} catch (error: any) {
				Vue.prototype.$app.$bvToast.toast(
					`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					},
				);
			}
		}
	}

	@Action
	getRegionCurrency(offeringid: DB.OfferingModel['id']) : void {
		if (auth0.isAuthenticated) {
			this.context.commit(
				'setLoadingStatus',
				true,
			);
			if (this.regionid.length !== 0) {
				httpClient
					.get<DB.RegionCurrencyModel[]>(`/api/regioncurrency?where={"regionid": [${this.regionid}]}`)
					.then((response) => {
						response.data.forEach((i) => {
							this.context.commit(
								'setRegionCurrency',
								i.currencyid,
							);
						});
						nextTick(() => {
							this.context.dispatch(
								'getSalesPrice',
								offeringid,
							);
						});
						return undefined;
					})
					.catch((err) => {
						Vue.prototype.$app.$bvToast.toast(
							`${err.message}`,
							{
								solid: true,
								variant: 'danger',
							},
						);
					});
			} else {
				this.context.commit(
					'setLoadingStatus',
					false,
				);
			}
		}
	}

	@Action
	getSalesPrice(offeringid: DB.OfferingModel['id']): void {
		if (auth0.isAuthenticated) {
			this.context.commit(
				'setLoadingStatus',
				true,
			);
			httpClient.get<DB.PricingModel[]>(
				`/api/price?where={"offeringid":${
					offeringid
				},"currency":${JSON.stringify(this.regionCurrency)}}`,
			)
				.then((response) => {
					this.context.commit(
						'setRegionCurrencySales',
						response.data,
					);
					return null;
				}).finally(() => this.context.commit(
					'setLoadingStatus',
					false,
				))
				.catch((err) => {
					Vue.prototype.$app.$bvToast.toast(
						`${err.message}`,
						{
							solid: true,
							variant: 'danger',
						},
					);
				});
		}
		return undefined;
	}

	@Action
	checkRegions(offeringid: DB.OfferingModel['id']) : void {
		this.context.commit(
			'setLoadingStatus',
			true,
		);
		httpClient.get<DB.RegionOfferingModel[]>(
			`/api/regionoffering?where={"offeringid":${offeringid}}`,
		)
			.then((response) => {
				this.context.commit(
					'checkReg',
					response.data,
				);
				response.data.forEach((region) => {
					// check if the region ie exist in the state regionid
					if (this.regionid.includes(region.regionid) === false) {
						this.context.commit(
							'regionId',
							region.regionid,
						);
					}
				});
				return null;
			})
			.then(() => {
				this.context.dispatch(
					'getRegionCurrency',
					offeringid,
				);
				return null;
			})
			.catch((err) => {
				Vue.prototype.$app.$bvToast.toast(
					`${err.message}`,
					{
						solid: true,
						variant: 'danger',
					},
				);
			});
	}

	@Action({ rawError: true })
	callRegions(offeringid: DB.OfferingModel['id']): void {
		this.context.dispatch(
			'checkRegions',
			offeringid,
		);
	}

	get checkregion(): Record<number, DB.RegionOfferingModel> {
		return this.checkRegion.reduce(
			(acc, currentValue) => ({ ...acc, [currentValue.regionid]: currentValue }),
			{},
		);
	}

	get regionCurrencyForSales(): Array<DB.RegionCurrencyModel> {
		return this.regionCurrencySales;
	}

	get regionsState(): Array<DB.RegionModel> {
		return this.regions;
	}

	get regionCurrencyForSalesGetter(): Array<DB.RegionCurrencyModel> {
		return this.regionCurrencySales;
	}

	get allCurrencies(): string[] {
		return this.currency.map((i) => i.id);
	}

	get getLoadingStatus(): boolean {
		return this.isLoading;
	}
}

export default Region;
