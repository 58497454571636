import { ProductGroupModel } from 'interfaces/database';
import ApiService from 'services/ApiService';

class ProductGroupService extends ApiService<ProductGroupModel> {
	constructor() {
		super('productgroup');
	}
}

export default new ProductGroupService();
