import {
	Vue, Component, Ref, toNative,
} from 'vue-facing-decorator';
import * as DB from 'interfaces/database';
import {
	CellComponent,
	CellComponentExtended, ColumnDefinitionExtended, TabulatorFull as Tabulator,
} from 'tabulator-tables';
import { createInstance } from 'utils/vue';
import { saveAs } from 'file-saver';
import TabulatorBtn from 'components/Tabulator/TabulatorBtn';
import mitt from 'mitt';
import generateRandomId from 'utils/generateRandomId';
import { TabEvent } from 'interfaces/app';
import DiscountVoucherService from 'services/DiscountVoucherService';
import Template from './template.vue';

const eventBus = mitt<TabEvent<DB.DiscountModel>>();
@Component({
	mixins: [Template],
})
class DiscountVoucherTable extends Vue {
	@Ref('discountVouchersTable')
	private readonly tableReference!: HTMLDivElement;

	private isLoaded = false;

	private table?: Tabulator;

	private columnDefs: ColumnDefinitionExtended[] = [];

	private selectedRows: DB.DiscountVoucherModel[] = [];

	protected get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	protected beforeMount(): void {
		this.columnDefs = [
			{
				field: 'id',
				title: 'ID',
				headerFilter: true,
			},
			{
				title: 'Voucher Code',
				field: 'code',
				headerFilter: true,
			},
			{
				title: 'Referrer ID',
				field: 'referrerid',
			},
			{
				title: 'Discount Campaign',
				field: 'campaignName',
			},
			{
				title: 'Remark',
				formatter: (cell: CellComponentExtended<DB.DiscountModel>) => {
					const instance = createInstance({
						component: TabulatorBtn,
						props: {
							data: cell.getData(),
							buttons: [
								{
									id: 'remark',
									eventName: 'remarkItem',
									className: 'fa-comment',
								},
							],
							eventBus,
						},
					});
					return instance;
				},
				editor: 'textarea',
				cellEdited: this.cellValueChanged,
			},
			{
				title: "Order ID's",
				field: 'orderIds',
			},
			{
				title: 'Valid',
				formatter: (cell: CellComponentExtended<DB.DiscountVoucherModel>) => {
					const validity = document.createElement('div');
					validity.classList.add('text-center');
					const icon = document.createElement('i');
					if (cell.getData().validity) {
						icon.classList.add(
							'fa',
							'fa-check-circle',
							'text-success',
						);
					} else {
						icon.classList.add(
							'fa',
							'fa-times-circle',
							'text-danger',
						);
					}
					validity.appendChild(icon);
					return validity;
				},
			},
			{
				title: 'Used',
				field: 'used',
			},
			{
				title: 'Claimed',
				field: 'claimed',
			},
			{
				title: 'Limit',
				field: 'limit',
			},
			{
				title: 'Start',
				field: 'startdate',
			},
			{
				title: 'End',
				field: 'enddate',
			},
		] as ColumnDefinitionExtended[];
	}

	protected mounted(): void {
		this.tableInitialization();
		this.table?.on(
			'tableBuilt',
			this.onTableBuilt,
		);
	}

	protected beforeUnmount(): void {
		this.table?.off(
			'tableBuilt',
			this.onTableBuilt,
		);
		this.table?.off(
			'rowSelectionChanged',
			this.onSelectionChanged,
		);
		this.table?.destroy();
	}

	private onTableBuilt(): void {
		this.onGridReadyDiscount().then(() => this.table?.on(
			'rowSelectionChanged',
			this.onSelectionChanged,
		)).catch((err) => {
			this.$toastError(err.message);
		});
	}

	private tableInitialization(): void {
		this.table = new Tabulator(
			this.tableReference,
			{
				height: '60vh',
				layout: 'fitColumns',
				columns: this.columnDefs,
				selectable: true,
			},
		);
	}

	private async onGridReadyDiscount(): Promise<void> {
		const parameter = new URLSearchParams({
			fields: 'id,code,referrerid,discountid,campaignName,remark,orderIds,validity,startdate,enddate,single,credit_absolute,credit_quantity,created_by,issued_by,used,limit,claimed',
			limit: '0',
		});

		this.table?.alert('Loading');
		try {
			const data = await DiscountVoucherService.getAll(parameter);
			this.table?.setData(data);
		} catch (err: any) {
			this.$toastError(err.message);
		} finally {
			this.table?.clearAlert();
		}
	}

	protected exportSelected(): void {
		const randomId = generateRandomId();
		const csvString = this.convertToCsvString(this.selectedRows);
		const blob = new Blob(
			[csvString],
			{ type: 'text/csv;charset=utf-8' },
		);
		saveAs(
			blob,
			`${randomId}.csv`,
		);
	}

	private convertToCsvString(rows: DB.DiscountVoucherModel[]): string {
		const separator = ',';
		const headers = Object.keys(rows[0]);
		const csvRows = [];

		// Create the header row
		const headerRow = headers.join(separator);
		csvRows.push(headerRow);

		// Create the data rows
		// eslint-disable-next-line no-restricted-syntax
		for (const row of rows) {
			const values = headers.map((header) => {
				const cellValue = row[header as keyof DB.DiscountVoucherModel];
				return this.escapeCsvValue(cellValue);
			});
			const csvRow = values.join(separator);
			csvRows.push(csvRow);
		}

		return csvRows.join('\n');
	}

	// eslint-disable-next-line class-methods-use-this
	private escapeCsvValue(value: string | number | null | undefined): string {
		let escapedValue = String(value).replace(
			/"/g,
			'""',
		);
		if (escapedValue.includes(',') || escapedValue.includes('"') || escapedValue.includes('\n')) {
			escapedValue = `"${escapedValue}"`;
		}
		return escapedValue;
	}

	protected cellValueChanged(cell: CellComponent): void {
		this.table?.alert('Loading..');
		const rowData = cell.getData() as DB.DiscountVoucherModel;
		DiscountVoucherService.update(
			rowData.id,
			{
				remark: rowData.remark,
			},
		).then(() => {
			this.$toastSuccess('Remark updated');
			return undefined;
		}).finally(() => {
			this.table?.clearAlert();
		})
			.catch((error) => {
				this.$toastError(error.message);
			});
	}

	protected async exportAll(): Promise<void> {
		const parameter = new URLSearchParams({
			limit: '0',
		});
		this.table?.alert('Loading');
		try {
			const data = await DiscountVoucherService.getAll(parameter);
			const randomId = generateRandomId();
			const csvString = this.convertToCsvString(data);
			const blob = new Blob(
				[csvString],
				{ type: 'text/csv;charset=utf-8' },
			);
			saveAs(
				blob,
				`${randomId}.csv`,
			);
		} catch (error: any) {
			this.$toastError(error.message);
		} finally {
			this.table?.clearAlert();
		}
	}

	private onSelectionChanged(): void {
		if (this.table) {
			this.selectedRows = this.table.getSelectedData();
		}
	}
}

export default toNative(DiscountVoucherTable);
