import {
	Vue, Component, Ref, toNative,
} from 'vue-facing-decorator';
import EditBadgesTable from 'components/BadgesTable/EditBadgesTable';
import { useRoute } from 'vue-router';
import { httpClient } from '../../../utils/http';
import Template from './EditBadges.vue';

@Component({
	components: {
		EditBadgesTable,
	},
	mixins: [Template],
})
class EditBadges extends Vue {
	@Ref('editbadge') readonly editbadgeChild!: HTMLFormElement;

	private route = useRoute();

	private isLoading = false;

	get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	updateName(): Promise<void> | undefined {
		if (this.loggedIn) {
			// set the loading state
			this.isLoading = true;
			return httpClient.put(
				`/api/badge/${this.route.params.id}`,
				{
					name: this.editbadgeChild.refs.badgeImageName.vModelValue,
				},
			)
				.then(() => {
					this.$toastSuccess('Badge name updated');
					return undefined;
				}).catch((err) => {
					this.$toastError(err.message);
				}).finally(() => {
					this.isLoading = false;
				});
		}
		return undefined;
	}
}

export default toNative(EditBadges);
