<template>
	<div
		class="ml-5 mt-4"
		style="width: 95%; height: 100%;"
	>
		<loading-overlay
			:active="isLoading"
			:can-cancel="true"
		/>
		<b-row>
			<b-col
				md="12"
				lg="12"
				sm="12"
			>
				<b-tabs
					v-model="tabIndex"
					card
					content-class="mt-3"
					fill
				>
					<b-tab
						title="All"
						active
					>
						<base-block
							title="Region properties"
							header-bg
						>
							<div
								v-if="spinner"
								class="text-center"
							>
								<div
									class="spinner-grow text-primary m-5"
									role="status"
								>
									<span class="sr-only">Loading...</span>
								</div>
							</div>
							<div
								v-else
								class="d-block text-center"
							>
								<b-form-group
									class="form-group"
									label="Name: "
									label-for="block-form1-name"
									label-cols-lg="5"
								>
									<Field
										v-slot="{ errors }"
										v-model="singleRegion.name"
										rules="required"
										name="name"
									>
										<div
											class="control"
										>
											<b-form-input
												v-model="singleRegion.name"
												type="text"
											/>
											<span>{{ errors[0] }}</span>
										</div>
									</Field>
								</b-form-group>
								<b-form-group
									class="form-group"
									label="Default locale "
									label-for="block-form1-name"
									label-cols-lg="5"
								>
									<Field
										v-slot="{ errors }"
										v-model="singleRegion.locale"
										rules="alpha_dash"
										name="Default locale"
									>
										<div
											class="control"
										>
											<b-form-input
												v-model="singleRegion.locale"
												type="text"
											/>
											<span>{{ errors[0] }}</span>
										</div>
									</Field>
								</b-form-group>
								<b-form-group
									label="Logo mobile"
									label-cols-lg="5"
								>
									<custom-file-input
										id="thumbnail"
										ref="logomobile"
										:placeholder="placeHolders.mobile"
										@click.prevent="updateLogoMobile"
									/>

									<div
										v-if="singleRegion.logo_mobile"
										class="thumbnail"
									>
										<img
											:src="singleRegion.logo_mobile"
											alt=""
											srcset=""
											height="100"
											width="100"
										>
										<div
											style="cursor:pointer"
											@click="removeMobile"
										>
											&#10006; Remove
										</div>
									</div>
								</b-form-group>
								<b-form-group
									label="Logo desktop"
									label-cols-lg="5"
								>
									<custom-file-input
										id="thumbnail"
										ref="logodesktop"
										:placeholder="placeHolders.desktop"
										@click.prevent="updateLogoDesktop"
									/>

									<div
										v-if="singleRegion.logo_desktop"
										class="thumbnail"
									>
										<img
											:src="singleRegion.logo_desktop"
											alt=""
											srcset=""
											height="100"
											width="100"
										>
										<div
											style="cursor:pointer"
											@click="removeDesktop"
										>
											&#10006; Remove
										</div>
									</div>
								</b-form-group>
								<b-form-group
									class="form-group"
									label="Creator URL: "
									label-for="block-form1-name"
									label-cols-lg="5"
								>
									<b-form-input
										v-model="singleRegion.url"
										type="text"
									/>
								</b-form-group>
								<b-form-group
									class="form-group"
									label="Homepage URL: "
									label-for="block-form1-name"
									label-cols-lg="5"
								>
									<b-input-group>
										<b-form-input
											v-model="singleRegion.home"
											type="text"
										/>
										<template #append>
											<b-input-group-text
												class="input-group-text-alt"
												style="cursor:pointer"
											>
												<custom-checkbox
													id="checkbox-5"
													name="checkbox-1"
													:checked="singleRegion.home == null ? true : false"
													@change="()=> singleRegion.home = null "
												/>
												Null
											</b-input-group-text>
										</template>
									</b-input-group>
								</b-form-group>
							</div>
						</base-block>
					</b-tab>
					<b-tab
						title="Country"
						@click="() => getCountryData()"
					>
						<base-block
							title="Country"
							header-bg
						>
							<div
								ref="countryTable"
							/>
						</base-block>
					</b-tab>
					<b-tab
						title="Currencies"
						@click="() => getCurrencyData()"
					>
						<base-block
							title="Currencies"
							header-bg
						>
							<div
								ref="currencyTable"
							/>
						</base-block>
					</b-tab>
					<b-tab
						title="Offerings"
						@click="() => offeringDisplay = true"
					>
						<OfferingTable
							v-if="offeringDisplay"
							:route-id="routeId"
						/>
					</b-tab>
				</b-tabs>
			</b-col>
		</b-row>
	</div>
</template>

<style lang="scss" scoped>
@import "../../../assets/scss/oneui/tooltip";
@import "../../../assets/scss/custom/errors";

.thumbnail{
	display:flex;
	justify-content:space-around;
	align-items:Center;
	background-color: #ccc;
	margin-top:10px;
}
.cameraCheckbox {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-left: 40px;
}
</style>
