<template>
	<base-block
		title="Themes Layouts"
		header-bg
	>
		<loading-overlay
			:active="isLoading"
			:can-cancel="true"
		/>
		<template #options>
			<b-button
				type="submit"
				size="sm"
				variant="primary"
				@click="toggleNewLayout"
			>
				Create new Layout
			</b-button>
			<b-button
				type="submit"
				size="sm"
				variant="primary"
				@click="toggleExistingLayout"
			>
				Add existing Layout(s)
			</b-button>
		</template>
		<b-row>
			<b-col
				v-show="showNewLayout"
				lg="12"
			>
				<div class="d-block text-left">
					<b-form-group
						class="form-group"
						label="Name"
						label-for="block-form1-name"
					>
						<b-form-input
							v-model="layoutdata.name"
							type="text"
						/>
					</b-form-group>
					<b-button
						class="mt-3 mb-2"
						@click="addLayout()"
					>
						Add Layout
					</b-button>
				</div>
			</b-col>
			<b-col
				v-show="showExistingLayout"
				lg="12"
			>
				<div class="d-block text-left">
					<b-form-group
						label-for="example-select"
					>
						<custom-select
							id="example-select"
							v-model="ExistingLayoutsData"
							class="mb-2"
							plain
						>
							<b-form-select-option :value="{}">
								Please select an option
							</b-form-select-option>
							<b-form-select-option
								v-for="(layout,id) in layoutTheme"
								:key="id"
								:value="layout"
							>
								{{ layout.name }}
							</b-form-select-option>
						</custom-select>
					</b-form-group>
					<b-button
						class="mt-3 mb-2"
						@click="addLayouts()"
					>
						Add Layouts(s)
					</b-button>
				</div>
			</b-col>
		</b-row>
		<div
			ref="ThemesLayoutsTable"
		/>
	</base-block>
</template>
