import { Vue, Component, toNative } from 'vue-facing-decorator';
import DiscountTable from 'components/Discounts/DiscountsTable';
import { useRouter } from 'vue-router';
import Template from './template.vue';

@Component({
	components: {
		DiscountTable,
	},

	mixins: [Template],
})
class Discount extends Vue {
	private router = useRouter();

	protected createDiscount(): void {
		this.router.push('/discountnew');
	}

	protected routeDiscount(id: number): void {
		this.router.push(`/discount/${id}`);
	}
}

export default toNative(Discount);
