import { CountryModel } from 'interfaces/database';
import ApiService from 'services/ApiService';

class CountryService extends ApiService<CountryModel> {
	constructor() {
		super('country');
	}
}

export default new CountryService();
