<template>
	<base-block
		title="Themes Pages Table"
		header-bg
	>
		<template #options>
			<b-button
				type="submit"
				size="sm"
				variant="primary"
				@click="toggleNewPage"
			>
				Create new page
			</b-button>
			<b-button
				type="submit"
				size="sm"
				variant="primary"
				@click="toggleExistingPage"
			>
				Add existing Pages
			</b-button>
		</template>
		<b-row>
			<b-col
				v-show="showNewPage"
				lg="12"
			>
				<div class="d-block text-left">
					<b-form-group
						class="form-group"
						label="Name"
						label-for="block-form1-name"
					>
						<b-form-input
							v-model="themedata.name"
							type="text"
						/>
					</b-form-group>
					<b-form-group
						class="form-group"
						label="Serialnumber"
						label-for="block-form1-name"
					>
						<b-form-input
							v-model="themedata.serialnumber"
							type="text"
						/>
					</b-form-group>
					<b-button
						class="mt-3 mb-2"
						@click="addPage()"
					>
						Add page
					</b-button>
				</div>
			</b-col>
			<b-col
				v-show="showExistingPage"
				lg="12"
			>
				<div class="d-block text-left">
					<b-form-group
						label-for="example-select"
					>
						<custom-select
							id="example-select"
							v-model="ExistingPagesData"
							class="mb-2"
							plain
						>
							<b-form-select-option :value="''">
								Please select an option
							</b-form-select-option>
							<b-form-select-option
								v-for="(theme,id) in themepageDate"
								:key="id"
								:value="theme"
							>
								{{ theme.name }}
							</b-form-select-option>
						</custom-select>
					</b-form-group>
					<b-button
						class="mt-3 mb-2"
						@click="addPages()"
					>
						Add Page(s)
					</b-button>
				</div>
			</b-col>
		</b-row>
		<div
			ref="ThemesPagesTable"
		/>
	</base-block>
</template>
