import {
	Component,
	Ref,
	Vue,
	toNative,
} from 'vue-facing-decorator';
import { saveAs } from 'file-saver';
import * as DB from 'interfaces/database';
import { PickerOptions, PickerResponse } from 'filestack-js';
import { BModal } from 'bootstrap-vue';
import mitt from 'mitt';
import Swal from 'sweetalert2';
import {
	LanguageOptionsModel, TabEvent, Translation, Translations, TranslationsResponse,
} from 'interfaces/app';
import { httpClient } from 'utils/http';
import 'vue-loading-overlay/dist/css/index.css';
import {
	CellComponent, CellComponentExtended, ColumnDefinitionExtended, TabulatorFull as Tabulator,
} from 'tabulator-tables';
import { createInstance } from 'utils/vue';
import TabulatorSwitchBox from 'components/Tabulator/TabulatorSwitchBox';
import TabulatorBtn from 'components/Tabulator/TabulatorBtn';
import { useRouter } from 'vue-router';
import CustomSelect from 'components/CustomSelect';
import CustomFileInput from 'components/CustomFileInput';
import newLineFormatter from 'utils/tabulator';
import Template from './template.vue';

const eventBus = mitt<TabEvent<DB.ThemeModel>>();
@Component({
	components: {
		TabulatorSwitchBox,
		TabulatorBtn,
		CustomSelect,
		CustomFileInput,
	},
	emits: ['openInNewTab', 'cloneTheme', 'routeTheme'],
	mixins: [Template],
})
class ThemesTable extends Vue {
	@Ref('theme-modal')
	private readonly modal!: BModal;

	@Ref('themeimport-modal')
	private readonly importModal!: BModal;

	@Ref('themesTable')
	private readonly tableReference!: HTMLDivElement;

	protected isLoading = false;

	private totalRows = 1;

	private currentPage = 1;

	private perPage = 10;

	private offering: DB.OfferingModel[] = [];

	private languageResponse: DB.LanguageModel[] = [];

	private selectedRows: DB.ThemeModel[] = [];

	private placeHolders = {
		icon: 'Choose a file',
	};

	private search = '';

	private id = '';

	private languageData = null;

	private offeringData = {} as DB.OfferingModel;

	private columnDefs: ColumnDefinitionExtended[] = [];

	private table?: Tabulator;

	private rowData: DB.ThemeCategoryModel[] = [];

	private translations = {} as Record<number, Translation>;

	private language = '';

	private languageOptions: LanguageOptionsModel[] = [];

	private router = useRouter();

	private importCatalogData = {
		id: [] as number[],
		offering: {} as DB.OfferingModel,
	};

	private addThemeData = {
		name: '',
		languageid: null,
		offering: {} as DB.OfferingModel,
	};

	private isLoaded = false;

	private selectedExport = [];

	private externalId = '';

	private get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	protected beforeMount(): void {
		this.columnDefs = [
			{
				title: 'Icon',
				field: 'icon',
				formatter: 'image',
				formatterParams: {
					height: 300,
					width: 300,
				},
				cellDblClick: this.updateImage,
			},
			{
				field: 'name',
				title: 'Name',
			},
			{
				title: 'Header',
				field: 'header',
				editor: 'input',
				cellEdited: this.handleCellEdit,
			},
			{
				title: 'Description',
				field: 'lines',
				editor: 'textarea',
				cellEdited: this.handleCellEdit,
				formatter: newLineFormatter,
			},
			{
				title: 'Actions',
				formatter: (cell: CellComponentExtended<DB.ThemeModel>) => {
					const instance = createInstance({
						component: TabulatorBtn,
						props: {
							data: cell.getData(),
							buttons: [
								{
									id: 'edit',
									eventName: 'editItem',
									className: 'fa-edit',
								},
								{
									id: 'clone',
									eventName: 'cloneItem',
									className: 'fa-clone',
								},
								{
									id: 'delete',
									eventName: 'deleteItem',
									className: 'fa-times',
								},
							],
							eventBus,
						},
					});
					return instance;
				},
			},
		] as ColumnDefinitionExtended[];
	}

	protected mounted(): void {
		eventBus.on(
			'deleteItem',
			this.deleteTheme,
		);
		eventBus.on(
			'editItem',
			this.editTheme,
		);
		eventBus.on(
			'switchBoxChanged',
			this.switchBoxChanged,
		);
		eventBus.on(
			'cloneItem',
			this.cloneItem,
		);
		this.tableInitialization();
		this.table?.on(
			'tableBuilt',
			this.onTableBuilt,
		);
		this.getLanguageData();
	}

	protected beforeUnmount(): void {
		eventBus.off(
			'deleteItem',
			this.deleteTheme,
		);
		eventBus.off(
			'editItem',
			this.editTheme,
		);
		eventBus.off(
			'switchBoxChanged',
			this.switchBoxChanged,
		);
		eventBus.off(
			'cloneItem',
			this.cloneItem,
		);
		this.table?.off(
			'tableBuilt',
			this.onTableBuilt,
		);
		this.table?.off(
			'rowSelectionChanged',
			this.onSelectionChanged,
		);
		this.table?.destroy();
	}

	private onTableBuilt(): void {
		this.getData().then(() => this.table?.on(
			'rowSelectionChanged',
			this.onSelectionChanged,
		)).catch((err) => {
			this.$toastError(err.message);
		});
	}

	private tableInitialization(): void {
		this.table = new Tabulator(
			this.tableReference,
			{
				height: '60vh',
				layout: 'fitColumns',
				columns: this.columnDefs,
				selectable: true,
				// create a context menu to open row on a new tab
				rowContextMenu: [
					{
						label: 'Open in new tab',
						action: (e, row) => {
							const data = row.getData();
							this.$emit(
								'openInNewTab',
								data.id,
							);
						},
					},
				],
			},
		);
	}

	private handleCellEdit(cell: CellComponent): void {
		const row = cell.getRow().getData();
		const field = cell.getField();
		const newValue = cell.getValue();
		row[field] = newValue;
		//  Find and Update 'rowData' state
		const foundRow = this.rowData.find((r) => r.id === row.id);

		if (foundRow) {
			(foundRow as unknown as Record<string, string | number>)[field] = newValue;
		}
		this.saveUpdatedData([cell]).catch((error) => {
			this.$toastError(error.message);
		});
	}

	async uploadCellImage(cell: CellComponent) {
		const data = cell.getData() as DB.ThemeModel;
		const image = cell.getValue();
		try {
			await httpClient.put(
				`/api/theme/${data.id}`,
				{
					icon: image,
				},
			);
			this.$toastSuccess('Successfully updated the data for the table');
		} catch (error: any) {
			this.$toastError(error.message);
		}
	}

	private async updateImage(_: UIEvent, cell: CellComponent): Promise<void> {
		const options: PickerOptions = {
			fromSources: ['local_file_system'],
			onUploadDone: async (files: PickerResponse) => {
				const image = files.filesUploaded[0].url;
				cell.setValue(image);
				this.uploadCellImage(cell);
			},
		};
		this.$client.picker(options).open();
	}

	// eslint-disable-next-line class-methods-use-this
	private formatCellsAsTranslations(cells: CellComponent[]): Record<number, Translation> {
		return cells.reduce(
			(result, cell) => {
				const row = cell.getRow().getData();
				return {
					...result,
					[row.id]: {
						description: row.header ? row.header : '',
						lines: row.lines ? row.lines : '',
					},
				};
			},
			{},
		);
	}

	private async saveUpdatedData(cells: CellComponent[]): Promise<void> {
		const bundle: Record<number, Translation> = this.formatCellsAsTranslations(cells);

		try {
			await httpClient.put(
				'/api/translation',
				{
					namespace: 'themes',
					language: this.language,
					bundle,
				},
			);
			this.$toastSuccess('Successfully updated the data for the table');
		} catch (error: any) {
			this.$toastError(error.message);
		}
	}

	private async fetchTranslations(language: string): Promise<TranslationsResponse> {
		const parameter = new URLSearchParams({
			ns: 'themes',
			lng: language,
			limit: '0',
		});

		try {
			const { data } = await httpClient.get<TranslationsResponse>(`/api/translation/multi?${parameter}`);
			return data;
		} catch (error: any) {
			this.$toastError(error.message);
			return Promise.reject(error);
		}
	}

	private async getData(): Promise<void> {
		const parameter = new URLSearchParams({
			limit: '0',
		});

		const data = {} as Record<string, string | number>;
		// if the Language data is not empty, set the locale to the selected language
		if (this.languageData && this.languageData !== null) {
			data.locale = this.languageData;
		}
		// if the offering data is not empty, set the groupid and typeid to the selected language
		if (Object.keys(this.offeringData).length !== 0) {
			data.groupid = this.offeringData.groupid;
			data.typeid = this.offeringData.typeid;
		}
		// append data to search params
		parameter.append(
			'where',
			JSON.stringify(data),
		);

		if (this.importCatalogData.id.length > 0) {
			parameter.append(
				'where',
				JSON.stringify({ id: this.importCatalogData.id }),
			);
		}
		this.table?.alert('Loading');
		try {
			const [templateData, offeringData, languageData] = await Promise.all([
				httpClient.get<DB.ThemeCategoryModel[]>(`/api/theme?${parameter}`),
				httpClient.get<DB.OfferingModel[]>('/api/offering?limit=0'),
				httpClient.get<DB.LanguageModel[]>('/api/language?limit=0'),
			]);
			this.offering = offeringData.data;
			this.languageResponse = languageData.data;
			this.rowData = templateData.data;
			this.table?.setData(this.rowData);
		} catch (err: any) {
			this.$toastError(err.message);
		} finally {
			this.importModal.hide();
			this.table?.clearAlert();
		}
	}

	private async getLanguageData(): Promise<void> {
		try {
			const languageResponse = await httpClient.get<DB.LanguageModel[]>('/api/language?limit=0');
			this.languageOptions = languageResponse.data.map((language) => ({ value: language.id, text: language.name }));
			// get the default language
			const defaultLang = languageResponse.data.find((language: DB.LanguageModel) => language.default);
			// set the language to the default language or the first language in the list
			this.language = defaultLang ? defaultLang.id : languageResponse.data[0].id;
			// get the translations for the default language
			const translationsData = await this.fetchTranslations(this.language);

			this.translations = translationsData[this.language].themes as Translations;

			// set the translations for the default language
			this.rowData = this.rowData.map((row) => {
				if (this.translations[row.id] !== undefined) {
					return {
						...row,
						header: this.translations[row.id].description,
						lines: Array.isArray(this.translations[row.id].lines) ? this.translations[row.id].lines?.join('\n') : this.translations[row.id].lines,
					};
				}
				return row;
			});

			this.table?.updateData(this.rowData);
		} catch (error: any) {
			this.$toastError(error.message);
		}
	}

	protected async translateTableData(data: string): Promise<void> {
		const translations = await this.fetchTranslations(data);
		this.translations = translations[this.language].themes as Translations;

		this.rowData = this.rowData.map((row) => {
			if (this.translations[row.id] !== undefined) {
				return {
					...row,
					header: this.translations[row.id].description,
					lines: Array.isArray(this.translations[row.id].lines) ? this.translations[row.id].lines?.join('\n') : this.translations[row.id].lines,
				};
			}
			return row;
		});
		this.table?.updateData(this.rowData);
	}

	protected onSelectionChanged(): void {
		if (this.table) {
			this.selectedRows = this.table.getSelectedData();
		}
	}

	protected async exportSelected(): Promise<void> {
		const id = this.selectedRows.map((i) => i.id);
		if (id.length === 1) {
			const { data } = await httpClient.get(`/api/theme/${id[0]}/all`);
			this.selectedExport = data;
		} else {
			const { data } = await httpClient.post(
				'/api/theme/batchexport',
				id,
			);
			this.selectedExport = data;
		}
		const jsonString = JSON.stringify(this.selectedExport);
		const blob = new Blob(
			[jsonString],
			{ type: 'application/json' },
		);
		saveAs(
			blob,
			'Data.json',
		);
	}

	protected async exportAll(): Promise<void> {
		this.table?.alert('Loading...');
		try {
			const { data } = await httpClient.get('/api/theme?limit=0');
			const jsonString = JSON.stringify(data);
			const blob = new Blob(
				[jsonString],
				{ type: 'application/json' },
			);
			saveAs(
				blob,
				'Data.json',
			);
		} catch (error: any) {
			this.$toastError(error.message);
		} finally {
			this.table?.clearAlert();
		}
	}

	protected addTheme(): void {
		this.isLoaded = true;
		httpClient.post(
			'/api/theme',
			{
				name: this.addThemeData.name,
				locale: this.addThemeData.languageid,
				typeid: this.addThemeData.offering.typeid,
				groupid: this.addThemeData.offering.groupid,
			},
		).then((res) => {
			this.$toastSuccess('Theme Added');
			this.router.push(`/themes/${res.data.id}`);

			return undefined;
		})
			.finally(() => {
				this.isLoaded = false;
			})
			.catch((err) => {
				this.$toastError(err.message);
			});
	}

	protected uploadFile(): void {
		const options: PickerOptions = {
			fromSources: ['local_file_system'],
			onUploadDone: async (files: PickerResponse) => {
				this.placeHolders.icon = files.filesUploaded[0].filename;
				const { data } = await httpClient.post<DB.ThemeModel[]>(
					'/api/theme/import',
					{
						url: files.filesUploaded[0].url,
					},
				);
				this.importCatalogData.id = data.map((item: DB.ThemeModel) => item.id);
				this.getData();
			},
		};
		this.$client.picker(options).open();
	}

	private switchBoxChanged(data: TabEvent<DB.ThemeModel>['switchBoxChanged']): void {
		this.table?.alert('Loading...');
		if (data.event) {
			httpClient
				.put(
					`/api/theme/${data.params.id}`,
					{
						offline: 1,
					},
				)
				.then(() => undefined)
				.finally(() => {
					this.table?.clearAlert();
				}).catch((err) => {
					this.$toastError(err.message);
				});
		} else {
			httpClient
				.put(
					`/api/theme/${data.params.id}`,
					{
						data: {
							offline: 0,
						},
					},
				)
				.then(() => undefined)
				.finally(() => {
					this.table?.clearAlert();
				}).catch((err) => {
					this.$toastError(err.message);
				});
		}
	}

	private cloneItem(data: TabEvent<DB.ThemeModel>['cloneItem']): void {
		this.table?.alert('Loading..');
		// eslint-disable-next-line promise/catch-or-return
		httpClient.get(`/api/theme/${data.id}/clone`)
			.then((response) => this.$emit(
				'cloneTheme',
				response.data.id,
			)).catch((error) => this.$toastError(error.message)).finally(() => this.table?.clearAlert());
	}

	// route to Edit Themes page
	private editTheme(data: TabEvent<DB.ThemeModel>['editItem']): void {
		this.$emit(
			'routeTheme',
			data.id,
		);
	}

	// Delete item from the Themes page
	private deleteTheme(data: TabEvent<DB.ThemeModel>['deleteItem']): void {
		Swal.fire({
			title: 'Are you sure?',
			text: 'You will not be able to recover this file!',
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'btn btn-danger m-1',
				cancelButton: 'btn btn-secondary m-1',
			},
			confirmButtonText: 'Yes, delete it!',
			html: false,
		}).then((result) => {
			if (result.value) {
				this.table?.alert('Loading');
				// eslint-disable-next-line promise/no-nesting
				return httpClient.delete(`/api/theme/${data.id}`).then(() => {
					this.$toastSuccess('Item Deleted');
					this.table?.deleteRow(data.id);
					return null;
				}).finally(() => {
					this.table?.clearAlert();
				}).catch((err) => {
					this.$toastError(err.message);
				});
			}
			return undefined;
		}).catch((err) => {
			this.$toastError(err.message);
		});
	}
}

export default toNative(ThemesTable);
