import { Vue, Component, toNative } from 'vue-facing-decorator';
import showOrdersTable from 'components/showOrdersTable';
import Template from './ShowOrder.vue';

@Component({
	components: {
		showOrdersTable,
	},
	mixins: [Template],
})
class ShowOrder extends Vue {
	private name = 'ShowOrder';
}

export default toNative(ShowOrder);
