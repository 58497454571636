<template>
	<base-block
		title="User Email Log"
		header-bg
	>
		<div
			ref="emailLogTable"
		/>
	</base-block>
</template>
