import { CrossSellModel } from 'interfaces/database';
import ApiService from 'services/ApiService';

class CrossSellService extends ApiService<CrossSellModel> {
	constructor() {
		super('crosssell');
	}
}

export default new CrossSellService();
