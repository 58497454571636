import { Vue, Component, toNative } from 'vue-facing-decorator';
import OfferingOptionTable from 'components/OfferingOptionTable';
import { useRouter } from 'vue-router';
import Template from './template.vue';

@Component({
	components: {
		OfferingOptionTable,
	},
	mixins: [Template],
})
class OfferingOption extends Vue {
	private router = useRouter();

	private showModal(): void {
		this.$root?.$emit(
			'bv::show::modal',
			'offeringOption-modal',
			'#offeringOptionShow',
		);
	}

	private routeOfferingOption(id: string): void {
		this.router.push(`/offeringoption/${id}`);
	}
}

export default toNative(OfferingOption);
