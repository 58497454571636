<template>
	<base-block
		title="User properties"
		class="mt-4"
		header-bg
	>
		<div
			v-if="spinner"
			class="text-center"
		>
			<div
				class="spinner-grow text-primary m-5"
				role="status"
			/>
		</div>
		<b-table-simple
			v-else
			responsive
			table-class="table-vcenter"
		>
			<b-thead head-variant="light" />
			<b-tbody>
				<b-tr>
					<b-td> Name</b-td>
					<b-td> {{ user.first_name }} {{ user.last_name }} </b-td>
				</b-tr>
				<b-tr>
					<b-td> Email </b-td>
					<b-td> {{ user.email }} </b-td>
				</b-tr>
				<b-tr>
					<b-td> Tracker</b-td>
					<b-td> {{ tracker[user.affiliateid].name }} </b-td>
				</b-tr>
				<b-tr>
					<b-td> Registration date</b-td>
					<b-td> {{ user.timestamp }} </b-td>
				</b-tr>
				<b-tr>
					<b-td> Registered? </b-td>
					<b-td> <i :class="user.temporary ? 'fa fa-fw fa-times' : 'fa fa-fw fa-check' " /> </b-td>
				</b-tr>
				<b-tr>
					<b-td> Currency </b-td>
					<b-td> {{ currencies[user.currency].name }} ({{ currencies[user.currency].sign }}) </b-td>
				</b-tr>
				<b-tr>
					<b-td> Language </b-td>
					<b-td> {{ user.language }} </b-td>
				</b-tr>
				<b-tr>
					<b-td> Country </b-td>
					<b-td>
						<country-flag
							:country="(countries[user.countryid].iso).toLowerCase()"
							size="normal"
						/> {{ countries[user.countryid].name }}
					</b-td>
				</b-tr>
			</b-tbody>
		</b-table-simple>
	</base-block>
</template>
