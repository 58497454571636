<template>
	<div
		class="ml-5 mt-4"
		style="width: 95%; height: 100%;"
	>
		<b-modal
			id="remark-modal"
			ref="reamark-modal"
			hide-footer
		>
			<template #modal-title>
				Edit remark
			</template>
			<div class="d-block text-center">
				<b-form-group
					class="form-group"
					label="Name: "
					label-for="block-form1-name"
				>
					<b-form-input
						v-model="addRemarkData.remark"
						type="text"
					/>
				</b-form-group>
			</div>
			<b-button
				class="mt-3"
				block
				@click="addRemark()"
			>
				Save
			</b-button>
		</b-modal>
		<b-row>
			<b-col
				md="12"
				lg="12"
				sm="12"
			>
				<b-tabs
					card
					content-class="mt-3"
					fill
				>
					<b-tab
						title="Properties"
						active
					>
						<base-block
							title="Discount Info"
							header-bg
						>
							<b-table-simple
								responsive
								table-class="table-vcenter"
							>
								<b-thead head-variant="light" />
								<b-tbody>
									<b-tr>
										<b-td>
											<h6>Claimed</h6>
											<span>Number of vouchers that have been entered by users in the app</span>
										</b-td>
										<b-td>
											<b-badge
												pill
												variant="secondary"
											>
												<h3>3</h3>
											</b-badge>
										</b-td>
									</b-tr>
									<b-tr>
										<b-td>
											<h6>Total Vouchers Issued</h6>
											<span>Number of vouchers that have been issued through the dashboard</span>
										</b-td>
										<b-td>
											<b-badge
												pill
												variant="secondary"
											>
												<h3>2</h3>
											</b-badge>
										</b-td>
									</b-tr>
									<b-tr>
										<b-td>
											<h6>Total Vouchers Used</h6>
											<span>Number of vouchers that have been used for purchase</span>
										</b-td>
										<b-td>
											<b-badge
												pill
												variant="secondary"
											>
												<h3>1</h3>
											</b-badge>
										</b-td>
									</b-tr>
								</b-tbody>
							</b-table-simple>
						</base-block>
					</b-tab>
					<b-tab
						title="Discount Properties"
					>
						<base-block
							title="Discount Properties"
							header-bg
						>
							<div
								v-if="spinner"
								class="text-center"
							>
								<div
									class="spinner-grow text-primary m-5"
									role="status"
								>
									<span class="sr-only">Loading...</span>
								</div>
							</div>
							<div
								v-else
								class="d-block text-center"
							>
								<b-form-group
									class="form-group"
									label="Campaign title: "
									label-for="block-form1-name"
									label-cols-lg="5"
								>
									<div
										class="control"
									>
										<b-form-input
											v-model="singleDiscountData.name"
											type="text"
										/>
									</div>
								</b-form-group>
								<b-form-group
									label="Tracker:"
									label-for="example-select1"
									label-cols-lg="5"
								>
									<b-input-group>
										<custom-select
											id="example-select8"
											v-model="singleDiscountData.trackerid"
											plain
										>
											<b-form-select-option :value="null">
												Please select an Option
											</b-form-select-option>
											<b-form-select-option
												v-for="(track) in trackerData"
												:key="track.id"
												:value="trackerData[index].id"
											>
												{{ track.name }}
											</b-form-select-option>
										</custom-select>
									</b-input-group>
								</b-form-group>

								<b-form-group
									label="Referral Program:"
									label-for="example-select2"
									label-cols-lg="5"
								>
									<b-input-group>
										<custom-select
											id="example-select8"
											v-model="singleDiscountData.referralprogramid"
											plain
										>
											<b-form-select-option :value="null">
												Please select an Option
											</b-form-select-option>
											<b-form-select-option
												v-for="(refer) in referral"
												:key="refer.id"
												:value="refer.id"
											>
												{{ refer.name }}
											</b-form-select-option>
										</custom-select>
									</b-input-group>
								</b-form-group>

								<b-form-group
									label="Percentage:"
									label-for="example-select"
									label-cols-lg="5"
								>
									<b-input-group>
										<b-form-input
											v-model="singleDiscountData.percentage"
											type="text"
											disabled
										/>
										<b-input-group-text
											class="input-group-text-alt"
											style="cursor:pointer"
										>
											<template #append>
												%
											</template>
										</b-input-group-text>
									</b-input-group>
								</b-form-group>
								<b-form-group
									class="form-group"
									label="Max. Products:"
									label-for="block-form1-name"
									label-cols-lg="5"
								>
									<b-badge
										pill
										variant="secondary"
									>
										<h3> {{ singleDiscountData.maxproduct }}</h3>
									</b-badge>
								</b-form-group>

								<b-form-group
									label="Extra Pages:"
									label-for="example-select"
									label-cols-lg="5"
								>
									<b-input-group>
										<b-form-input
											v-model="singleDiscountData.extrapages"
											type="text"
										/>
									</b-input-group>
								</b-form-group>

								<b-form-group
									label="Max orders:"
									label-for="example-select"
									label-cols-lg="5"
								>
									<b-input-group>
										<b-form-input
											v-model="singleDiscountData.maxorders"
											type="text"
										/>
									</b-input-group>
								</b-form-group>

								<b-form-group
									label="Global Shipping discount:"
									label-for="example-select2"
									label-cols-lg="5"
								>
									<b-input-group>
										<custom-select
											id="example-select8"
											v-model="singleDiscountData.shipping_discount"
											plain
										>
											<b-form-select-option :value="null">
												Please select an Option
											</b-form-select-option>
											<b-form-select-option
												v-for="(ship,id) in shippingOptions"
												:key="id"
												:value="ship"
											>
												{{ ship }}
											</b-form-select-option>
										</custom-select>
									</b-input-group>
								</b-form-group>

								<b-form-group
									label="Free handling:"
									label-for="example-select"
									label-cols-lg="5"
								>
									<i
										v-if="singleDiscountData.free_handling"
										class="fa fa-fw fa-check"
									/>
									<i
										v-else
										class="fa fa-fw fa-times"
									/>
								</b-form-group>

								<b-form-group
									label="1 voucher per user:"
									label-for="example-select"
									label-cols-lg="5"
								>
									<i
										v-if="singleDiscountData.voucher_per_user"
										class="fa fa-fw fa-check"
									/>
									<i
										v-else
										class="fa fa-fw fa-times"
									/>
								</b-form-group>

								<b-form-group
									label="Registered users only?"
									label-class="font-w600"
									label-cols-lg="5"
								>
									<custom-checkbox
										id="checkbox-1"
										class="cameraCheckbox"
										:is-switch="true"
										size="lg"
										:checked="singleDiscountData.registered === 1"
										@change="singleDiscountData.registered = $event"
									/>
								</b-form-group>

								<b-form-group
									label="New customers only?"
									label-class="font-w600"
									label-cols-lg="5"
								>
									<custom-checkbox
										id="checkbox-2"
										class="cameraCheckbox"
										:is-switch="true"
										size="lg"
										:checked="singleDiscountData.newcustomers === 1"
										@change="singleDiscountData.newcustomers = $event"
									/>
								</b-form-group>
							</div>
						</base-block>
					</b-tab>
					<b-tab
						title="Discount Vouchers"
					>
						<base-block
							title="Discount Vouchers"
							header-bg
						>
							<template #options>
								<b-button
									type="submit"
									size="sm"
									variant="primary"
									@click="toggleDiscountVoucher"
								>
									Create new
								</b-button>
							</template>
							<b-row>
								<b-col
									v-show="showDiscountVoucher"
									lg="12"
								>
									<div class="d-block text-left">
										<b-form-group
											label="Start Date"
											label-for="example-select"
											label-cols-lg="4"
										>
											<b-form-datepicker
												id="example-datepicker"
												v-model="discountVoucherData.startddate"
												class="mb-2"
											/>
										</b-form-group>
										<b-form-group
											label="end Date"
											label-for="example-select"
											label-cols-lg="4"
										>
											<b-form-datepicker
												id="example-datepicker"
												v-model="discountVoucherData.enddate"
												class="mb-2"
											/>
										</b-form-group>
										<b-form-group
											label="Add vouchers:"
											label-for="example-select"
											label-cols-lg="4"
										>
											<b-input-group>
												<b-form-input
													v-model="discountVoucherData.code"
													type="text"
												/>
											</b-input-group>
										</b-form-group>
										<b-form-group
											label="Voucher length:"
											label-for="example-select"
											label-cols-lg="4"
										>
											<b-input-group>
												<b-form-input
													v-model="voucherLength"
													type="text"
												/>
											</b-input-group>
										</b-form-group>
										<b-button
											class="mt-3 mb-2"
											@click="saveDiscountVoucher()"
										>
											save
										</b-button>
									</div>
								</b-col>
							</b-row>
							<b-row class="d-flex flex-column justify-content-left">
								<b-col
									sm="5"
									md="9"
									class="my-1"
								>
									<b-button
										class="mt-3"
										:disabled="selectedRows.length == 0"
										@click="table.deselectRow()"
									>
										Deselect all
									</b-button>
									<b-button
										class="mt-3"
										:disabled="selectedRows.length == 0"
										@click="exportSelected()"
									>
										Export Selected
									</b-button>
									<b-button
										class="mt-3"
										@click="exportAll()"
									>
										Export all
									</b-button>
								</b-col>
							</b-row>
							<div
								ref="discountVouchersTable"
							/>
						</base-block>
					</b-tab>
					<b-tab
						title="Valid Offerings"
					>
						<base-block
							title="Valid Offerings"
							header-bg
						>
							<div>
								<b-form-group v-slot="{ ariaDescribedby }">
									<custom-checkbox-group
										id="checkbox-group-1"
										ref="checkboxRef"
										v-model="selectedOfferings"
										:options="productGroup"
										value-field="id"
										text-field="name"
										:aria-describedby="ariaDescribedby"
										name="flavour-1"
										@change="toggleOfferings(selectedOfferings, $event)"
									/>
								</b-form-group>
							</div>
							<b-row
								v-for="(off, index) in offering"
								:key="index"
							>
								<b-col md="4">
									<custom-checkbox
										:id="`checkbox-${index}`"
										:is-switch="true"
										size="lg"
										:checked="selectedOfferings.includes(off.groupid)"
									/>
								</b-col>
								<b-col md="8">
									<div>{{ off.id }} - {{ off.name }} - {{ off.description }}</div>
								</b-col>
							</b-row>
						</base-block>
					</b-tab>
					<b-tab
						title="Theme Categories"
					>
						<base-block
							title="Theme Categories"
							header-bg
						>
							<loading-overlay
								:active="isLoading"
								:can-cancel="true"
							/>
							<b-row class="mb-3">
								<b-col md="5">
									<b-card header="Available">
										<b-list-group
											style="overflow-y: auto; height: 200px"
										>
											<b-list-group-item
												v-for="(category, index) in availableCategories"
												:key="category.id"
												:active="selectedCategoryActiveIndex === index"
												@click="availableItem(category, index)"
											>
												{{ `(${category.locale})` }} {{ category.name }}
											</b-list-group-item>
										</b-list-group>
									</b-card>
								</b-col>

								<b-col
									md="2"
									class="d-flex flex-column justify-content-around align-items-center"
								>
									<b-button
										size="sm"
										variant="outline-dark"
										@click="addToSelected"
									>
										<i class="fa fa-arrow-right mr-1" />
									</b-button>

									<b-button
										size="sm"
										variant="outline-dark"
										@click="removeFromSelected"
									>
										<i class="fa fa-arrow-left mr-1" />
									</b-button>
								</b-col>

								<b-col md="5">
									<b-card header="Selected">
										<b-list-group
											style="overflow-y: auto; height: 200px"
										>
											<b-list-group-item
												v-for="(category, index) in selectedCategories"
												:key="category.id"
												:active="availableCategoryActiveIndex === index"
												@click="selectedItem(category, index)"
											>
												{{ `(${category.locale})` }} {{ category.name }}
											</b-list-group-item>
										</b-list-group>
									</b-card>
								</b-col>
							</b-row>
						</base-block>
					</b-tab>
				</b-tabs>
			</b-col>
		</b-row>
	</div>
</template>

<style lang="scss" scoped>
@import "../../../../assets/scss/custom/errors";

.thumbnail{
	display:flex;
	justify-content:space-around;
	align-items:Center;
	background-color: #ccc;
	margin-top:10px;
}
.cameraCheckbox {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-left: 40px;
}

</style>
