import {
	Vue, Component, Prop, toNative,
} from 'vue-facing-decorator';
import * as DB from 'interfaces/database';
import Template from './template.vue';

@Component({
	mixins: [Template],
})
class CurrencySalesTextLink extends Vue {
	@Prop({
		type: Object,
		required: true,
	})
	public readonly data!: DB.OrderItemModel;

	@Prop({
		type: Boolean,
	})
	public readonly salesvalue!: boolean;

	@Prop({
		type: Boolean,
	})
	public readonly discount_voucher!: boolean;
}

export default toNative(CurrencySalesTextLink);
