<template>
	<base-block
		rounded
		title="Upsell items"
		header-bg
	>
		<loading-overlay
			:active="isLoading"
			:can-cancel="true"
		/>
		<div
			v-if="spinner"
			class="text-center"
		>
			<div
				class="spinner-grow text-primary m-5"
				role="status"
			>
				<span class="sr-only">Loading...</span>
			</div>
		</div>
		<b-col
			v-else
			v-show="isHidden"
			lg="12"
		>
			<b-form-group
				label="Name"
				label-for="example-text-input"
			>
				<b-form-input
					v-model="data.name"
					placeholder="Name"
				/>
			</b-form-group>
			<b-form-group
				label="Description"
				label-for="example-text-input"
			>
				<b-form-input
					v-model="data.description"
					placeholder="Description"
				/>
			</b-form-group>

			<b-form-group
				label="Upsell Id"
				label-for="example-text-input"
			>
				<custom-select
					id="example-select"
					v-model="data.upsellid"
				>
					<b-form-select-option
						v-for="Off in offerings"
						:key="Off.id"
						:value="Off.id"
					>
						{{ Off.name }} - {{ Off.description }}
					</b-form-select-option>
				</custom-select>
			</b-form-group>

			<b-form-group
				label="Optional"
			>
				<custom-checkbox
					id="checkbox-1"
					v-model="data.optional"
					:is-switch="true"
					size="lg"
				/>
			</b-form-group>

			<b-form-group
				label="Per item"
			>
				<custom-checkbox
					id="checkbox-2"
					v-model="data.peritem"
					:is-switch="true"
					size="lg"
				/>
			</b-form-group>

			<b-form-group
				label="Auto Include"
			>
				<custom-checkbox
					id="checkbox-3"
					v-model="data.autoinclude"
					:is-switch="true"
					size="lg"
				/>
			</b-form-group>

			<b-button
				type="submit"
				variant="primary"
				@click="addUpsell"
			>
				Save
			</b-button>
		</b-col>

		<template #options>
			<b-button
				type="submit"
				size="sm"
				variant="primary"
				@click="isHidden = !isHidden"
			>
				Add Upsell
			</b-button>
		</template>
		<b-table-simple
			responsive
			table-class="table-vcenter"
			class="mt-3"
		>
			<b-thead head-variant="dark">
				<b-tr>
					<b-th style="font-size: 10px">
						Offering ID
					</b-th>
					<b-th style="font-size: 10px">
						Description
					</b-th>
					<b-th style="font-size: 10px">
						Optional?
					</b-th>
					<b-th style="font-size: 10px">
						Per item?
					</b-th>
					<b-th style="font-size: 10px">
						Auto Include?
					</b-th>
					<b-th style="font-size: 10px">
						Actions?
					</b-th>
				</b-tr>
			</b-thead>
			<b-tbody
				v-for="(up) in upsell"
				:key="up.id"
			>
				<tableData
					:up="up"
					@deleteUpsell="deleteUpsellData"
				/>
			</b-tbody>
		</b-table-simple>
	</base-block>
</template>
