import {
	Vue, Component, Prop, toNative,
} from 'vue-facing-decorator';
import Template from './template.vue';

@Component({
	mixins: [Template],
})
class PhotoDate extends Vue {
	@Prop({
		type: Number,
		required: true,
	})
	public readonly photodate!: number;

	public get unixPhotodate() {
		return new Date(this.photodate).toLocaleString('en-US');
	}
}

export default toNative(PhotoDate);
