import {
	Vue, Component, Watch, Prop, toNative,
} from 'vue-facing-decorator';
import * as DB from 'interfaces/database';
import CustomCheckbox from 'components/CustomCheckbox';
import { httpClient } from 'utils/http';
import TemplateState from '../TemplateState';
import Template from './template.vue';

@Component({
	components: {
		TemplateState,
		CustomCheckbox,
	},
	emits: ['routeTemplateEdit'],
	mixins: [Template],
})
class EditTemplateTable extends Vue {
	private singleTemplate = {} as Record<string, DB.TemplateModel | number | null>;

	private loaded = false;

	private visible = false;

	private spinner = false;

	private index = 0;

	private rowHeight = 60;

	private isLoading = false;

	private get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	@Prop({
		type: String,
		required: true,
	})
	public readonly templateId!: string;

	protected mounted(): void {
		this.getSingleTemplate();
	}

	protected isChecked(key: string): boolean {
		return this.singleTemplate[key] === 1;
	}

	protected onChangeCheckbox(event: number, key: string): void {
		this.singleTemplate[key] = event;
	}

	protected setPropertyNull(key: string): void {
		this.singleTemplate[key] = null;
	}

	protected checkPropertyNull(key: string): boolean {
		return this.singleTemplate[key] === null;
	}

	@Watch('loggedIn')
	private async getSingleTemplate(): Promise<Record<string, DB.TemplateModel | number | null> | undefined> {
		if (this.loggedIn) {
			// set the loading state
			this.isLoading = true;
			try {
				const resp = await httpClient.get<Record<string, DB.TemplateModel | number>>(`/api/template/${this.templateId}`);
				this.singleTemplate = resp.data;
				// eslint-disable-next-line consistent-return
				return this.singleTemplate;
			} catch (error: any) {
				this.$toastError(error.message);
			} finally {
				this.isLoading = false;
			}
		}
		return undefined;
	}

	protected routeTemplateEdit(
		data: {
			id: string,
			templateid: string
		},
	): void {
		this.$emit(
			'routeTemplateEdit',
			{
				id: data.id,
				templateid: data.templateid,
			},
		);
	}

	protected async editTemplate(): Promise<void> {
		if (this.loggedIn) {
			try {
				this.isLoading = true;
				await httpClient.put<DB.TemplateModel>(
					`/api/template/${this.templateId}`,
					{ ...this.singleTemplate },
				);
				this.$toastSuccess('Template Edited successfully');
			} catch (error: any) {
				this.$toastError(error.message);
			} finally {
				this.isLoading = false;
			}
		}
	}
}

export default toNative(EditTemplateTable);
