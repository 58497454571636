<template>
	<div class="">
		<!-- Hero -->
		<base-page-heading
			title="Templates"
			subtitle=""
			class="d-print-none"
		>
			<template #extra>
				<b-button
					ref="importShow"
					type="submit"
					size="md"
					variant="secondary"
					@click="showImport"
				>
					<i class="fa fa-fw fa-plus" /> 	Import
				</b-button>
				<b-button
					ref="templateShow"
					type="submit"
					size="md"
					variant="secondary"
					@click="showModal"
				>
					<i class="fa fa-fw fa-plus" /> 	Create new
				</b-button>
			</template>
		</base-page-heading>

		<div class="row">
			<TemplateTable
				ref="updateTemplate"
				@routeTemplate="routeTemplate"
				@openInNewTab="openInNewTab"
			/>
		</div>
	</div>
</template>
