import {
	Component,
	Prop,
	Vue,
	toNative,
} from 'vue-facing-decorator';
import Template from './template.vue';

@Component({
	emits: ['change', 'click'],
	mixins: [Template],
})
class CustomFileInput extends Vue {
	@Prop({ type: String, default: '' })
	public readonly name!: string;

	@Prop({ type: Boolean, default: false })
	public readonly disabled!: boolean;

	@Prop({ type: Boolean, default: false })
	public readonly required!: boolean;

	@Prop({ type: Boolean, default: null })
	public readonly state!: boolean | null;

	@Prop({ type: String, default: 'Choose file' })
	public readonly label!: string;

	@Prop({ type: String, default: 'Choose file' })
	public placeholder!: string;

	protected onChange(event: Event) {
		const file = (event.target as HTMLInputElement).files![0];
		this.$emit(
			'change',
			file,
		);
	}

	protected onClick(event: Event) {
		this.$emit(
			'click',
			event,
		);
	}
}

export default toNative(CustomFileInput);
