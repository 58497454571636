<template>
	<div
		style="width: 95%"
		class="ml-5"
	>
		<loading-overlay
			:active="isLoading"
			:can-cancel="true"
		/>
		<b-modal
			id="bv-modal"
			ref="productOrdered-modal"
			hide-footer
		>
			<template #modal-title>
				Order Item Details
			</template>
			<div
				v-for="item in printUrls"
				:key="item.id"
			>
				<b-alert show>
					<a
						:href="item"
						target="_blank"
					>{{ item }}</a>
				</b-alert>
			</div>
		</b-modal>
		<div
			ref="productOrderedTable"
		/>
	</div>
</template>
<style scoped>
 .showFilter{
	cursor: pointer;
	font-weight: 600;
 }
 .showFilter:hover{
	text-decoration: underline;
 }
</style>
