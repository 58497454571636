import { Vue, Component, toNative } from 'vue-facing-decorator';
import OrderItemTable from 'components/OrderItemTable';
import Template from './OrderItem.vue';

@Component({
	components: {
		OrderItemTable,
	},
	mixins: [Template],
})
class Orderitem extends Vue {
	private name = 'OrderItem';
}

export default toNative(Orderitem);
