import {
	Vue, Component, Ref, toNative,
} from 'vue-facing-decorator';
import EditRegionTable from 'components/RegionTable/EditRegionTable';
import Template from './template.vue';

@Component({
	components: {
		EditRegionTable,
	},
	mixins: [Template],
})
class EditRegion extends Vue {
	@Ref('editregion') readonly regionModal!: HTMLFormElement;

	private isLoading = false;

	protected updateRegionProperties(): void {
		this.regionModal.editRegion();
	}
}

export default toNative(EditRegion);
