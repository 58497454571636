import { Vue, Component, toNative } from 'vue-facing-decorator';
import FlexGroupTable from 'components/FlexGroupTable';
import { useRouter } from 'vue-router';
import Template from './template.vue';

@Component({
	components: {
		FlexGroupTable,
	},
	mixins: [Template],
})
class FlexGroup extends Vue {
	private router = useRouter();

	protected showModal(): void {
		this.$root?.$emit(
			'bv::show::modal',
			'flexGroup-modal',
			'#flexGroupShow',
		);
	}

	protected routeFlexGroup(id: string): void {
		this.router.push(`/flexgroup/${id}`);
	}
}

export default toNative(FlexGroup);
