import {
	Vue, Component, Watch, Ref, toNative,
} from 'vue-facing-decorator';
import PhotoDate from 'components/Tabulator/PhotoDate';
import * as DB from 'interfaces/database';
import { httpClient } from 'utils/http';
import { CellComponentExtended, ColumnDefinitionExtended, TabulatorFull as Tabulator } from 'tabulator-tables';
import { createInstance } from 'utils/vue';
import TabulatorImage from 'components/Tabulator/TabulatorImage';
import { useRoute } from 'vue-router';
import CustomSelect from 'components/CustomSelect';
import Template from './template.vue';

@Component({
	components: {
		PhotoDate,
		CustomSelect,
	},
	mixins: [Template],
})
class Photos extends Vue {
	@Ref('photosTable')
	private allTableReference!: HTMLDivElement;

	private photos: DB.PhotoModel[] | Record<string, DB.PhotoModel[]> = [];

	private photosources: DB.PhotoSourceModel[] = [];

	private isLoading = false;

	private visible = false;

	private index = 0;

	private table?: Tabulator;

	private photosourcesInput = '';

	private columnDefs: ColumnDefinitionExtended[] = [];

	private route = useRoute();

	private get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	protected beforeMount(): void {
		this.columnDefs = [
			{
				field: 'id',
				title: 'ID',
			},
			{
				title: 'Thumbnail',
				field: 'thumb_url',
				formatter: (cell: CellComponentExtended<DB.PhotoModel>) => {
					const data = cell.getData();
					const instance = createInstance({
						component: TabulatorImage,
						props: {
							size: 100,
							url: cell.getValue(),
							data,
						},
					});
					return instance;
				},
			},
			{
				title: 'Source',
				field: 'source',
			},
			{
				title: 'Date Added',
				field: 'timestamp',
			},
			{
				title: 'Date Captured',
				field: 'photodate',
				formatter: (cell: CellComponentExtended<DB.PhotoModel>) => {
					const instance = createInstance({
						component: PhotoDate,
						props: {
							photodate: cell.getValue(),
						},
					});
					return instance;
				},
			},
			{
				title: 'Full width',
				field: 'full_width',
			},
			{
				title: 'Full Height',
				field: 'full_height',
			},
		] as ColumnDefinitionExtended[];
	}

	protected mounted(): void {
		this.fetchData();
		this.tableInitialization();
		this.table?.on(
			'tableBuilt',
			this.onTableBuilt,
		);
	}

	protected beforeUnmount() {
		this.table?.off(
			'tableBuilt',
			this.onTableBuilt,
		);
		this?.table?.destroy();
	}

	private onTableBuilt(): void {
		this.getData();
	}

	private tableInitialization(): void {
		this.table = new Tabulator(
			this.allTableReference,
			{
				height: '60vh',
				layout: 'fitColumns',
				columns: this.columnDefs,
			},
		);
	}

	private async getData(): Promise<void> {
		const parameter = new URLSearchParams({
			limit: '0',
			orderby: 'id DESC',
			where: JSON.stringify({
				userid: parseInt(
					this.route.params.id as string,
					10,
				),
			}),
		});
		if (this.photosourcesInput) {
			parameter.append(
				'where',
				JSON.stringify({ source: this.photosourcesInput }),
			);
		}

		this.table?.alert('Loading');
		try {
			const { data } = await httpClient.get<DB.PhotoModel[]>(`/api/photo?${parameter}`);
			this.table?.setData(data);
		} catch (err: any) {
			this.$toastError(err.message);
		} finally {
			this.table?.clearAlert();
		}
	}

	@Watch('loggedIn')
	private async fetchData(): Promise<void> {
		if (this.loggedIn) {
			try {
				const photosource = await httpClient.get<DB.PhotoSourceModel[]>('/api/photosource?limit=0');
				this.photosources = photosource.data.filter((i) => i.id !== 'app');
			} catch (e: any) {
				this.$toastError(e.message);
			}
		}
	}
}

export default toNative(Photos);
