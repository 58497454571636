import { Vue, Component, toNative } from 'vue-facing-decorator';
import PdpTable from 'components/PdpTable';
import { useRouter } from 'vue-router';
import Template from './template.vue';

@Component({
	components: {
		PdpTable,
	},
	mixins: [Template],
})
class Pdp extends Vue {
	private router = useRouter();

	showModal(): void {
		this.$root?.$emit(
			'bv::show::modal',
			'pdp-modal',
			'#pdpShow',
		);
	}

	routePdp(id: string): void {
		this.router.push(`/pdp/${id}`);
	}
}

export default toNative(Pdp);
