import { Vue, Component, toNative } from 'vue-facing-decorator';
import ThemesTable from 'components/Themes/ThemesTable';
import { useRouter } from 'vue-router';
import Template from './template.vue';

@Component({
	components: {
		ThemesTable,
	},
	mixins: [Template],
})
class Themes extends Vue {
	private router = useRouter();

	protected showModal(): void {
		this.$root?.$emit(
			'bv::show::modal',
			'theme-modal',
			'#themeShow',
		);
	}

	protected showImport(): void {
		this.$root?.$emit(
			'bv::show::modal',
			'themeimport-modal',
			'#themeimportShow',
		);
	}

	protected routeTheme(id: string): void {
		this.router.push(`/theme/${id}`);
	}

	protected cloneTheme(id: string): void {
		this.router.push(`/theme/${id}`);
	}

	protected openNewTab(id: string): void {
		const route = this.router.resolve({ name: 'EditTheme', params: { id } });
		window.open(
			route.href,
			'_blank',
		);
	}
}

export default toNative(Themes);
