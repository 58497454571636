import {
	Vue, Component, Watch, Prop, Ref, toNative,
} from 'vue-facing-decorator';
import * as DB from 'interfaces/database';
import { PickerOptions, PickerResponse } from 'filestack-js';
import mitt from 'mitt';
import { TabEvent } from 'interfaces/app';
import { CellComponentExtended, ColumnDefinitionExtended, TabulatorFull as Tabulator } from 'tabulator-tables';
import { createInstance } from 'utils/vue';
import TabulatorSwitchBox from 'components/Tabulator/TabulatorSwitchBox';
import ProductCategoriesTable from 'components/ProductCategoriesTable/';
import ProductCategoryStore from 'store/offerings/productCategory';
import CustomSelect from 'components/CustomSelect';
import { getModule } from 'vuex-module-decorators';
import { BModal } from 'bootstrap-vue';
import { nextTick } from 'vue';
import { useStore } from 'vuex';
import CustomCheckbox from 'components/CustomCheckbox';
import CustomFileInput from 'components/CustomFileInput';
import ProductCategoryOfferingService from 'services/ProductCategoryOfferingService';
import ProductCategoryService from 'services/ProductCategoryService';
import PDPService from 'services/PDPService';
import LanguageService from 'services/LanguageService';
import OfferingService from 'services/OfferingService';
import Template from './template.vue';

const eventBus = mitt<TabEvent<DB.OfferingModel>>();
let ProductCategoryVuexStore: ReturnType<typeof getModule<ProductCategoryStore>>;
@Component({
	components: {
		ProductCategoriesTable,
		CustomSelect,
		CustomCheckbox,
		CustomFileInput,
	},
	mixins: [Template],
})
class EditProductCategoriesTable extends Vue {
	@Ref('item-modal') readonly itemModal!: BModal;

	@Ref('offeringTable')
	private readonly tableReference!: HTMLDivElement;

	private spinner = false;

	private visible = false;

	private selected = false;

	private index = 0;

	private subCategory: DB.ProductCategoryModel[] = [];

	private subcategoryDisplay = false;

	private offeringDisplay = false;

	private existingCategoryDisplay = false;

	private pdp: DB.PDPModel[] = [];

	private langid = '';

	private totalRows = 1;

	private currentPage = 1;

	private perPage = 10;

	private table?: Tabulator;

	private language : DB.LanguageModel[] = [];

	private productcategory: DB.ProductCategoryModel[] = [];

	private singleProductCategory = {} as DB.ProductCategoryModel;

	private offerings:	DB.OfferingModel[] = [];

	private checkProductCategory = {} as Record<string, DB.ProductCategoryOfferingModel>;

	private columnDefs: ColumnDefinitionExtended[] = [];

	private rowData: DB.OfferingModel[] = [];

	private placeHolders = {
		thumbnailPlaceholder: 'Choose a file',
	};

	private filterSubCatergory = null;

	private categorySearch = '';

	private itemName: DB.ProductCategoryModel['name'] | null = null;

	private isLoading = false;

	private pageOptions = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50];

	private store = useStore();

	@Prop({
		type: String,
		required: true,
	})
	public readonly routeId!: string;

	private get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	public beforeMount(): void {
		this.columnDefs = [
			{
				field: 'id',
				title: 'Offering ID',
				headerFilter: true,
			},
			{
				title: 'Title',
				field: 'name',
				visible: true,
				mutatorData: (val, data) => {
					let label = val;
					if (data.description) {
						label += ` | ${data.description}`;
					}
					if (data.variantname) {
						label += ` | ${data.variantname}`;
					}
					if (data.size) {
						label += ` | ${data.size}`;
					}
					return label;
				},
				headerFilter: true,
			},
			{
				title: 'Available In Product Category',
				formatter: (cell: CellComponentExtended<DB.OfferingModel>) => {
					const data = cell.getData();
					const instance = createInstance({
						component: TabulatorSwitchBox,
						props: {
							checked: Boolean(data && this.checkProductCategory[data.id]),
							data,
							eventBus,
						},
					});

					return instance;
				},
			},
		] as ColumnDefinitionExtended[];
	}

	public mounted(): void {
		ProductCategoryVuexStore = getModule(
			ProductCategoryStore,
			this.store,
		);
		eventBus.on(
			'switchBoxChanged',
			this.switchBoxChanged,
		);
		this.tableInitialization();
		this.fetchData();
		this.onGridReadyProductCategory();
		nextTick(() => {
			this.getProductCategoryOffering();
		});
	}

	private tableInitialization(): void {
		this.table = new Tabulator(
			this.tableReference,
			{
				height: '60vh',
				layout: 'fitColumns',
				columns: this.columnDefs,
			},
		);
	}

	private switchBoxChanged(data: TabEvent<DB.OfferingModel>['switchBoxChanged']): void {
		this.table?.alert('Loading');
		if (data.event) {
			ProductCategoryOfferingService.create(
				{
					productcategoryid: parseInt(
						this.routeId,
						10,
					),
					offeringid: data.params.id,
				},
			)
				.then((response) => {
					this.checkProductCategory[response.offeringid] = response;
					return undefined;
				})
				.finally(() => {
					this.table?.clearAlert();
				}).catch((err) => {
					this.$toastError(err.message);
				});
		} else {
			ProductCategoryOfferingService
				.delete(this.checkProductCategory[data.params.id].id)
				.then(() => {
					delete this.checkProductCategory[data.params.id];
					return undefined;
				})
				.finally(() => {
					this.table?.clearAlert();
				}).catch((err) => {
					this.$toastError(err.message);
				});
		}
	}

	public beforeUnmount() {
		eventBus.off(
			'switchBoxChanged',
			this.switchBoxChanged,
		);
		this.table?.destroy();
	}

	private onGridReadyProductCategory(): void {
		const parameter = new URLSearchParams({
			limit: '0',
		});

		this.table?.alert('Loading');
		OfferingService.getAll(parameter)
			.then((res) => {
				this.rowData = res;
				this.table?.setData(this.rowData);
				return undefined;
			})
			.finally(() => this.table?.clearAlert())
			.catch((error) => {
				this.$toastError(error.message);
			});
	}

	// eslint-disable-next-line class-methods-use-this
	private convertArrayToObject(array: (DB.ProductCategoryOfferingModel)[]): Record<string, DB.ProductCategoryOfferingModel> {
		return array.reduce(
			(obj, item) => ({
				...obj,
				[item.offeringid]: item,
			}),
			{},
		);
	}

	@Watch('loggedIn')
	private async getProductCategoryOffering(): Promise<void> {
		if (this.loggedIn) {
			const params = new URLSearchParams({
				where: JSON.stringify({ productcategoryid: this.routeId }),
				limit: '0',
			});

			try {
				const resp = await ProductCategoryOfferingService.getAll(params);
				this.checkProductCategory = this.convertArrayToObject(resp);
			} catch (error: any) {
				this.$toastError(error.message);
			}
		}
		return undefined;
	}

	protected editSingleProductCategory(): Promise<void> | undefined {
		if (this.loggedIn) {
			this.spinner = true;
			ProductCategoryService.update(
				this.routeId,
				{
					...this.singleProductCategory,
				},
			).then(() => {
				this.$toastSuccess('Product category Edited successfully');
				return undefined;
			})
				.finally(() => {
					this.spinner = false;
				})
				.catch((error) => {
					this.$toastError(error.message);
				});
		}
		return undefined;
	}

	@Watch('loggedIn')
	private fetchData(): void {
		if (this.loggedIn) {
			const params = new URLSearchParams({
				limit: '0',
			});
			// set the loading state
			this.spinner = true;
			Promise.all([
				ProductCategoryService.get(this.routeId),
				PDPService.getAll(params),
				LanguageService.getAll(params),
				ProductCategoryService.getAll(params),
			])
				.then(([productCategoryResponse, pdpResponse, languageResponse, ProductResponse]) => {
					this.singleProductCategory = productCategoryResponse;
					this.pdp = pdpResponse;
					this.language = languageResponse;
					this.productcategory = ProductResponse.map((i) => i);
					return null;
				}).finally(() => {
					this.spinner = false;
				})
				.catch((error) => {
					this.$toastError(error.message);
				});
		}
		return undefined;
	}

	//  update image preview
	protected async updateThumbnailPreview(): Promise<void> {
		const options: PickerOptions = {
			fromSources: ['local_file_system'],
			onUploadDone: async (files: PickerResponse) => {
				this.singleProductCategory.thumbnail = files.filesUploaded[0].url;
				this.placeHolders.thumbnailPlaceholder = files.filesUploaded[0].filename;
			},
		};
		this.$client.picker(options).open();
	}

	// remove the glb preview
	protected removeThumbnail(): void {
		this.singleProductCategory.thumbnail = '';
	}

	protected showItemModal(): void {
		this.itemModal.show();
	}

	protected async addSubItem(): Promise<void> {
		this.spinner = true;
		try {
			await ProductCategoryService.create(
				{ name: this.itemName as string, parentid: Number(this.routeId) },
			);
			this.itemName = '';

			await ProductCategoryVuexStore.getProductCategoriesAction(
				Number(this.routeId),
			);
			this.$toastSuccess('Product Category Added');
		} catch (err: any) {
			this.$toastError(err.message);
		} finally {
			this.spinner = false;
			this.itemModal.hide();
		}
	}
}

export default toNative(EditProductCategoriesTable);
