import {
	Vue, Component, Watch, Prop, Ref, toNative,
} from 'vue-facing-decorator';
import * as DB from 'interfaces/database';
import mitt from 'mitt';
import { TabEvent } from 'interfaces/app';
import { TabulatorFull as Tabulator, CellComponentExtended, ColumnDefinitionExtended } from 'tabulator-tables';
import TabulatorSwitchBox from 'components/Tabulator/TabulatorSwitchBox';
import { createInstance } from 'utils/vue';
import BadgeProductCategoryService from 'services/BadgeProductCategoryService';
import ProductCategoryService from 'services/ProductCategoryService';
import Template from './template.vue';

const eventBus = mitt<TabEvent<DB.ProductCategoryModel>>();
@Component({
	components: {
		TabulatorSwitchBox,
	},
	mixins: [Template],
})
class BadgeProductCategoryTable extends Vue {
	@Ref('badgeProductCategories')
	private readonly tableReference!: HTMLDivElement;

	private pageOptions = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50];

	private isLoading = false;

	private checkProductCategory: Record<string, DB.BadgeProductCategoryModel> = {};

	private columnDefs: ColumnDefinitionExtended[] = [];

	private table?: Tabulator;

	private perPage = 20;

	@Prop()
	private readonly routeId!: string;

	private get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	protected beforeMount(): void {
		this.columnDefs = [
			{ field: 'id', title: 'ID' },
			{
				title: 'Name',
				field: 'name',
				headerFilter: true,
			},
			{
				title: 'Available',
				field: 'url',
				titleFormatter: () => {
					const instance = createInstance({
						component: TabulatorSwitchBox,
						props: {
							checked: false,
							text: 'Available',
							eventName: 'headerSwitchBoxChanged',
							eventBus,
						},
					});

					return instance;
				},
				formatter: (cell: CellComponentExtended<DB.ProductCategoryModel>) => {
					const data = cell.getData();
					const instance = createInstance({
						component: TabulatorSwitchBox,
						props: {
							checked: Boolean(data && this.checkProductCategory[data.id]),
							data,
							eventBus,
						},
					});

					return instance;
				},
			},
		] as ColumnDefinitionExtended[];
	}

	protected mounted(): void {
		this.getBadgeProductCategory();
		eventBus.on(
			'switchBoxChanged',
			this.switchBoxChanged,
		);
		eventBus.on(
			'headerSwitchBoxChanged',
			this.headerSwitchBoxChanged,
		);
		this.tableInitialization();
		this.table?.on(
			'tableBuilt',
			this.onTableBuilt,
		);
	}

	private tableInitialization(): void {
		this.table = new Tabulator(
			this.tableReference,
			{
				height: '60vh',
				layout: 'fitColumns',
				columns: this.columnDefs,
			},
		);
	}

	private onTableBuilt(): void {
		this.getData();
	}

	private async getData(): Promise<void> {
		const parameter = new URLSearchParams({
			limit: '0',
		});
		this.table?.alert('Loading');
		try {
			await this.getBadgeProductCategory();
			const data = await ProductCategoryService.getAll(parameter);
			// set table data
			this.table?.setData(data);
			const allChecked = this.table?.getData().every((item) => this.checkProductCategory[item.id]);
			if (allChecked) {
				this.updateHeaderBox(true);
			} else {
				this.updateHeaderBox(false);
			}
		} catch (error: any) {
			this.$toastError(error.message);
		} finally {
			this.table?.clearAlert();
		}
	}

	private switchBoxChanged(data: TabEvent<DB.ProductCategoryModel>['switchBoxChanged']): void {
		this.table?.alert('Loading');
		if (data.event) {
			BadgeProductCategoryService
				.create(
					{
						productcategoryid: data.params.id,
						badgeid: parseInt(
							this.routeId,
							10,
						),
					},
				)
				.then((response) => {
					this.checkProductCategory[response.productcategoryid] = response;
					// this.table?.redraw();
					const allChecked = this.table?.getData().every((item) => this.checkProductCategory[item.id]);
					this.updateHeaderBox(allChecked as boolean);
					this.table?.scrollToRow(response.productcategoryid);
					return undefined;
				})
				.finally(() => {
					this.table?.clearAlert();
				}).catch((err) => {
					this.$toastError(err.message);
				});
		} else {
			BadgeProductCategoryService
				.delete(this.checkProductCategory[data.params.id].id)
				.then(() => {
					delete this.checkProductCategory[data.params.id];
					// this.table?.redraw();
					const allChecked = this.table?.getData().every((item) => this.checkProductCategory[item.id]);
					this.updateHeaderBox(allChecked as boolean);
					this.table?.scrollToRow(data.params.id);
					return undefined;
				})
				.finally(() => {
					this.table?.clearAlert();
				}).catch((err) => {
					this.$toastError(err.message);
				});
		}
	}

	protected beforeUnmount() {
		eventBus.off(
			'switchBoxChanged',
			this.switchBoxChanged,
		);
		eventBus.off(
			'headerSwitchBoxChanged',
			this.headerSwitchBoxChanged,
		);
		this.table?.off(
			'tableBuilt',
			this.onTableBuilt,
		);
		this.table?.destroy();
	}

	private headerSwitchBoxChanged(data: TabEvent<DB.ProductCategoryModel>['headerSwitchBoxChanged']): void {
		this.isLoading = true;
		const allProductCategoryId: number[] = [];

		// Get all the rows from the table
		const allRows = this.table?.getData() || [];

		// Get the current filters applied to the table
		const currentFilters = this.table?.getFilters(true);

		// Manually filter rows based on current filter criteria
		const filteredRows = allRows.filter((row) => currentFilters?.every((filter) => {
			const rowValue = String(row[filter.field]).toLowerCase();
			const filterValue = String(filter.value).toLowerCase();

			return rowValue.includes(filterValue);
		}));

		filteredRows?.map((node) => {
			if (node) {
				allProductCategoryId.push(node.id);
			}
			return undefined;
		});

		const productCategoryId = Object.values(this.checkProductCategory)
			.filter((item) => allProductCategoryId.includes(item.productcategoryid))
			.map((item) => item.id);

		const parameter = new URLSearchParams({
			where: JSON.stringify({
				id: productCategoryId,
			}),
		});

		// Create postData array, but only include offering IDs that are not checked
		const postData = allProductCategoryId
			.filter((productcategory) => !Object.keys(this.checkProductCategory).includes(productcategory.toString()))
			.map((productcategory) => ({
				productcategoryid: productcategory,
				badgeid: parseInt(
					this.routeId,
					10,
				),
			}));

		(data.event ? BadgeProductCategoryService.create(postData) : BadgeProductCategoryService.deleteWhere(parameter))
			.then((response) => {
				if (data.event) {
					const resp = response as DB.BadgeProductCategoryModel[];
					resp.forEach((item: DB.BadgeProductCategoryModel) => {
						this.checkProductCategory[item.productcategoryid] = item;
						this.table?.redraw(true);
					});
				} else {
					allProductCategoryId.forEach((id) => {
						delete this.checkProductCategory[id];
						this.table?.redraw(true);
					});
				}
				return undefined;
			})
			.finally(() => {
				this.isLoading = false;
			})
			.catch((err) => {
				this.$toastError(err.message);
			});
	}

	// This function updates the header formatter switchbox anytime the table renders or redrawn
	private updateHeaderBox(updateCellRenderer: boolean): void {
		const columnDef = this.table?.getColumnDefinitions().map((column) => {
			if (column.field === 'url') {
				return {
					...column,
					title: 'Available',
					field: 'url',
					titleFormatter: updateCellRenderer ? () => {
						const allChecked = this.table?.getData().every((item) => this.table?.getData().length !== 0 && this.checkProductCategory[item.id]);
						const instance = createInstance({
							component: TabulatorSwitchBox,
							props: {
								checked: Boolean(allChecked && this.table?.getData().length !== 0),
								text: 'Available',
								eventName: 'headerSwitchBoxChanged',
								eventBus,
							},
						});

						return instance;
					} : column.titleFormatter,
					formatter: (cell: CellComponentExtended<DB.PDPModel>) => {
						const data = cell.getData();
						const instance = createInstance({
							component: TabulatorSwitchBox,
							props: {
								checked: Boolean(data && this.checkProductCategory[data.id]),
								data,
								eventBus,
							},
						});

						return instance;
					},
				};
			}
			return column;
		}) as ColumnDefinitionExtended[];

		// update the column definitions
		this.table?.setColumns(columnDef);
	}

	@Watch('loggedIn')
	async getBadgeProductCategory(): Promise<void> {
		if (this.loggedIn) {
			const params = new URLSearchParams({
				where: JSON.stringify({ badgeid: this.routeId }),
				limit: '0',
			});

			try {
				const resp = await BadgeProductCategoryService.getAll(params);
				this.checkProductCategory = resp.reduce(
					(obj, item) => ({
						...obj,
						[item.productcategoryid]: item,
					}),
					{},
				);
			} catch (error: any) {
				this.$toastError(error.message);
			}
		}
		return undefined;
	}
}

export default toNative(BadgeProductCategoryTable);
