<template>
	<div
		style="width: 95%"
		class="ml-5"
	>
		<loading-overlay
			:active="isLoading"
			:can-cancel="true"
		/>

		<b-row>
			<b-col
				md="6"
				lg="6"
				sm="12"
			>
				<base-block
					title="Pickup store properties"
					header-bg
				>
					<div class="d-block">
						<b-form-group
							class="form-group"
							label="Name"
							label-for="block-form1-username"
							label-cols-lg="5"
						>
							<Field
								v-slot="{ errors }"
								v-model="singlePickupStore.name"
								rules="required"
								name="Name"
							>
								<div
									class="control"
								>
									<b-form-input
										v-model="singlePickupStore.name"
										type="text"
									/>
									<span>{{ errors[0] }}</span>
								</div>
							</Field>
						</b-form-group>

						<b-form-group
							class="form-group"
							label="Address 1"
							label-for="block-form1-username"
							label-cols-lg="5"
						>
							<b-form-input
								v-model="singlePickupStore.address1"
								type="text"
							/>
						</b-form-group>

						<b-form-group
							class="form-group"
							label="Address 2"
							label-for="block-form1-username"
							label-cols-lg="5"
						>
							<b-form-input
								v-model="singlePickupStore.address2"
								type="text"
							/>
						</b-form-group>

						<b-form-group
							class="form-group"
							label="Zipcode"
							label-for="block-form1-username"
							label-cols-lg="5"
						>
							<Field
								v-slot="{ errors }"
								v-model="singlePickupStore.zipcode"
								rules="integer"
								name="Zipcode"
							>
								<div
									class="control"
								>
									<b-form-input
										v-model="singlePickupStore.zipcode"
										type="text"
									/>
									<span>{{ errors[0] }}</span>
								</div>
							</Field>
						</b-form-group>
						<b-form-group
							class="form-group"
							label="City"
							label-for="block-form1-username"
							label-cols-lg="5"
						>
							<Field
								v-slot="{ errors }"
								v-model="singlePickupStore.city"
								rules="alpha"
								name="city"
							>
								<div
									class="control"
								>
									<b-form-input
										v-model="singlePickupStore.city"
										type="text"
									/>
									<span>{{ errors[0] }}</span>
								</div>
							</Field>
						</b-form-group>

						<b-form-group
							class="form-group"
							label="Country"
							label-for="block-form1-username"
							label-cols-lg="5"
						>
							<Field
								v-slot="{ errors }"
								v-model="singlePickupStore.country"
								rules="alpha"
								name="Country"
							>
								<div
									class="control"
								>
									<b-form-input
										v-model="singlePickupStore.country"
										type="text"
									/>
									<span>{{ errors[0] }}</span>
								</div>
							</Field>
						</b-form-group>

						<b-form-group
							class="form-group"
							label="State"
							label-for="block-form1-username"
							label-cols-lg="5"
						>
							<Field
								v-slot="{ errors }"
								v-model="singlePickupStore.state"
								rules="alpha"
								name="State"
							>
								<div
									class="control"
								>
									<b-form-input
										v-model="singlePickupStore.state"
										type="text"
									/>
									<span>{{ errors[0] }}</span>
								</div>
							</Field>
						</b-form-group>

						<b-form-group
							class="form-group"
							label="E-mail address"
							label-for="block-form1-username"
							label-cols-lg="5"
						>
							<Field
								v-slot="{ errors }"
								v-model="singlePickupStore.email"
								rules="email"
								name="Email address"
							>
								<div
									class="control"
								>
									<b-form-input
										v-model="singlePickupStore.email"
										type="text"
									/>
									<span>{{ errors[0] }}</span>
								</div>
							</Field>
						</b-form-group>

						<b-form-group
							class="form-group"
							label="Phone nr."
							label-for="block-form1-username"
							label-cols-lg="5"
						>
							<Field
								v-slot="{ errors }"
								v-model="singlePickupStore.phone"
								rules="integer"
								name="Phone Number"
							>
								<div
									class="control"
								>
									<b-form-input
										v-model="singlePickupStore.phone"
										type="text"
									/>
									<span>{{ errors[0] }}</span>
								</div>
							</Field>
						</b-form-group>

						<b-form-group
							label="Pay in store"
							label-class="font-w600"
						>
							<custom-checkbox
								id="checkbox-1"
								:is-switch="true"
								size="lg"
								:checked="singlePickupStore.payinstore === 1 ? true : false"
								@change="singlePickupStore.payinstore = $event"
							/>
						</b-form-group>
					</div>
				</base-block>
			</b-col>
		</b-row>
	</div>
</template>

<style lang="scss" scoped>
@import "../../../assets/scss/custom/errors";

.thumbnail{
	display:flex;
	justify-content:space-around;
	align-items:Center;
	background-color: #ccc;
	margin-top:10px;
}
.cameraCheckbox {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-left: 40px;
}
</style>
