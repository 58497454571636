import {
	Vue, Component, Ref, toNative,
} from 'vue-facing-decorator';
import EditTemplatePositionEdit from 'components/Themes/TemplateTable/TemplatePositionEdit';
import Template from './template.vue';

@Component({
	components: {
		EditTemplatePositionEdit,
	},
	mixins: [Template],
})
class EditTemplatePosition extends Vue {
	@Ref('editTemplatePositionEdit')
	private readonly template!: HTMLFormElement;

	protected updateTemplatePositionEdit(): void {
		this.template.editTemplate();
	}
}

export default toNative(EditTemplatePosition);
