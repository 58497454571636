import {
	Component,
	Model,
	Prop,
	Vue,
	Watch,
	toNative,
} from 'vue-facing-decorator';
import Template from './template.vue';

@Component({
	emits: ['change', 'input'],
	mixins: [Template],
})
class CustomCheckbox extends Vue {
	@Prop({
		type: String,
		default: '',
	})
	public id!: string;

	@Prop({
		type: String,
		required: true,
	})
	public readonly name!: string;

	@Prop({
		type: [String, Number, Boolean, Object, Symbol, Function],
		default: true,
	})
	// eslint-disable-next-line @typescript-eslint/ban-types
	public checkedValue!: string | number | boolean | object | symbol | Function;

	@Prop({
		type: [String, Number, Boolean, Object, Symbol, Function],
		default: false,
	})
	// eslint-disable-next-line @typescript-eslint/ban-types
	public uncheckedValue!: string | number | boolean | object | symbol | Function;

	@Prop({
		type: Boolean,
		default: undefined,
	})
	public checked!: boolean;

	@Prop({
		type: Boolean,
		default: false,
	})
	public disabled!: boolean;

	@Prop({
		type: Boolean,
		default: false,
	})
	public isSwitch!: boolean;

	@Prop({
		type: String,
		default: '',
	})
	public size!: string;

	@Model({
		name: 'value',
		type: [String, Number, Boolean, Object, Symbol, Function],
		default: undefined,
	})
	// eslint-disable-next-line @typescript-eslint/ban-types
	public readonly modelValue!: string | number | boolean | object | symbol | Function;

	// eslint-disable-next-line @typescript-eslint/ban-types
	private localChecked: string | number | boolean | object | symbol | Function = false;

	protected mounted(): void {
		if (this.checked !== undefined) {
			this.localChecked = this.checked ? this.checkedValue : this.uncheckedValue;
		}
		if (this.modelValue !== undefined && this.modelValue !== this.localChecked) {
			this.localChecked = this.modelValue;
		}
	}

	@Watch(
		'checked',
	)
	protected onCheckedChanged(newVal: boolean) {
		if (this.checked !== undefined) {
			this.localChecked = newVal ? this.checkedValue : this.uncheckedValue;
		}
	}

	@Watch(
		'modelValue',
	)
	modelValueChanged(): void {
		if (this.modelValue !== undefined && this.modelValue !== this.localChecked) {
			this.localChecked = this.modelValue;
		}
	}

	protected onChange() {
		this.$emit(
			'input',
			this.localChecked,
		);
		this.$emit(
			'change',
			this.localChecked,
		);
	}
}

export default toNative(CustomCheckbox);
