import {
	Vue, Component, Ref, toNative,
} from 'vue-facing-decorator';
import EditFlexGroupTable from 'components/FlexGroupTable/EditFlexGroupTable';
import Template from './template.vue';

@Component({
	components: {
		EditFlexGroupTable,
	},
	mixins: [Template],
})
class EditFlexGroup extends Vue {
	@Ref('editflexgroup') readonly flexGroupModal!: HTMLFormElement;

	private isLoading = false;

	protected updateFlexGroupProperties(): void {
		this.flexGroupModal.editFlexGroup();
	}
}

export default toNative(EditFlexGroup);
