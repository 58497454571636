/* eslint-disable implicit-arrow-linebreak */
import { Vue, Component, toNative } from 'vue-facing-decorator';
import Swal from 'sweetalert2';
import * as DB from 'interfaces/database';
import { httpClient } from 'utils/http';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import CustomSelect from 'components/CustomSelect';
import CustomCheckbox from 'components/CustomCheckbox';
import tableData from './tableData.vue';
import Template from './template.vue';

@Component({
	components: {
		tableData,
		CustomSelect,
		CustomCheckbox,
	},
	mixins: [Template],
})
class Upsell extends Vue {
	private isHidden = false;

	private upsell: DB.UpsellModel[] = [];

	private upsellData: DB.UpsellModel[] = [];

	private route = useRoute();

	private store = useStore();

	private data = {
		name: '',
		upsellid: null,
		description: '',
		externalid: '',
		optional: false,
		peritem: false,
		autoinclude: false,
	};

	private isLoading = false;

	private spinner = false;

	private editing = false;

	get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	get offerings(): Array<DB.OfferingModel> | void {
		if (this.store.state.offerings !== null) {
			return this.store.state.offerings;
		}
		return undefined;
	}

	mounted(): void {
		this.store.dispatch('getOfferings');
		this.getUpsell();
	}

	addUpsell(): void {
		if (this.loggedIn) {
			this.isLoading = true;
			httpClient.post<DB.UpsellModel>(
				'/api/upsell',
				{ ...this.data },
			)
				.then((response) => {
					this.upsell.push(response.data);
					return null;
				})
				.finally(() => {
					this.isLoading = false;
				})
				.catch((err) => {
					this.$toastError(err.message);
				});
		}
	}

	getUpsell(): void {
		if (this.loggedIn) {
			this.spinner = true;
			httpClient
				.get<DB.UpsellModel[]>(`/api/upsell?where={"offeringid":${this.route.params.id}}`)
				.then((response) => {
					this.upsell = response.data;
					return null;
				})
				.finally(() => {
					this.spinner = false;
				})
				.catch((err) => {
					this.$toastError(err.message);
				});
		}
	}

	deleteUpsellData(id: number): void {
		Swal.fire({
			title: 'Are you sure?',
			text: 'You will not be able to recover this imaginary file!',
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'btn btn-danger m-1',
				cancelButton: 'btn btn-secondary m-1',
			},
			confirmButtonText: 'Yes, delete it!',
			html: false,
		})
			.then((result) => {
				if (result.value) {
					// eslint-disable-next-line promise/no-nesting
					httpClient
						.delete(`/api/upsell/${id}`)
						.then(() => {
							// find the item by id
							const indexOfUserToRemove = this.upsell.findIndex(
								(u) => u.id === id,
							);
							// remove the item from the list of upsells
							this.upsell.splice(
								indexOfUserToRemove,
								1,
							);
							return null;
						})
						.catch((err) => {
							this.$toastError(err.message);
						});
				}
				return undefined;
			})
			.catch((err) => {
				this.$toastError(err.message);
			});
	}
}

export default toNative(Upsell);
