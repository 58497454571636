import {
	Vue, Component, Ref, toNative,
} from 'vue-facing-decorator';
import EditPageBgTable from 'components/Themes/PageBgTable/EditPageBgTable';
import Template from './template.vue';

@Component({
	components: {
		EditPageBgTable,
	},
	mixins: [Template],
})
class EditPageBg extends Vue {
	@Ref('editpagebg') private readonly editPagebg!: HTMLFormElement;

	protected updatePageBgProperties(): void {
		this.editPagebg.editPageBg();
	}
}

export default toNative(EditPageBg);
