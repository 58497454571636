<template>
	<div
		class="d-flex justify-content-cent align-items-center"
	>
		<country-flag
			v-if="data"
			:country="data.iso"
			size="small"
		/> {{ data.name }}
	</div>
</template>
