<template>
	<div
		class="ml-5 mt-4"
		style="width: 95%; height: 100%;"
	>
		<loading-overlay
			:active="isLoading"
			:can-cancel="true"
		/>
		<b-row>
			<b-col
				md="12"
				lg="12"
				sm="12"
			>
				<b-tabs
					card
					content-class="mt-3"
					fill
				>
					<b-tab
						title="Template properties"
						active
					>
						<base-block
							title="Template properties"
							header-bg
						>
							<div
								v-if="spinner"
								class="text-center"
							>
								<div
									class="spinner-grow text-primary m-5"
									role="status"
								>
									<span class="sr-only">Loading...</span>
								</div>
							</div>
							<div
								v-else
								class="d-block"
							>
								<b-form-group
									class="form-group"
									label="name"
									label-for="block-form1-name"
									label-cols-lg="4"
								>
									<b-input-group>
										<b-form-input
											v-model="singleTemplate.name"
											type="text"
										/>
									</b-input-group>
								</b-form-group>
								<b-form-group
									label-class="font-w600"
									label-cols-lg="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												Transformable
											</div>
											<div
												id="transformable"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="transformable"
										triggers="hover"
										placement="bottom"
									>
										Can new objects be freely dropped in this template
									</b-tooltip>
									<custom-checkbox
										id="checkbox-1"
										:is-switch="true"
										size="lg"
										:checked="isChecked('transformable')"
										@change="onChangeCheckbox($event, 'transformable')"
									/>
								</b-form-group>

								<b-form-group
									class="form-group"
									label="Height"
									label-for="block-form1-name"
									label-cols-lg="4"
								>
									<b-input-group>
										<b-form-input
											v-model="singleTemplate.height"
											type="text"
										/>
									</b-input-group>
								</b-form-group>

								<b-form-group
									class="form-group"
									label="Width"
									label-for="block-form1-name"
									label-cols-lg="4"
								>
									<b-input-group>
										<b-form-input
											v-model="singleTemplate.width"
											type="text"
										/>
									</b-input-group>
								</b-form-group>

								<b-form-group
									label="Dynamic"
									label-class="font-w600"
									label-cols-lg="4"
								>
									<custom-checkbox
										id="checkbox-2"
										:is-switch="true"
										size="lg"
										:checked="isChecked('dynamic')"
										@change="onChangeCheckbox($event, 'dynamic')"
									/>
								</b-form-group>

								<b-form-group
									label="Dynamic Page Margin"
									class="form-group"
									label-for="block-form1-name"
									label-cols-lg="4"
								>
									<b-input-group>
										<b-form-input
											v-model="singleTemplate.dynamicPageMargin"
											type="text"
										/>
										<template #append>
											<b-input-group-text
												class="input-group-text-alt"
												style="cursor:pointer"
											>
												<custom-checkbox
													id="checkbox-dynamicPageMargin"
													name="checkbox-1"
													:checked="checkPropertyNull('dynamicPageMargin')"
													unchecked-value="not_accepted"
													@change="setPropertyNull('dynamicPageMargin')"
												/>
												Null
											</b-input-group-text>
										</template>
									</b-input-group>
								</b-form-group>

								<b-form-group
									label="Dynamic Page Padding"
									class="form-group"
									label-for="block-form1-name"
									label-cols-lg="4"
								>
									<b-input-group>
										<b-form-input
											v-model="singleTemplate.dynamicPagePadding"
											type="text"
										/>
										<template #append>
											<b-input-group-text
												class="input-group-text-alt"
												style="cursor:pointer"
											>
												<custom-checkbox
													id="checkbox-dynamicPagePadding"
													name="checkbox-1"
													:checked="checkPropertyNull('dynamicPagePadding')"
													unchecked-value="not_accepted"
													@change="setPropertyNull('dynamicPagePadding')"
												/>
												Null
											</b-input-group-text>
										</template>
									</b-input-group>
								</b-form-group>

								<b-form-group
									label="Dynamic Photo Count"
									class="form-group"
									label-for="block-form1-name"
									label-cols-lg="4"
								>
									<b-input-group>
										<b-form-input
											v-model="singleTemplate.dynamicPhotoCount"
											type="text"
										/>
										<template #append>
											<b-input-group-text
												class="input-group-text-alt"
												style="cursor:pointer"
											>
												<custom-checkbox
													id="checkbox-dynamicPhotoCount"
													name="checkbox-1"
													:checked="checkPropertyNull('dynamicPhotoCount')"
													unchecked-value="not_accepted"
													@change="setPropertyNull('dynamicPhotoCount')"
												/>
												Null
											</b-input-group-text>
										</template>
									</b-input-group>
								</b-form-group>

								<b-form-group
									class="form-group"
									label="Bleed Margin"
									label-for="block-form1-name"
									label-cols-lg="4"
								>
									<b-input-group>
										<b-form-input
											v-model.number="singleTemplate.bleedmargin"
											type="text"
										/>

										<template #append>
											<b-input-group-text
												class="input-group-text-alt"
												style="cursor:pointer"
											>
												<custom-checkbox
													id="checkbox-bleedmargin"
													name="checkbox-1"
													:checked="checkPropertyNull('bleedmargin')"
													unchecked-value="not_accepted"
													@change="setPropertyNull('bleedmargin')"
												/>
												Null
											</b-input-group-text>
										</template>
									</b-input-group>
								</b-form-group>
							</div>
						</base-block>
					</b-tab>
					<b-tab
						title="Template State"
					>
						<TemplateState
							:template-id="templateId "
							@routeTemplateEdit="routeTemplateEdit"
						/>
					</b-tab>
				</b-tabs>
			</b-col>
		</b-row>
	</div>
</template>

<style lang="scss" scoped>
@import "../../../../assets/scss/oneui/tooltip";
.thumbnail{
	display:flex;
	justify-content:space-around;
	align-items:Center;
	background-color: #ccc;
	margin-top:10px;
}
.cameraCheckbox {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-left: 40px;
}
</style>
