<template>
	<div
		style="width: 95%"
		class="ml-5"
	>
		<loading-overlay
			:active="isLoading"
			:can-cancel="true"
		/>

		<b-row>
			<b-col
				md="6"
				lg="6"
				sm="12"
			>
				<base-block
					title="Cross Sell properties"
					header-bg
				>
					<div class="d-block text-center">
						<b-form-group
							class="form-group"
							label="Name: "
							label-for="block-form1-name"
							label-cols-lg="4"
						>
							<div
								class="control"
							>
								<b-form-input
									v-model="singleCrossSell.name"
									type="text"
								/>
							</div>
						</b-form-group>

						<b-form-group
							class="form-group"
							label="Preview Theme"
							label-for="block-form1-name"
							label-cols-lg="4"
						>
							<Field
								v-slot="{ errors }"
								v-model="singleCrossSell.previewTheme"
								rules="alpha_dash"
								name="Preview Theme"
							>
								<div
									class="control"
								>
									<b-form-input
										v-model="singleCrossSell.previewTheme"
										type="text"
									/>
									<span>{{ errors[0] }}</span>
								</div>
							</Field>
						</b-form-group>

						<b-form-group
							label="Cross Sell ID:"
							label-for="example-select"
							label-cols-lg="4"
						>
							<b-input-group>
								<custom-select
									id="example-select"
									v-model="singleCrossSell.crosssellid"
									plain
								>
									<b-form-select-option :value="null">
										Please select an Option
									</b-form-select-option>
									<b-form-select-option
										v-for="(off,id) in offerings"
										:key="id"
										:value="off.id"
									>
										{{ off.id }} | {{ off.size }} - {{ off.description }}
									</b-form-select-option>
								</custom-select>
								<template #append>
									<b-input-group-text
										class="input-group-text-alt"
										style="cursor:pointer"
									>
										<custom-checkbox
											id="checkbox-4"
											name="checkbox-2"
											:checked="singleCrossSell.crosssellid == null ? true : false"
											unchecked-value="not_accepted"
											@change="()=> singleCrossSell.crosssellid = null "
										/>
										Null
									</b-input-group-text>
								</template>
							</b-input-group>
						</b-form-group>

						<b-form-group
							label="Offering ID:"
							label-for="example-select1"
							label-cols-lg="4"
						>
							<b-input-group>
								<custom-select
									id="example-select8"
									v-model="singleCrossSell.offeringid"
									plain
								>
									<b-form-select-option :value="null">
										Please select an Option
									</b-form-select-option>
									<b-form-select-option
										v-for="(off,id) in offerings"
										:key="id"
										:value="off.id"
									>
										{{ off.id }} | {{ off.size }} - {{ off.description }}
									</b-form-select-option>
								</custom-select>
								<template #append>
									<b-input-group-text
										class="input-group-text-alt"
										style="cursor:pointer"
									>
										<custom-checkbox
											id="checkbox-5"
											name="checkbox-6"
											:checked="singleCrossSell.offeringid == null ? true : false"
											unchecked-value="not_accepted"
											@change="()=> singleCrossSell.offeringid = null "
										/>
										Null
									</b-input-group-text>
								</template>
							</b-input-group>
						</b-form-group>

						<b-form-group
							class="form-group"
							label="show offering options"
							label-for="block-form1-name"
							label-cols-lg="4"
						>
							<Field
								v-slot="{ errors }"
								v-model.number="singleCrossSell.showOfferingOption"
								rules="alpha_dash"
								name="offering options"
							>
								<div
									class="control"
								>
									<b-input-group>
										<b-form-input
											v-model.number="singleCrossSell.showOfferingOption"
											type="text"
										/>
										<template #append>
											<b-input-group-text
												class="input-group-text-alt"
												style="cursor:pointer"
											>
												<custom-checkbox
													id="checkbox-6"
													name="checkbox-7"
													:checked="singleCrossSell.showOfferingOption == null ? true : false"
													unchecked-value="not_accepted"
													@change="()=> singleCrossSell.showOfferingOption = null "
												/>
												Null
											</b-input-group-text>
										</template>
									</b-input-group>
									<span>{{ errors[0] }}</span>
								</div>
							</Field>
						</b-form-group>

						<b-form-group
							label="Theme ID:"
							label-for="example-select1"
							label-cols-lg="4"
						>
							<b-input-group>
								<custom-select
									id="example-select8"
									v-model="singleCrossSell.themeid"
									plain
								>
									<b-form-select-option :value="null">
										Please select an Option
									</b-form-select-option>
									<b-form-select-option
										v-for="(them,id) in theme"
										:key="id"
										:value="them.id"
									>
										{{ them.id }} | {{ them.name }} - {{ them.description }}
									</b-form-select-option>
								</custom-select>
								<template #append>
									<b-input-group-text
										class="input-group-text-alt"
										style="cursor:pointer"
									>
										<custom-checkbox
											id="checkbox-7"
											name="checkbox-7"
											:checked="singleCrossSell.themeid == null ? true : false"
											unchecked-value="not_accepted"
											@change="()=> singleCrossSell.themeid = null "
										/>
										Null
									</b-input-group-text>
								</template>
							</b-input-group>
						</b-form-group>

						<b-form-group
							class="form-group"
							label="Serial number"
							label-for="block-form1-name"
							label-cols-lg="4"
						>
							<Field
								v-slot="{ errors }"
								v-model.number="singleCrossSell.serialnumber"
								rules="integer"
								name="Serial number"
							>
								<div
									class="control"
								>
									<b-form-input
										v-model.number="singleCrossSell.serialnumber"
										type="text"
									/>
									<span>{{ errors[0] }}</span>
								</div>
							</Field>
						</b-form-group>

						<b-form-group
							class="form-group"
							label="Photo Quantity"
							label-for="block-form1-name"
							label-cols-lg="4"
						>
							<Field
								v-slot="{ errors }"
								v-model.number="singleCrossSell.photoQuantity"
								rules="integer"
								name="Photo Quantity"
							>
								<div
									class="control"
								>
									<b-form-input
										v-model.number="singleCrossSell.photoQuantity"
										type="text"
									/>
									<span>{{ errors[0] }}</span>
								</div>
							</Field>
						</b-form-group>

						<b-form-group
							label="Published"
							label-class="font-w600"
							label-cols-lg="3"
						>
							<custom-checkbox
								id="checkbox-8"
								class="pubCheckbox"
								:is-switch="true"
								size="lg"
								:checked="singleCrossSell.published === 1 ? true : false"
								@change="singleCrossSell.published = $event"
							/>
						</b-form-group>
					</div>
				</base-block>
			</b-col>
		</b-row>
	</div>
</template>

<style lang="scss" scoped>
.thumbnail{
	display:flex;
	justify-content:space-around;
	align-items:Center;
	background-color: #ccc;
	margin-top:10px;
}
.cameraCheckbox {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-left: 40px;
}
</style>
