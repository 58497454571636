import { LanguageModel } from 'interfaces/database';
import ApiService from 'services/ApiService';

class LanguageService extends ApiService<LanguageModel> {
	constructor() {
		super('language');
	}
}

export default new LanguageService();
