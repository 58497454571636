<template>
	<div
		class="ml-5 mt-4"
		style="width: 95%; height: 100%;"
	>
		<loading-overlay
			:active="isLoading"
			:can-cancel="true"
		/>
		<b-row>
			<b-col
				md="12"
				lg="12"
				sm="12"
			>
				<base-block
					title="Template position properties"
					header-bg
				>
					<div
						v-if="spinner"
						class="text-center"
					>
						<div
							class="spinner-grow text-primary m-5"
							role="status"
						>
							<span class="sr-only">Loading...</span>
						</div>
					</div>
					<div
						v-else
						class="d-block"
					>
						<template v-if="isPhotoRoute">
							<b-form-group
								class="form-group"
								label-for="block-form1-name"
								label-cols-lg="4"
							>
								<template #label>
									<div class="d-flex flex-row">
										<div class="mr-2">
											X-axis
										</div>
										<div
											id="x_axis"
											class="tip"
										/>
									</div>
								</template>
								<b-tooltip
									target="x_axis"
									triggers="hover"
									placement="bottom"
								>
									The x-axis coordinate of the position on the page
								</b-tooltip>
								<b-input-group>
									<b-form-input
										v-model="singleTemplatePosition.x_axis"
										type="text"
									/>
									<template #append>
										<b-input-group-text
											class="input-group-text-alt"
											style="cursor:pointer"
										>
											<custom-checkbox
												id="checkbox-x_axis"
												name="checkbox-1"
												:checked="singleTemplatePosition.x_axis == null ? true : false"
												unchecked-value="not_accepted"
												@change="()=> singleTemplatePosition.x_axis = null "
											/>
											Null
										</b-input-group-text>
									</template>
								</b-input-group>
							</b-form-group>
							<b-form-group
								class="form-group"
								label-for="block-form1-name"
								label-cols-lg="4"
							>
								<template #label>
									<div class="d-flex flex-row">
										<div class="mr-2">
											Y-axis
										</div>
										<div
											id="y_axis"
											class="tip"
										/>
									</div>
								</template>
								<b-tooltip
									target="y_axis"
									triggers="hover"
									placement="bottom"
								>
									The y-coordinate of the position on the page
								</b-tooltip>
								<b-input-group>
									<b-form-input
										v-model="singleTemplatePosition.y_axis"
										type="text"
									/>
									<template #append>
										<b-input-group-text
											class="input-group-text-alt"
											style="cursor:pointer"
										>
											<custom-checkbox
												id="checkbox-y_axis"
												name="checkbox-1"
												:checked="singleTemplatePosition.y_axis == null ? true : false"
												unchecked-value="not_accepted"
												@change="()=> singleTemplatePosition.y_axis = null "
											/>
											Null
										</b-input-group-text>
									</template>
								</b-input-group>
							</b-form-group>
							<b-form-group
								class="form-group"
								label-for="block-form1-name"
								label-cols-lg="4"
							>
								<template #label>
									<div class="d-flex flex-row">
										<div class="mr-2">
											Z-axis
										</div>
										<div
											id="z_axis"
											class="tip"
										/>
									</div>
								</template>
								<b-tooltip
									target="z_axis"
									triggers="hover"
									placement="bottom"
								>
									The z-coordinate of the position on the page
								</b-tooltip>
								<b-input-group>
									<b-form-input
										v-model="singleTemplatePosition.z_axis"
										type="text"
									/>
									<template #append>
										<b-input-group-text
											class="input-group-text-alt"
											style="cursor:pointer"
										>
											<custom-checkbox
												id="checkbox-z_axis"
												name="checkbox-1"
												:checked="singleTemplatePosition.z_axis == null ? true : false"
												unchecked-value="not_accepted"
												@change="()=> singleTemplatePosition.z_axis = null "
											/>
											Null
										</b-input-group-text>
									</template>
								</b-input-group>
							</b-form-group>
							<b-form-group
								class="form-group"
								label-for="block-form1-name"
								label-cols-lg="4"
							>
								<template #label>
									<div class="d-flex flex-row">
										<div class="mr-2">
											Width
										</div>
										<div
											id="width"
											class="tip"
										/>
									</div>
								</template>
								<b-tooltip
									target="width"
									triggers="hover"
									placement="bottom"
								>
									The width of the position in pixels
								</b-tooltip>
								<b-input-group>
									<b-form-input
										v-model="singleTemplatePosition.width"
										type="text"
									/>
									<template #append>
										<b-input-group-text
											class="input-group-text-alt"
											style="cursor:pointer"
										>
											<custom-checkbox
												id="checkbox-width"
												name="checkbox-1"
												:checked="singleTemplatePosition.width == null ? true : false"
												unchecked-value="not_accepted"
												@change="()=> singleTemplatePosition.width = null "
											/>
											Null
										</b-input-group-text>
									</template>
								</b-input-group>
							</b-form-group>
							<b-form-group
								class="form-group"
								label-for="block-form1-name"
								label-cols-lg="4"
							>
								<template #label>
									<div class="d-flex flex-row">
										<div class="mr-2">
											Height
										</div>
										<div
											id="height"
											class="tip"
										/>
									</div>
								</template>
								<b-tooltip
									target="height"
									triggers="hover"
									placement="bottom"
								>
									The height of the position in pixels
								</b-tooltip>
								<b-input-group>
									<b-form-input
										v-model="singleTemplatePosition.height"
										type="text"
									/>
									<template #append>
										<b-input-group-text
											class="input-group-text-alt"
											style="cursor:pointer"
										>
											<custom-checkbox
												id="checkbox-height"
												name="checkbox-1"
												:checked="singleTemplatePosition.height == null ? true : false"
												unchecked-value="not_accepted"
												@change="()=> singleTemplatePosition.height = null "
											/>
											Null
										</b-input-group-text>
									</template>
								</b-input-group>
							</b-form-group>
							<b-form-group
								class="form-group"
								label-for="block-form1-name"
								label-cols-lg="4"
							>
								<template #label>
									<div class="d-flex flex-row">
										<div class="mr-2">
											Rotation angle
										</div>
										<div
											id="rotation_angle"
											class="tip"
										/>
									</div>
								</template>
								<b-tooltip
									target="rotation_angle"
									triggers="hover"
									placement="bottom"
								>
									The angle of the position in degrees
								</b-tooltip>
								<b-input-group>
									<b-form-input
										v-model="singleTemplatePosition.angle"
										type="text"
									/>
									<template #append>
										<b-input-group-text
											class="input-group-text-alt"
											style="cursor:pointer"
										>
											<custom-checkbox
												id="checkbox-angle"
												name="checkbox-1"
												:checked="singleTemplatePosition.angle == null ? true : false"
												unchecked-value="not_accepted"
												@change="()=> singleTemplatePosition.angle = null "
											/>
											Null
										</b-input-group-text>
									</template>
								</b-input-group>
							</b-form-group>
							<b-form-group
								class="form-group"
								label="Border Image"
								label-for="block-form1-name"
								label-cols-lg="4"
							>
								<b-input-group>
									<custom-select
										id="block-form-layoutid"
										v-model="singleTemplatePosition.borderimageid"
									>
										<b-form-select-option :value="null">
											Please select an option
										</b-form-select-option>
										<b-form-select-option
											v-for="(bd,id) in borderImage"
											:key="id"
											:value="bd.id"
										>
											{{ bd.name }}
										</b-form-select-option>
									</custom-select>
								</b-input-group>
							</b-form-group>
							<b-form-group
								class="form-group"
								label-for="block-form1-name"
								label-cols-lg="4"
							>
								<template #label>
									<div class="d-flex flex-row">
										<div class="mr-2">
											Border width
										</div>
										<div
											id="border_width"
											class="tip"
										/>
									</div>
								</template>
								<b-tooltip
									target="border_width"
									triggers="hover"
									placement="bottom"
								>
									The width of the border around the object in pixels
								</b-tooltip>
								<b-input-group>
									<b-form-input
										v-model="singleTemplatePosition.borderwidth"
										type="text"
									/>
									<template #append>
										<b-input-group-text
											class="input-group-text-alt"
											style="cursor:pointer"
										>
											<custom-checkbox
												id="checkbox-borderwidth"
												name="checkbox-1"
												:checked="singleTemplatePosition.borderwidth == null ? true : false"
												unchecked-value="not_accepted"
												@change="()=> singleTemplatePosition.borderwidth = null "
											/>
											Null
										</b-input-group-text>
									</template>
								</b-input-group>
							</b-form-group>
							<b-form-group
								class="form-group"
								label-for="block-form1-name"
								label-cols-lg="4"
							>
								<template #label>
									<div class="d-flex flex-row">
										<div class="mr-2">
											Border Color
										</div>
										<div
											id="border_color"
											class="tip"
										/>
									</div>
								</template>
								<b-tooltip
									target="border_color"
									triggers="hover"
									placement="bottom"
								>
									The hex color of the border around the object
								</b-tooltip>
								<b-input-group>
									<b-form-input
										v-model="singleTemplatePosition.bordercolor"
										type="text"
									/>
									<template #append>
										<b-input-group-text
											class="input-group-text-alt"
											style="cursor:pointer"
										>
											<custom-checkbox
												id="checkbox-bordercolor"
												name="checkbox-1"
												:checked="singleTemplatePosition.bordercolor == null ? true : false"
												unchecked-value="not_accepted"
												@change="()=> singleTemplatePosition.bordercolor = null "
											/>
											Null
										</b-input-group-text>
									</template>
								</b-input-group>
							</b-form-group>
							<b-form-group
								class="form-group"
								label-for="block-form1-name"
								label-cols-lg="4"
							>
								<template #label>
									<div class="d-flex flex-row">
										<div class="mr-2">
											Overlap Top
										</div>
										<div
											id="overlap_top"
											class="tip"
										/>
									</div>
								</template>
								<b-tooltip
									target="overlap_top"
									triggers="hover"
									placement="bottom"
								>
									For how many the top of the position overlap with other objects on the page
								</b-tooltip>
								<b-input-group>
									<b-form-input
										v-model="singleTemplatePosition.bordercolor"
										type="text"
									/>
									<template #append>
										<b-input-group-text
											class="input-group-text-alt"
											style="cursor:pointer"
										>
											<custom-checkbox
												id="checkbox-bordercolor2"
												name="checkbox-1"
												:checked="singleTemplatePosition.bordercolor == null ? true : false"
												unchecked-value="not_accepted"
												@change="()=> singleTemplatePosition.bordercolor = null "
											/>
											Null
										</b-input-group-text>
									</template>
								</b-input-group>
							</b-form-group>
							<b-form-group
								label-class="font-w600"
								label-cols-lg="4"
							>
								<template #label>
									<div class="d-flex flex-row">
										<div class="mr-2">
											Automatic rotation
										</div>
										<div
											id="automatic_rotation"
											class="tip"
										/>
									</div>
								</template>
								<b-tooltip
									target="automatic_rotation"
									triggers="hover"
									placement="bottom"
								>
									Should portrait/landscape photos automatically be rotated to best fit the dimensions of the position
								</b-tooltip>
								<custom-checkbox
									id="checkbox-1"
									:is-switch="true"
									size="lg"
									:checked="singleTemplatePosition.autorotate === 1 ? true : false"
									@change="singleTemplatePosition.autorotate = $event"
								/>
							</b-form-group>
							<b-form-group
								class="form-group"
								label-for="block-form1-name"
								label-cols-lg="4"
							>
								<template #label>
									<div class="d-flex flex-row">
										<div class="mr-2">
											Overlap top
										</div>
										<div
											id="overlap_top"
											class="tip"
										/>
									</div>
								</template>
								<b-tooltip
									target="overlap_top"
									triggers="hover"
									placement="bottom"
								>
									for how many pixels may the top of the position overlap with other objects on the page?
								</b-tooltip>
								<b-input-group>
									<b-form-input
										v-model="singleTemplatePosition.overlap_top"
										type="text"
									/>
									<template #append>
										<b-input-group-text
											class="input-group-text-alt"
											style="cursor:pointer"
										>
											<custom-checkbox
												id="checkbox-overlap_top"
												name="checkbox-1"
												:checked="singleTemplatePosition.overlap_top == null ? true : false"
												unchecked-value="not_accepted"
												@change="()=> singleTemplatePosition.overlap_top = null "
											/>
											Null
										</b-input-group-text>
									</template>
								</b-input-group>
							</b-form-group>
							<b-form-group
								class="form-group"
								label-for="block-form1-name"
								label-cols-lg="4"
							>
								<template #label>
									<div class="d-flex flex-row">
										<div class="mr-2">
											Overlap right
										</div>
										<div
											id="overlap_right"
											class="tip"
										/>
									</div>
								</template>
								<b-tooltip
									target="overlap_right"
									triggers="hover"
									placement="bottom"
								>
									for how many pixels may the right of the position overlap with other objects on the page?
								</b-tooltip>
								<b-input-group>
									<b-form-input
										v-model="singleTemplatePosition.overlap_right"
										type="text"
									/>
									<template #append>
										<b-input-group-text
											class="input-group-text-alt"
											style="cursor:pointer"
										>
											<custom-checkbox
												id="checkbox-overlap_right"
												name="checkbox-1"
												:checked="singleTemplatePosition.overlap_right == null ? true : false"
												unchecked-value="not_accepted"
												@change="()=> singleTemplatePosition.overlap_right = null "
											/>
											Null
										</b-input-group-text>
									</template>
								</b-input-group>
							</b-form-group>
							<b-form-group
								class="form-group"
								label-for="block-form1-name"
								label-cols-lg="4"
							>
								<template #label>
									<div class="d-flex flex-row">
										<div class="mr-2">
											Overlap bottom
										</div>
										<div
											id="overlap_bottom"
											class="tip"
										/>
									</div>
								</template>
								<b-tooltip
									target="overlap_bottom"
									triggers="hover"
									placement="bottom"
								>
									for how many pixels may the bottom of the position overlap with other objects on the page?
								</b-tooltip>
								<b-input-group>
									<b-form-input
										v-model="singleTemplatePosition.overlap_bottom"
										type="text"
									/>
									<template #append>
										<b-input-group-text
											class="input-group-text-alt"
											style="cursor:pointer"
										>
											<custom-checkbox
												id="checkbox-overlap_bottom"
												name="checkbox-1"
												:checked="singleTemplatePosition.overlap_bottom == null ? true : false"
												unchecked-value="not_accepted"
												@change="()=> singleTemplatePosition.overlap_bottom = null "
											/>
											Null
										</b-input-group-text>
									</template>
								</b-input-group>
							</b-form-group>
							<b-form-group
								class="form-group"
								label-for="block-form1-name"
								label-cols-lg="4"
							>
								<template #label>
									<div class="d-flex flex-row">
										<div class="mr-2">
											Overlap left
										</div>
										<div
											id="overlap_left"
											class="tip"
										/>
									</div>
								</template>
								<b-tooltip
									target="overlap_left"
									triggers="hover"
									placement="bottom"
								>
									for how many pixels may the left of the position overlap with other objects on the page?
								</b-tooltip>
								<b-input-group>
									<b-form-input
										v-model="singleTemplatePosition.overlap_left"
										type="text"
									/>
									<template #append>
										<b-input-group-text
											class="input-group-text-alt"
											style="cursor:pointer"
										>
											<custom-checkbox
												id="checkbox-overlap_left"
												name="checkbox-1"
												:checked="singleTemplatePosition.overlap_left == null ? true : false"
												unchecked-value="not_accepted"
												@change="()=> singleTemplatePosition.overlap_left = null "
											/>
											Null
										</b-input-group-text>
									</template>
								</b-input-group>
							</b-form-group>
							<b-form-group
								class="form-group"
								label-for="block-form1-name"
								label-cols-lg="4"
							>
								<template #label>
									<div class="d-flex flex-row">
										<div class="mr-2">
											Matrix
										</div>
										<div
											id="matrix"
											class="tip"
										/>
									</div>
								</template>
								<b-tooltip
									target="matrix"
									triggers="hover"
									placement="bottom"
								>
									create a matrix with x photos? Or use value 1 to create a matrix with all uploaded photos
								</b-tooltip>
								<b-input-group>
									<b-form-input
										v-model="singleTemplatePosition.matrix"
										type="text"
									/>
								</b-input-group>
							</b-form-group>
							<b-form-group
								label-class="font-w600"
								label-cols-lg="4"
							>
								<template #label>
									<div class="d-flex flex-row">
										<div class="mr-2">
											Transformable
										</div>
										<div
											id="transformable"
											class="tip"
										/>
									</div>
								</template>
								<b-tooltip
									target="transformable"
									triggers="hover"
									placement="bottom"
								>
									Is the object that fills this position draggable, resizable and rotatable by the user?
								</b-tooltip>
								<custom-checkbox
									id="checkbox-2"
									:is-switch="true"
									size="lg"
									:checked="singleTemplatePosition.transformable === 1 ? true : false"
									@change="singleTemplatePosition.transformable = $event"
								/>
							</b-form-group>
							<b-form-group
								label-class="font-w600"
								label-cols-lg="4"
							>
								<template #label>
									<div class="d-flex flex-row">
										<div class="mr-2">
											Flag
										</div>
										<div
											id="flag"
											class="tip"
										/>
									</div>
								</template>
								<b-tooltip
									target="flag"
									triggers="hover"
									placement="bottom"
								>
									When photo fills this position, flag it to avoid photo being used in other positions when auto filling?
								</b-tooltip>
								<custom-checkbox
									id="checkbox-3"
									:is-switch="true"
									size="lg"
									:checked="singleTemplatePosition.flag === 1 ? true : false"
									@change="singleTemplatePosition.flag = $event"
								/>
							</b-form-group>
							<b-form-group
								class="form-group"
								label="Mask"
								label-for="block-form1-name"
								label-cols-lg="4"
							>
								<b-input-group>
									<custom-select
										id="block-form-layoutid"
										v-model="singleTemplatePosition.mask"
									>
										<b-form-select-option :value="null">
											Please select an option
										</b-form-select-option>
										<b-form-select-option
											v-for="(ms,id) in mask"
											:key="id"
											:value="ms"
										>
											{{ ms }}
										</b-form-select-option>
									</custom-select>
								</b-input-group>
							</b-form-group>
							<b-form-group
								label="fill Method"
								label-for="example-select"
								label-cols-lg="5"
							>
								<custom-select
									id="example-select"
									v-model="singleTemplatePosition.fillMethod"
									plain
								>
									<b-form-select-option
										v-for="(fill,index) in fillMethod"
										id="temp-data"
										:key="index"
										:value="fill"
									>
										{{ fill }}
									</b-form-select-option>
								</custom-select>
							</b-form-group>
						</template>
						<template
							v-else
						>
							<b-form-group
								class="form-group"
								label-for="block-form1-name"
								label-cols-lg="4"
							>
								<template #label>
									<div class="d-flex flex-row">
										<div class="mr-2">
											X-axis
										</div>
										<div
											id="x_axis"
											class="tip"
										/>
									</div>
								</template>
								<b-tooltip
									target="x_axis"
									triggers="hover"
									placement="bottom"
								>
									The x-axis coordinate of the position on the page
								</b-tooltip>
								<b-input-group>
									<b-form-input
										v-model="singleTemplatePosition.x_axis"
										type="text"
									/>
									<template #append>
										<b-input-group-text
											class="input-group-text-alt"
											style="cursor:pointer"
										>
											<custom-checkbox
												id="checkbox-x_axis"
												name="checkbox-1"
												:checked="singleTemplatePosition.x_axis == null ? true : false"
												unchecked-value="not_accepted"
												@change="()=> singleTemplatePosition.x_axis = null "
											/>
											Null
										</b-input-group-text>
									</template>
								</b-input-group>
							</b-form-group>
							<b-form-group
								class="form-group"
								label-for="block-form1-name"
								label-cols-lg="4"
							>
								<template #label>
									<div class="d-flex flex-row">
										<div class="mr-2">
											Y-axis
										</div>
										<div
											id="y_axis"
											class="tip"
										/>
									</div>
								</template>
								<b-tooltip
									target="y_axis"
									triggers="hover"
									placement="bottom"
								>
									The y-coordinate of the position on the page
								</b-tooltip>
								<b-input-group>
									<b-form-input
										v-model="singleTemplatePosition.y_axis"
										type="text"
									/>
									<template #append>
										<b-input-group-text
											class="input-group-text-alt"
											style="cursor:pointer"
										>
											<custom-checkbox
												id="checkbox-y_axis"
												name="checkbox-1"
												:checked="singleTemplatePosition.y_axis == null ? true : false"
												unchecked-value="not_accepted"
												@change="()=> singleTemplatePosition.y_axis = null "
											/>
											Null
										</b-input-group-text>
									</template>
								</b-input-group>
							</b-form-group>
							<b-form-group
								class="form-group"
								label-for="block-form1-name"
								label-cols-lg="4"
							>
								<template #label>
									<div class="d-flex flex-row">
										<div class="mr-2">
											Z-axis
										</div>
										<div
											id="z_axis"
											class="tip"
										/>
									</div>
								</template>
								<b-tooltip
									target="z_axis"
									triggers="hover"
									placement="bottom"
								>
									The z-coordinate of the position on the page
								</b-tooltip>
								<b-input-group>
									<b-form-input
										v-model="singleTemplatePosition.z_axis"
										type="text"
									/>
									<template #append>
										<b-input-group-text
											class="input-group-text-alt"
											style="cursor:pointer"
										>
											<custom-checkbox
												id="checkbox-z_axis"
												name="checkbox-1"
												:checked="singleTemplatePosition.z_axis == null ? true : false"
												unchecked-value="not_accepted"
												@change="()=> singleTemplatePosition.z_axis = null "
											/>
											Null
										</b-input-group-text>
									</template>
								</b-input-group>
							</b-form-group>
							<b-form-group
								class="form-group"
								label-for="block-form1-name"
								label-cols-lg="4"
							>
								<template #label>
									<div class="d-flex flex-row">
										<div class="mr-2">
											Width
										</div>
										<div
											id="width"
											class="tip"
										/>
									</div>
								</template>
								<b-tooltip
									target="width"
									triggers="hover"
									placement="bottom"
								>
									The width of the position in pixels
								</b-tooltip>
								<b-input-group>
									<b-form-input
										v-model="singleTemplatePosition.width"
										type="text"
									/>
									<template #append>
										<b-input-group-text
											class="input-group-text-alt"
											style="cursor:pointer"
										>
											<custom-checkbox
												id="checkbox-width"
												name="checkbox-1"
												:checked="singleTemplatePosition.width == null ? true : false"
												unchecked-value="not_accepted"
												@change="()=> singleTemplatePosition.width = null "
											/>
											Null
										</b-input-group-text>
									</template>
								</b-input-group>
							</b-form-group>
							<b-form-group
								class="form-group"
								label-for="block-form1-name"
								label-cols-lg="4"
							>
								<template #label>
									<div class="d-flex flex-row">
										<div class="mr-2">
											Height
										</div>
										<div
											id="height"
											class="tip"
										/>
									</div>
								</template>
								<b-tooltip
									target="height"
									triggers="hover"
									placement="bottom"
								>
									The height of the position in pixels
								</b-tooltip>
								<b-input-group>
									<b-form-input
										v-model="singleTemplatePosition.height"
										type="text"
									/>
									<template #append>
										<b-input-group-text
											class="input-group-text-alt"
											style="cursor:pointer"
										>
											<custom-checkbox
												id="checkbox-height"
												name="checkbox-1"
												:checked="singleTemplatePosition.height == null ? true : false"
												unchecked-value="not_accepted"
												@change="()=> singleTemplatePosition.height = null "
											/>
											Null
										</b-input-group-text>
									</template>
								</b-input-group>
							</b-form-group>
							<b-form-group
								class="form-group"
								label-for="block-form1-name"
								label-cols-lg="4"
							>
								<template #label>
									<div class="d-flex flex-row">
										<div class="mr-2">
											Rotation angle
										</div>
										<div
											id="rotation_angle"
											class="tip"
										/>
									</div>
								</template>
								<b-tooltip
									target="rotation_angle"
									triggers="hover"
									placement="bottom"
								>
									The angle of the position in degrees
								</b-tooltip>
								<b-input-group>
									<b-form-input
										v-model="singleTemplatePosition.angle"
										type="text"
									/>
									<template #append>
										<b-input-group-text
											class="input-group-text-alt"
											style="cursor:pointer"
										>
											<custom-checkbox
												id="checkbox-angle"
												name="checkbox-1"
												:checked="singleTemplatePosition.angle == null ? true : false"
												unchecked-value="not_accepted"
												@change="()=> singleTemplatePosition.angle = null "
											/>
											Null
										</b-input-group-text>
									</template>
								</b-input-group>
							</b-form-group>
							<b-form-group
								class="form-group"
								label-for="block-form1-name"
								label-cols-lg="4"
							>
								<template #label>
									<div class="d-flex flex-row">
										<div class="mr-2">
											Border width
										</div>
										<div
											id="border_width"
											class="tip"
										/>
									</div>
								</template>
								<b-tooltip
									target="border_width"
									triggers="hover"
									placement="bottom"
								>
									The width of the border around the object in pixels
								</b-tooltip>
								<b-input-group>
									<b-form-input
										v-model="singleTemplatePosition.borderwidth"
										type="text"
									/>
									<template #append>
										<b-input-group-text
											class="input-group-text-alt"
											style="cursor:pointer"
										>
											<custom-checkbox
												id="checkbox-borderwidth"
												name="checkbox-1"
												:checked="singleTemplatePosition.borderwidth == null ? true : false"
												unchecked-value="not_accepted"
												@change="()=> singleTemplatePosition.borderwidth = null "
											/>
											Null
										</b-input-group-text>
									</template>
								</b-input-group>
							</b-form-group>
							<b-form-group
								class="form-group"
								label-for="block-form1-name"
								label-cols-lg="4"
							>
								<template #label>
									<div class="d-flex flex-row">
										<div class="mr-2">
											Border Color
										</div>
										<div
											id="border_color"
											class="tip"
										/>
									</div>
								</template>
								<b-tooltip
									target="border_color"
									triggers="hover"
									placement="bottom"
								>
									The hex color of the border around the object
								</b-tooltip>
								<b-input-group>
									<b-form-input
										v-model="singleTemplatePosition.bordercolor"
										type="text"
									/>
									<template #append>
										<b-input-group-text
											class="input-group-text-alt"
											style="cursor:pointer"
										>
											<custom-checkbox
												id="checkbox-bordercolor"
												name="checkbox-1"
												:checked="singleTemplatePosition.bordercolor == null ? true : false"
												unchecked-value="not_accepted"
												@change="()=> singleTemplatePosition.bordercolor = null "
											/>
											Null
										</b-input-group-text>
									</template>
								</b-input-group>
							</b-form-group>
							<b-form-group
								class="form-group"
								label="Font"
								label-for="block-form1-name"
								label-cols-lg="4"
							>
								<b-input-group>
									<custom-select
										id="block-form-layoutid"
										v-model="singleTemplatePosition.fontface"
									>
										<b-form-select-option :value="null">
											Please select an option
										</b-form-select-option>
										<b-form-select-option
											v-for="(ft,id) in font"
											:key="id"
											:value="ft.id"
										>
											{{ ft.id }}
										</b-form-select-option>
									</custom-select>
								</b-input-group>
							</b-form-group>
							<b-form-group
								class="form-group"
								label-for="block-form1-name"
								label-cols-lg="4"
							>
								<template #label>
									<div class="d-flex flex-row">
										e
										<div class="mr-2">
											Font size
										</div>
										<div
											id="fontsize"
											class="tip"
										/>
									</div>
								</template>
								<b-tooltip
									target="fontsize"
									triggers="hover"
									placement="bottom"
								>
									The size of the font in points
								</b-tooltip>
								<b-input-group>
									<b-form-input
										v-model="singleTemplatePosition.pointsize"
										type="text"
									/>
									<template #append>
										<b-input-group-text
											class="input-group-text-alt"
											style="cursor:pointer"
										>
											<custom-checkbox
												id="checkbox-font-size"
												name="checkbox-1"
												:checked="singleTemplatePosition.pointsize == null ? true : false"
												unchecked-value="not_accepted"
												@change="()=> singleTemplatePosition.pointsize = null "
											/>
											Null
										</b-input-group-text>
									</template>
								</b-input-group>
							</b-form-group>
							<b-form-group
								label-class="font-w600"
								label-cols-lg="4"
							>
								<template #label>
									<div class="d-flex flex-row">
										<div class="mr-2">
											Snap
										</div>
										<div
											id="snap"
											class="tip"
										/>
									</div>
								</template>
								<b-tooltip
									target="snap"
									triggers="hover"
									placement="bottom"
								>
									The hex color of the text
								</b-tooltip>
								<custom-checkbox
									id="checkbox-4"
									:is-switch="true"
									size="lg"
									:checked="singleTemplatePosition.snap === 1 ? true : false"
									@change="singleTemplatePosition.snap = $event"
								/>
							</b-form-group>
							<b-form-group
								class="form-group"
								label-for="block-form1-name"
								label-cols-lg="4"
							>
								<template #label>
									<div class="d-flex flex-row">
										<div class="mr-2">
											Overlap top
										</div>
										<div
											id="overlap_top"
											class="tip"
										/>
									</div>
								</template>
								<b-tooltip
									target="overlap_top"
									triggers="hover"
									placement="bottom"
								>
									for how many pixels may the top of the position overlap with other objects on the page?
								</b-tooltip>
								<b-input-group>
									<b-form-input
										v-model="singleTemplatePosition.overlap_top"
										type="text"
									/>
									<template #append>
										<b-input-group-text
											class="input-group-text-alt"
											style="cursor:pointer"
										>
											<custom-checkbox
												id="checkbox-overlap_top"
												name="checkbox-1"
												:checked="singleTemplatePosition.overlap_top == null ? true : false"
												unchecked-value="not_accepted"
												@change="()=> singleTemplatePosition.overlap_top = null "
											/>
											Null
										</b-input-group-text>
									</template>
								</b-input-group>
							</b-form-group>
							<b-form-group
								class="form-group"
								label-for="block-form1-name"
								label-cols-lg="4"
							>
								<template #label>
									<div class="d-flex flex-row">
										<div class="mr-2">
											Overlap right
										</div>
										<div
											id="overlap_right"
											class="tip"
										/>
									</div>
								</template>
								<b-tooltip
									target="overlap_right"
									triggers="hover"
									placement="bottom"
								>
									for how many pixels may the right of the position overlap with other objects on the page?
								</b-tooltip>
								<b-input-group>
									<b-form-input
										v-model="singleTemplatePosition.overlap_right"
										type="text"
									/>
									<template #append>
										<b-input-group-text
											class="input-group-text-alt"
											style="cursor:pointer"
										>
											<custom-checkbox
												id="checkbox-overlap_right"
												name="checkbox-1"
												:checked="singleTemplatePosition.overlap_right == null ? true : false"
												unchecked-value="not_accepted"
												@change="()=> singleTemplatePosition.overlap_right = null "
											/>
											Null
										</b-input-group-text>
									</template>
								</b-input-group>
							</b-form-group>
							<b-form-group
								class="form-group"
								label-for="block-form1-name"
								label-cols-lg="4"
							>
								<template #label>
									<div class="d-flex flex-row">
										<div class="mr-2">
											Overlap bottom
										</div>
										<div
											id="overlap_bottom"
											class="tip"
										/>
									</div>
								</template>
								<b-tooltip
									target="overlap_bottom"
									triggers="hover"
									placement="bottom"
								>
									for how many pixels may the bottom of the position overlap with other objects on the page?
								</b-tooltip>
								<b-input-group>
									<b-form-input
										v-model="singleTemplatePosition.overlap_bottom"
										type="text"
									/>
									<template #append>
										<b-input-group-text
											class="input-group-text-alt"
											style="cursor:pointer"
										>
											<custom-checkbox
												id="checkbox-overlap_bottom"
												name="checkbox-1"
												:checked="singleTemplatePosition.overlap_bottom == null ? true : false"
												unchecked-value="not_accepted"
												@change="()=> singleTemplatePosition.overlap_bottom = null "
											/>
											Null
										</b-input-group-text>
									</template>
								</b-input-group>
							</b-form-group>
							<b-form-group
								class="form-group"
								label-for="block-form1-name"
								label-cols-lg="4"
							>
								<template #label>
									<div class="d-flex flex-row">
										<div class="mr-2">
											Overlap left
										</div>
										<div
											id="overlap_left"
											class="tip"
										/>
									</div>
								</template>
								<b-tooltip
									target="overlap_left"
									triggers="hover"
									placement="bottom"
								>
									for how many pixels may the left of the position overlap with other objects on the page?
								</b-tooltip>
								<b-input-group>
									<b-form-input
										v-model="singleTemplatePosition.overlap_left"
										type="text"
									/>
									<template #append>
										<b-input-group-text
											class="input-group-text-alt"
											style="cursor:pointer"
										>
											<custom-checkbox
												id="checkbox-overlap_left"
												name="checkbox-1"
												:checked="singleTemplatePosition.overlap_left == null ? true : false"
												unchecked-value="not_accepted"
												@change="()=> singleTemplatePosition.overlap_left = null "
											/>
											Null
										</b-input-group-text>
									</template>
								</b-input-group>
							</b-form-group>
							<b-form-group
								class="form-group"
								label-for="block-form1-name"
								label-cols-lg="4"
							>
								<template #label>
									<div class="d-flex flex-row">
										<div class="mr-2">
											Matrix
										</div>
										<div
											id="matrix"
											class="tip"
										/>
									</div>
								</template>
								<b-tooltip
									target="matrix"
									triggers="hover"
									placement="bottom"
								>
									create a matrix with x photos? Or use value 1 to create a matrix with all uploaded photos
								</b-tooltip>
								<b-input-group>
									<b-form-input
										v-model="singleTemplatePosition.matrix"
										type="text"
									/>
								</b-input-group>
							</b-form-group>
							<b-form-group
								label-class="font-w600"
								label-cols-lg="4"
							>
								<template #label>
									<div class="d-flex flex-row">
										<div class="mr-2">
											Transformable
										</div>
										<div
											id="transformable"
											class="tip"
										/>
									</div>
								</template>
								<b-tooltip
									target="transformable"
									triggers="hover"
									placement="bottom"
								>
									Is the object that fills this position draggable, resizable and rotatable by the user?
								</b-tooltip>
								<custom-checkbox
									id="checkbox-5"
									:is-switch="true"
									size="lg"
									:checked="singleTemplatePosition.transformable === 1 ? true : false"
									@change="singleTemplatePosition.transformable = $event"
								/>
							</b-form-group>
							<b-form-group
								label-class="font-w600"
								label-cols-lg="4"
							>
								<template #label>
									<div class="d-flex flex-row">
										<div class="mr-2">
											Flag
										</div>
										<div
											id="flag"
											class="tip"
										/>
									</div>
								</template>
								<b-tooltip
									target="flag"
									triggers="hover"
									placement="bottom"
								>
									When photo fills this position, flag it to avoid photo being used in other positions when auto filling?
								</b-tooltip>
								<custom-checkbox
									id="checkbox-6"
									:is-switch="true"
									size="lg"
									:checked="singleTemplatePosition.flag === 1 ? true : false"
									@change="singleTemplatePosition.flag = $event"
								/>
							</b-form-group>
							<b-form-group
								class="form-group"
								label="Mask"
								label-for="block-form1-name"
								label-cols-lg="4"
							>
								<b-input-group>
									<custom-select
										id="block-form-layoutid"
										v-model="singleTemplatePosition.mask"
									>
										<b-form-select-option :value="null">
											Please select an option
										</b-form-select-option>
										<b-form-select-option
											v-for="(ms,id) in mask"
											:key="id"
											:value="ms"
										>
											{{ ms }}
										</b-form-select-option>
									</custom-select>
								</b-input-group>
							</b-form-group>
						</template>
					</div>
				</base-block>
			</b-col>
		</b-row>
	</div>
</template>

<style lang="scss" scoped>
@import "../../../../assets/scss/oneui/tooltip";
.thumbnail{
	display:flex;
	justify-content:space-around;
	align-items:Center;
	background-color: #ccc;
	margin-top:10px;
}
.cameraCheckbox {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-left: 40px;
}
</style>
