import { BadgeModel } from 'interfaces/database';
import ApiService from 'services/ApiService';

class BadgeService extends ApiService<BadgeModel> {
	constructor() {
		super('badge');
	}
}

export default new BadgeService();
