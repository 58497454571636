import {
	Vue, Component, Watch, Prop, Ref, toNative,
} from 'vue-facing-decorator';
import * as DB from 'interfaces/database';
import mitt from 'mitt';
import TabulatorSwitchBox from 'components/Tabulator/TabulatorSwitchBox';
import {
	CellComponentExtended,
	ColumnDefinitionExtended,
	TabulatorFull as Tabulator,
} from 'tabulator-tables';
import { createInstance } from 'utils/vue';
import { TabEvent } from 'interfaces/app';
import PDPService from 'services/PDPService';
import BadgePDPService from 'services/BadgePDPService';
import Template from './template.vue';

const eventBus = mitt<TabEvent<DB.PDPModel>>();

@Component({
	components: {
		TabulatorSwitchBox,
	},
	mixins: [Template],
})
class BadgePdpTable extends Vue {
	@Prop()
	public readonly routeId!: string;

	private get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	@Ref('badgepdp')
	private readonly tableReference!: HTMLDivElement;

	private perPage = 20;

	private table?: Tabulator;

	private isLoading = false;

	private pageOptions = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50];

	private checkPdp: Record<string, DB.BadgePDPModel> = {};

	private columnDefs: ColumnDefinitionExtended[] = [];

	protected beforeMount(): void {
		this.columnDefs = [
			{
				field: 'id',
				title: 'ID',
			},
			{
				title: 'Name',
				field: 'name',
				headerFilter: true,
			},
			{
				title: 'Available',
				field: 'url',
				titleFormatter: () => {
					const instance = createInstance({
						component: TabulatorSwitchBox,
						props: {
							checked: false,
							text: 'Available',
							eventName: 'headerSwitchBoxChanged',
							eventBus,
						},
					});

					return instance;
				},
				formatter: (cell: CellComponentExtended<DB.PDPModel>) => {
					const data = cell.getData();
					const instance = createInstance({
						component: TabulatorSwitchBox,
						props: {
							checked: Boolean(data && this.checkPdp[data.id]),
							data,
							eventBus,
						},
					});

					return instance;
				},
			},
		] as ColumnDefinitionExtended[];
	}

	protected mounted(): void {
		this.getBadgePdp();
		eventBus.on(
			'headerSwitchBoxChanged',
			this.headerSwitchBoxChanged,
		);
		eventBus.on(
			'switchBoxChanged',
			this.switchBoxChanged,
		);
		this.tableInitialization();
		this.table?.on(
			'tableBuilt',
			this.onTableBuilt,
		);
	}

	private tableInitialization(): void {
		this.table = new Tabulator(
			this.tableReference,
			{
				height: '60vh',
				layout: 'fitColumns',
				columns: this.columnDefs,
			},
		);
	}

	private onTableBuilt(): void {
		this.getData();
	}

	private async getData(): Promise<void> {
		const parameter = new URLSearchParams({
			limit: '0',
		});
		this.table?.alert('Loading');
		try {
			await this.getBadgePdp();
			const data = await PDPService.getAll(parameter);
			// set table data
			this.table?.setData(data);

			const allChecked = this.table?.getData().every((item) => this.checkPdp[item.id]);
			if (allChecked) {
				this.updateHeaderBox(true);
			} else {
				this.updateHeaderBox(false);
			}
		} catch (error: any) {
			this.$toastError(error.message);
		} finally {
			this.table?.clearAlert();
		}
	}

	private switchBoxChanged(data: TabEvent<DB.PDPModel>['switchBoxChanged']): void {
		this.table?.alert('Loading');
		const postData: Pick<DB.BadgePDPModel, 'badgeid' | 'pdpid'> = {
			pdpid: data.params.id,
			badgeid: parseInt(
				this.routeId,
				10,
			),
		};
		(data.event ? BadgePDPService.create(postData) : BadgePDPService.delete(this.checkPdp[data.params.id].id))
			.then((response) => {
				if (data.event) {
					const resp = response as DB.BadgePDPModel;
					this.checkPdp[resp.pdpid] = resp;
					const allChecked = this.table?.getData().every((item) => this.checkPdp[item.id]);
					this.updateHeaderBox(allChecked as boolean);
					this.table?.scrollToRow(data.params.id);
				} else {
					delete this.checkPdp[data.params.id];
					const allChecked = this.table?.getData().every((item) => this.checkPdp[item.id]);
					this.updateHeaderBox(allChecked as boolean);
					this.table?.scrollToRow(data.params.id);
				}
				this.table?.redraw();
				return undefined;
			})
			.finally(() => {
				this.table?.clearAlert();
			})
			.catch((err) => {
				this.$toastError(err.message);
			});
	}

	protected beforeUnmount() {
		eventBus.off(
			'switchBoxChanged',
			this.switchBoxChanged,
		);
		eventBus.off(
			'headerSwitchBoxChanged',
			this.headerSwitchBoxChanged,
		);
		this.table?.off(
			'tableBuilt',
			this.onTableBuilt,
		);
		this.table?.destroy();
	}

	private headerSwitchBoxChanged(data: TabEvent<DB.PDPModel>['headerSwitchBoxChanged']): void {
		this.isLoading = true;
		const allPdpId: number[] = [];

		// Get all the rows from the table
		const allRows = this.table?.getData() || [];

		// Get the current filters applied to the table
		const currentFilters = this.table?.getFilters(true);

		let filteredRows: DB.BadgePDPModel[];

		// Manually filter rows based on current filter criteria
		if (!currentFilters) {
			filteredRows = allRows;
		} else {
			filteredRows = allRows.filter((row) => currentFilters.every((filter) => {
				const rowValue = String(row[filter.field]).toLowerCase();
				const filterValue = String(filter.value).toLowerCase();

				return rowValue.includes(filterValue);
			}));
		}

		filteredRows.forEach((node) => {
			if (node) {
				allPdpId.push(node.id);
			}
			return undefined;
		});

		const offeringid = Object.values(this.checkPdp)
			.filter((item) => allPdpId.includes(item.pdpid))
			.map((item) => item.id);

		const parameter = new URLSearchParams({
			where: JSON.stringify({
				id: offeringid,
			}),
		});

		// Create postData array, but only include offering IDs that are not checked
		const postData = allPdpId
			.filter((pdpId) => !Object.keys(this.checkPdp).includes(pdpId.toString()))
			.map((pdpId) => ({
				pdpid: pdpId,
				badgeid: parseInt(
					this.routeId,
					10,
				),
			}));

		(data.event ? BadgePDPService.create(postData) : BadgePDPService.deleteWhere(parameter))
			.then((response) => {
				if (data.event) {
					const resp = response as DB.BadgePDPModel[];
					resp.forEach((item: DB.BadgePDPModel) => {
						this.checkPdp[item.pdpid] = item;
						this.table?.redraw(true);
					});
				} else {
					allPdpId.forEach((id) => {
						delete this.checkPdp[id];
						this.table?.redraw(true);
					});
				}
				return undefined;
			})
			.finally(() => {
				this.isLoading = false;
			})
			.catch((err) => {
				this.$toastError(err.message);
			});
	}

	// This function updates the header formatter switchbox anytime the table renders or redrawn
	private updateHeaderBox(updateCellRenderer: boolean): void {
		const columnDef = this.table?.getColumnDefinitions().map((column) => {
			if (column.field === 'url') {
				return {
					...column,
					title: 'Available',
					field: 'url',
					titleFormatter: updateCellRenderer ? () => {
						const allChecked = this.table?.getData().every((item) => this.table?.getData().length !== 0 && this.checkPdp[item.id]);
						const instance = createInstance({
							component: TabulatorSwitchBox,
							props: {
								checked: Boolean(allChecked && this.table?.getData().length !== 0),
								text: 'Available',
								eventName: 'headerSwitchBoxChanged',
								eventBus,
							},
						});

						return instance;
					} : column.titleFormatter,
					formatter: (cell: CellComponentExtended<DB.PDPModel>) => {
						const data = cell.getData();
						const instance = createInstance({
							component: TabulatorSwitchBox,
							props: {
								checked: Boolean(data && this.checkPdp[data.id]),
								data,
								eventBus,
							},
						});

						return instance;
					},
				};
			}
			return column;
		}) as ColumnDefinitionExtended[];

		// update the column definitions
		this.table?.setColumns(columnDef);
	}

	@Watch('loggedIn')
	private async getBadgePdp(): Promise<void> {
		if (this.loggedIn) {
			const params = new URLSearchParams({
				where: JSON.stringify({ badgeid: this.routeId }),
				limit: '0',
			});

			try {
				const resp = await BadgePDPService.getAll(params);
				this.checkPdp = resp.reduce(
					(obj, item) => ({
						...obj,
						[item.pdpid]: item,
					}),
					{},
				);
			} catch (error: any) {
				this.$toastError(error.message);
			}
		}
		return undefined;
	}
}

export default toNative(BadgePdpTable);
