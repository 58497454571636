import { Vue, Component, toNative } from 'vue-facing-decorator';
import LayoutTable from 'components/Themes/LayoutsTable';
import { useRouter } from 'vue-router';
import Template from './template.vue';

@Component({
	components: {
		LayoutTable,
	},
	mixins: [Template],
})
class Layouts extends Vue {
	private router = useRouter();

	protected routeLayout(id: string): void {
		this.router.push(`/layout/${id}`);
	}

	protected openInNewTab(id: string): void {
		const route = this.router.resolve({ name: 'EditLayout', params: { id } });
		window.open(
			route.href,
			'_blank',
		);
	}
}

export default toNative(Layouts);
