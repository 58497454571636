import { Vue, Component, toNative } from 'vue-facing-decorator';
import PhotoFrameTable from 'components/Themes/PhotoframeTable';
import { useRouter } from 'vue-router';
import Template from './template.vue';

@Component({
	components: {
		PhotoFrameTable,
	},
	mixins: [Template],
})
class Photoframe extends Vue {
	private router = useRouter();

	protected showModal(): void {
		this.$root?.$emit(
			'bv::show::modal',
			'photoframe-modal',
			'#photoframeShow',
		);
	}

	protected routePhotoframe(id: string): void {
		this.router.push(`/photoframe/${id}`);
	}

	protected openInNewTab(id: string): void {
		const route = this.router.resolve({ name: 'editPhotoframe', params: { id } });
		window.open(
			route.href,
			'_blank',
		);
	}
}

export default toNative(Photoframe);
