import {
	Vue, Component, Ref, Prop, Watch, toNative,
} from 'vue-facing-decorator';
import * as DB from 'interfaces/database';
import { BModal } from 'bootstrap-vue';
import mitt from 'mitt';
import Swal from 'sweetalert2';
import {
	TabEvent, TranslationsResponse, LanguageOptionsModel, Translation, Translations,
} from 'interfaces/app';
import {
	CellComponent,
	CellComponentExtended, ColumnDefinitionExtended, TabulatorFull as Tabulator,
} from 'tabulator-tables';
import TabulatorBtn from 'components/Tabulator/TabulatorBtn';
import TabulatorImage from 'components/Tabulator/TabulatorImage';
import { PickerOptions, PickerResponse } from 'filestack-js';
import { createInstance } from 'utils/vue';
import { useRoute, useRouter } from 'vue-router';
import CustomSelect from 'components/CustomSelect';
import { useStore } from 'vuex';
import { getModule } from 'vuex-module-decorators';
import { namespace } from 'vuex-facing-decorator';
import ProductCategoryStore from 'store/offerings/productCategory';
import newLineFormatter from 'utils/tabulator';
import TranslationService from 'services/TranslationService';
import LanguageService from 'services/LanguageService';
import ProductCategoryService from 'services/ProductCategoryService';
import { httpClient } from '../../utils/http';
import Template from './template.vue';

const eventBus = mitt<TabEvent<DB.ProductCategoryModel>>();
const ProductCategory = namespace('ProductCategory');
let ProductCategoryVuexStore: ReturnType<typeof getModule<ProductCategoryStore>>;
@Component({
	components: {
		TabulatorImage,
		CustomSelect,
	},
	mixins: [Template],
})
class ProductCategoriesTable extends Vue {
	@Ref('addproductcategory-modal')
	private readonly modal!: BModal;

	@Ref('productCategory')
	private readonly tableReference!: HTMLDivElement;

	private name = '';

	private table?: Tabulator;

	private isLoaded = false;

	private columnDefs: ColumnDefinitionExtended[] = [];

	private rowData: DB.ProductCategoryModel[] = [];

	private translations = {} as Record<number, Translation>;

	private language = '';

	private languageOptions: LanguageOptionsModel[] = [];

	private image = '';

	private route = useRoute();

	private router = useRouter();

	private store = useStore();

	@Prop({
		type: Boolean,
	})
	private readonly subcategory!: boolean;

	@ProductCategory.Getter
	private getProductCategories!: DB.ProductCategoryModel[];

	private get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	protected beforeMount(): void {
		this.columnDefs = [
			{
				title: '',
				rowHandle: true,
				formatter: 'handle',
				headerSort: false,
				frozen: true,
				width: 40,
			},
			{
				field: 'name',
				title: 'Details',
				headerFilter: true,
			},
			{
				title: 'Header*',
				field: 'header',
				editor: 'input',
				cellEdited: this.handleCellEdit,
			},
			{
				title: 'Description*',
				field: 'description',
				formatter: newLineFormatter,
				editor: 'textarea',
				cellEdited: this.handleCellEdit,
			},
			{
				title: 'Image*',
				field: 'thumbnail',
				formatter: 'image',
				formatterParams: {
					height: '70px',
				},
				cellDblClick: (_, cell) => this.editImageCell(cell),
			},
			{
				title: 'Actions',
				formatter: (cell: CellComponentExtended<DB.ProductCategoryModel>) => {
					const instance = createInstance({
						component: TabulatorBtn,
						props: {
							data: cell.getData(),
							buttons: [
								{
									id: 'edit',
									eventName: 'editItem',
									className: 'fa-edit',
								},
								{
									id: 'delete',
									eventName: 'deleteItem',
									className: 'fa-times',
								},
							],
							eventBus,
						},
					});
					return instance;
				},
			},
		] as ColumnDefinitionExtended[];
	}

	protected mounted(): void {
		ProductCategoryVuexStore = getModule(
			ProductCategoryStore,
			this.store,
		);
		eventBus.on(
			'deleteItem',
			this.deleteProductCategory,
		);
		eventBus.on(
			'editItem',
			this.editProductCategory,
		);
		eventBus.on(
			'editImage',
			this.editImageButton,
		);
		this.tableInitialization();
		this.table?.on(
			'tableBuilt',
			() => {
				this.getData();
			},
		);
		this.table?.on(
			'rowMoved',
			this.onRowDragEnd,
		);
	}

	protected beforeUnmount(): void {
		eventBus.off(
			'deleteItem',
			this.deleteProductCategory,
		);
		eventBus.off(
			'editItem',
			this.editProductCategory,
		);
		eventBus.off(
			'editImage',
			this.editImageButton,
		);
		this.table?.off(
			'rowMoved',
			this.onRowDragEnd,
		);
		this.table?.off(
			'tableBuilt',
			() => {
				this.getData();
			},
		);
		this.table?.destroy();
	}

	private tableInitialization(): void {
		this.table = new Tabulator(
			this.tableReference,
			{
				height: '60vh',
				layout: 'fitColumns',
				columns: this.columnDefs,
				movableRows: true,
			},
		);
	}

	private onRowDragEnd(): void {
		try {
			const arr = this.table?.getData().map((element, index) => ({ id: element.id, serialnumber: index }));
			this?.table?.alert('Loading');
			ProductCategoryService.batchUpdate(
				arr as DB.ProductCategoryModel[],
			);
			this.$toastSuccess('Lister Items have been updated');
		} catch (err: any) {
			this.$toastError(err.message);
		} finally {
			this.table?.clearAlert();
		}
	}

	private handleCellEdit(cell: CellComponent): void {
		const row = cell.getRow().getData();
		const field = cell.getField();
		const newValue = cell.getValue();

		row[field] = newValue;

		//  Find and Update 'rowData' state
		const foundRow = this.rowData.find((r) => r.id === row.id);

		if (foundRow) {
			(foundRow as unknown as Record<string, string | number>)[field] = newValue;
		}
		this.saveUpdatedData([cell]).catch((error) => {
			this.$toastError(error.message);
		});
	}

	// eslint-disable-next-line class-methods-use-this
	private formatCellsAsTranslations(cells: CellComponent[]): Record<number, Translation> {
		return cells.reduce(
			(result, cell) => {
				const row = cell.getRow().getData();
				return {
					...result,
					[row.id]: {
						name: row.header ? row.header : '',
						lines: row.description ? row.description.split('\n') : null,
					},
				};
			},
			{},
		);
	}

	private async saveUpdatedData(cells: CellComponent[]): Promise<void> {
		const bundle: Record<number, Translation> = this.formatCellsAsTranslations(cells);
		try {
			await httpClient.put(
				'/api/translation',
				{
					namespace: 'productcategories',
					language: this.language,
					bundle,
				},
			);
			this.$toastSuccess('Successfully updated the data for the table');
		} catch (error: any) {
			this.$toastError(error.message);
		}
	}

	private async fetchTranslations(language: string): Promise<TranslationsResponse> {
		const parameter = new URLSearchParams({
			ns: 'productcategories',
			lng: language,
			limit: '0',
		});

		try {
			const data = await TranslationService.getMultiTranslation(parameter);
			return data;
		} catch (error: any) {
			this.$toastError(error.message);
			return Promise.reject(error);
		}
	}

	private setRowData(): void {
		this.rowData = [...this.getProductCategories.filter((i) => (this.subcategory ? i.parentid !== null : i.parentid === null))];
		// set the translations for the default language
		this.rowData = this.rowData.map((row) => {
			if (typeof this.translations?.[row.id] !== 'undefined') {
				return {
					...row,
					header: this.translations[row.id].name,
					description: this.translations[row.id].lines?.join('\n'),
				};
			}

			return { ...row };
		});
		this.table?.setData(this.rowData);
	}

	private async getData(): Promise<void> {
		this.table?.alert('Loading');
		try {
			const languageResponse = await LanguageService.getAll(new URLSearchParams({ limit: '0' }));
			this.languageOptions = languageResponse.map((language) => ({ value: language.id, text: language.name }));
			// get the default language
			const defaultLang = languageResponse.find((language: DB.LanguageModel) => language.default);
			// set the language to the default language or the first language in the list
			this.language = defaultLang ? defaultLang.id : languageResponse[0].id;
			// get the translations for the default language
			const translationsData = await this.fetchTranslations(this.language);
			this.translations = translationsData[this.language]?.productcategories as Translations;

			const id = this.subcategory && this.route.params.id ? Number(this.route.params.id) : null;
			await ProductCategoryVuexStore.getProductCategoriesAction(id);
		} catch (err: any) {
			this.$toastError(err.message);
		} finally {
			this.table?.clearAlert();
		}
	}

	protected async translateTableData(data: string): Promise<void> {
		const translations = await this.fetchTranslations(data);
		this.translations = translations[this.language]?.productcategories as Translations;

		this.rowData = this.rowData.map((row) => {
			if (typeof this.translations?.[row.id] !== 'undefined') {
				return {
					...row,
					header: this.translations[row.id].name,
					description: this.translations[row.id].lines?.join('\n'),
				};
			}
			return row;
		});

		this.table?.updateData(this.rowData);
	}

	protected async addProductCategory(): Promise<void> {
		this.isLoaded = true;
		try {
			const response = await ProductCategoryService.create(
				{ name: this.name },
			);
			this.hideModal();
			this.name = '';
			this.$toastSuccess('Product Category Added');
			this.$emit(
				'routeProductCategory',
				response.id,
			);
		} catch (err: any) {
			this.$toastError(err.message);
		} finally {
			this.isLoaded = false;
		}
	}

	private hideModal(): void {
		this.modal.hide();
	}

	private editImage(id: DB.ProductCategoryModel['id']): void {
		const options: PickerOptions = {
			fromSources: ['local_file_system'],
			onUploadDone: async (files: PickerResponse) => {
				// send an edit request to the API
				this.table?.alert('Loading');
				ProductCategoryService.update(
					id,
					{
						thumbnail: files.filesUploaded[0].url,
					},
				)
					.then((res) => {
						this.table?.updateData([res]);
						return null;
					}).finally(() => this.table?.clearAlert())
					.catch((err) => {
						this.$toastError(err.message);
					});
			},
		};
		this.$client.picker(options).open();
	}

	private editImageButton(data: TabEvent<DB.ProductCategoryModel>['editImage']): void {
		this.editImage(data.id);
	}

	private editImageCell(cell: CellComponent): void {
		const data = cell.getData() as DB.ProductCategoryModel;
		this.editImage(data.id);
	}

	private editProductCategory(data: TabEvent<DB.ProductCategoryModel>['editItem']): void {
		this.router.push(`/productcategory/${data.id}`);
	}

	private deleteProductCategory(data: TabEvent<DB.ProductCategoryModel>['deleteItem']): void {
		Swal.fire({
			title: 'Are you sure?',
			text: 'You will not be able to recover this file!',
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'btn btn-danger m-1',
				cancelButton: 'btn btn-secondary m-1',
			},
			confirmButtonText: 'Yes, delete it!',
			html: false,
		}).then((result) => {
			if (result.value) {
				this.table?.alert('Loading');
				// eslint-disable-next-line promise/no-nesting
				ProductCategoryService.delete(data.id).then(() => {
					this.$toastSuccess('Item Deleted');
					this.table?.deleteRow(data.id);
					return null;
				}).finally(() => {
					this.table?.clearAlert();
				}).catch((err) => {
					this.$toastError(err.message);
				});
			}
			return undefined;
		}).catch((err) => {
			this.$toastError(err.message);
		});
	}

	@Watch(
		'getProductCategories',
	)
	onProductCategoriesChange(): void {
		this.setRowData();
	}
}

export default toNative(ProductCategoriesTable);
