<template>
	<div
		class="ml-5 mt-4"
		style="width: 95%; height: 100%;"
	>
		<loading-overlay
			:active="isLoading"
			:can-cancel="true"
		/>
		<b-row>
			<b-col
				md="12"
				lg="12"
				sm="12"
			>
				<b-tabs
					card
					content-class="mt-3"
					fill
				>
					<b-tab
						title="Page properties"
						active
					>
						<base-block
							title="Page properties"
							header-bg
						>
							<div
								v-if="spinner"
								class="text-center"
							>
								<div
									class="spinner-grow text-primary m-5"
									role="status"
								>
									<span class="sr-only">Loading...</span>
								</div>
							</div>
							<div
								v-else
								class="d-block text-center"
							>
								<b-form-group
									label-for="example-select"
									label-cols-lg="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												Type
											</div>
											<div
												id="type"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="type"
										triggers="hover"
										placement="bottom"
									>
										One of the three object types(photo, text)
									</b-tooltip>
									<custom-select
										id="type"
										class="mb-2"
										disabled
										plain
									>
										<template #first>
											<b-form-select-option>
												{{ singlePageObjectEdit.type }}
											</b-form-select-option>
										</template>
									</custom-select>
								</b-form-group>
								<b-form-group
									class="form-group"
									label-for="block-form1-name"
									label-cols-lg="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												X-axis
											</div>
											<div
												id="xaxis"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="xaxis"
										triggers="hover"
										placement="bottom"
									>
										The x-axis coordinate of the object on the page
									</b-tooltip>
									<b-input-group>
										<b-form-input
											v-model.number="singlePageObjectEdit.x_axis"
											type="text"
										/>
									</b-input-group>
								</b-form-group>
								<b-form-group
									class="form-group"
									label-for="block-form1-name"
									label-cols-lg="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												Y-axis
											</div>
											<div
												id="yaxis"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="yaxis"
										triggers="hover"
										placement="bottom"
									>
										The y-axis coordinate of the object on the page
									</b-tooltip>
									<b-input-group>
										<b-form-input
											v-model.number="singlePageObjectEdit.y_axis"
											type="text"
										/>
									</b-input-group>
								</b-form-group>
								<b-form-group
									class="form-group"
									label-for="block-form1-name"
									label-cols-lg="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												Z-axis
											</div>
											<div
												id="zaxis"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="zaxis"
										triggers="hover"
										placement="bottom"
									>
										The z-axis coordinate of the object on the page
									</b-tooltip>
									<b-input-group>
										<b-form-input
											v-model.number="singlePageObjectEdit.z_axis"
											type="text"
										/>
									</b-input-group>
								</b-form-group>
								<b-form-group
									class="form-group"
									label-for="block-form1-name"
									label-cols-lg="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												Width
											</div>
											<div
												id="width"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="width"
										triggers="hover"
										placement="bottom"
									>
										The width of the object in pixels on the page
									</b-tooltip>
									<b-input-group>
										<b-form-input
											v-model.number="singlePageObjectEdit.width"
											type="text"
										/>
									</b-input-group>
								</b-form-group>
								<b-form-group
									class="form-group"
									label-for="block-form1-name"
									label-cols-lg="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												Height
											</div>
											<div
												id="height"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="height"
										triggers="hover"
										placement="bottom"
									>
										The height of the object in pixels on the page
									</b-tooltip>
									<b-input-group>
										<b-form-input
											v-model.number="singlePageObjectEdit.height"
											type="text"
										/>
									</b-input-group>
								</b-form-group>

								<b-form-group
									class="form-group"
									label-for="block-form1-name"
									label-cols-lg="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												Maximum Width
											</div>
											<div
												id="maxwidth"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="maxwidth"
										triggers="hover"
										placement="bottom"
									>
										The maximum width the object can be in pixels
									</b-tooltip>
									<b-input-group>
										<custom-select
											id="example-select"
											v-model="singlePageObjectEdit.maxwidth"
											class="mb-2"
											plain
										/>
										<template #append>
											<b-input-group-text
												class="input-group-text-alt"
												style="cursor:pointer"
											>
												<custom-checkbox
													id="checkbox-maxwidth"
													name="checkbox-1"
													:checked="checkPropertyNull('maxwidth')"
													unchecked-value="not_accepted"
													@change="setPropertyNull('maxwidth')"
												/>
												Null
											</b-input-group-text>
										</template>
									</b-input-group>
								</b-form-group>

								<b-form-group
									class="form-group"
									label-for="block-form1-name"
									label-cols-lg="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												Maximum Height
											</div>
											<div
												id="maxheight"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="maxheight"
										triggers="hover"
										placement="bottom"
									>
										The maximum height the object can be in pixels
									</b-tooltip>
									<b-input-group>
										<custom-select
											id="example-select"
											v-model="singlePageObjectEdit.maxheight"
											class="mb-2"
											plain
										/>
										<template #append>
											<b-input-group-text
												class="input-group-text-alt"
												style="cursor:pointer"
											>
												<custom-checkbox
													id="checkbox-maxheight"
													name="checkbox-1"
													:checked="checkPropertyNull('maxheight')"
													unchecked-value="not_accepted"
													@change="setPropertyNull('maxheight')"
												/>
												Null
											</b-input-group-text>
										</template>
									</b-input-group>
								</b-form-group>

								<b-form-group
									class="form-group"
									label-for="block-form1-name"
									label-cols-lg="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												Rotational angle
											</div>
											<div
												id="rotationalangle"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="rotationalangle"
										triggers="hover"
										placement="bottom"
									>
										The angle(In degrees) in which the object is displayed on the page
									</b-tooltip>
									<b-input-group>
										<b-form-input
											v-model="singlePageObjectEdit.rotate"
											type="text"
										/>
									</b-input-group>
								</b-form-group>

								<b-form-group
									class="form-group"
									label-for="block-form1-name"
									label-cols-lg="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												Border width
											</div>
											<div
												id="borderwidth"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="borderwidth"
										triggers="hover"
										placement="bottom"
									>
										The hex width of the border around the object
									</b-tooltip>
									<b-input-group>
										<b-form-input
											v-model="singlePageObjectEdit.borderwidth"
											type="text"
										/>
									</b-input-group>
								</b-form-group>

								<b-form-group
									class="form-group"
									label-for="block-form1-name"
									label-cols-lg="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												Border color
											</div>
											<div
												id="bordercolor"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="bordercolor"
										triggers="hover"
										placement="bottom"
									>
										The hex color of the border around the object
									</b-tooltip>
									<b-input-group>
										<b-form-input
											v-model="singlePageObjectEdit.bordercolor"
											type="text"
										/>
									</b-input-group>
								</b-form-group>

								<b-form-group
									class="form-group"
									label-for="block-form1-name"
									label-cols-lg="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												Border image
											</div>
											<div
												id="bgimage"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="bgimage"
										triggers="hover"
										placement="bottom"
									>
										The border image that should be place around the object
									</b-tooltip>
									<b-input-group>
										<custom-select
											id="example-select"
											v-model="singlePageObjectEdit.borderimage"
											class="mb-2"
											plain
										/>
										<template #append>
											<b-input-group-text
												class="input-group-text-alt"
												style="cursor:pointer"
											>
												<custom-checkbox
													id="checkbox-borderimage"
													name="checkbox-1"
													:checked="checkPropertyNull('border_image')"
													unchecked-value="not_accepted"
													@change="setPropertyNull('border_image')"
												/>
												Null
											</b-input-group-text>
										</template>
									</b-input-group>
								</b-form-group>

								<b-form-group
									label-class="font-w600"
									label-cols-md="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												Editable
											</div>
											<div
												id="editable"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="editable"
										triggers="hover"
										placement="bottom"
									>
										Is the page editable by the user
									</b-tooltip>
									<custom-checkbox
										id="checkbox-1"
										:is-switch="true"
										size="lg"
										:checked="checkPropertyTrue('editable')"
										@change="updateProperty('editable', $event)"
									/>
								</b-form-group>

								<b-form-group
									label-class="font-w600"
									label-cols-md="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												Transformable
											</div>
											<div
												id="movable"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="transformable"
										triggers="hover"
										placement="bottom"
									>
										Is the object draggable, resizable and rotatable by the user
									</b-tooltip>
									<custom-checkbox
										id="checkbox-2"
										:is-switch="true"
										size="lg"
										:checked="checkPropertyTrue('transformable')"
										@change="updateProperty('transformable', $event)"
									/>
								</b-form-group>
							</div>
						</base-block>
					</b-tab>
					<b-tab
						title="Sub Object properties"
					>
						<base-block
							title="Page properties"
							header-bg
						>
							<div v-show="singlePageObjectEdit.type === 'photo'">
								<b-form-group
									label-cols-lg="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												image
											</div>
											<div
												id="image"
												class="tip"
											/>
										</div>
									</template>
									<custom-file-input
										id="thumbnail"
										ref="logodesktop"
										:placeholder="placeHolders.icon"
										@click.prevent="updateIcon"
									/>

									<div
										v-if="subObjectProperty.source"
										class="thumbnail"
									>
										<img
											:src="subObjectProperty.source"
											alt=""
											srcset=""
											height="100"
											width="100"
										>
										<div
											style="cursor:pointer"
											@click="removeIcon"
										>
											&#10006; Remove
										</div>
									</div>
								</b-form-group>
							</div>
							<div v-show="subObjectProperty.type === 'text'">
								<b-form-group
									label-for="example-select"
								>
									<custom-select
										id="example-select"
										v-model="fonts.fontface"
										disabled
										class="mb-2"
										plain
									>
										<b-form-select-option :value="''">
											Please select an option
										</b-form-select-option>
										<b-form-select-option
											v-for="(font,id) in fonts"
											:key="id"
											:value="font"
										>
											{{ font }}
										</b-form-select-option>
									</custom-select>
								</b-form-group>

								<b-form-group
									v-if="fonts.fontbold === true"
									label-class="font-w600"
									label-cols-md="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												Bold
											</div>
											<div
												id="bold"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="Bold"
										triggers="hover"
										placement="bottom"
									>
										Is the text displayed in Bold
									</b-tooltip>
									<custom-checkbox
										id="checkbox-3"
										:is-switch="true"
										size="lg"
										:checked="checkPropertyTrue('fontbold')"
										@change="updateProperty('fontbold', $event)"
									/>
								</b-form-group>

								<b-form-group
									v-if="fonts.fontbold === true"
									label-class="font-w600"
									label-cols-md="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												Italic
											</div>
											<div
												id="italic"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="italic"
										triggers="hover"
										placement="bottom"
									>
										Is the text displayed in Italic
									</b-tooltip>
									<custom-checkbox
										id="checkbox-4"
										:is-switch="true"
										size="lg"
										:checked="checkPropertyTrue('fontitalic')"
										@change="updateProperty('fontitalic', $event)"
									/>
								</b-form-group>

								<b-form-group
									class="form-group"
									label-for="block-form1-name"
									label-cols-lg="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												Text color
											</div>
											<div
												id="textcolor"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="textcolor"
										triggers="hover"
										placement="bottom"
									>
										The hex color of the text
									</b-tooltip>
									<b-input-group>
										<b-form-input
											v-model="fonts.fontcolor"
											type="text"
										/>
									</b-input-group>
								</b-form-group>

								<b-form-group
									class="form-group"
									label-for="block-form1-name"
									label-cols-lg="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												Background color
											</div>
											<div
												id="bgcolor"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="bgcolor"
										triggers="hover"
										placement="bottom"
									>
										The Hex color behind the text
									</b-tooltip>
									<b-input-group>
										<custom-select
											id="example-select"
											class="mb-2"
											plain
										/>
										<template #append>
											<b-input-group-text
												class="input-group-text-alt"
												style="cursor:pointer"
											>
												<custom-checkbox
													id="checkbox-bgcolor"
													name="checkbox-1"
													:checked="checkPropertyNull('bgcolor')"
													unchecked-value="not_accepted"
													@change="setPropertyNull('bgcolor')"
												/>
												Null
											</b-input-group-text>
										</template>
									</b-input-group>
								</b-form-group>
								<b-form-group
									class="form-group"
									label-for="block-form1-name"
									label-cols-lg="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												Font size
											</div>
											<div
												id="fontsize"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="fontsize"
										triggers="hover"
										placement="bottom"
									>
										The size of the font in points
									</b-tooltip>
									<b-input-group>
										<b-form-input
											v-model="fonts.fontSize"
											type="text"
										/>
									</b-input-group>
								</b-form-group>

								<b-form-group
									class="form-group"
									label-for="block-form1-name"
									label-cols-lg="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												Raw text
											</div>
											<div
												id="rawtext"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="rawtext"
										triggers="hover"
										placement="bottom"
									>
										The raw text
									</b-tooltip>
									<b-input-group>
										<b-form-input
											v-model="fonts.text"
											type="text"
										/>
									</b-input-group>
								</b-form-group>

								<b-form-group
									class="form-group"
									label-for="block-form1-name"
									label-cols-lg="4"
								>
									<template #label>
										<div class="d-flex flex-row">
											<div class="mr-2">
												Formatted text
											</div>
											<div
												id="formattedtext"
												class="tip"
											/>
										</div>
									</template>
									<b-tooltip
										target="formattedtext"
										triggers="hover"
										placement="bottom"
									>
										The text including line breaks
									</b-tooltip>
									<b-input-group>
										<b-form-input
											v-model="fonts.text_formatted"
											type="text"
										/>
									</b-input-group>
								</b-form-group>

								<b-form-group
									label-for="example-select"
								>
									<custom-select
										id="example-select"
										v-model="fonts.align"
										disabled
										class="mb-2"
										plain
									>
										<b-form-select-option :value="''">
											Please select an option
										</b-form-select-option>
										<b-form-select-option
											v-for="(ali,id) in alignment"
											:key="id"
											:value="ali"
										>
											{{ ali }}
										</b-form-select-option>
									</custom-select>
								</b-form-group>
							</div>
						</base-block>
					</b-tab>
				</b-tabs>
			</b-col>
		</b-row>
	</div>
</template>

<style lang="scss" scoped>
@import "../../../../assets/scss/oneui/tooltip";
.thumbnail{
	display:flex;
	justify-content:space-around;
	align-items:Center;
	background-color: #ccc;
	margin-top:10px;
}
.cameraCheckbox {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-left: 40px;
}
</style>
