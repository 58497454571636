import {
	CellComponent, ColumnComponent, RowComponent,
} from 'tabulator-tables';

export enum Borders {
	Top = 'top',
	Bottom = 'bottom',
	Left = 'left',
	Right = 'right'
}

export function drawBorder(cell: CellComponent, direction: string) {
	cell.getElement().classList.add(`selected-${direction}`);
}

export function removeBorder(cell: CellComponent, direction: string) {
	cell.getElement().classList.remove(`selected-${direction}`);
}

export function removeHorizontalBorders(cell: CellComponent) {
	removeBorder(
		cell,
		Borders.Top,
	);
	removeBorder(
		cell,
		Borders.Bottom,
	);
}

export function removeVerticalBorders(cell: CellComponent) {
	removeBorder(
		cell,
		Borders.Left,
	);
	removeBorder(
		cell,
		Borders.Right,
	);
}

export function drawAllBorders(cell: CellComponent) {
	drawBorder(
		cell,
		Borders.Top,
	);
	drawBorder(
		cell,
		Borders.Bottom,
	);
	drawBorder(
		cell,
		Borders.Left,
	);
	drawBorder(
		cell,
		Borders.Right,
	);
}

export function removeAllBorders(cell: CellComponent) {
	removeHorizontalBorders(cell);
	removeVerticalBorders(cell);
}

export function singleSelectCell(
	key: string,
	cell: CellComponent,
	rows: RowComponent[],
	cols: ColumnComponent[],
	editableCols: Set<string>,
): CellComponent | undefined {
	const table = cell.getTable();
	const currRow = cell.getRow();
	const nextRow = currRow.getNextRow();
	const prevRow = currRow.getPrevRow();
	const currCol = cell.getColumn();
	const nextCol = currCol.getNextColumn();
	const prevCol = currCol.getPrevColumn();

	let cellToSelect: CellComponent | undefined;

	switch (key) {
		case 'ArrowUp':
			if (prevRow) {
				table?.scrollToRow(
					prevRow,
					'nearest',
					false,
				);
				cellToSelect = prevRow.getCell(currCol);
			}
			break;
		case 'ArrowDown':
			if (nextRow) {
				table?.scrollToRow(
					nextRow,
					'nearest',
					false,
				);
				cellToSelect = nextRow.getCell(currCol);
			}
			break;
		case 'ArrowLeft':
			if (prevCol && editableCols.has(prevCol.getField())) {
				table?.scrollToColumn(
					prevCol,
					'left',
					false,
				);
				cellToSelect = currRow.getCell(prevCol);
			}
			break;
		case 'ArrowRight':
			if (nextCol && editableCols.has(nextCol.getField())) {
				table?.scrollToColumn(
					nextCol,
					'right',
					false,
				);
				cellToSelect = currRow.getCell(nextCol);
			}
			break;
		default:
			break;
	}

	if (cellToSelect) {
		rows.forEach((row) => {
			cols.forEach((col) => {
				removeAllBorders(row.getCell(col));
			});
		});

		drawAllBorders(cellToSelect);
	}
	return cellToSelect;
}

export function multiSelectCell(
	key: string,
	cell: CellComponent,
	rows: RowComponent[],
	cols: ColumnComponent[],
	editableCols: Set<string>,
): CellComponent | undefined {
	const table = cell.getTable();

	const rowsLength = rows.length;
	const colsLength = cols.length;

	const firstRow = rows[0];
	const lastRow = rows[rowsLength - 1];
	const currRow = cell.getRow();
	const nextRow = currRow.getNextRow();
	const prevRow = currRow.getPrevRow();

	const firstCol = cols[0];
	const lastCol = cols[colsLength - 1];
	const currCol = cell.getColumn();
	const nextCol = currCol.getNextColumn();
	const prevCol = currCol.getPrevColumn();

	let cellToSelect: CellComponent | undefined;
	switch (key) {
		case 'ArrowUp':
			if (prevRow) {
				if (rows[rowsLength - 2] === prevRow) {
					cols.forEach((col) => {
						const currCell = currRow.getCell(col);
						removeBorder(
							currCell,
							Borders.Bottom,
						);
						removeVerticalBorders(currCell);

						drawBorder(
							prevRow.getCell(col),
							Borders.Bottom,
						);
					});

					rows.pop();
				} else {
					cols.forEach((col) => {
						removeBorder(
							currRow.getCell(col),
							Borders.Top,
						);
						drawBorder(
							prevRow.getCell(col),
							Borders.Top,
						);
					});

					drawBorder(
						prevRow.getCell(firstCol),
						Borders.Left,
					);
					drawBorder(
						prevRow.getCell(lastCol),
						Borders.Right,
					);

					rows.unshift(prevRow);
				}
				table?.scrollToRow(
					prevRow,
					'nearest',
					false,
				);

				cellToSelect = prevRow.getCell(currCol);
			}
			break;
		case 'ArrowDown':
			if (nextRow) {
				if (rows[1] === nextRow) {
					cols.forEach((col) => {
						const currCell = currRow.getCell(col);
						removeBorder(
							currCell,
							Borders.Top,
						);
						removeVerticalBorders(currCell);

						drawBorder(
							nextRow.getCell(col),
							Borders.Top,
						);
					});
					rows.shift();
				} else {
					cols.forEach((col) => {
						removeBorder(
							currRow.getCell(col),
							Borders.Bottom,
						);
						drawBorder(
							nextRow.getCell(col),
							Borders.Bottom,
						);
					});

					drawBorder(
						nextRow.getCell(firstCol),
						Borders.Left,
					);
					drawBorder(
						nextRow.getCell(lastCol),
						Borders.Right,
					);

					rows.push(nextRow);
				}
				table?.scrollToRow(
					nextRow,
					'nearest',
					false,
				);
				cellToSelect = nextRow.getCell(currCol);
			}
			break;
		case 'ArrowLeft':
			if (prevCol && editableCols.has(prevCol.getField())) {
				if (cols[colsLength - 2] === prevCol) {
					rows.forEach((row) => {
						const currCell = row.getCell(currCol);
						removeBorder(
							currCell,
							Borders.Right,
						);
						removeHorizontalBorders(currCell);

						drawBorder(
							row.getCell(prevCol),
							Borders.Right,
						);
					});

					cols.pop();
				} else {
					rows.forEach((row) => {
						drawBorder(
							row.getCell(currCol),
							Borders.Right,
						);
						drawBorder(
							row.getCell(prevCol),
							Borders.Left,
						);
					});

					drawBorder(
						firstRow.getCell(prevCol),
						Borders.Top,
					);
					drawBorder(
						lastRow.getCell(prevCol),
						Borders.Bottom,
					);

					cols.unshift(prevCol);
				}
				table?.scrollToColumn(
					prevCol,
					'left',
					false,
				);
				cellToSelect = currRow.getCell(prevCol);
			}
			break;
		case 'ArrowRight':
			if (nextCol && editableCols.has(nextCol.getField())) {
				if (cols[1] === nextCol) {
					rows.forEach((row) => {
						const currCell = row.getCell(currCol);
						removeBorder(
							currCell,
							Borders.Left,
						);
						removeHorizontalBorders(currCell);

						drawBorder(
							row.getCell(nextCol),
							Borders.Left,
						);
					});

					cols.shift();
				} else {
					rows.forEach((row) => {
						removeBorder(
							row.getCell(currCol),
							Borders.Right,
						);
						drawBorder(
							row.getCell(nextCol),
							Borders.Right,
						);
					});

					drawBorder(
						firstRow.getCell(nextCol),
						Borders.Top,
					);
					drawBorder(
						lastRow.getCell(nextCol),
						Borders.Bottom,
					);

					cols.push(nextCol);
				}
				table?.scrollToColumn(
					nextCol,
					'right',
					false,
				);
				cellToSelect = currRow.getCell(nextCol);
			}
			break;
		default:
			break;
	}

	return cellToSelect;
}
