import {
	Vue, Component, Watch, Prop, toNative,
} from 'vue-facing-decorator';
import * as DB from 'interfaces/database';
import FlexGroupService from 'services/FlexGroupService';
import Template from './template.vue';

@Component({
	mixins: [Template],
})
class EditFlexGroupTable extends Vue {
	@Prop({
		type: String,
		required: true,
	})
	private readonly routeId!: string;

	private singleFlexGroup = {} as DB.FlexGroupModel;

	private loaded = false;

	private visible = false;

	private spinner = false;

	private index = 0;

	private isLoading = false;

	private pageOptions = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50];

	private get loggedIn(): boolean {
		return this.$auth0.isAuthenticated.value;
	}

	protected mounted(): void {
		this.getData();
	}

	@Watch('loggedIn')
	private async getData(): Promise<DB.FlexGroupModel | undefined> {
		if (this.loggedIn) {
			const parameter = new URLSearchParams({
				limit: '0',
			});
			this.isLoading = true;
			try {
				const flexGroupData = await FlexGroupService.get(
					this.routeId,
					parameter,
				);
				this.singleFlexGroup = flexGroupData;
				// eslint-disable-next-line consistent-return
				return this.singleFlexGroup;
			} catch (error: any) {
				this.$toastError(error.message);
			} finally {
				this.isLoading = false;
			}
		}
		return undefined;
	}

	protected editFlexGroup(): Promise<void> | undefined {
		if (this.loggedIn) {
			this.isLoading = true;
			FlexGroupService.update(
				this.routeId,
				{ ...this.singleFlexGroup },
			).then(() => {
				this.$toastSuccess('Flex Group Edited successfully');
				return undefined;
			})
				.finally(() => {
					this.isLoading = false;
				})
				.catch((error) => {
					this.$toastError(error.message);
				});
		}
		return undefined;
	}
}

export default toNative(EditFlexGroupTable);
