<template>
	<div>
		<loading-overlay
			:active="isLoading"
			:can-cancel="true"
		/>
		<div
			v-if="spinner"
			class="text-center"
		>
			<div
				class="spinner-grow text-primary m-5"
				role="status"
			>
				<span class="sr-only">Loading...</span>
			</div>
		</div>
		<base-block
			rounded
			title="Display Frame"
			header-bg
		>
			<template #options>
				<b-button
					type="submit"
					size="sm"
					variant="primary"
					@click="isHidden = !isHidden"
				>
					Add
				</b-button>
			</template>

			<b-col
				v-show="isHidden"
				lg="12"
			>
				<b-form-group
					label="Image"
					label-cols-lg="5"
				>
					<custom-file-input
						id="image"
						ref="image"
						:placeholder="placeHolders.thumbnailPlaceholder"
						@click.prevent="updateImage"
					/>
					<div
						v-if="imagePreview"
						class="thumbnail"
					>
						<img
							:src="imagePreview"
							alt=""
							height="100"
							width="100"
						>
						<div
							style="cursor:pointer"
							@click="removeImage"
						>
							&#10006; Remove
						</div>
					</div>
				</b-form-group>

				<b-form-group
					class="form-group"
					label="X-axis"
					label-for="block-form1-username"
					label-cols-lg="5"
				>
					<b-input-group>
						<b-form-input
							v-model="frame.frame_x"
							type="email"
						/>
						<template #append>
							<b-input-group-text
								class="input-group-text-alt"
							>
								<custom-checkbox
									id="checkbox-8"
									name="checkbox-1"
									:checked="frame.frame_x == null ? true : false"
									@change="()=> frame.frame_x = null "
								/>
								Null
							</b-input-group-text>
						</template>
					</b-input-group>
				</b-form-group>

				<b-form-group
					class="form-group"
					label="Y-axis"
					label-for="block-form1-username"
					label-cols-lg="5"
				>
					<b-input-group>
						<b-form-input
							v-model="frame.frame_y"
							type="email"
						/>
						<template #append>
							<b-input-group-text
								class="input-group-text-alt"
							>
								<custom-checkbox
									id="checkbox-7"
									name="checkbox-1"
									:checked="frame.frame_y == null ? true : false"
									@change="()=> frame.frame_y = null "
								/>
								Null
							</b-input-group-text>
						</template>
					</b-input-group>
				</b-form-group>

				<b-form-group
					class="form-group"
					label="Width"
					label-for="block-form1-username"
					label-cols-lg="5"
				>
					<b-input-group>
						<b-form-input
							v-model="frame.frame_width"
							type="email"
						/>
						<template #append>
							<b-input-group-text
								class="input-group-text-alt"
							>
								<custom-checkbox
									id="checkbox-6"
									name="checkbox-1"
									:checked="frame.frame_width == null ? true : false"
									@change="()=> frame.frame_width = null "
								/>
								Null
							</b-input-group-text>
						</template>
					</b-input-group>
				</b-form-group>

				<b-form-group
					class="form-group"
					label="Height"
					label-for="block-form1-username"
					label-cols-lg="5"
				>
					<b-input-group>
						<b-form-input
							v-model="frame.frame_height"
							type="email"
						/>
						<template #append>
							<b-input-group-text
								class="input-group-text-alt"
							>
								<custom-checkbox
									id="checkbox-5"
									name="checkbox-1"
									:checked="frame.frame_height == null ? true : false"
									@change="()=> frame.frame_height = null "
								/>
								Null
							</b-input-group-text>
						</template>
					</b-input-group>
				</b-form-group>

				<b-form-group
					label="Required"
					label-class="font-w600"
					label-cols-lg="5"
				>
					<custom-checkbox
						id="checkbox-4"
						:is-switch="true"
						size="lg"
						:checked="frame.required === 1 ? true : false"
						@change="frame.required = $event"
					/>
				</b-form-group>
				<b-form-group
					label="Show on top?"
					label-class="font-w600"
					label-cols-lg="5"
				>
					<custom-checkbox
						id="checkbox-3"
						:is-switch="true"
						size="lg"
						:checked="frame.overpage === 1 ? true : false"
						@change="frame.overpage = $event"
					/>
				</b-form-group>
				<b-button
					type="submit"
					variant="primary"
					@click="handleClick"
				>
					{{ frameDataExist ? 'Edit' : 'Add' }}
				</b-button>
			</b-col>
		</base-block>
	</div>
</template>

<style scoped>
.thumbnail{
	display:flex;
	justify-content:space-around;
	align-items:Center;
	background-color: #ccc;
	margin-top:10px;
}
</style>
