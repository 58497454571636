import { BadgeImageModel } from 'interfaces/database';
import ApiService from 'services/ApiService';

class BadgeImageService extends ApiService<BadgeImageModel> {
	constructor() {
		super('badgeimage');
	}
}

export default new BadgeImageService();
