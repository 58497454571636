<template>
	<base-block
		rounded
		title="Handling price"
	>
		<loading-overlay
			:active="isLoading"
			:can-cancel="true"
		/>
		<template #options>
			<div class="block-options-item" />
		</template>
		<b-table-simple
			responsive
			table-class="table-vcenter"
		>
			<b-thead head-variant="dark">
				<b-tr>
					<b-th>Currency</b-th>
					<b-th>Price(in cents)</b-th>
					<b-th
						class="text-center"
						style="width: 100px;"
					>
						Scope
					</b-th>
					<b-th>#</b-th>
				</b-tr>
			</b-thead>
			<b-tbody
				v-for="(item, index) in HandlingPrice"
				:key="index"
			>
				<handlingRow
					:item="item"
					@removeItem="removePrice"
				/>
			</b-tbody>
			<b-tfoot v-show="footer">
				<b-tr>
					<b-td>
						<b-form-group>
							<custom-select
								id="example-select"
								v-model="currency"
								plain
							>
								<b-form-select-option
									v-for="(currency,index) in currentCurrency"
									:key="index"
									:value="currency.currency"
								>
									{{ currency.currency }}
								</b-form-select-option>
							</custom-select>
						</b-form-group>
					</b-td>
					<b-td>
						<b-form-group
							label-for="example-text-input"
						>
							<b-form-input
								placeholder=""
								:value="price"
								@change="price = $event"
							/>
						</b-form-group>
					</b-td>
					<b-td>
						<b-form-group>
							<custom-select
								id="example-select"
								v-model="scope"
							>
								<b-form-select-option
									v-for="(scope,index) in arrScopes"
									:key="index"
									:value="scope"
								>
									{{ scope }}
								</b-form-select-option>
							</custom-select>
						</b-form-group>
					</b-td>
					<b-td>
						<b-btn-group class="mb-4">
							<b-button
								size="sm"
								variant="alt-primary"
								@click="add"
							>
								<i class="fa fa-fw fa-save" />
							</b-button>
						</b-btn-group>
					</b-td>
				</b-tr>
			</b-tfoot>
		</b-table-simple>
	</base-block>
</template>
