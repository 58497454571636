<template>
	<div
		class="ml-5 mt-4"
		style="width: 95%; height: 100%;"
	>
		<loading-overlay
			:active="isLoading"
			:can-cancel="true"
		/>
		<b-row>
			<b-col
				md="12"
				lg="12"
				sm="12"
			>
				<base-block
					title="Photoframe properties"
					header-bg
				>
					<div
						v-if="spinner"
						class="text-center"
					>
						<div
							class="spinner-grow text-primary m-5"
							role="status"
						>
							<span class="sr-only">Loading...</span>
						</div>
					</div>
					<div
						v-else
						class="d-block text-center"
					>
						<b-form-group
							class="form-group"
							label="name"
							label-for="block-form1-name"
							label-cols-lg="4"
						>
							<b-input-group>
								<b-form-input
									v-model="singlePhotoframe.name"
									type="text"
								/>
								<template #append>
									<b-input-group-text
										class="input-group-text-alt"
										style="cursor:pointer"
									>
										<custom-checkbox
											id="checkbox-name-3"
											name="checkbox-1"
											:checked="singlePhotoframe.name == null ? true : false"
											unchecked-value="not_accepted"
											@change="setPropertyNull('name')"
										/>
										Null
									</b-input-group-text>
								</template>
							</b-input-group>
						</b-form-group>
						<b-form-group
							label="source"
							label-for="thumbnail"
							label-cols-lg="4"
						>
							<custom-file-input
								id="thumbnail"
								ref="logodesktop"
								:placeholder="placeHolders.icon"
								@click.prevent="updateIcon"
							/>

							<div
								v-if="singlePhotoframe.source"
								class="thumbnail"
							>
								<img
									:src="singlePhotoframe.source"
									alt=""
									srcset=""
									height="100"
									width="100"
								>
								<div
									style="cursor:pointer"
									@click="removeIcon"
								>
									&#10006; Remove
								</div>
							</div>
						</b-form-group>
						<b-form-group
							class="form-group"
							label="width"
							label-for="block-form1-width"
							label-cols-lg="4"
						>
							<b-input-group>
								<b-form-input
									v-model="singlePhotoframe.width"
									type="text"
								/>
								<template #append>
									<b-input-group-text
										class="input-group-text-alt"
										style="cursor:pointer"
									>
										<custom-checkbox
											id="checkbox-width-3"
											name="checkbox-1"
											:checked="isPropertyNull('width')"
											unchecked-value="not_accepted"
											@change="setPropertyNull('width')"
										/>
										Null
									</b-input-group-text>
								</template>
							</b-input-group>
						</b-form-group>
						<b-form-group
							class="form-group"
							label="height"
							label-for="block-form1-height"
							label-cols-lg="4"
						>
							<b-input-group>
								<b-form-input
									v-model="singlePhotoframe.height"
									type="text"
								/>
								<template #append>
									<b-input-group-text
										class="input-group-text-alt"
										style="cursor:pointer"
									>
										<custom-checkbox
											id="checkbox-height-3"
											name="checkbox-1"
											:checked="isPropertyNull('height')"
											unchecked-value="not_accepted"
											@change="setPropertyNull('height')"
										/>
										Null
									</b-input-group-text>
								</template>
							</b-input-group>
						</b-form-group>
						<b-form-group
							class="form-group"
							label="borderwidth_top"
							label-for="block-form1-borderwidth_top"
							label-cols-lg="4"
						>
							<b-input-group>
								<b-form-input
									v-model="singlePhotoframe.borderwidth_top"
									type="text"
								/>
								<template #append>
									<b-input-group-text
										class="input-group-text-alt"
										style="cursor:pointer"
									>
										<custom-checkbox
											id="checkbox-borderwidth_top-3"
											name="checkbox-1"
											:checked="isPropertyNull('borderwidth_top')"
											unchecked-value="not_accepted"
											@change="setPropertyNull('borderwidth_top')"
										/>
										Null
									</b-input-group-text>
								</template>
							</b-input-group>
						</b-form-group>
						<b-form-group
							class="form-group"
							label="borderwidth_right"
							label-for="block-form1-borderwidth_right"
							label-cols-lg="4"
						>
							<b-input-group>
								<b-form-input
									v-model="singlePhotoframe.borderwidth_right"
									type="text"
								/>
								<template #append>
									<b-input-group-text
										class="input-group-text-alt"
										style="cursor:pointer"
									>
										<custom-checkbox
											id="checkbox-borderwidth_right-3"
											name="checkbox-1"
											:checked="isPropertyNull('borderwidth_right')"
											unchecked-value="not_accepted"
											@change="setPropertyNull('borderwidth_right')"
										/>
										Null
									</b-input-group-text>
								</template>
							</b-input-group>
						</b-form-group>
						<b-form-group
							class="form-group"
							label="borderwidth_bottom"
							label-for="block-form1-borderwidth_bottom"
							label-cols-lg="4"
						>
							<b-input-group>
								<b-form-input
									v-model="singlePhotoframe.borderwidth_bottom"
									type="text"
								/>
								<template #append>
									<b-input-group-text
										class="input-group-text-alt"
										style="cursor:pointer"
									>
										<custom-checkbox
											id="checkbox-borderwidth_bottom-3"
											name="checkbox-1"
											:checked="isPropertyNull('borderwidth_bottom')"
											unchecked-value="not_accepted"
											@change="setPropertyNull('borderwidth_bottom')"
										/>
										Null
									</b-input-group-text>
								</template>
							</b-input-group>
						</b-form-group>
						<b-form-group
							class="form-group"
							label="borderwidth_left"
							label-for="block-form1-borderwidth_left"
							label-cols-lg="4"
						>
							<b-input-group>
								<b-form-input
									v-model="singlePhotoframe.borderwidth_left"
									type="text"
								/>
								<template #append>
									<b-input-group-text
										class="input-group-text-alt"
										style="cursor:pointer"
									>
										<custom-checkbox
											id="checkbox-borderwidth_left-3"
											name="checkbox-1"
											:checked="isPropertyNull('borderwidth_left')"
											unchecked-value="not_accepted"
											@change="setPropertyNull('borderwidth_left')"
										/>
										Null
									</b-input-group-text>
								</template>
							</b-input-group>
						</b-form-group>
						<b-form-group
							class="form-group"
							label="scaling"
							label-for="block-form1-scaling"
							label-cols-lg="4"
						>
							<b-input-group>
								<b-form-input
									v-model="singlePhotoframe.scaling"
									type="text"
								/>
								<template #append>
									<b-input-group-text
										class="input-group-text-alt"
										style="cursor:pointer"
									>
										<custom-checkbox
											id="checkbox-scaling-3"
											name="checkbox-1"
											:checked="isPropertyNull('scaling')"
											unchecked-value="not_accepted"
											@change="setPropertyNull('scaling')"
										/>
										Null
									</b-input-group-text>
								</template>
							</b-input-group>
						</b-form-group>

						<b-form-group
							label="fixedratio"
							label-class="font-w600"
							label-cols-lg="4"
						>
							<custom-checkbox
								id="checkbox-9"
								:is-switch="true"
								size="lg"
								:checked="singlePhotoframe.fixedratio === 1 ? true : false"
								@change="singlePhotoframe.fixedratio = $event"
							/>
						</b-form-group>
					</div>
				</base-block>
			</b-col>
		</b-row>
	</div>
</template>

<style lang="scss" scoped>
@import "../../../../assets/scss/oneui/tooltip";
.thumbnail{
	display:flex;
	justify-content:space-around;
	align-items:Center;
	background-color: #ccc;
	margin-top:10px;
}
.cameraCheckbox {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-left: 40px;
}
</style>
