<template>
	<div
		class="ml-5 mt-4"
		style="width: 95%; height: 100%;"
	>
		<b-modal
			id="bv-modal"
			ref="item-modal"
			hide-footer
		>
			<template #modal-title>
				Add Sub Item
			</template>
			<div
				v-if="spinner"
				class="text-center"
			>
				<div
					class="spinner-grow text-primary m-5"
					role="status"
				>
					<span class="sr-only">Loading...</span>
				</div>
			</div>
			<div v-else>
				<b-form-group
					class="form-group"
					label="name "
					label-for="block-form1-name"
					label-cols-lg="3"
				>
					<div
						class="control"
					>
						<b-form-input
							v-model="itemName"
							type="text"
						/>
					</div>
				</b-form-group>
				<b-button
					class="mt-3"
					block
					@click="addSubItem"
				>
					Save
				</b-button>
			</div>
		</b-modal>
		<b-row>
			<b-col
				md="12"
				lg="12"
				sm="12"
			>
				<b-tabs
					card
					content-class="mt-3"
					fill
				>
					<b-tab
						title="Properties"
						active
					>
						<base-block
							title="Lister Item properties"
							header-bg
						>
							<div
								v-if="spinner"
								class="text-center"
							>
								<div
									class="spinner-grow text-primary m-5"
									role="status"
								>
									<span class="sr-only">Loading...</span>
								</div>
							</div>
							<div
								v-else
								class="d-block text-center"
							>
								<b-form-group
									label="Parent ID:"
									label-for="example-select1"
									label-cols-lg="5"
								>
									<b-input-group>
										<custom-select
											id="example-select8"
											v-model="singleProductCategory.parentid"
											plain
										>
											<b-form-select-option :value="null">
												Please select an Option
											</b-form-select-option>
											<b-form-select-option
												v-for="(pdcat,id) in productcategory"
												:key="id"
												:value="pdcat.id"
											>
												{{ pdcat.id }} | {{ pdcat.name }}
											</b-form-select-option>
										</custom-select>
										<template #append>
											<b-input-group-text
												class="input-group-text-alt"
												style="cursor:pointer"
											>
												<custom-checkbox
													id="checkbox-5"
													name="checkbox-6"
													:checked="singleProductCategory.parentid == null ? true : false"
													unchecked-value="not_accepted"
													@change="()=> singleProductCategory.parentid = null "
												/>
												Null
											</b-input-group-text>
										</template>
									</b-input-group>
								</b-form-group>

								<b-form-group
									label="PDP ID:"
									label-for="example-select"
									label-cols-lg="5"
								>
									<b-input-group>
										<custom-select
											id="example-select"
											v-model="singleProductCategory.pdpid"
											plain
										>
											<b-form-select-option :value="null">
												Please select an Option
											</b-form-select-option>
											<b-form-select-option
												v-for="(pd,id) in pdp "
												:key="id"
												:value="pd.id"
											>
												{{ pd.id }} | {{ pd.name }}
											</b-form-select-option>
										</custom-select>
										<template #append>
											<b-input-group-text
												class="input-group-text-alt"
												style="cursor:pointer"
											>
												<custom-checkbox
													id="checkbox-4"
													name="checkbox-2"
													:checked="singleProductCategory.pdpid == null ? true : false"
													unchecked-value="not_accepted"
													@change="()=> singleProductCategory.pdpid = null "
												/>
												Null
											</b-input-group-text>
										</template>
									</b-input-group>
								</b-form-group>
								<b-form-group
									class="form-group"
									label="Name: "
									label-for="block-form1-name"
									label-cols-lg="5"
								>
									<b-form-input
										v-model="singleProductCategory.name"
										type="text"
									/>
								</b-form-group>

								<b-form-group
									label="Auto forward?"
									label-class="font-w600"
									label-cols-lg="5"
								>
									<custom-checkbox
										id="checkbox-3"
										class="cameraCheckbox"
										:is-switch="true"
										size="lg"
										:checked="singleProductCategory.autoforward === 1 ? true : false"
										@change="singleProductCategory.autoforward = $event"
									/>
								</b-form-group>

								<b-form-group
									label="Image:"
									label-cols-lg="5"
								>
									<custom-file-input
										id="thumbnail"
										ref="thumbnail"
										:placeholder="placeHolders.thumbnailPlaceholder"
										@click.prevent="updateThumbnailPreview"
									/>

									<div
										v-if="singleProductCategory.thumbnail"
										class="thumbnail"
									>
										<img
											:src="singleProductCategory.thumbnail"
											alt=""
											srcset=""
											height="100"
											width="100"
										>
										<div
											style="cursor:pointer"
											@click="removeThumbnail"
										>
											&#10006; Remove
										</div>
									</div>
								</b-form-group>
							</div>
						</base-block>
					</b-tab>
					<b-tab
						title="Sub Items"
						@click="() => subcategoryDisplay = true"
					>
						<base-block
							title="Sub Items"
							header-bg
						>
							<b-row
								class="d-flex justify-content-end"
							>
								<b-col
									md="6"
									class="d-flex justify-content-end mb-2"
								>
									<router-link
										to="#"
										@click.native="showItemModal"
									>
										Add Sub Item
									</router-link>
								</b-col>
							</b-row>
							<ProductCategoriesTable
								v-if="subcategoryDisplay"
								:subcategory="subcategoryDisplay"
							/>
						</base-block>
					</b-tab>
					<b-tab
						title="Offerings"
						@click="() => offeringDisplay = true"
					>
						<base-block
							title="Offerings"
							header-bg
						>
							<div
								ref="offeringTable"
							/>
						</base-block>
					</b-tab>
				</b-tabs>
			</b-col>
		</b-row>
	</div>
</template>

<style lang="scss" scoped>
@import "../../../assets/scss/custom/errors";

.thumbnail{
	display:flex;
	justify-content:space-around;
	align-items:Center;
	background-color: #ccc;
	margin-top:10px;
}
.cameraCheckbox {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-left: 40px;
}

</style>
