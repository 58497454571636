import { Vue, Component, toNative } from 'vue-facing-decorator';
import EditApiPermissionTable from 'components/ApiPermissionsTable/EditApiPermission/';
import Template from './template.vue';

@Component({
	components: {
		EditApiPermissionTable,
	},
	mixins: [Template],
})
class EditApiPermission extends Vue {
	protected showModal(): void {
		this.$root?.$emit(
			'bv::show::modal',
			'editapipermission-modal',
			'#showBtn',
		);
	}
}

export default toNative(EditApiPermission);
