<template>
	<div class="bg-body-light">
		<div class="container-fluid pt-3 pb-3 ml-3 mr-3">
			<slot>
				<div
					v-if="title || $slots.extra"
					class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center"
				>
					<h1
						v-if="title"
						class="flex-sm-fill h3 my-2"
					>
						{{ title }}
						<small
							v-if="subtitle"
							class="d-block d-sm-inline-block mt-2 mt-sm-0 font-size-base font-w400 text-muted"
						>{{ subtitle }}</small>
					</h1>
					<div
						v-if="$slots.extra"
						class="flex-sm-00-auto ml-sm-3"
					>
						<slot name="extra" />
					</div>
				</div>
			</slot>
		</div>
	</div>
</template>

<script>
export default {
	name: 'BasePageHeading',
	props: {
		title: {
			type: String,
			description: 'The title of page heading section',
		},
		subtitle: {
			type: String,
			description: 'The subtitle of page heading section',
		},
	},
};
</script>
