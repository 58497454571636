import { MatchedOffering } from 'interfaces/app';
import ApiService from 'services/ApiService';

class BulkOfferingService extends ApiService<MatchedOffering> {
	constructor() {
		super('bulkOffering');
	}
}

export default new BulkOfferingService();
